import {
  Applicant,
  ApplicationAssignedUser,
  ApplicationStage,
  ApplicationStatus,
  ApplicationWarningKeys,
  ClosestUncompletedTask,
  CustomerType,
  PriorityType,
  InsuranceQuote,
  PropertyType,
  ApplicationPurposeType,
  MortgageStatus,
  MortgageState,
  ServicingType,
} from '..';

export interface PropertyAddressesObject {
  id: string;
  address: string;
  type: PropertyType;
  addressShort: string;
}

export interface PipelineApplicationMortgage {
  loanAmount?: number | null;
  purpose: string;
  closingDate: string;
  deadlineToAcceptDate?: string | null;
  cofDate?: string | null;
  loanNumber?: string | null;
  status?: MortgageStatus[] | null;
  state?: MortgageState | null;
  stateChangeComment?: string | null;
  stateChangeDate?: Date | null;
  propertyId?: string | null;
  loanType?: string | null;
  propertyAddress?: PropertyAddressesObject | undefined;
}

export interface PipelineApplication {
  requestedMortgages: PipelineApplicationMortgage[];
  applicants: Applicant[];
  applicationAssignedUser: ApplicationAssignedUser[] | null;
  applicationDate: string;
  code: string;
  createdAt: string;
  id: string;
  name: string;
  primaryApplicantName?: string | undefined;
  primaryApplicantCustomerType?: CustomerType | undefined;
  otherApplicants?: Applicant[];
  currentStage?: ApplicationStage | undefined;
  previousStage?: ApplicationStage | undefined;
  priority?: PriorityType | null;
  purpose?: ApplicationPurposeType | null;
  status?: string | null;
  updatedAt: string;
  applicationAdvancedProductIds?: string[] | null;
  createdBy?: PipelineApplicationUser | null;
  dateLabel?: string | undefined;
  declineComment?: string;
  declineReasonId?: string;
  archived: boolean;
  locked: boolean;
  applicationStatus: ApplicationStatus;
  source: string;
  properties: PropertyAddressesObject[];
  primaryPropertyAddress?: PropertyAddressesObject | undefined;
  externalPropertiesAddresses?: PropertyAddressesObject[] | undefined;
  warnings: ApplicationWarningKeys[];
  canEdit?: boolean;
  externalApplicationId: string | null;
  latestInsuranceQuote: InsuranceQuote | null;
  /**
   * computed on FE only
   *
   * marked as undefined to be ignored by BE data and DTO validation
   */
  closestUncompletedTask?: ClosestUncompletedTask | null;
  dueDate: string | null;
  approval?: boolean | null | undefined;
  applicationLoanNumber?: string | null;
  isServicing: boolean;
  servicingType?: ServicingType[];
  effectiveDate?: string;
  applicationSourceForOther?: string | null;
}

export interface PipelineApplicationUser {
  id: string;
}
