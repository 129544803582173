import { ApplicationSource } from './application';
import { AgentContactType, AgentContact } from './enums';

export interface Lawyer {
  id: string;
  tenantId: string;
  firstName: string;
  lastName: string;
  emailAddress?: string | null;
  cellPhoneNumber?: string | null;
  workPhoneNumber?: string | null;
  homePhoneNumber?: string | null;
  street: string;
  city: string;
  province: string;
  postalCode: string;
  company: string;
  phoneNumber: string;
  faxNumber?: string | null;
  firmCode?: string | null;
  source?: string | null;
  notes?: string | null;
  riskScore?: number | null;
  preferredContactMethod?: AgentContactType | null;
  contacts: AgentContact;
  externalSrc?: string | null;
  externalRef?: string | null;
  externalId?: { [key in ApplicationSource]?: string | null } | null;
  approved?: boolean | null;
  verified?: boolean | null;
  verifiedBy?: string | null;
}

export interface LawyerV2
  extends Omit<
    Lawyer,
    | 'contacts'
    | 'company'
    | 'externalId'
    | 'city'
    | 'province'
    | 'postalCode'
    | 'phoneNumber'
    | 'street'
  > {
  company: string | null;
  city: string | null;
  province: string | null;
  postalCode: string | null;
  phoneNumber: string | null;
  street: string | null;
}
