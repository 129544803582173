import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, combineLatest, map } from 'rxjs';
import { DocumentTemplate } from 'src/app/features/manager-portal/documents-management/model';
import { ApplicantsState } from '../../../../portal/applicants.state';
import { MortgagesServices } from '../../../../portal/mortgages.services';
import { ConditionsDisplayService } from '../../../../shared/services/conditions-display.service';
import {
  BaseDocumentContext,
  EQDocumentContext,
  GenerateDocumentsPackageServiceBase,
} from '../model';
import { AmortizationScheduleService } from './amortization-schedule.service';
import { DefaultPackageService } from './default.package.service';
import { StandardRatesState } from 'src/app/portal/standard-rates.state';

@Injectable()
export class EQPackageService
  extends DefaultPackageService
  implements GenerateDocumentsPackageServiceBase
{
  constructor(
    amortizationScheduleService: AmortizationScheduleService,
    applicantsState: ApplicantsState,
    conditionDisplayService: ConditionsDisplayService,
    mortgagesServices: MortgagesServices,
    store: Store,
  ) {
    super(
      amortizationScheduleService,
      applicantsState,
      conditionDisplayService,
      mortgagesServices,
      store,
    );
  }

  getContext$(documentTemplate: DocumentTemplate): Observable<EQDocumentContext> {
    return combineLatest([super.getContext$(documentTemplate)]).pipe(
      map(([defaultContext]) => {
        return this.getEQContext(defaultContext);
      }),
    );
  }

  private getEQContext(defaultContext: BaseDocumentContext): EQDocumentContext {
    const totalRegularPayment =
      (defaultContext.mortgage.monthlyPayment ?? 0) +
      (defaultContext.primaryProperty?.annualTaxes ?? 0);

    return {
      ...defaultContext,
      primeRate: this.getPrimeRate(),
      computed: {
        totalRegularPayment: totalRegularPayment,
      },
    };
  }

  private getPrimeRate(): number {
    return this.store.selectSnapshot(StandardRatesState.standardRates).primeRate ?? 0;
  }
}
