import { MortgageIncrement } from '@fundmoreai/models';
import { injectable } from 'tsyringe';

export interface IncrementCalculationInput {
  increments?: Pick<MortgageIncrement, 'amount' | 'category' | 'isChecked'>[] | null;
}

@injectable()
export class IncrementCalculationModel implements IncrementCalculationInput {
  increments?: Pick<MortgageIncrement, 'amount' | 'category' | 'isChecked'>[] | null;
  maxStandardIncrement: number | undefined;
}
