export interface FCTProduct {
  NVIP_IAVM?: NVIP_IAVM;
  NVIP_Flex?: NVIP_Flex;
}

export interface NVIP_IAVM {
  InsuredValue: number;
  InsuredDate: string;
  PDF?: string; // base64 encoded
  pdf?: string; // base64 encoded. Sometimes FCT sends this instead of PDF
}

export interface NVIP_Flex {
  InsuredValue: number;
  InsuredDate: string;
  PDF?: string; // base64 encoded
  pdf?: string; // base64 encoded. Sometimes FCT sends this instead of PDF
}
