export interface FctMMSSendDocumentMessage {
  mmsRequestId: string;
  applicationId: string;
  applicationDocumentId: string;
  mmsDocumentId: string;
  mmsDocumentType: number;
  documentName: string;
  userId: string;
}

export interface MMSDocumentConfig {
  id: string;
  documentType: number;
  documentEnglishName: string;
  documentFrenchName?: string | null;
}
