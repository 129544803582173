import { inject, injectable } from 'tsyringe';
import {
  ApplicationAggregatesCalculationModel,
  ComputedApplicationAggregate,
} from '../models/application-aggregates.model';
import {
  AggregatesLiabilityInput,
  AggregatesRequestedMortgageInput,
  ApplicationAggregateComputationInput,
  LoanType,
  NonMortgageLiabilitiesComputationInput,
  PayoffPaydownType,
  SecurityType,
  TotalConnectionInput,
  TotalRetailAggregatesInput,
} from '@fundmoreai/models';
import { TYPES } from '../container/types';
import { ILiabilityIsLoanCalculator } from './liability-is-loan.calculator';

interface ComputationResult {
  total: number | null;
  inputs: ApplicationAggregateComputationInput;
}

export interface IApplicationAggregatesCalculator {
  getApplicationAggregate(): ComputedApplicationAggregate;
}

@injectable()
export class ApplicationAggregatesCalculator implements IApplicationAggregatesCalculator {
  private liabilities: AggregatesLiabilityInput[];
  private requestedMortgages: AggregatesRequestedMortgageInput[];
  private businessBankingRelationCurrent: number | undefined;
  private businessBankingRelationFuture: number | undefined;

  private applicationAggregate: ComputedApplicationAggregate;

  constructor(
    @inject(ApplicationAggregatesCalculationModel)
    applicationAggregatesCalculationModel: ApplicationAggregatesCalculationModel,
    @inject(TYPES.ILiabilityIsLoanCalculator)
    public liabilityIsLoanCalculator: ILiabilityIsLoanCalculator,
  ) {
    this.liabilities = applicationAggregatesCalculationModel.liabilities;
    this.requestedMortgages = applicationAggregatesCalculationModel.requestedMortgages;
    this.businessBankingRelationCurrent =
      applicationAggregatesCalculationModel.businessBankingRelationCurrent;
    this.businessBankingRelationFuture =
      applicationAggregatesCalculationModel.businessBankingRelationFuture;
  }

  private getLiabilityAmount(liability: AggregatesLiabilityInput) {
    if (this.liabilityIsLoanCalculator.isLiabilityLoan(liability)) {
      return liability.balance;
    }
    return liability.value;
  }

  #computeLiabilities(liabilities: AggregatesLiabilityInput[]): number | null {
    if (liabilities.length === 0) {
      return null;
    }
    const total = liabilities.reduce(
      (sum, liability) => sum + (this.getLiabilityAmount(liability) ?? 0),
      0,
    );
    const roundedTotal = Math.round(total * 100) / 100;
    return roundedTotal;
  }

  private computeMortgageSecuredCurrent(): ComputationResult | undefined {
    const mortgageSecuredLiability = this.liabilities.filter(
      (l) => l.securityType === SecurityType.MORTGAGE_SECURED,
    );
    return {
      total: this.#computeLiabilities(mortgageSecuredLiability),
      inputs: { liabilities: mortgageSecuredLiability },
    };
  }

  private computeMortgageSecuredFuture(): ComputationResult | undefined {
    const liabilities = this.liabilities.filter(
      (l) =>
        l.securityType === SecurityType.MORTGAGE_SECURED &&
        (!l.payoffPaydown || l.payoffPaydown === PayoffPaydownType.NONE),
    );
    const computedLiabilitiesTotal = this.#computeLiabilities(liabilities);
    const mortgageSecuredMortgage = this.requestedMortgages.filter(
      (m) => m.loanType !== LoanType.BRIDGE,
    );
    const futureMortgageSecuredTotal = mortgageSecuredMortgage.reduce(
      (sum, mortgage) => sum + (mortgage.totalLoanAmount ?? 0),
      0,
    );

    const total = futureMortgageSecuredTotal + (computedLiabilitiesTotal ?? 0);
    const roundedTotal = Math.round(total * 100) / 100;
    return {
      total: roundedTotal,
      inputs: {
        liabilities,
        requestedMortgages: mortgageSecuredMortgage,
      },
    };
  }

  private computeBridgeSecuredCurrent(): ComputationResult | undefined {
    // bridge secured liabilities will be empty
    return { total: null, inputs: { liabilities: undefined } };
  }

  private computeBridgeSecuredFuture(): ComputationResult | undefined {
    const bridgeSecuredMortgage = this.requestedMortgages.filter(
      (m) => m.loanType === LoanType.BRIDGE,
    );
    const total = bridgeSecuredMortgage.reduce(
      (sum, mortgage) => sum + (mortgage.totalLoanAmount ?? 0),
      0,
    );
    const roundedTotal = Math.round(total * 100) / 100;
    return {
      total: roundedTotal,
      inputs: { requestedMortgages: bridgeSecuredMortgage },
    };
  }

  private computeNonMortgageCashSecuredCurrent(): ComputationResult | undefined {
    const cashSecuredLiabilities = this.liabilities.filter(
      (l) => l.securityType === SecurityType.CASH_SECURED,
    );
    const total = this.#computeLiabilities(cashSecuredLiabilities);
    return { inputs: { liabilities: cashSecuredLiabilities }, total };
  }

  private computeNonMortgageCashSecuredFuture(): ComputationResult | undefined {
    const cashSecuredLiabilities = this.liabilities.filter(
      (l) =>
        l.securityType === SecurityType.CASH_SECURED &&
        (!l.payoffPaydown || l.payoffPaydown === PayoffPaydownType.NONE),
    );
    const total = this.#computeLiabilities(cashSecuredLiabilities);

    return { inputs: { liabilities: cashSecuredLiabilities }, total };
  }

  private computeNonMortgageSecuredCurrent(): ComputationResult | undefined {
    const nonMortgageSecuredLiabilities = this.liabilities.filter(
      (liability) => liability.securityType === SecurityType.SECURED,
    );
    const total = this.#computeLiabilities(nonMortgageSecuredLiabilities);
    return { inputs: { liabilities: nonMortgageSecuredLiabilities }, total };
  }

  private computeNonMortgageSecuredFuture(): ComputationResult | undefined {
    const nonMortgageSecuredLiabilities = this.liabilities.filter(
      (l) =>
        l.securityType === SecurityType.SECURED &&
        (!l.payoffPaydown || l.payoffPaydown === PayoffPaydownType.NONE),
    );
    const total = this.#computeLiabilities(nonMortgageSecuredLiabilities);
    return { inputs: { liabilities: nonMortgageSecuredLiabilities }, total };
  }

  private computeNonMortgageUnsecuredCurrent(): ComputationResult | undefined {
    const unsecuredLiabilities = this.liabilities.filter(
      (liability) => liability.securityType === SecurityType.UNSECURED,
    );
    const total = this.#computeLiabilities(unsecuredLiabilities);
    return { total, inputs: { liabilities: unsecuredLiabilities } };
  }

  private computeNonMortgageUnsecuredFuture(): ComputationResult | undefined {
    const unsecuredLiabilities = this.liabilities.filter(
      (l) =>
        l.securityType === SecurityType.UNSECURED &&
        (!l.payoffPaydown || l.payoffPaydown === PayoffPaydownType.NONE),
    );
    const total = this.#computeLiabilities(unsecuredLiabilities);
    return { total, inputs: { liabilities: unsecuredLiabilities } };
  }

  private computeApplicationAggregate(): ComputedApplicationAggregate {
    const computedMortgageSecuredCurrent = this.computeMortgageSecuredCurrent();
    const computedMortgageSecuredFuture = this.computeMortgageSecuredFuture();
    const computedBridgeSecuredCurrent = this.computeBridgeSecuredCurrent();
    const computedBridgeSecuredFuture = this.computeBridgeSecuredFuture();
    const computedNonMortgageCashSecuredCurrent = this.computeNonMortgageCashSecuredCurrent();
    const computedNonMortgageCashSecuredFuture = this.computeNonMortgageCashSecuredFuture();
    const computedNonMortgageSecuredCurrent = this.computeNonMortgageSecuredCurrent();
    const computedNonMortgageSecuredFuture = this.computeNonMortgageSecuredFuture();
    const computedNonMortgageUnsecuredCurrent = this.computeNonMortgageUnsecuredCurrent();
    const computedNonMortgageUnsecuredFuture = this.computeNonMortgageUnsecuredFuture();

    const computedNonMortgageLiabilitiesCurrentTotal =
      (computedNonMortgageCashSecuredCurrent?.total ?? 0) +
      (computedNonMortgageSecuredCurrent?.total ?? 0) +
      (computedNonMortgageUnsecuredCurrent?.total ?? 0);
    const computedNonMortgageLiabilitiesCurrentTotalRounded =
      Math.round(computedNonMortgageLiabilitiesCurrentTotal * 100) / 100;
    const nonMortgageLiabilitiesCurrentInput: NonMortgageLiabilitiesComputationInput = {
      nonMortgageCashSecured: computedNonMortgageCashSecuredCurrent?.total,
      nonMortgageSecured: computedNonMortgageSecuredCurrent?.total,
      nonMortgageUnsecured: computedNonMortgageUnsecuredCurrent?.total,
    };

    const computedNonMortgageLiabilitiesFutureTotal =
      (computedNonMortgageCashSecuredFuture?.total ?? 0) +
      (computedNonMortgageSecuredFuture?.total ?? 0) +
      (computedNonMortgageUnsecuredFuture?.total ?? 0);
    const computedNonMortgageLiabilitiesFutureTotalRounded =
      Math.round(computedNonMortgageLiabilitiesFutureTotal * 100) / 100;
    const nonMortgageLiabilitiesFutureInput: NonMortgageLiabilitiesComputationInput = {
      nonMortgageCashSecured: computedNonMortgageCashSecuredFuture?.total,
      nonMortgageSecured: computedNonMortgageSecuredFuture?.total,
      nonMortgageUnsecured: computedNonMortgageUnsecuredFuture?.total,
    };

    const totalRetailAggregatesCurrent =
      (computedMortgageSecuredCurrent?.total ?? 0) +
      (computedBridgeSecuredCurrent?.total ?? 0) +
      computedNonMortgageLiabilitiesCurrentTotalRounded;
    const totalRetailAggregatesCurrentRounded =
      Math.round(totalRetailAggregatesCurrent * 100) / 100;
    const totalRetailAggregatesCurrentInput: TotalRetailAggregatesInput = {
      mortgageSecured: computedMortgageSecuredCurrent?.total,
      bridgeSecured: computedBridgeSecuredCurrent?.total,
      nonMortgageLiabilities: computedNonMortgageLiabilitiesCurrentTotalRounded,
    };

    const totalRetailAggregatesFuture =
      (computedMortgageSecuredFuture?.total ?? 0) +
      (computedBridgeSecuredFuture?.total ?? 0) +
      computedNonMortgageLiabilitiesFutureTotalRounded;
    const totalRetailAggregatesFutureRounded = Math.round(totalRetailAggregatesFuture * 100) / 100;
    const totalRetailAggregatesFutureInput: TotalRetailAggregatesInput = {
      mortgageSecured: computedMortgageSecuredFuture?.total,
      bridgeSecured: computedBridgeSecuredFuture?.total,
      nonMortgageLiabilities: computedNonMortgageLiabilitiesFutureTotalRounded,
    };

    const totalConnectionCurrentTotal =
      totalRetailAggregatesCurrentRounded + (this.businessBankingRelationCurrent ?? 0);
    const totalConnectionCurrentTotalRounded = Math.round(totalConnectionCurrentTotal * 100) / 100;
    const totalConnectionCurrentInput: TotalConnectionInput = {
      businessBankingRelation: this.businessBankingRelationCurrent,
      totalRetail: totalRetailAggregatesCurrentRounded,
    };

    const totalConnectionFutureTotal =
      totalRetailAggregatesFutureRounded + (this.businessBankingRelationFuture ?? 0);
    const totalConnectionFutureTotalRounded = Math.round(totalConnectionFutureTotal * 100) / 100;
    const totalConnectionFutureInput: TotalConnectionInput = {
      businessBankingRelation: this.businessBankingRelationFuture,
      totalRetail: totalConnectionFutureTotalRounded,
    };

    return {
      computedMortgageSecuredCurrent: computedMortgageSecuredCurrent?.total,
      mortgageSecuredCurrentInput: computedMortgageSecuredCurrent?.inputs,
      computedMortgageSecuredFuture: computedMortgageSecuredFuture?.total,
      mortgageSecuredFutureInput: computedMortgageSecuredFuture?.inputs,
      bridgeSecuredCurrentInput: computedBridgeSecuredCurrent?.inputs,
      computedBridgeSecuredCurrent: computedBridgeSecuredCurrent?.total,
      bridgeSecuredFutureInput: computedBridgeSecuredFuture?.inputs,
      computedBridgeSecuredFuture: computedBridgeSecuredFuture?.total,
      computedNonMortgageCashSecuredCurrent: computedNonMortgageCashSecuredCurrent?.total,
      nonMortgageCashSecuredCurrentInput: computedNonMortgageCashSecuredCurrent?.inputs,
      computedNonMortgageCashSecuredFuture: computedNonMortgageCashSecuredFuture?.total,
      nonMortgageCashSecuredFutureInput: computedNonMortgageCashSecuredFuture?.inputs,
      computedNonMortgageSecuredCurrent: computedNonMortgageSecuredCurrent?.total,
      nonMortgageSecuredCurrentInput: computedNonMortgageSecuredCurrent?.inputs,
      computedNonMortgageSecuredFuture: computedNonMortgageSecuredFuture?.total,
      nonMortgageSecuredFutureInput: computedNonMortgageSecuredFuture?.inputs,
      computedNonMortgageUnsecuredCurrent: computedNonMortgageUnsecuredCurrent?.total,
      nonMortgageUnsecuredCurrentInput: computedNonMortgageUnsecuredCurrent?.inputs,
      computedNonMortgageUnsecuredFuture: computedNonMortgageUnsecuredFuture?.total,
      nonMortgageUnsecuredFutureInput: computedNonMortgageUnsecuredFuture?.inputs,
      computedNonMortgageLiabilitiesCurrent: computedNonMortgageLiabilitiesCurrentTotalRounded,
      nonMortgageLiabilitiesCurrentInput: nonMortgageLiabilitiesCurrentInput,
      computedNonMortgageLiabilitiesFuture: computedNonMortgageLiabilitiesFutureTotalRounded,
      nonMortgageLiabilitiesFutureInput: nonMortgageLiabilitiesFutureInput,
      computedRetailAggregatesCurrent: totalRetailAggregatesCurrentRounded,
      totalRetailAggregatesCurrentInput: totalRetailAggregatesCurrentInput,
      computedRetailAggregatesFuture: totalRetailAggregatesFutureRounded,
      totalRetailAggregatesFutureInput: totalRetailAggregatesFutureInput,
      computedTotalConnectionCurrent: totalConnectionCurrentTotalRounded,
      totalConnectionCurrentInput: totalConnectionCurrentInput,
      computedTotalConnectionFuture: totalConnectionFutureTotalRounded,
      totalConnectionFutureInput: totalConnectionFutureInput,
    };
  }

  public getApplicationAggregate() {
    if (!this.applicationAggregate) {
      this.applicationAggregate = this.computeApplicationAggregate();
    }
    return this.applicationAggregate;
  }
}
