import 'reflect-metadata';
import { container } from 'tsyringe';
import { SubjectPropertyCalculator } from '../calculator/subject-property.calculator';
import { TotalLoanAmountCalculator } from '../calculator/total-loan-amount.calculator';
import { LTVCalculator } from '../calculator/ltv.calculator';
import { FeeCalculator } from '../calculator/fee.calculator';
import { TYPES } from './types';
import { IncrementsCalculator } from '../calculator/increments.calculator';
import { ApplicationAggregatesCalculator } from '../calculator/application-aggregates.calculator';
import { LiabilityIsLoanCalculator } from '../calculator/liability-is-loan.calculator';
import { BlendedRateRenewalCalculator } from '../calculator/blended-rate-renewal.calculator';
import { RemainingMortgageTermCalculator } from '../calculator/remaining-mortgage-term.calculator';
import { TotalActualPaymentCalculator } from '../calculator/total-actual-payment.calculator';

export class Container {
  public static initialize(tenant: string | null) {
    container.register(TYPES.ISubjectPropertyCalculatorToken, {
      useClass: SubjectPropertyCalculator,
    });
    container.register(TYPES.ITotalLoanAmountCalculatorToken, {
      useClass: TotalLoanAmountCalculator,
    });
    container.register(TYPES.ILTVCalculatorToken, { useClass: LTVCalculator });
    container.register(TYPES.IFeeCalculatorToken, { useClass: FeeCalculator });
    container.register(TYPES.IIncrementCalculatorToken, { useClass: IncrementsCalculator });
    container.register(TYPES.IApplicationAggregatesToken, {
      useClass: ApplicationAggregatesCalculator,
    });
    container.register(TYPES.ILiabilityIsLoanCalculator, {
      useClass: LiabilityIsLoanCalculator,
    });
    container.register(TYPES.IRemainingMortgageTermCalculator, {
      useClass: RemainingMortgageTermCalculator,
    });
    container.register(TYPES.ITotalActualPaymentCalculator, {
      useClass: TotalActualPaymentCalculator,
    });
    container.register(TYPES.IBlendedRateRenewalCalculatorToken, {
      useClass: BlendedRateRenewalCalculator,
    });

    if (tenant === 'EQB') {
      // register EQB specific services
    }

    return container;
  }
}
