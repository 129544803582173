import { Injectable } from '@angular/core';
import {
  AvaChat,
  AvaChatMessage,
  AvaChatMessageMetadata,
  AvaChatMessageSource,
  AvaFunctionNames,
  AvaSuggestion,
} from '@fundmoreai/models';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { LoadingEnd, LoadingStart } from 'src/app/core/loading.state';
import { AvaService } from './ava.service';
import { AuthState } from 'src/app/auth/auth.state';
import { combineLatest, delay, EMPTY, finalize, Observable, of, switchMap, tap } from 'rxjs';
import { UpsertCompleteTask } from '../../tasks/tasks.actions';

export class AddAvaMessage {
  static readonly type = 'ava.AddAvaMessage';

  constructor(public avaChatId: string, public message: Partial<AvaChatMessage>) {}
}

export class HandleAvaMessageAction {
  static readonly type = 'ava.HandleAvaMessageAction';

  constructor(public avaChatId: string, public action: AvaChatMessageMetadata) {}
}

export class GetAvaChat {
  static readonly type = 'ava.GetAvaChat';

  constructor(public avaChatId: string) {}
}

export class CreateAvaChat {
  static readonly type = 'ava.CreateAvaChat';

  constructor(public applicationId: string) {}
}

export class GetMostRecentAvaChat {
  static readonly type = 'ava.GetMostRecentAvaChat';

  constructor(public applicationId: string) {}
}

export class LoadAva {
  static readonly type = 'ava.LoadAva';

  constructor(public applicationId: string, public avaChatId?: string) {}
}

export class GetAvaSuggestions {
  static readonly type = 'ava.GetAvaSuggestions';

  constructor(public avaChatId: string) {}
}

export class UploadDocumentsToAva {
  static readonly type = 'ava.UploadDocumentsToAva';

  constructor(public avaChatId: string, public documentIds: string[]) {}
}

export class ShareAvaChat {
  static readonly type = 'ava.ShareAvaChat';

  constructor(public avaChatId: string) {}
}

export class GetAvaChats {
  static readonly type = 'ava.GetAvaChats';

  constructor(public applicationId: string) {}
}

export interface AvaStateModel {
  avaChat?: AvaChat;
  avaSuggestions?: AvaSuggestion[];
  otherChats?: AvaChat[];
}

@State<AvaStateModel>({
  name: 'ava',
  defaults: {
    avaChat: undefined,
    avaSuggestions: undefined,
    otherChats: undefined,
  },
})
@Injectable()
export class AvaState {
  @Selector() static isContextReady(state: AvaStateModel): boolean | undefined {
    return state.avaChat?.isContextReady;
  }

  @Selector() static isThinking(state: AvaStateModel): boolean | undefined {
    return state.avaChat?.isThinking;
  }

  @Selector() static messages(state: AvaStateModel): AvaChatMessage[] | undefined {
    return state.avaChat?.messages;
  }

  @Selector() static chat(state: AvaStateModel): AvaChat | undefined {
    return state.avaChat;
  }

  @Selector() static suggestions(state: AvaStateModel): AvaSuggestion[] | undefined {
    return state.avaSuggestions;
  }

  @Selector() static otherChats(state: AvaStateModel): AvaChat[] | undefined {
    return [...(state.otherChats ?? [])].sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }

  constructor(private store: Store, private avaService: AvaService) {}

  @Action(ShareAvaChat) shareAVAChat(
    ctx: StateContext<AvaStateModel>,
    { avaChatId }: ShareAvaChat,
  ) {
    ctx.dispatch(new LoadingStart(AvaState.name));

    return this.avaService.shareAVAChat(avaChatId).pipe(
      tap((avaChat) => {
        ctx.patchState({ avaChat });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(AvaState.name))),
    );
  }

  @Action(GetAvaChats) getAVAChats(
    ctx: StateContext<AvaStateModel>,
    { applicationId }: GetAvaChats,
  ) {
    ctx.dispatch(new LoadingStart(AvaState.name));

    return this.avaService.getAVAChats(applicationId).pipe(
      tap((avaChats) => {
        ctx.patchState({ otherChats: avaChats });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(AvaState.name))),
    );
  }

  @Action(AddAvaMessage) addAvaMessage(
    ctx: StateContext<AvaStateModel>,
    { avaChatId, message }: AddAvaMessage,
  ) {
    ctx.dispatch(new LoadingStart(AvaState.name));

    const state = ctx.getState();

    ctx.patchState({
      avaChat: state.avaChat
        ? {
            ...state.avaChat,
            messages: [
              ...(state.avaChat?.messages || []),
              {
                ...message,
                createdAt: new Date(),
              } as AvaChatMessage,
            ],
          }
        : undefined,
    });

    return this.avaService.addAvaMessage(avaChatId, message).pipe(
      tap((avaChat) => {
        ctx.patchState({ avaChat, avaSuggestions: undefined });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(AvaState.name))),
    );
  }

  @Action(GetAvaChat) getAvaChat(ctx: StateContext<AvaStateModel>, { avaChatId }: GetAvaChat) {
    ctx.dispatch(new LoadingStart(AvaState.name));

    ctx.patchState({ avaSuggestions: undefined });

    return this.avaService.getAvaChat(avaChatId).pipe(
      tap((avaChat) => {
        ctx.patchState({ avaChat });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(AvaState.name))),
    );
  }

  @Action(CreateAvaChat) createAvaChat(
    ctx: StateContext<AvaStateModel>,
    { applicationId }: CreateAvaChat,
  ) {
    ctx.dispatch(new LoadingStart(AvaState.name));

    ctx.patchState({ avaChat: undefined, avaSuggestions: undefined });

    const currentUser = this.store.selectSnapshot(AuthState.currentUser);

    return this.avaService.createAvaChat(applicationId, currentUser.user.id).pipe(
      tap((avaChat) => {
        ctx.patchState({ avaChat, otherChats: [...(ctx.getState().otherChats ?? []), avaChat] });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(AvaState.name))),
    );
  }

  @Action(GetMostRecentAvaChat) getMostRecentAvaChat(
    ctx: StateContext<AvaStateModel>,
    { applicationId }: CreateAvaChat,
  ) {
    ctx.dispatch(new LoadingStart(AvaState.name));

    ctx.patchState({ avaChat: undefined, avaSuggestions: undefined });

    const currentUser = this.store.selectSnapshot(AuthState.currentUser);

    return this.avaService.getMostRecentAvaChat(applicationId, currentUser.user.id).pipe(
      tap((avaChat) => {
        ctx.patchState({ avaChat: avaChat || undefined });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(AvaState.name))),
    );
  }

  @Action(LoadAva) loadAva(ctx: StateContext<AvaStateModel>, action: LoadAva) {
    if (action.avaChatId) {
      ctx.setState({ avaChat: undefined });

      return ctx.dispatch(new GetAvaChat(action.avaChatId));
    }

    return ctx.dispatch(new GetMostRecentAvaChat(action.applicationId)).pipe(
      switchMap(() => {
        const state = ctx.getState();
        if (!state.avaChat) {
          return ctx.dispatch(new CreateAvaChat(action.applicationId));
        }

        return EMPTY;
      }),
    );
  }

  @Action(HandleAvaMessageAction) handleAvaMessageAction(
    ctx: StateContext<AvaStateModel>,
    { avaChatId, action }: HandleAvaMessageAction,
  ) {
    ctx.dispatch(new LoadingStart(AvaState.name));

    const state = ctx.getState();

    if (state.avaChat?.id !== avaChatId) {
      throw new Error('Chat ID mismatch!');
    }

    if (action.result === 'confirm') {
      const actionToDispatch = this.getActionToDispatch(
        action.additionalAction?.name,
        action.additionalAction?.args,
      );

      return actionToDispatch.pipe(
        switchMap(() => {
          return ctx.dispatch(
            new AddAvaMessage(avaChatId, {
              metadata: action,
              avaChatId: state.avaChat?.id,
              source: AvaChatMessageSource.USER,
              userId: state.avaChat?.userId,
              applicationId: state.avaChat?.applicationId,
              content: action.actionLabeling?.confirmMessage,
            }),
          );
        }),
      );
    } else {
      return ctx.dispatch(
        new AddAvaMessage(avaChatId, {
          metadata: action,
          avaChatId: state.avaChat?.id,
          source: AvaChatMessageSource.USER,
          userId: state.avaChat?.userId,
          applicationId: state.avaChat?.applicationId,
          content: action.actionLabeling?.denyMessage,
        }),
      );
    }
  }

  @Action(GetAvaSuggestions) getAvaSuggestions(
    ctx: StateContext<AvaStateModel>,
    { avaChatId }: GetAvaSuggestions,
  ) {
    ctx.dispatch(new LoadingStart(AvaState.name));

    return this.avaService.getAvaSuggestions(avaChatId).pipe(
      tap((avaSuggestions) => {
        ctx.patchState({ avaSuggestions });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(AvaState.name))),
    );
  }

  @Action(UploadDocumentsToAva) uploadDocumentsToAva(
    ctx: StateContext<AvaStateModel>,
    { avaChatId, documentIds }: UploadDocumentsToAva,
  ) {
    ctx.dispatch(new LoadingStart(AvaState.name));

    return this.avaService
      .uploadDocumentsToAva(avaChatId, documentIds)
      .pipe(finalize(() => ctx.dispatch(new LoadingEnd(AvaState.name))));
  }

  private getActionToDispatch(actionName?: string, args?: unknown): Observable<unknown> {
    // TODO: Use a real action
    if (!actionName) {
      return EMPTY;
    }

    switch (actionName) {
      case 'markTasksAsCompleted': {
        const typedArgs = args as { tasks: { id: string; description: string }[] };

        const actions = typedArgs.tasks.map((task) => {
          return new UpsertCompleteTask({
            id: task.id,
            isCompleted: true,
            completedAt: new Date().toISOString(),
            completedByUserId: null,
            stage: null,
          });
        });

        return this.store.dispatch(actions);
      }
      default: {
        return EMPTY;
      }
    }
  }
}
