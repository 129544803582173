import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { filter, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { LoadingEnd, LoadingStart } from 'src/app/core/loading.state';
import { AppFeaturesState } from '../shared/app-features.state';
import { PermissionsService } from './permissions.service';
import {
  ApplicationListPermissions,
  ApplicationStage,
  GeneralAbstractPermissions,
  GeneralAbstractPermissionsResponse,
  StageMovePermissionMap,
} from '@fundmoreai/models';
import { RouterState } from '@ngxs/router-plugin';

export interface PermissionsStateModel {
  applicationStageMovePermissionsMap: StageMovePermissionMap;
  permissions: GeneralAbstractPermissionsResponse;
}

export class FetchGeneralPermissions {
  static readonly type = '@permissions.fetchGeneralPermissions';
}

const allStages = [
  ApplicationStage[ApplicationStage.ADJUDICATION],
  ApplicationStage[ApplicationStage.COMPLIANCE],
  ApplicationStage[ApplicationStage.DEAL_ACCEPTANCE],
  ApplicationStage[ApplicationStage.DEAL_SIGNED],
  ApplicationStage[ApplicationStage.DECLINED],
  ApplicationStage[ApplicationStage.DOCUMENT_REVIEW],
  ApplicationStage[ApplicationStage.FINAL_APPROVAL],
  ApplicationStage[ApplicationStage.FINAL_REVIEW],
  ApplicationStage[ApplicationStage.FORECLOSURE],
  ApplicationStage[ApplicationStage.FUNDED],
  ApplicationStage[ApplicationStage.LAWYER_INSTRUCTED],
  ApplicationStage[ApplicationStage.NEW_APPLICATION],
  ApplicationStage[ApplicationStage.OPERATIONS_FULFILLMENT],
  ApplicationStage[ApplicationStage.PAID_IN_FULL],
  ApplicationStage[ApplicationStage.PAID_OUT],
  ApplicationStage[ApplicationStage.PAST_DUE_UNDER_90],
  ApplicationStage[ApplicationStage.PAST_DUE_OVER_90],
  ApplicationStage[ApplicationStage.PRE_FUND],
  ApplicationStage[ApplicationStage.PRESENT_DEAL],
  ApplicationStage[ApplicationStage.PROPOSE_DEAL],
  ApplicationStage[ApplicationStage.RENEWAL],
  ApplicationStage[ApplicationStage.UNDERWRITING],
];

@State({
  name: 'permissions',
  defaults: {},
})
@Injectable()
export class PermissionsState {
  @Selector()
  static allApplicationStageMovePermissionsMap(state: PermissionsStateModel) {
    return state.applicationStageMovePermissionsMap;
  }

  @Selector([RouterState.url, PermissionsState.allApplicationStageMovePermissionsMap])
  static applicationStageMovePermissionsMap(
    url: string | undefined,
    applicationStageMovePermissionsMap: StageMovePermissionMap,
  ): {
    [key: string]: ApplicationStage[];
  } {
    const isServicing = url?.includes('servicing');

    return isServicing
      ? applicationStageMovePermissionsMap.servicingStageMovePermissionsMap
      : applicationStageMovePermissionsMap.originationStageMovePermissionsMap;
  }

  @Selector()
  static allPermissions(
    state: PermissionsStateModel,
  ): GeneralAbstractPermissionsResponse | undefined {
    return state.permissions;
  }

  @Selector([RouterState.url, PermissionsState.allPermissions])
  static permissions(
    url: string | undefined,
    permissions: GeneralAbstractPermissionsResponse | undefined,
  ): GeneralAbstractPermissions | undefined {
    const isServicing = url?.includes('servicing');
    if (!permissions) {
      return undefined;
    }

    const abstractPermissions: GeneralAbstractPermissions = {
      general: permissions.general,
      applicationList: isServicing
        ? permissions.applicationListServicing
        : permissions.applicationListOrigination,
      computed: isServicing ? permissions.computedServicing : permissions.computedOrigination,
    };

    return abstractPermissions;
  }

  @Selector()
  static canManageSystem(state: PermissionsStateModel): boolean {
    return state.permissions.general.canManageSystemConfiguration;
  }

  @Selector()
  static canManageBrokers(state: PermissionsStateModel): boolean {
    return state.permissions.general.manageBrokers;
  }

  @Selector()
  static canManageLawyers(state: PermissionsStateModel): boolean {
    return state.permissions.general.manageLawyers;
  }

  constructor(private permissionsService: PermissionsService, private store: Store) {}

  @Action(FetchGeneralPermissions)
  fetchGeneralPermissions(ctx: StateContext<PermissionsStateModel>) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.permissionsService.loadGeneralPermissionsOwn().pipe(
      switchMap((permissions) => {
        return this.store.select(AppFeaturesState.underwritingAndServicingTenantStages).pipe(
          filter((x) => !!x),
          first(),
          map((underwritingAndServicingTenantStages) => ({
            permissions,
            underwritingAndServicingTenantStages,
          })),
        );
      }),
      tap(({ permissions, underwritingAndServicingTenantStages }) => {
        ctx.setState({
          applicationStageMovePermissionsMap: {
            servicingStageMovePermissionsMap:
              PermissionsService.getApplicationStageMovePermissionsMap(
                permissions.applicationListServicing.canMoveStage,
                underwritingAndServicingTenantStages,
              ),
            originationStageMovePermissionsMap:
              PermissionsService.getApplicationStageMovePermissionsMap(
                permissions.applicationListOrigination.canMoveStage,
                underwritingAndServicingTenantStages,
              ),
          },
          permissions: {
            ...permissions,
            computedOrigination: {
              canDeleteApplicationInStages: this.canDeleteApplicationInStages(
                permissions.applicationListOrigination,
              ),
              canMergeApplications: this.canMergeApplications(
                permissions.applicationListOrigination,
              ),
              canManageDuplicates: this.canManageDuplicates(permissions.applicationListOrigination),
            },
            computedServicing: {
              canDeleteApplicationInStages: this.canDeleteApplicationInStages(
                permissions.applicationListServicing,
              ),
              canMergeApplications: this.canMergeApplications(permissions.applicationListServicing),
              canManageDuplicates: this.canManageDuplicates(permissions.applicationListServicing),
            },
          },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  private canMergeApplications(applicationList: ApplicationListPermissions): {
    [key: string]: boolean;
  } {
    const { canCreate, canArchiveApplication } = applicationList;
    const canDeleteApplicationInStages = this.canDeleteApplicationInStages(applicationList);

    return allStages.reduce((result, stage) => {
      result[stage] =
        canCreate && (canDeleteApplicationInStages.includes(stage) || canArchiveApplication);

      return result;
    }, {} as { [key: string]: boolean });
  }

  private canManageDuplicates(applicationList: ApplicationListPermissions): {
    [key: string]: boolean;
  } {
    const { canDecline, canArchiveApplication } = applicationList;
    const canDeleteApplicationInStages = this.canDeleteApplicationInStages(applicationList);

    return allStages.reduce((result, stage) => {
      result[stage] =
        canDecline || canDeleteApplicationInStages.includes(stage) || canArchiveApplication;

      return result;
    }, {} as { [key: string]: boolean });
  }

  private canDeleteApplicationInStages(applicationList: ApplicationListPermissions) {
    return applicationList.canDeleteApplication.reduce((result, item) => {
      result.push(...item.stage);

      return result;
    }, [] as ApplicationStage[]);
  }
}
