import { MMSMilestone } from '../../shared/model';
export type MilestonesInput = Pick<MMSMilestone, 'milestoneCodeID' | 'milestoneValue'>;

export class SetFCTMMSMilestoneOnClosingInstruction {
  static readonly type = '@closingInstructions.setFCTMMSMilestoneOnClosingInstruction';
  constructor(public closingInstructionId: string, public milestones: MilestonesInput[]) {}
}

export class FetchFctMMSUpdates {
  static readonly type = '@closingInstructions.fetchFctMMSUpdates';
  constructor(public fctMMSId: string) {}
}

export class SendMMSFundingConfirmation {
  static readonly type = '@closingInstructions.sendMMSFundingConfirmation';
  constructor(public fctMMSId: string, public confirmationAmount: number) {}
}
