export interface ApplicationNote {
  id?: string | null;
  applicationId?: string | null;
  authorNames?: string[];
  authorRoles?: string[];
  editableById?: string | null;
  status?: ApplicationNoteStatus | null;
  content?: string | null;
  metadata?: ApplicationNoteMetadata;
  lastModifiedAt?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  externalSequenceNumber?: number | null;
}

export interface ApplicationNoteMetadata {
  source: ApplicationNoteSource;
}

export enum ApplicationNoteStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
}

export enum ApplicationNoteSource {
  API = 'API',
  CREATE_APPLICATION = 'CREATE_APPLICATION',
  MIGRATION = 'MIGRATION',
  UPDATE_APPLICATION = 'UPDATE_APPLICATION',
  DOCUMENT_REVIEW = 'DOCUMENT_REVIEW',
  APPROVAL_REVIEW = 'APPROVAL_REVIEW',
  CONDITION_REVIEW = 'CONDITION_REVIEW',
}

export enum ApplicationNoteRole {
  // this is not an exhaustive list of roles
  BROKER = 'Broker',
}

export enum ApplicationNoteKey {
  ID = 'id',
  APPLICATION_ID = 'applicationId',
  AUTHOR_NAMES = 'authorNames',
  AUTHOR_ROLES = 'authorRoles',
  EDITABLE_BY_ID = 'editableById',
  STATUS = 'status',
  CONTENT = 'content',
  LAST_MODIFIED_AT = 'lastModifiedAt',
}
