export enum AuthResultStatus {
  NEW_PASSWORD_REQUIRED = 'newPasswordRequired',
  SUCCESS = 'success',
  MFA_REQUIRED = 'mfaRequired',
  TOTP_REQUIRED = 'totpRequired',
  CUSTOM_CHALLENGE = 'customChallenge',
  MFA_SETUP = 'mfaSetup',
  SELECT_MFA_TYPE = 'selectMFAType',
  INPUT_VERIFICATION_CODE = 'inputVerificationCode',
  ERROR = 'error',
  FORGOT_PASSWORD = 'forgotPassword',
  LOGIN = 'login',
}

export class AuthResult {
  status: AuthResultStatus;
  data: AuthResultData;
  returnUrl?: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(status: AuthResultStatus, data: any = {}, returnUrl?: string) {
    this.status = status;
    this.data = data;
    this.returnUrl = returnUrl;
  }
}

export interface AuthResultData {
  code: AuthResultDataCode;
  name: string;
}

export enum AuthResultDataCode {
  RESET_PASSWORD = 'PasswordResetRequiredException',
}
