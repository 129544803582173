/* eslint-disable max-len */
import { ApplicationConditionDocumentFile } from '@fundmoreai/models';
import { ConditionDocumentPreview } from '../../../../../../portal/states/application-condition-documents.state.actions';

export interface FctMMSDocumentToUpload {
  mmsDocumentType: number | undefined;
  mmsDocumentName: string | undefined;
  applicationDocumentId?: string;
  applicationConditionDocument: ApplicationConditionDocumentFile;
  uploadSource: MMSDocumentUploadSource.FUNDMORE;
}

export interface FctMMSFileSystemDocumentToUpload {
  mmsDocumentType: number | undefined;
  mmsDocumentName: string | undefined;
  applicationDocumentId?: string;
  file: File;
  uploadSource: MMSDocumentUploadSource.FILE_SYSTEM;
}

export interface FCTMMSDocumentInput {
  applicationDocumentId: string;
  mmsDocumentType: number;
  documentName: string;
}

export enum MMSDocumentUploadSource {
  FILE_SYSTEM = 'FILE_SYSTEM',
  FUNDMORE = 'FUNDMORE',
}

export class UploadFctMMSDocuments {
  static readonly type = '@fctMMSDocumentsState.mmsUploadApplicationDocuments';
  constructor(
    public applicationId: string,
    public mmsRequestId: string,
    public documentsData: (FctMMSDocumentToUpload | FctMMSFileSystemDocumentToUpload)[],
  ) {}
}

export class GetMMSDocumentConfig {
  static readonly type = '@fctMMSDocumentsState.getMMSDocumentConfig';
}

export class GetMMSDocuments {
  static readonly type = '@fctMMSDocumentsState.getMMSDocuments';
  constructor(public applicationId: string) {}
}

export class SetSelectedMMSDocumentInView {
  static readonly type = '@fctMMSDocumentsState.setSelectedDocumentInView';
  constructor(public conditionDocumentPreview: ConditionDocumentPreview | null) {}
}

export class FetchMMSDocumentContent {
  static readonly type = '@fctMMSDocumentsState.fetchMMSDocumentContent';
  constructor(public mmsDocumentId: string, public fileName: string | undefined) {}
}

export class DownloadMMSDocumentInReview {
  static readonly type = '@fctMMSDocumentsState.downloadMMSDocumentInReview';
}

export class DownloadMMSDocumentById {
  static readonly type = '@fctMMSDocumentsState.downloadMMSDocumentById';
  constructor(public mmsDocumentId: string) {}
}

export class SetMMSDocumentUploadStatus {
  static readonly type = '@fctMMSDocumentsState.setMMSDocumentUploadStatus';
  constructor(public isUploading: boolean) {}
}

export class UnlinkApplicationDocumentWithMMSDocument {
  static readonly type = '@fctMMSDocumentsState.unlinkApplicationDocumentWithMMSDocument';
  constructor(public applicationConditionDocumentId: string) {}
}

export class AssignStakeholderToMMSDocument {
  static readonly type = '@fctMMSDocumentsState.assignStakeholderToMMSDocument';
  constructor(public mmsDocumentId: string, public stakeholderId: string) {}
}
