import { FCTOccupancyType } from '../enums/occupancyType';
import { FCTPropertyInspectionContactType } from '../enums/propertyInspectionContactType';
import { FCTPropertyType } from '../enums/propertyType';
import { FCTPropertyUse } from '../enums/propertyUse';
import { FCTProvince } from '../enums/province';
import { FCTStreetDirection } from '../enums/streetDirection';
import { FCTStreetType } from '../enums/streetType';

export interface FCTPropertyInfo {
  propertyType?: FCTPropertyType;
  unitNumber?: string;
  streetNumber?: string;
  streetNumberSuffix?: string;
  streetName?: string;
  streetType?: FCTStreetType;
  streetDirection?: FCTStreetDirection;
  municipality?: string;
  postalCode?: string;
  rollNumber?: string;
  province?: FCTProvince;
  propertyUse?: FCTPropertyUse;
  occupancyType?: FCTOccupancyType;
  FCTAddressId?: string;
  LegalDescription?: string;
  DeclaredValue?: number;
  PropertyInspectionContactFirstName?: string;
  PropertyInspectionContactLastName?: string;
  PropertyInspectionContactPhone?: string; // xxx-xxx-xxxx,xxxxxxxxxx
  PropertyInspectionContactCell?: string; // xxx-xxx-xxxx,xxxxxxxxxx
  PropertyInspectionContactEmail?: string; // name@domain.xxx
  PropertyInspectionContactType?: FCTPropertyInspectionContactType;
}
