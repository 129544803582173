import { EventMessageType } from '../pubnub/model';

export class ResetState {
  static readonly type = '@store.resetState';
}

export class ResetEditModeState {
  static readonly type = '@store.resetEditModeState';
}

export class ApplicationResetState {
  static readonly type = '@store.applicationResetState ';
}

export class AddNotification {
  static readonly type = '@notification.add';
  constructor(public notification: FundmoreNotification) {}
}

export class AddNotifications {
  static readonly type = '@notification.AddNotifications';
  constructor(public notifications: FundmoreNotification[]) {}
}

export interface FundmoreNotification {
  message: string;
  channel: string;
  title?: string;
  applicationId?: string;
  seen?: boolean;
  notificationDuration?: number;
  customIcon?: string;
  customPath?: string[];
  addedAt: Date | string;
  timetoken?: string | number;
  readAt?: Date;
  applicationIsServicing?: boolean;
  eventType?: EventMessageType;
}
