import {
  OptaArchitecturalStyleType,
  OptaAuxiliaryHeatingType,
  OptaBooleanWithUnknownType,
  OptaCommercialExteriorWallType,
  OptaCommercialHeatingTypeType,
  OptaCommercialParkingTypeType,
  OptaCommercialPlumbingTypeType,
  OptaCommercialRoofSurfaceType,
  OptaConfidenceRatingType,
  OptaExteriorWallType,
  OptaFoundationType,
  OptaGarageNumberOfCarsType,
  OptaGarageType,
  OptaMultiResidentialStyleType,
  OptaPlumbingType,
  OptaPrimaryHeatingType,
  OptaPropertyType,
  OptaResidentialBathroomCountType,
  OptaResidentialKitchenCountType,
  OptaResidentialStoreyCountType,
  OptaRoofType,
  OptaSewageType,
  OptaStatusCode,
  OptaSwimmingPoolType,
  OptaWaterType,
} from './index';
import { Property } from '../property';

export interface OptaAddressResult {
  searchString: string;
  result: OptaAddress;
}

export interface OptaAddress {
  municipality: string;
  optaAddressKey: string;
  postalCode: string;
  province: string;
  streetName: string;
  streetNumber: string;
  streetType: string;
  streetDirection: string;
  unitNumber: string;
}

export interface OptaMarketValuationCorroborationValue {
  forAddress: OptaAddress;
  marketValuation: OptaMarketValuation;
}
export interface OptaMarketValuation {
  value: number;
  date: string;
  confidenceRating: OptaConfidenceRatingType;
}

export interface OptaPropertyDetailsCorroborationValue {
  forAddress: OptaAddress;
  propertyDetails: OptaProperty;
}

export interface OptaMessage {
  message: string[];
}
export interface OptaAddressResponse {
  address: OptaAddress[];
  messages: OptaMessage;
}

export interface OptaMarketValuationResponse {
  marketValuation: OptaMarketValuation[];
  statusCode: OptaStatusCode;
}

export interface OptaResidentialBuilding {
  architecturalStyleType: OptaArchitecturalStyleType;
  auxiliaryHeatingType: OptaAuxiliaryHeatingType;
  bathroomCount: number;
  commercialIndicator: boolean;
  exteriorWallType: OptaExteriorWallType;
  finishedBasement: boolean;
  foundationType: OptaFoundationType;
  garageNumberOfCars: OptaGarageNumberOfCarsType;
  garageType: OptaGarageType;
  kitchenCount: OptaResidentialKitchenCountType;
  lotSize: number;
  numberOfBedrooms: number;
  outbuildingPresent: OptaBooleanWithUnknownType;
  plumbingType: OptaPlumbingType;
  primaryHeatingType: OptaPrimaryHeatingType;
  roofType: OptaRoofType;
  sewageType: OptaSewageType;
  squareFootage: number;
  storeyCount: OptaResidentialStoreyCountType;
  swimmingPoolType: OptaSwimmingPoolType;
  waterType: OptaWaterType;
  yearBuilt: number;
}

export interface OptaBuldingFeatures {
  exteriorWallType: OptaCommercialExteriorWallType;
  heatingType: OptaCommercialHeatingTypeType;
  multiResidentialStyleType: OptaMultiResidentialStyleType;
  numberOfStoreys: number;
  parkingType: OptaCommercialParkingTypeType;
  plumbingType: OptaCommercialPlumbingTypeType;
  roofSurface: OptaCommercialRoofSurfaceType;
  sewageType: OptaSewageType;
  waterType: OptaWaterType;
  yearBuilt: number;
}

export interface OptaUnitFeatures {
  commercialIndicator: boolean;
  floorLevel: number;
  garageNumberOfCars: OptaGarageNumberOfCarsType;
  numberOfBathrooms: OptaResidentialBathroomCountType;
  numberOfBedrooms: number;
  numberOfDens: number;
  numberOfKitchens: OptaResidentialKitchenCountType;
  totalFloorArea: number;
}

export interface OptaMultiResidentialBuilding {
  buildingFeatures: OptaBuldingFeatures;
  unitFeatures: OptaUnitFeatures;
}

export interface OptaProperty {
  propertyType: OptaPropertyType;
  residentialBuilding: OptaResidentialBuilding;
  multiResidentialBuilding: OptaMultiResidentialBuilding;
}

export interface OptaClarifyFinancialResidentialPrefillResponse {
  property: OptaProperty;
  mappedProperty?: Property | null;
  statusCode: OptaStatusCode;
}
