/**
 * Patterns for the Delta Processing
 * Check Status - runs the check status endpoint
 *              - gets updated statuses from FCT
 *              - statuses are only received once (if lost, they are not resent)
 *              - compatible with notes functionality
 * Get Ready Deals - runs the get ready deals endpoint
 *                 - gets updated statuses from FCT (same payload format as check status)
 *                 - deals are reveived from when they are ready,
 *                   until they are pulled by us (from FCT standpoint - delivered)
 *                 - not compatible with notes functionality
 */
export enum DeltaProcessingPattern {
  CHECK_STATUS = 'CHECK_STATUS',
  GET_READY_DEALS = 'GET_READY_DEALS',
}
