import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Broker, AgentAction } from '../shared/model';

@Injectable({
  providedIn: 'root',
})
export class BrokerService {
  constructor(private http: HttpClient) {}

  public getBrokers(): Observable<Broker[]> {
    return this.http.get<Broker[]>(`${environment.fundmore_api_url}/brokers`);
  }

  addBroker(broker: Partial<Broker>) {
    return this.http.post<Broker>(`${environment.fundmore_api_url}/brokers`, broker);
  }

  updateBroker(brokerId: string, broker: Partial<Broker>) {
    return this.http.patch(`${environment.fundmore_api_url}/brokers/${brokerId}`, broker);
  }

  deleteBroker(brokerId: string) {
    return this.http.delete<void>(`${environment.fundmore_api_url}/brokers/${brokerId}`);
  }

  bulkActionBrokers(brokerIds: string[], action: AgentAction) {
    return this.http.patch<Broker[]>(`${environment.fundmore_v2_api_url}/brokers/bulkAction`, {
      brokerIds,
      action,
    });
  }

  deleteBulkBrokers(brokerIds: string[]) {
    return this.http.post(`${environment.fundmore_api_url}/brokers/bulkDelete`, {
      brokerIds,
    });
  }
}
