import { ConfigurationState } from './configs/configuration-state';
import { FundmoreScoreInput } from './models/models';
import { ConfigurationProcessor, FundmoreScoreOutput } from './processors/configuration-processor';
import { StorageState } from './storage/storage';

export * from './models/models';
export { FundmoreScoreOutput } from './processors/configuration-processor';
export { OctopusResult } from './processors/octopus-processor';

declare global {
  interface Number {
    between: (a: number, b: number) => boolean;
  }
}

Number.prototype.between = function (a: number, b: number): boolean {
  const min = Math.min.apply(Math, [a, b]),
    max = Math.max.apply(Math, [a, b]);
  return this > min && this < max;
};

export class FundmoreOctopus {
  s3BasePath: string | undefined = undefined;
  constructor(s3BasePath?: string) {
    this.s3BasePath = s3BasePath;
  }
  public getScore = async (input: FundmoreScoreInput): Promise<FundmoreScoreOutput> => {
    if (!input.tenantId) {
      throw new Error('Tenant ID is required');
    }
    if (this.s3BasePath) {
      const scoreConfig = await StorageState.getScoreConfig(input.tenantId, this.s3BasePath);

      if (scoreConfig) {
        ConfigurationState.scoreConfig = scoreConfig;
      }

      if (input.tenantCode?.startsWith('RM')) {
        const rocketExtraConfig = await StorageState.getRocketExtraConfig(
          input.tenantId,
          this.s3BasePath,
        );

        if (rocketExtraConfig) {
          ConfigurationState.rocketExtraConfig = rocketExtraConfig;
        }
      }
    }

    const processor = new ConfigurationProcessor();

    const output = processor.process(input);

    return output;
  };
}
