import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext, Store } from '@ngxs/store';
import {
  EXISTING_MORTGAGES_DEFAULT_DISPLAY_COLUMNS,
  MortgagesV2State,
} from 'src/app/portal/mortgages-v2/mortgages-v2.state';
import {
  FeeKeyRecord,
  FinancialLiabilityKeyRecord,
  MortgageKeyRecord,
  PropertyAppraisalKeyRecord,
  PropertyKeyRecord,
} from 'src/app/shared/enum-records-metadata';
// eslint-disable-next-line max-len
import { APPLICATION_CONDITIONS_DEFAULT_DISPLAY_COLUMNS } from '../../application/widgets/conditions/application-conditions.state';
// eslint-disable-next-line max-len
import { MANAGE_CONDITIONS_DEFAULT_DISPLAY_COLUMNS } from '../../manager-portal/condition-manage/condition-manage.state';
import {
  Column,
  ConditionTableKey,
  ConditionTableKeyRecord,
} from '../../manager-portal/condition-manage/model';
import {
  ConditionTableKey as ApplicationConditionTableKey,
  ConditionOfApprovalTableKeyRecord,
} from '../documents/model';
import {
  FreezeDatatableColumn,
  OrderDatatableColumns,
  UnfreezeDatatableColumn,
  UpdateDatatableSelectedDisplayColumns,
} from './datatable-columns.actions';
import {
  FeeKey,
  FinancialLiabilityKey,
  MortgageKey,
  PropertyAppraisalKey,
  PropertyKey,
} from '@fundmoreai/models';
import { PROPERTIES_NET_WORTH_DEFAULT_DISPLAY_COLUMNS } from 'src/app/portal/properties.state';
import {
  ACTIVE_LIABILITIES_DEFAULT_DISPLAY_COLUMNS,
  FinancialLiabilityState,
  WRITTEN_OFF_LIABILITIES_DEFAULT_DISPLAY_COLUMNS,
} from '../../application/widgets/credit/financial-liability.state';
import {
  PROPERTY_APPRAISALS_DEFAULT_DISPLAY_COLUMNS,
  PropertyAppraisalState,
} from '../../../portal/property-appraisals/property-appraisals.state';
import { TenantSettingsInitialized } from '../../../shared/app-features.state';
import { MANAGE_BROKERS_DEFAULT_DISPLAY_COLUMNS } from 'src/app/portal/brokers.state';
import {
  BrokerTableKey,
  BrokerTableKeyRecord,
  LawyerTableKey,
  LawyerTableKeyRecord,
} from '../../manager-portal/lawyer-broker-shared/lawyer-broker-details/model';
import { MANAGE_LAWYERS_DEFAULT_DISPLAY_COLUMNS } from 'src/app/portal/lawyers.state';
import { FEES_DEFAULT_DISPLAY_COLUMNS } from 'src/app/portal/fees.state';
import { DefaultFeeKey, DefaultFeeKeyRecord } from '../../manager-portal/default-fees/model';
import { DEFAULT_FEES_DEFAULT_DISPLAY_COLUMNS } from '../../manager-portal/default-fees/default-fees.state';

export enum DatatableName {
  ACTIVE_LIABILITIES = 'ACTIVE_LIABILITIES',
  ADD_APPLICATION_CONDITION_FROM_PRESET = 'ADD_APPLICATION_CONDITION_FROM_PRESET',
  APPLICATION_CONDITIONS = 'APPLICATION_CONDITIONS',
  DEFAULT_FEES = 'DEFAULT_FEES',
  EXISTING_MORTGAGES = 'EXISTING_MORTGAGES',
  FEES = 'FEES',
  MANAGE_CONDITIONS = 'MANAGE_CONDITIONS',
  PROPERTIES_NET_WORTH = 'PROPERTIES_NET_WORTH',
  WRITTEN_OFF_LIABILITIES = 'WRITTEN_OFF_LIABILITIES',
  PROPERTY_APPRAISALS = 'PROPERTY_APPRAISALS',
  MANAGE_BROKERS = 'MANAGE_BROKERS',
  MANAGE_LAWYERS = 'MANAGE_LAWYERS',
}

export const DatatableNameEntityUrlRecord: Record<DatatableName, string> = {
  [DatatableName.ACTIVE_LIABILITIES]: 'active-liabilities',
  [DatatableName.ADD_APPLICATION_CONDITION_FROM_PRESET]: 'add-preset-application-condition',
  [DatatableName.APPLICATION_CONDITIONS]: 'conditions',
  [DatatableName.DEFAULT_FEES]: 'manage-default-fees',
  [DatatableName.EXISTING_MORTGAGES]: 'existing-mortgages',
  [DatatableName.FEES]: 'fees',
  [DatatableName.MANAGE_CONDITIONS]: 'manage-conditions',
  [DatatableName.PROPERTIES_NET_WORTH]: 'properties-net-worth',
  [DatatableName.WRITTEN_OFF_LIABILITIES]: 'written-off-liabilities',
  [DatatableName.PROPERTY_APPRAISALS]: 'property-appraisals',
  [DatatableName.MANAGE_BROKERS]: 'manage-brokers',
  [DatatableName.MANAGE_LAWYERS]: 'manage-lawyers',
};

export interface DatatableColumnsStateModel {
  displayColumns: {
    [key in DatatableName]: {
      columns: Column[];
      version: number;
    };
  };
  version: number;
}

@State<DatatableColumnsStateModel>({
  name: 'datatableColumns',
  defaults: DatatableColumnsState.DEFAULT_STATE_VALUES,
})
@Injectable()
export class DatatableColumnsState {
  constructor(private store: Store) {}

  static NAME = 'datatableColumns';
  static DEFAULT_STATE_VALUES = {
    displayColumns: {
      [DatatableName.ACTIVE_LIABILITIES]: {
        columns: ACTIVE_LIABILITIES_DEFAULT_DISPLAY_COLUMNS,
        version: 4,
      },
      [DatatableName.ADD_APPLICATION_CONDITION_FROM_PRESET]: {
        columns: APPLICATION_CONDITIONS_DEFAULT_DISPLAY_COLUMNS.filter(
          (c) => c.field !== ApplicationConditionTableKey.STAKEHOLDER,
        ),
        version: 1,
      },
      [DatatableName.APPLICATION_CONDITIONS]: {
        columns: APPLICATION_CONDITIONS_DEFAULT_DISPLAY_COLUMNS,
        version: 1,
      },
      [DatatableName.DEFAULT_FEES]: {
        columns: DEFAULT_FEES_DEFAULT_DISPLAY_COLUMNS,
        version: 1,
      },
      [DatatableName.EXISTING_MORTGAGES]: {
        columns: EXISTING_MORTGAGES_DEFAULT_DISPLAY_COLUMNS,
        version: 5,
      },
      [DatatableName.FEES]: {
        columns: FEES_DEFAULT_DISPLAY_COLUMNS,
        version: 1,
      },
      [DatatableName.MANAGE_CONDITIONS]: {
        columns: MANAGE_CONDITIONS_DEFAULT_DISPLAY_COLUMNS,
        version: 1,
      },
      [DatatableName.PROPERTIES_NET_WORTH]: {
        columns: PROPERTIES_NET_WORTH_DEFAULT_DISPLAY_COLUMNS,
        version: 1,
      },
      [DatatableName.WRITTEN_OFF_LIABILITIES]: {
        columns: WRITTEN_OFF_LIABILITIES_DEFAULT_DISPLAY_COLUMNS,
        version: 4,
      },
      [DatatableName.PROPERTY_APPRAISALS]: {
        columns: PROPERTY_APPRAISALS_DEFAULT_DISPLAY_COLUMNS,
        version: 1,
      },
      [DatatableName.MANAGE_BROKERS]: {
        columns: MANAGE_BROKERS_DEFAULT_DISPLAY_COLUMNS,
        version: 2,
      },
      [DatatableName.MANAGE_LAWYERS]: {
        columns: MANAGE_LAWYERS_DEFAULT_DISPLAY_COLUMNS,
        version: 2,
      },
    },
    version: 6,
  };

  static datatableColumns(datatableName: DatatableName) {
    return createSelector(
      [DatatableColumnsState],
      (state: DatatableColumnsStateModel): Column[] => {
        return state.displayColumns[datatableName]?.columns || [];
      },
    );
  }

  static selectedDatatableColumns(
    datatableName: DatatableName,
    prefixColumns: string[] = [],
    suffixColumns: string[] = ['options'],
  ) {
    return createSelector(
      [DatatableColumnsState],
      (state: DatatableColumnsStateModel): string[] => {
        const displayColumns = (state.displayColumns[datatableName]?.columns || [])
          .filter((x) => x.isSelected)
          .map((x) => x.field);

        return [...prefixColumns, ...displayColumns, ...suffixColumns];
      },
    );
  }

  @Action(OrderDatatableColumns)
  orderDatatableColumns(
    ctx: StateContext<DatatableColumnsStateModel>,
    action: OrderDatatableColumns,
  ) {
    const state = ctx.getState();

    ctx.patchState({
      displayColumns: {
        ...state.displayColumns,
        [action.datatableName]: {
          ...state.displayColumns[action.datatableName],
          columns: action.columns,
        },
      },
    });
  }

  @Action(UpdateDatatableSelectedDisplayColumns)
  updateDatatableSelectedDisplayColumns(
    ctx: StateContext<DatatableColumnsStateModel>,
    action: UpdateDatatableSelectedDisplayColumns,
  ) {
    const state = ctx.getState();
    const displayColumns = [
      ...(state.displayColumns[action.datatableName]?.columns ?? []).map((x) => ({ ...x })),
    ];

    displayColumns.forEach((x) => {
      const displayColumn = action.selectedColumns.find(
        (selectedColumn) => selectedColumn.field === x.field,
      );

      x.isSelected = !!displayColumn;
    });

    ctx.patchState({
      displayColumns: {
        ...state.displayColumns,
        [action.datatableName]: {
          ...state.displayColumns[action.datatableName],
          columns: displayColumns,
        },
      },
    });
  }

  @Action(FreezeDatatableColumn)
  freezeDatatableColumn(
    ctx: StateContext<DatatableColumnsStateModel>,
    action: FreezeDatatableColumn,
  ) {
    const state = ctx.getState();
    const displayColumns = [
      ...(state.displayColumns[action.datatableName]?.columns ?? []).map((x) => ({ ...x })),
    ];

    const column = displayColumns.find((x) => {
      return x.field === action.field;
    });

    if (!column) {
      return;
    }

    column.isFrozen = true;
    column.isSelected = false;

    ctx.patchState({
      displayColumns: {
        ...state.displayColumns,
        [action.datatableName]: {
          ...state.displayColumns[action.datatableName],
          columns: displayColumns,
        },
      },
    });
  }

  @Action(UnfreezeDatatableColumn)
  unfreezeDatatableColumn(
    ctx: StateContext<DatatableColumnsStateModel>,
    action: UnfreezeDatatableColumn,
  ) {
    const state = ctx.getState();
    const displayColumns = [
      ...(state.displayColumns[action.datatableName]?.columns ?? []).map((x) => ({ ...x })),
    ];

    const column = displayColumns.find((x) => {
      return x.field === action.field;
    });

    if (!column) {
      return;
    }

    column.isFrozen = false;
    column.isSelected = true;

    ctx.patchState({
      displayColumns: {
        ...state.displayColumns,
        [action.datatableName]: {
          ...state.displayColumns[action.datatableName],
          columns: displayColumns,
        },
      },
    });
  }

  @Action(TenantSettingsInitialized)
  initializeDisplayColumns(ctx: StateContext<DatatableColumnsStateModel>) {
    const state = ctx.getState();
    const propertyAppraisalsOptionalDisplayColumns = this.store.selectSnapshot(
      PropertyAppraisalState.propertyAppraisalsOptionalDisplayColumns,
    );
    const liabilitiesOptionalDisplayColumns = this.store.selectSnapshot(
      FinancialLiabilityState.liabilitiesOptionalDisplayColumns,
    );
    const existingMortgageOptionalDisplayColumns = this.store.selectSnapshot(
      MortgagesV2State.existingMortgageOptionalDisplayColumns,
    );

    ctx.patchState({
      displayColumns: {
        [DatatableName.ACTIVE_LIABILITIES]: {
          ...state.displayColumns[DatatableName.ACTIVE_LIABILITIES],
          columns: this.filterDuplicates([
            ...(state.displayColumns[DatatableName.ACTIVE_LIABILITIES]?.columns || [])
              .map((c) => {
                let name =
                  c.field === 'ApplicantFinancialLiabilities'
                    ? FinancialLiabilityKeyRecord[FinancialLiabilityKey.APPLICANT_ID]
                    : FinancialLiabilityKeyRecord[<FinancialLiabilityKey>c.field];
                if (!name) {
                  // data table crashes when name is undefined
                  name = $localize`No name`;
                }
                return {
                  ...c,
                  name,
                };
              })
              .filter(
                (c) =>
                  !c.isOptional ||
                  liabilitiesOptionalDisplayColumns.map((x) => x.field).includes(c.field),
              ),
            ...liabilitiesOptionalDisplayColumns,
          ]),
        },
        [DatatableName.ADD_APPLICATION_CONDITION_FROM_PRESET]: {
          ...state.displayColumns[DatatableName.ADD_APPLICATION_CONDITION_FROM_PRESET],
          columns: [
            ...(
              state.displayColumns[DatatableName.ADD_APPLICATION_CONDITION_FROM_PRESET]?.columns ||
              []
            ).map((c) => ({
              ...c,
              name: ConditionOfApprovalTableKeyRecord[<ApplicationConditionTableKey>c.field],
            })),
          ],
        },
        [DatatableName.APPLICATION_CONDITIONS]: {
          ...state.displayColumns[DatatableName.APPLICATION_CONDITIONS],
          columns: [
            ...(state.displayColumns[DatatableName.APPLICATION_CONDITIONS]?.columns || []).map(
              (c) => ({
                ...c,
                name: ConditionOfApprovalTableKeyRecord[<ApplicationConditionTableKey>c.field],
              }),
            ),
          ],
        },
        [DatatableName.DEFAULT_FEES]: {
          ...state.displayColumns[DatatableName.DEFAULT_FEES],
          columns: [
            ...(state.displayColumns[DatatableName.DEFAULT_FEES]?.columns || []).map((c) => ({
              ...c,
              name: DefaultFeeKeyRecord[<DefaultFeeKey>c.field],
            })),
          ],
        },
        [DatatableName.EXISTING_MORTGAGES]: {
          ...state.displayColumns[DatatableName.EXISTING_MORTGAGES],
          columns: this.filterDuplicates([
            ...(state.displayColumns[DatatableName.EXISTING_MORTGAGES]?.columns || [])
              .map((c) => {
                const name =
                  c.field === MortgageKey.TOTAL_MORTGAGE_AMOUNT
                    ? $localize`Loan Amount / Credit Limit`
                    : MortgageKeyRecord[<MortgageKey>c.field];

                return {
                  ...c,
                  name,
                };
              })
              .filter(
                (c) =>
                  !c.isOptional ||
                  existingMortgageOptionalDisplayColumns.map((x) => x.field).includes(c.field),
              ),
            ...existingMortgageOptionalDisplayColumns,
          ]),
        },
        [DatatableName.FEES]: {
          ...state.displayColumns[DatatableName.FEES],
          columns: [
            ...(state.displayColumns[DatatableName.FEES]?.columns || []).map((c) => ({
              ...c,
              name: FeeKeyRecord[<FeeKey>c.field],
            })),
          ],
        },
        [DatatableName.MANAGE_CONDITIONS]: {
          ...state.displayColumns[DatatableName.MANAGE_CONDITIONS],
          columns: [
            ...(state.displayColumns[DatatableName.MANAGE_CONDITIONS]?.columns || []).map((c) => ({
              ...c,
              name: ConditionTableKeyRecord[<ConditionTableKey>c.field],
            })),
          ],
        },
        [DatatableName.PROPERTIES_NET_WORTH]: {
          ...state.displayColumns[DatatableName.PROPERTIES_NET_WORTH],
          columns: [
            ...(state.displayColumns[DatatableName.PROPERTIES_NET_WORTH]?.columns || []).map(
              (c) => {
                const name =
                  c.field === 'mortgageBalance'
                    ? $localize`:@@existingMortgagesTotal:Existing Mortgages Total`
                    : c.field === 'monthlyPayment'
                    ? MortgageKeyRecord[MortgageKey.MONTHLY_PAYMENT]
                    : PropertyKeyRecord[<PropertyKey>c.field];

                return {
                  ...c,
                  name,
                };
              },
            ),
          ],
        },
        [DatatableName.WRITTEN_OFF_LIABILITIES]: {
          ...state.displayColumns[DatatableName.WRITTEN_OFF_LIABILITIES],
          columns: this.filterDuplicates([
            ...(state.displayColumns[DatatableName.WRITTEN_OFF_LIABILITIES]?.columns || [])
              .map((c) => {
                const name =
                  c.field === 'ApplicantFinancialLiabilities'
                    ? FinancialLiabilityKeyRecord[FinancialLiabilityKey.APPLICANT_ID]
                    : FinancialLiabilityKeyRecord[<FinancialLiabilityKey>c.field];

                return {
                  ...c,
                  name,
                };
              })
              .filter(
                (c) =>
                  !c.isOptional ||
                  liabilitiesOptionalDisplayColumns.map((x) => x.field).includes(c.field),
              ),
            ...liabilitiesOptionalDisplayColumns,
          ]),
        },
        [DatatableName.PROPERTY_APPRAISALS]: {
          ...state.displayColumns[DatatableName.PROPERTY_APPRAISALS],
          columns: this.filterDuplicates([
            ...(state.displayColumns[DatatableName.PROPERTY_APPRAISALS]?.columns || [])
              .map((c) => ({
                ...c,
                name: PropertyAppraisalKeyRecord[<PropertyAppraisalKey>c.field],
              }))
              .filter(
                (c) =>
                  !c.isOptional ||
                  propertyAppraisalsOptionalDisplayColumns.map((x) => x.field).includes(c.field),
              ),
            ...propertyAppraisalsOptionalDisplayColumns,
          ]),
        },
        [DatatableName.MANAGE_BROKERS]: {
          ...state.displayColumns[DatatableName.MANAGE_BROKERS],
          columns: [
            ...(state.displayColumns[DatatableName.MANAGE_BROKERS]?.columns || []).map((c) => ({
              ...c,
              name: BrokerTableKeyRecord[<BrokerTableKey>c.field],
            })),
          ],
        },
        [DatatableName.MANAGE_LAWYERS]: {
          ...state.displayColumns[DatatableName.MANAGE_LAWYERS],
          columns: [
            ...(state.displayColumns[DatatableName.MANAGE_LAWYERS]?.columns || []).map((c) => ({
              ...c,
              name: LawyerTableKeyRecord[<LawyerTableKey>c.field],
            })),
          ],
        },
      },
    });
  }

  filterDuplicates(columns: Column[]): Column[] {
    return columns.filter(
      (column, index, self) => index === self.findIndex((c) => c.field === column.field),
    );
  }
}
