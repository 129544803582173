import { Mortgage } from '@fundmoreai/models';
import { inject, injectable } from 'tsyringe';
import { TotalActualPaymentModel } from '../models/total-actual-payment.model';

export interface ITotalActualPaymentCalculator {
  getTotalActualPayment(): number;
}

@injectable()
export class TotalActualPaymentCalculator implements ITotalActualPaymentCalculator {
  private mortgage: Pick<Mortgage, 'paymentAmount' | 'propertyTaxAmount'>;

  constructor(@inject(TotalActualPaymentModel) model: TotalActualPaymentModel) {
    this.mortgage = model.mortgage;
  }

  getTotalActualPayment(): number {
    return (this.mortgage.paymentAmount ?? 0) + (this.mortgage.propertyTaxAmount ?? 0);
  }
}
