import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ApplicationIngest,
  ApplicationSource,
  ApplicationWarningKeys,
  VelocityExternalDealContent,
} from '@fundmoreai/models';
import { Action, State, StateContext } from '@ngxs/store';
import { finalize, of, switchMap, tap } from 'rxjs';
import { LoadingEnd, LoadingStart } from 'src/app/core/loading.state';
import { DuplicatesEmail } from 'src/app/features/application/sidebar/manage-duplicates/model';
import { AnalysedMortgageApplicationModel } from 'src/app/features/pipeline/upload/recognitionModel';
import { Application } from 'src/app/shared';
import { PatchApplication } from '../mortgage-application.actions';
import { MortgageApplicationService } from '../mortgage-application.service';
import { Note } from '../notes/model';
import { AttachNote } from '../notes/notes.state';
import { CreateEzidoxApplication } from './ezidox.state.actions';

export class CreateApplication {
  static readonly type = '@applicationState.CreateApplication';
  constructor(
    public newApplication: Partial<AnalysedMortgageApplicationModel>,
    public duplicatesEmail: DuplicatesEmail | undefined,
    public note?: Note | null,
  ) {}
}

export class UpdateApplication {
  static readonly type = '@applicationState.UpdateApplication';
  constructor(public id: string, public changes: Partial<Application>) {}
}

export class SetApplicationWarnings {
  static readonly type = '@applicationState.SetApplicationWarnings';
  constructor(public applicationId: string, public warnings: ApplicationWarningKeys[]) {}
}

export class CloseSidenav {
  static readonly type = '@applicationState.CloseSidenav';
  public constructor() {}
}

export class IngestApplication {
  static readonly type = '@applicationState.IngestApplication';
  constructor(public applicationIngest: ApplicationIngest) {}
}

interface ApplicationStateModel {
  application: Application;
}
@State<ApplicationStateModel>({
  name: 'applicationState',
})
@Injectable()
export class ApplicationState {
  constructor(
    private router: Router,
    private applicationService: MortgageApplicationService,
    private activatedRoute: ActivatedRoute,
  ) {}

  @Action(CreateApplication)
  private createApplication(ctx: StateContext<ApplicationStateModel>, action: CreateApplication) {
    const { newApplication, duplicatesEmail, note } = action;
    const customer = newApplication.primaryApplicant;
    const createDocumentRequest = customer?.createDocumentRequest;
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationService.createApplication(newApplication, duplicatesEmail).pipe(
      switchMap((application) => {
        if (note) {
          ctx.dispatch(new AttachNote(note, note.id, application.id));
        }

        if (createDocumentRequest) {
          ctx.dispatch(new CreateEzidoxApplication(application.id));
        }

        return of(application.id);
      }),
      tap((applicationId) => {
        if (newApplication.isServicing) {
          this.router.navigate(['/portal/application/servicing', applicationId]);
        } else {
          this.router.navigate(['/portal/application', applicationId]);
        }
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateApplication)
  private updateApplication(ctx: StateContext<ApplicationStateModel>, action: UpdateApplication) {
    const { id, changes } = action;
    // ctx.dispatch(new LoadingStart(this.constructor.name));
    // return this.applicationService.patchApplication(id, changes).pipe(
    //   finalize(() => {
    //     ctx.dispatch(new LoadingEnd(this.constructor.name));
    //   }),
    //   finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    // );
    // TODO: remove this state dependency
    return ctx.dispatch(new PatchApplication(id, changes));
  }

  @Action(SetApplicationWarnings) private setWarnings(
    ctx: StateContext<ApplicationStateModel>,
    action: SetApplicationWarnings,
  ) {
    const { applicationId, warnings } = action;
    return ctx.dispatch(new PatchApplication(applicationId, { warnings }));
  }

  @Action(CloseSidenav) private closeSidenav(
    ctx: StateContext<ApplicationStateModel>,
    action: CloseSidenav,
  ) {
    return this.router.navigate([], { relativeTo: this.activatedRoute });
  }

  @Action(IngestApplication)
  private ingestApplication(ctx: StateContext<ApplicationStateModel>, action: IngestApplication) {
    const { applicationIngest } = action;
    ctx.dispatch(new LoadingStart(this.constructor.name));
    if (!applicationIngest || !applicationIngest.payload) {
      return of(null);
    }

    switch (applicationIngest.source) {
      case ApplicationSource.VELOCITY: {
        return this.applicationService.createVelocityApplication(applicationIngest.payload).pipe(
          tap((applicationId) => {
            this.router.navigate(['/portal/application', applicationId]);
          }),
          finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
        );
      }
      case ApplicationSource.LENDESK:
        return this.applicationService.createLendeskApplication(applicationIngest.payload).pipe(
          tap((applicationId) => {
            this.router.navigate(['/portal/application', applicationId]);
          }),
          finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
        );
      case ApplicationSource.FILOGIX:
        return this.applicationService.createFilogixApplication(applicationIngest.payload).pipe(
          tap((applicationId) => {
            this.router.navigate(['/portal/application', applicationId]);
          }),
          finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
        );
      default:
        return of(null);
    }
  }
}
