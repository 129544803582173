import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { EMPTY, finalize, forkJoin, merge, of, switchMap, tap } from 'rxjs';
import { LoadingEnd, LoadingStart } from 'src/app/core/loading.state';
import { Mortgage, MortgageType, Property } from 'src/app/shared';
import { ApplicationResetState } from 'src/app/shared/state.model';
import { MortgagesV2Services } from './mortgages-v2.service';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import { FeesState } from '../fees.state';
import { PropertiesState } from 'src/app/portal/properties.state';
import {
  CalculationResult,
  DownPaymentType,
  Fee,
  FundmoreRecommendationModel,
  FundmoreRecommendationType,
} from '../../shared/model';
import { AuthState } from '../../auth/auth.state';
import { AppFeaturesState, AppFeaturesStateModel } from '../../shared/app-features.state';
import {
  ApplicationPurposeType,
  assertUnreachable,
  CompoundPeriod,
  DatesProcessingType,
  PropertyType,
  LoanType,
  MortgageCalculationAutomationSettings,
  MortgageKey,
  RepaymentType,
  PaymentFrequency,
  AmortizedMonthlyPaymentSnapshot,
  MCUIntegrationFields,
  MortgageState,
  MortgageStatus,
  AdvancedProduct,
  DiscretionStatus,
  RefinancedMortgageMarkAsType,
  OtherRateConfigurations,
  Application,
} from '@fundmoreai/models';
import { FlipMortgageDetails, FlipUpdateMortgageFields } from '../flip/flip.state.action';
import { DialogsComponent } from 'src/app/features/application/sidebar/dialogs/dialogs.component';
import { MatDialog } from '@angular/material/dialog';
import { DownPaymentState } from '../downpayments.state';
import { PreviewMortgageAdvancedProductSelection } from '../advanced-products/advanced-product.state';
import {
  ApplyRecommendation,
  CreateMortgage,
  DeleteMortgage,
  SetMortgages,
  UpdateMortgage,
  UpdateRequestedMortgagePaymentIfNeeded,
  UpdateMortgageTotalLoanAmountIfNeeded,
  UpdateRequestedMortgage,
  RecomputeLoanAmountOnRequestedMortgage,
  UpdatePSTOnRequestedMortgageIfNeeded,
  ClosingDateChanged,
  IADDateChanged,
  PatchMortgage,
  PaymentFrequencyChanged,
  TermChanged,
  UpdateMortgagePrepaymentAmountIfNeeded,
  UpdateMortgageBaseRate,
  UpdateMortgageBuyDownRate,
  UpdateMortgageDiscount,
  RateTypeChanged,
  RateTypeChangedForPaymentFrequency,
  FirstRegularPaymentDateChanged,
  UpdateMortgagePropertyId,
  UpdateMortgageStatus,
  UpdateMortgagesLoanNumberLocal,
  UpdateMortgageState,
  UpdateMortgageIncrement,
  UpsertMortgageCustomIncrement,
  UpdateMortgageDecrement,
  PatchMortgageBrokerCommission,
  UpdateMortgageDiscretion,
  UpdateExistingLOCMortgagePayments,
  AddUploadedStatusToRequestedMortgages,
  RefreshProjectedBalance,
  ClearPropertyLinksAndDropLinkedExistingMortgages,
  SetProjectedBalance,
} from './mortgages-v2.actions';
import { InsuranceState } from '../insurances.state';
import { MortgageApplicationState } from '../mortgage-application.state';
import { AddLocalDownPayment } from '../downpayments.actions';
import { max } from 'date-fns';
import { MortgagesServices } from '../mortgages.services';
import { EqCoreState } from '../eq-core.state';
import { FeatureConfig } from '../../../environments/environment.base';
import { Column } from 'src/app/features/manager-portal/condition-manage/model';
import { MortgageKeyRecord } from 'src/app/shared/enum-records-metadata';
import { fromShortISODateToMiddleLocalDate } from '@fundmoreai/helpers';
import { compose, patch, updateItem } from '@ngxs/store/operators';
import { MortgageRefreshRateHoldDate } from '../mortgage-application.actions';
import { computeExistingLOCMortgagePayments } from '@fundmoreai/calculator/dist/src/fundmore.calculator';
import {
  BlendedRateRenewalCalculationModel,
  Container,
  IBlendedRateRenewalCalculator,
  TYPES,
} from '@fundmore/calculator-v2';
import { TotalActualPaymentCalculator } from '@fundmore/calculator-v2';

const EXISTING_MORTGAGE_SELECTABLE_COLUMNS: MortgageKey[] = [
  MortgageKey.MARK_AS,
  MortgageKey.MORTGAGE_TYPE,
  MortgageKey.PAYMENT_FREQUENCY,
  MortgageKey.MONTHLY_PAYMENT,
  MortgageKey.MORTGAGE_BALANCE,
  MortgageKey.BORROWER,
  MortgageKey.CLOSING_DATE,
  MortgageKey.REFI_BLENDED_AMORTIZATION,
  MortgageKey.LENDER,
  MortgageKey.TOTAL_MORTGAGE_AMOUNT,
  MortgageKey.LOAN_TYPE,
  MortgageKey.MATURITY_DATE,
  MortgageKey.MORTGAGE_NUMBER,
  MortgageKey.NET_RATE,
  MortgageKey.ORIGINAL_MI_NUMBER,
  MortgageKey.ORIGINAL_MI_PROVIDER,
  MortgageKey.PAYOFF,
  MortgageKey.PAYOFF_PAYDOWN,
  MortgageKey.PAYOUT_BALANCE,
  MortgageKey.PURPOSE,
];

export const EXISTING_MORTGAGES_DEFAULT_DISPLAY_COLUMNS: Column[] =
  EXISTING_MORTGAGE_SELECTABLE_COLUMNS.map((value: MortgageKey) => {
    const name =
      value === MortgageKey.TOTAL_MORTGAGE_AMOUNT
        ? $localize`Loan Amount / Credit Limit`
        : MortgageKeyRecord[value];

    return {
      field: value,
      name: name,
      isSelected: [
        MortgageKey.MARK_AS,
        MortgageKey.MORTGAGE_TYPE,
        MortgageKey.PAYMENT_FREQUENCY,
        MortgageKey.MONTHLY_PAYMENT,
        MortgageKey.MORTGAGE_BALANCE,
      ].includes(value),
      isFrozen: false,
    };
  });

export interface MortgagesStateModel {
  mortgages: Mortgage[];
}

// DO NOT MIGRATE requestedMortgage$. Rather than migrating requestedMortgage$, adapt code to use requestedMortgages$
// requestedMortgage$ is to be migrated to firstFoundRequestedMortgage$ and is to be used for backwards
// compatibility with documments

@State<MortgagesStateModel>({
  defaults: {
    mortgages: [],
  },
  name: 'mortgagesV2State',
})
@Injectable()
export class MortgagesV2State {
  static NAME = 'mortgages';

  constructor(
    private mortgageServices: MortgagesV2Services,
    private mortgageServicesV1: MortgagesServices,
    private store: Store,
    private dialog: MatDialog,
  ) {}

  @Selector() static mortgages(state: MortgagesStateModel) {
    return state.mortgages;
  }

  static mortgageById(mortgageId: string) {
    return createSelector([MortgagesV2State.mortgages], (mortgages: Mortgage[]) =>
      mortgages.find((mortgage) => mortgage.id === mortgageId),
    );
  }

  @Selector() static requestedMortgages(state: MortgagesStateModel) {
    return state.mortgages
      .filter((m) => m.type === MortgageType.REQUESTED)
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  }

  @Selector([MortgagesV2State.mortgages]) static incrementMortgageDetails(mortgages: Mortgage[]) {
    const result: Partial<Mortgage>[] = [];
    const sortedMortgages = [...mortgages].sort(
      (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );

    for (const mortgage of sortedMortgages) {
      if (mortgage.type === MortgageType.REQUESTED || mortgage.type === MortgageType.SERVICING) {
        result.push({
          termMonths: mortgage.termMonths,
          amortizationMonths: mortgage.amortizationMonths,
          mortgageType: mortgage.mortgageType,
          purpose: mortgage.purpose,
          type: mortgage.type,
          createdAt: mortgage.createdAt,
        });
      }
    }

    return result;
  }

  @Selector([MortgagesV2State.requestedMortgages])
  static activeRequestedMortgages(requestedMortgages: Mortgage[]) {
    return requestedMortgages.filter(
      (m) => m.state !== MortgageState.CANCELLED && m.state !== MortgageState.DECLINED,
    );
  }

  @Selector([MortgagesV2State.activeRequestedMortgages])
  static activeRequestedMortgagesForCalculations(requestedMortgages: Mortgage[]) {
    return requestedMortgages.filter((m) => m.loanType !== LoanType.BRIDGE);
  }

  @Selector([MortgagesV2State.activeRequestedMortgages, AppFeaturesState.isProductStatusEnabled])
  static activeRequestedMortgagesStatusBlockingFunding(
    requestedMortgages: Mortgage[],
    isProductStatusEnabled: boolean,
  ) {
    if (!isProductStatusEnabled) {
      return false;
    }

    return !requestedMortgages.every((rm) => rm.status?.includes(MortgageStatus.FUNDS_DISBURSED));
  }

  @Selector([MortgagesV2State.requestedMortgages])
  static requestedMortgagesIds(requestedMortgages: Mortgage[]) {
    return requestedMortgages.map((x) => x.id);
  }

  @Selector() static refinanceMortgages(state: MortgagesStateModel) {
    return state.mortgages.filter((m) => m.type === MortgageType.REFINANCE);
  }

  @Selector() static existingMortgages(state: MortgagesStateModel) {
    return state.mortgages.filter((m) => m.type === MortgageType.EXISTING);
  }

  @Selector([MortgagesV2State.existingAndRefinanceMortgages])
  static getExistingMortgageForBlendedRate(mortgages: Mortgage[]) {
    const existingMortgageForPort = mortgages.find(
      (mortgage) =>
        mortgage.markAs === RefinancedMortgageMarkAsType.PORTED ||
        mortgage.markAs === RefinancedMortgageMarkAsType.REFINANCED,
    );
    if (!existingMortgageForPort) {
      return null;
    }
    return existingMortgageForPort;
  }

  @Selector() static servicingMortgages(state: MortgagesStateModel) {
    return state.mortgages.filter((m) => m.type === MortgageType.SERVICING);
  }

  @Selector([MortgagesV2State.servicingMortgages])
  static servicingMortgagesIds(servicingMortgages: Mortgage[]) {
    return servicingMortgages.map((x) => x.id);
  }

  static existingMortgage(existingMortgageId: string) {
    return createSelector([MortgagesV2State.existingMortgages], (existingMortgages: Mortgage[]) => {
      return existingMortgages.find((x) => x.id === existingMortgageId);
    });
  }

  static existingAndRefinancedMortgage(mortgageId: string) {
    return createSelector(
      [MortgagesV2State.existingAndRefinanceMortgages],
      (mortgages: Mortgage[]) => mortgages.find((mortgage) => mortgage.id === mortgageId),
    );
  }

  @Selector([MortgagesV2State.existingMortgages])
  static existingMortgagesIds(existingMortgages: Mortgage[]) {
    return existingMortgages.map((x) => x.id).sort();
  }

  @Selector([AppFeaturesState])
  static existingMortgageOptionalDisplayColumns(appFeaturesState: AppFeaturesStateModel): Column[] {
    const optionalFields = appFeaturesState?.features?.optionalFields ?? [];

    const securityFieldsEnabled = optionalFields?.includes(MCUIntegrationFields.SECURITY);

    const optionalColumns: Column[] = [];
    if (securityFieldsEnabled) {
      optionalColumns.push({
        field: MortgageKey.SECURITY_TYPE,
        name: MortgageKeyRecord[MortgageKey.SECURITY_TYPE],
        isSelected: true,
        isFrozen: false,
        isOptional: true,
      });
    }

    return optionalColumns;
  }

  @Selector([
    MortgagesV2State.requestedMortgages,
    AppFeaturesState.features,
    EqCoreState.getLoanUpdateErrors,
  ])
  static mortgageCalculationAutomationSettings(
    requestedMortgages: Mortgage[],
    features: FeatureConfig,
    eqErrors: string[],
  ) {
    if (features.eqMapsEnabled) {
      const mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings =
        FundmoreCalculator.getEqSettingsPerMortgage(requestedMortgages, eqErrors?.length > 0);

      return mortgageCalculationAutomationSettings;
    }

    if (!features.excludeExtraPaymentInTerm) {
      return null;
    }

    return {
      ANY_MORTGAGE: {
        isInterestAdjustmentAmountDisabled: undefined,
        isInterestAdjustmentDateDisabled: undefined,
        isMaturityDateDisabled: undefined,
        excludeExtraPaymentInTerm: features.excludeExtraPaymentInTerm,
        isMonthlyPaymentDisabled: undefined,
        isPaymentAmountDisabled: undefined,
        setGDSToZero: undefined,
        setTDSToZero: undefined,
      },
    };
  }

  @Selector([MortgagesV2State.requestedMortgages]) static oldestClosingDate(
    requestedMortgages: Mortgage[],
  ) {
    if (!requestedMortgages || requestedMortgages.length === 0) {
      return;
    }
    const mortgage = [...requestedMortgages].sort(function (a, b) {
      if (a.closingDate === null) {
        return 1;
      }
      if (b.closingDate === null) {
        return -1;
      }
      if (a.closingDate === b.closingDate) {
        return 0;
      }
      return a.closingDate < b.closingDate ? -1 : 1;
    })[0];
    return mortgage?.closingDate;
  }

  static partnerLoanYield(requestedMortgageId: string | undefined) {
    return createSelector(
      [MortgagesV2State.requestedMortgage(requestedMortgageId ?? '')],
      (requestedMortgage: Mortgage | undefined) => {
        if (!requestedMortgage) {
          return null;
        }

        return requestedMortgage.partnerLoanYield;
      },
    );
  }

  static computePaymentInputByRequestedMortgage(requestedMortgageId: string) {
    return createSelector(
      [MortgagesV2State.requestedMortgage(requestedMortgageId)],
      (requestedMortgage: Mortgage | undefined) => {
        return JSON.stringify({
          compounding: requestedMortgage?.compounding,
          amortizationMonths: requestedMortgage?.amortizationMonths,
          monthlyPayment: requestedMortgage?.monthlyPayment,
          repaymentType: requestedMortgage?.repaymentType,
          paymentFrequency: requestedMortgage?.paymentFrequency,
          prepaymentType: requestedMortgage?.prepaymentType,
          netRate: requestedMortgage?.netRate,
          loanType: requestedMortgage?.loanType,
        });
      },
    );
  }

  @Selector([MortgagesV2State.existingMortgages, MortgagesV2State.refinanceMortgages])
  static existingAndRefinanceMortgages(existing: Mortgage[], refinance: Mortgage[]) {
    return [...(existing ?? []), ...(refinance ?? [])];
  }

  @Selector([MortgagesV2State.requestedMortgages])
  static firstFoundRequestedMortgage(requestedMortgages: Mortgage[]): Mortgage | undefined {
    return requestedMortgages[0];
  }

  @Selector([MortgagesV2State.requestedMortgages])
  static firstFoundRequestedLoanTypeMortgage(requestedMortgages: Mortgage[]) {
    return requestedMortgages.find((m) => m.loanType == LoanType.MORTGAGE);
  }

  @Selector([MortgagesV2State.requestedMortgages])
  static firstFoundRequestedLoanTypeLOC(requestedMortgages: Mortgage[]) {
    return requestedMortgages.find(
      (m) =>
        m.loanType == LoanType.SECURE_LINE_OF_CREDIT ||
        m.loanType == LoanType.SECURE_LINE_OF_CREDIT_FLEX,
    );
  }

  static requestedMortgage(requestedMortgageId: string) {
    return createSelector(
      [MortgagesV2State.requestedMortgages],
      (requestedMortgages: Mortgage[]) => {
        return requestedMortgages.find((x) => x.id === requestedMortgageId);
      },
    );
  }

  @Selector([MortgagesV2State.mortgages, PropertiesState.primaryProperty])
  static primaryPropertyExistingAndRefinanceMortgages(
    mortgages: Mortgage[],
    primaryProperty: Property,
  ) {
    return mortgages
      .filter(
        (mortgage) =>
          mortgage.propertyId === primaryProperty?.id &&
          (mortgage.type === MortgageType.EXISTING || mortgage.type === MortgageType.REFINANCE),
      )
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }

  @Selector([MortgagesV2State.mortgages, PropertiesState.borrowerProperties])
  static borrowerExistingMortgages(
    mortgages: Mortgage[],
    borrowerProperties: Property[] | undefined,
  ): Mortgage[] {
    const borrowerPropertiesId = borrowerProperties?.map((property) => property.id) || [];

    return mortgages.filter(
      (m) =>
        m.type === MortgageType.EXISTING &&
        m.propertyId &&
        borrowerPropertiesId.includes(m.propertyId),
    );
  }

  @Selector([MortgagesV2State.mortgages, PropertiesState.borrowerProperties])
  static borrowerExistingAndRefinanceMortgages(
    mortgages: Mortgage[],
    borrowerProperties: Property[] | undefined,
  ): Mortgage[] {
    const borrowerPropertiesId = borrowerProperties?.map((property) => property.id) || [];

    return mortgages.filter(
      (m) =>
        (m.type === MortgageType.EXISTING || m.type === MortgageType.REFINANCE) &&
        m.propertyId &&
        borrowerPropertiesId.includes(m.propertyId),
    );
  }

  static existingMortgageAmount(applicantId?: string) {
    return createSelector(
      [MortgagesV2State.borrowerExistingMortgages],
      (borrowerExistingMortgages: Mortgage[]): number => {
        return FundmoreCalculator.computeExistingMortgageAmount(
          borrowerExistingMortgages,
          applicantId,
        );
      },
    );
  }

  static existingAndRefinanceMortgageAmount(applicantId?: string) {
    return createSelector(
      [MortgagesV2State.borrowerExistingAndRefinanceMortgages],
      (borrowerExistingMortgages: Mortgage[]): number => {
        return FundmoreCalculator.computeExistingMortgageAmountRegardlessOfPayoff(
          borrowerExistingMortgages,
          applicantId,
        );
      },
    );
  }

  @Selector([MortgagesV2State.mortgages, PropertiesState.borrowerProperties])
  static borrowerExistingMortgagesForPrimaryProperty(
    mortgages: Mortgage[],
    borrowerProperties: Property[] | undefined,
  ): Mortgage[] {
    const primaryProperty = borrowerProperties?.find((p) => p?.type === PropertyType.PRIMARY);
    const borrowerPropertyId = primaryProperty?.id;

    return mortgages.filter(
      (m) => m.type === MortgageType.EXISTING && borrowerPropertyId === m.propertyId,
    );
  }

  @Selector([MortgagesV2State.borrowerExistingMortgagesForPrimaryProperty])
  static primaryPropertyExistingMortgageAmount(borrowerExistingMortgages: Mortgage[]): number {
    return FundmoreCalculator.computeExistingMortgageAmount(borrowerExistingMortgages);
  }

  static linkedPropertyExistingMortgageAmount(mortgageId: string) {
    return createSelector(
      [
        MortgagesV2State.mortgages,
        MortgagesV2State.primaryPropertyExistingMortgageAmount,
        AppFeaturesState.isBlanketEnabled,
      ],
      (
        mortgages: Mortgage[],
        primaryPropertyExistingMortgageAmount: number,
        isBlanketEnabled: boolean,
      ) => {
        if (isBlanketEnabled) {
          return primaryPropertyExistingMortgageAmount;
        }

        const linkedPropertyId = mortgages.find((m) => m.id === mortgageId)?.propertyId;

        const borrowerExistingMortgages = mortgages.filter(
          (m) => m.type === MortgageType.EXISTING && linkedPropertyId === m.propertyId,
        );

        return FundmoreCalculator.computeExistingMortgageAmount(borrowerExistingMortgages);
      },
    );
  }
  @Selector([
    MortgagesV2State.activeRequestedMortgages,
    MortgagesV2State.mortgages,
    MortgagesV2State.primaryPropertyExistingMortgageAmount,
    AppFeaturesState.isBlanketEnabled,
  ])
  static linkedPropertyExistingMortgageAmountAllRequestedMortgages(
    activeRequestedMortgages: Mortgage[],
    mortgages: Mortgage[],
    primaryPropertyExistingMortgageAmount: number,
    isBlanketEnabled: boolean,
  ) {
    if (isBlanketEnabled) {
      return primaryPropertyExistingMortgageAmount;
    }

    let existingMortgageAmountAccumulator = 0;

    for (const requestedMortgage of activeRequestedMortgages) {
      const linkedPropertyId = requestedMortgage?.propertyId;

      const borrowerExistingMortgages = mortgages.filter(
        (m) => m.type === MortgageType.EXISTING && linkedPropertyId === m.propertyId,
      );

      const existingMortgageAmount =
        FundmoreCalculator.computeExistingMortgageAmount(borrowerExistingMortgages);

      existingMortgageAmountAccumulator += existingMortgageAmount;
    }

    return existingMortgageAmountAccumulator;
  }

  static linkedPropertyValue(mortgageId: string) {
    return createSelector(
      [
        MortgagesV2State.requestedMortgage(mortgageId),
        PropertiesState.securities,
        PropertiesState.primaryProperty,
        AppFeaturesState.isBlanketEnabled,
      ],
      (
        mortgage: Mortgage | undefined,
        securities: Property[] | undefined,
        primaryProperty: Property | undefined,
        isBlanketEnabled: boolean,
      ) => {
        if (isBlanketEnabled) {
          return FundmoreCalculator.computePropertyValue(primaryProperty);
        }

        const linkedPropertyId = mortgage?.propertyId;

        const linkedProperty = securities?.find((s) => s.id === linkedPropertyId);

        return FundmoreCalculator.computePropertyValue(linkedProperty);
      },
    );
  }

  @Selector([
    MortgagesV2State.activeRequestedMortgagesForCalculations,
    PropertiesState.securities,
    PropertiesState.primaryProperty,
    AppFeaturesState.isBlanketEnabled,
  ])
  static linkedPropertyValueAllRequestedMortgages(
    activeRequestedMortgages: Mortgage[],
    securities: Property[] | undefined,
    primaryProperty: Property | undefined,
    isBlanketEnabled: boolean,
  ) {
    if (isBlanketEnabled) {
      return FundmoreCalculator.computePropertyValue(primaryProperty);
    }

    let linkedPropertyValueAccumulator = 0;

    const handledProperties: string[] = [];

    for (const requestedMortgage of activeRequestedMortgages) {
      const linkedPropertyId = requestedMortgage?.propertyId;

      const linkedProperty = securities?.find((s) => s.id === linkedPropertyId);

      if (linkedProperty && !handledProperties.includes(linkedProperty.id)) {
        linkedPropertyValueAccumulator += FundmoreCalculator.computePropertyValue(linkedProperty);
        handledProperties.push(linkedProperty.id);
      }
    }

    return linkedPropertyValueAccumulator;
  }

  @Selector([MortgagesV2State.activeRequestedMortgages])
  static totalLoanAmountWithoutCapFees(requestedMortgages: Mortgage[]) {
    let totalLoanAmountValue = 0;
    requestedMortgages.forEach((requestedMortgage) => {
      totalLoanAmountValue +=
        FundmoreCalculator.computeTotalLoanAmountWithoutCapFees(
          requestedMortgage,
          requestedMortgage.insuranceAmount,
        ) ?? 0;
    });
    return Math.round(totalLoanAmountValue * 100) / 100;
  }

  @Selector([MortgagesV2State.servicingNewMortgage])
  static totalLoanAmountWithoutCapFeesForServicing(servicingMortgage: Mortgage | undefined) {
    if (!servicingMortgage) {
      return 0;
    }

    const totalLoanAmountValue =
      FundmoreCalculator.computeTotalLoanAmountWithoutCapFees(
        servicingMortgage,
        servicingMortgage.insuranceAmount,
      ) ?? 0;

    return Math.round(totalLoanAmountValue * 100) / 100;
  }

  @Selector([
    FeesState.feesList,
    MortgagesV2State.totalLoanAmountWithoutCapFees,
    MortgagesV2State.existingMortgageAmount,
    PropertiesState.primaryProperty,
  ])
  static capFeesMaxPercentage(
    fees: Fee[] | undefined,
    totalLoanAmount: number | undefined,
    existingMortgageAmount: number,
    property: Property,
  ): number | undefined {
    return FundmoreCalculator.computeCapFeesMaxPercentage(
      fees || [],
      totalLoanAmount,
      existingMortgageAmount,
      property?.estimatedValue,
    );
  }

  @Selector([
    FeesState.feesList,
    MortgagesV2State.totalLoanAmountWithoutCapFeesForServicing,
    MortgagesV2State.existingMortgageAmount,
    PropertiesState.primaryProperty,
  ])
  static capFeesMaxPercentageForServicing(
    fees: Fee[] | undefined,
    totalLoanAmount: number | undefined,
    existingMortgageAmount: number,
    property: Property,
  ): number | undefined {
    return FundmoreCalculator.computeCapFeesMaxPercentage(
      fees || [],
      totalLoanAmount,
      existingMortgageAmount,
      property?.estimatedValue,
    );
  }

  static totalLoanAmount(requestedMortgageId: string) {
    return createSelector(
      [
        MortgagesV2State.activeRequestedMortgages,
        FeesState.feesList,
        MortgagesV2State.capFeesMaxPercentage,
      ],
      (
        requestedMortgages: Mortgage[],
        fees: Fee[] | undefined,
        capFeesMaxPercentage: number | undefined,
      ) => {
        const requestedMortgage = requestedMortgages?.find((m) => m.id === requestedMortgageId);
        if (!requestedMortgage) {
          return 0;
        }

        const totalLoanAmount =
          FundmoreCalculator.computeTotalLoanAmount(
            requestedMortgage,
            fees || [],
            requestedMortgage?.insuranceAmount,
            capFeesMaxPercentage,
          ) ?? 0;

        return totalLoanAmount;
      },
    );
  }

  @Selector([
    MortgagesV2State.activeRequestedMortgages,
    FeesState.feesList,
    MortgagesV2State.capFeesMaxPercentage,
  ])
  static totalLoanAmountForAllRequestedMortgages(
    requestedMortgages: Mortgage[],
    fees: Fee[] | undefined,
    capFeesMaxPercentage: number | undefined,
  ): number {
    let total = 0;

    for (const mortgage of requestedMortgages) {
      const totalLoanAmount =
        FundmoreCalculator.computeTotalLoanAmount(
          mortgage,
          fees || [],
          mortgage.insuranceAmount,
          capFeesMaxPercentage,
        ) ?? 0;

      total += totalLoanAmount;
    }

    return total;
  }

  @Selector([FeesState.feesList, MortgagesV2State.capFeesMaxPercentage])
  static cappedFees(
    fees: Fee[] | undefined,
    capFeesMaxPercentage: number | undefined,
  ): number | undefined {
    const cappedFees = FundmoreCalculator.computeCappedFees(fees || [], capFeesMaxPercentage);

    return cappedFees;
  }

  @Selector([MortgagesV2State.primaryPropertyExistingAndRefinanceMortgages])
  static payoutMortgageBalance(mortgages: Mortgage[]) {
    return FundmoreCalculator.computePayoutMortgageBalance(mortgages);
  }

  @Selector([MortgagesV2State.requestedMortgages])
  static applicationHasDiscretionLimitExceededOnMortgage(mortgages: Mortgage[]) {
    const applicationHasDiscretionLimitExceededOnMortgage =
      mortgages?.some(
        (m) =>
          m.type === MortgageType.REQUESTED &&
          m.discretionStatus === DiscretionStatus.NEED_APPROVAL,
      ) ?? false;

    return applicationHasDiscretionLimitExceededOnMortgage;
  }

  static loanAmountForLTVByMortgage(mortgageId: string) {
    return createSelector(
      [
        MortgagesV2State.requestedMortgage(mortgageId),
        FeesState.feesList,
        MortgagesV2State.capFeesMaxPercentage,
      ],
      (
        requestedMortgage: Mortgage | undefined,
        fees: Fee[] | undefined,
        capFeesMaxPercentage: number,
      ) => {
        if (!requestedMortgage) {
          return;
        }

        return FundmoreCalculator.computeLoanAmountForLTV(
          [requestedMortgage],
          fees || [],
          capFeesMaxPercentage,
        );
      },
    );
  }

  @Selector([
    MortgagesV2State.activeRequestedMortgages,
    FeesState.feesList,
    MortgagesV2State.capFeesMaxPercentage,
  ])
  static loanAmountForLTVAllRequestedMortgages(
    requestedMortgages: Mortgage[],
    fees: Fee[] | undefined,
    capFeesMaxPercentage: number,
  ) {
    return FundmoreCalculator.computeLoanAmountForLTV(
      requestedMortgages,
      fees || [],
      capFeesMaxPercentage,
    );
  }

  @Selector([PropertiesState.primaryProperty, MortgagesV2State.borrowerExistingMortgages])
  static primaryPropertyExistingMortgagePayment(
    primaryProperty: Property | undefined,
    mortgages: Mortgage[],
  ) {
    const existingMortgages = mortgages.filter(
      (mortgage) => mortgage.propertyId === primaryProperty?.id,
    );
    return FundmoreCalculator.computeExistingMortgagePayments(existingMortgages);
  }

  static aprForMortgage(requestedMortgageId: string) {
    return createSelector(
      [
        MortgagesV2State.activeRequestedMortgages,
        MortgagesV2State.totalLoanAmountForAllRequestedMortgages,
        FeesState.feesList,
        AppFeaturesState.processingDates,
        AuthState.tenantCode,
        MortgagesV2State.mortgageCalculationAutomationSettings,
        AppFeaturesState.iadByFpdEnabled,
        AppFeaturesState.takeFullFirstPayment,
      ],
      (
        requestedMortgages: Mortgage[],
        totalLoanAmount: number | undefined,
        fees: Fee[] | undefined,
        processingDates: DatesProcessingType | undefined,
        tenantCode: string,
        mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
        iadByFpdEnabled: boolean,
        takeFullFirstPayment: boolean,
      ) => {
        const reqMortgage = requestedMortgages.find((rm) => rm.id === requestedMortgageId);

        if (
          !reqMortgage ||
          !totalLoanAmount ||
          reqMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
          reqMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX
        ) {
          return;
        }

        const updatedRequestedMortgage = FundmoreCalculator.addDefaultConditionDatesIfMissing(
          reqMortgage,
          processingDates,
          mortgageCalculationAutomationSettings,
          iadByFpdEnabled,
        );

        const apr =
          FundmoreCalculator.computeAPR(
            updatedRequestedMortgage,
            totalLoanAmount,
            fees || [],
            tenantCode,
            takeFullFirstPayment,
            requestedMortgages.length > 1,
          ) ?? 0;

        return Math.round(apr * 1000) / 1000;
      },
    );
  }

  @Selector([
    MortgagesV2State.servicingNewMortgage,
    FeesState.feesList,
    AppFeaturesState.processingDates,
    AuthState.tenantCode,
    MortgagesV2State.mortgageCalculationAutomationSettings,
    AppFeaturesState.iadByFpdEnabled,
    AppFeaturesState.takeFullFirstPayment,
  ])
  static servicingApr(
    servicingMortgage: Mortgage,
    fees: Fee[] | undefined,
    processingDates: DatesProcessingType | undefined,
    tenantCode: string,
    mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
    iadByFpdEnabled: boolean,
    takeFullFirstPayment: boolean,
  ) {
    if (
      !servicingMortgage ||
      !servicingMortgage.totalLoanAmount ||
      servicingMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
      servicingMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX
    ) {
      return;
    }

    const updatedRequestedMortgage = FundmoreCalculator.addDefaultConditionDatesIfMissing(
      servicingMortgage,
      processingDates,
      mortgageCalculationAutomationSettings,
      iadByFpdEnabled,
    );

    const apr =
      FundmoreCalculator.computeAPR(
        updatedRequestedMortgage,
        servicingMortgage.totalLoanAmount,
        fees || [],
        tenantCode,
        takeFullFirstPayment,
        false,
      ) ?? 0;

    return Math.round(apr * 1000) / 1000;
  }

  static maxBetweenClosingDateAndIAD(requestedMortgageId: string) {
    return createSelector(
      [MortgagesV2State.requestedMortgages],
      (requestedMortgages: Mortgage[]) => {
        const reqMortgage = requestedMortgages.find((rm) => rm.id === requestedMortgageId);

        const closingDate = reqMortgage?.closingDate
          ? new Date(reqMortgage.closingDate)
          : new Date();
        const iad = reqMortgage?.interestAdjustmentDate
          ? new Date(reqMortgage.interestAdjustmentDate)
          : new Date();

        return max([closingDate, iad]);
      },
    );
  }

  static amountToBeAdvanced(requestedMortgageId: string) {
    return createSelector(
      [MortgagesV2State.activeRequestedMortgages, FeesState.feesList],
      (requestedMortgages: Mortgage[], fees: Fee[] | undefined) => {
        const reqMortgage = requestedMortgages.find((rm) => rm.id === requestedMortgageId);
        if (!reqMortgage) {
          return null;
        }
        const amountToAdvanceValue =
          FundmoreCalculator.computeAmountToAdvance(
            reqMortgage,
            fees || [],
            reqMortgage.pst,
            reqMortgage.prepaymentAmount,
          ) ?? 0;
        return amountToAdvanceValue;
      },
    );
  }

  @Selector([MortgagesV2State.activeRequestedMortgages])
  static totalAmountToBeAdvanced(requestedMortgages: Mortgage[]) {
    return requestedMortgages.reduce(
      (sum, requestedMortgage) =>
        sum +
        (requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
        requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX
          ? requestedMortgage.loanAmount ?? 0 // aka cash advance
          : requestedMortgage.amountToBeAdvanced ?? 0),
      0,
    );
  }

  @Selector([MortgagesV2State.activeRequestedMortgages])
  static totalCreditLimit(requestedMortgages: Mortgage[]) {
    return requestedMortgages
      .filter(
        (x) =>
          x?.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
          x?.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX,
      )
      .reduce((sum, requestedMortgage) => sum + (requestedMortgage.loanAmount ?? 0), 0);
  }

  static netAmountToBeAdvanced(mortgageId: string) {
    return createSelector(
      [
        MortgagesV2State.activeRequestedMortgages,
        FeesState.feesList,
        AppFeaturesState.processingDates,
        MortgagesV2State.mortgageCalculationAutomationSettings,
        AppFeaturesState.iadByFpdEnabled,
      ],
      (
        requestedMortgages: Mortgage[],
        fees: Fee[] | undefined,
        processingDates: DatesProcessingType | undefined,
        mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
        iadByFpdEnabled: boolean,
      ) => {
        const requestedMortgage = requestedMortgages.find((m) => m.id === mortgageId);

        if (!requestedMortgage) {
          return null;
        }

        const updatedMortgage = FundmoreCalculator.addDefaultConditionDatesIfMissing(
          requestedMortgage,
          processingDates,
          mortgageCalculationAutomationSettings,
          iadByFpdEnabled,
        );

        return (
          FundmoreCalculator.computeNetAmountToAdvance(
            updatedMortgage,
            fees || [],
            updatedMortgage.pst,
            requestedMortgage.prepaymentAmount,
            mortgageCalculationAutomationSettings,
          ) ?? 0
        );
      },
    );
  }

  // Loan Amount + Insurance Amount + Capped Fees
  static totalLoanAmountByRequestedMortgage(requestedMortgageId: string) {
    return createSelector(
      [
        MortgagesV2State.requestedMortgage(requestedMortgageId),
        FeesState.feesList,
        MortgagesV2State.capFeesMaxPercentage,
      ],
      (
        requestedMortgage: Mortgage | undefined,
        fees: Fee[] | undefined,
        capFeesMaxPercentage: number,
      ) => {
        const totalLoanAmount =
          FundmoreCalculator.computeTotalLoanAmount(
            requestedMortgage,
            fees || [],
            requestedMortgage?.insuranceAmount,
            capFeesMaxPercentage,
          ) ?? 0;

        return Math.round(totalLoanAmount * 100) / 100;
      },
    );
  }

  static paymentAmountWithLift(mortgageId: string, newMortgageValues?: Partial<Mortgage>) {
    return createSelector(
      [
        MortgagesV2State.requestedMortgage(mortgageId),
        MortgagesV2State.totalLoanAmount(mortgageId),
        AppFeaturesState.otherRateConfigurations,
      ],
      (
        requestedMortgage: Mortgage | undefined,
        totalLoanAmount: number,
        { liftForVariableRate }: OtherRateConfigurations,
      ) => {
        if (!requestedMortgage || !liftForVariableRate) {
          return;
        }
        const requestedMortgageForCalculation: Mortgage = {
          ...requestedMortgage,
          netRate: requestedMortgage.netRate + liftForVariableRate,
          ...newMortgageValues,
        };

        return (
          FundmoreCalculator.computeMortgagePaymentAmount(
            requestedMortgageForCalculation,
            totalLoanAmount,
            requestedMortgageForCalculation.netRate,
          ) ?? 0
        );
      },
    );
  }

  static paymentAmountCalculationDetails(
    mortgageId: string,
    useAmortizedPaymentSnapshot: boolean = false,
  ) {
    return createSelector(
      [
        MortgagesV2State.requestedMortgage(mortgageId),
        MortgagesV2State.totalLoanAmount(mortgageId),
        MortgagesV2State.mortgageCalculationAutomationSettings,
        EqCoreState.getLoanUpdateErrors,
      ],
      (
        requestedMortgage: Mortgage | undefined,
        totalLoanAmount: number | undefined,
        mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
        eqLoanUpdateErrors: string[],
      ) => {
        if (!requestedMortgage) {
          return;
        }

        let requestedMortgageForCalculation = requestedMortgage;

        if (
          useAmortizedPaymentSnapshot &&
          (requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
            requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX)
        ) {
          requestedMortgageForCalculation = {
            ...requestedMortgage,
            ...requestedMortgage.amortizedMonthlyPaymentSnapshot,
          };
        }

        const paymentAmount =
          !mortgageCalculationAutomationSettings ||
          !mortgageCalculationAutomationSettings[mortgageId]?.isPaymentAmountDisabled
            ? FundmoreCalculator.computeMortgagePaymentAmount(
                requestedMortgageForCalculation,
                totalLoanAmount,
                requestedMortgageForCalculation.netRate,
              ) ?? 0
            : requestedMortgageForCalculation.paymentAmount ?? 0;

        return FundmoreCalculator.paymentCalculationDetails(
          paymentAmount,
          requestedMortgageForCalculation,
          totalLoanAmount,
          mortgageCalculationAutomationSettings,
          eqLoanUpdateErrors,
        );
      },
    );
  }

  static paymentIsValid(mortgageId: string) {
    return createSelector(
      [MortgagesV2State.paymentAmountCalculationDetails(mortgageId)],
      (payment: CalculationResult | undefined) => {
        return !(!payment && payment !== 0);
      },
    );
  }

  static existingMortgagesByProperty(propertyId: string) {
    return createSelector([MortgagesV2State.mortgages], (mortgages: Mortgage[]) => {
      return mortgages
        .filter(
          (mortgage) =>
            mortgage.propertyId === propertyId &&
            (mortgage.type === MortgageType.EXISTING || mortgage.type === MortgageType.REFINANCE),
        )
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    });
  }

  static existingMortgagePaymentByProperty(propertyId: string | undefined) {
    return createSelector([MortgagesV2State.borrowerExistingMortgages], (mortgages: Mortgage[]) => {
      const existingMortgages = mortgages.filter((mortgage) => mortgage.propertyId === propertyId);
      return FundmoreCalculator.computeExistingMortgagePayments(existingMortgages);
    });
  }

  static isPaymentFrequencyDisabled(requestedMortgageId: string | undefined) {
    return createSelector(
      [
        MortgagesV2State.requestedMortgage(requestedMortgageId ?? ''),
        AppFeaturesState.paymentFrequencyByRateType,
      ],
      (
        requestedMortgage: Mortgage | undefined,
        paymentFrequencyByRateType: { [key: string]: string },
      ) => {
        if (!requestedMortgage || !requestedMortgage.rateType || !paymentFrequencyByRateType) {
          return false;
        }

        const mappedPaymentFrequency = paymentFrequencyByRateType[requestedMortgage.rateType];
        if (
          mappedPaymentFrequency &&
          requestedMortgage.paymentFrequency === mappedPaymentFrequency
        ) {
          return true;
        }
        return false;
      },
    );
  }

  static isDifferenceBetweenFirstPaymentDateAndClosingDateMoreThanFiveDays(
    requestedMortgageId: string | undefined,
  ) {
    return createSelector(
      [
        MortgagesV2State.requestedMortgage(requestedMortgageId ?? ''),
        AppFeaturesState.iadByFpdEnabled,
      ],
      (requestedMortgage: Mortgage | undefined, iadByFpdEnabled: boolean) => {
        if (
          !iadByFpdEnabled ||
          !requestedMortgage ||
          !requestedMortgage.closingDate ||
          !requestedMortgage.firstRegularPaymentDate
        ) {
          return false;
        }

        if (
          FundmoreCalculator.differenceInDaysBetweenDates(
            fromShortISODateToMiddleLocalDate(requestedMortgage.firstRegularPaymentDate),
            fromShortISODateToMiddleLocalDate(requestedMortgage.closingDate),
          ) > 5
        ) {
          return true;
        }
        return false;
      },
    );
  }

  @Selector([MortgagesV2State.mortgages])
  static mortgagesValuesForProductReapplyTriggers(mortgages: Mortgage[]) {
    return mortgages.map((mortgage) => ({
      ApplicantMortgages: mortgage.ApplicantMortgages,
      amortizationMonths: mortgage.amortizationMonths,
      amortizedMonthlyPayment: mortgage.amortizedMonthlyPayment,
      amortizedMonthlyPaymentSnapshot: mortgage.amortizedMonthlyPaymentSnapshot,
      amountToBeAdvanced: mortgage.amountToBeAdvanced,
      balloonAmount: mortgage.balloonAmount,
      baseRate: mortgage.baseRate,
      buyDownRate: mortgage.buyDownRate,
      closingDate: mortgage.closingDate,
      compounding: mortgage.compounding,
      cofDate: mortgage.cofDate,
      conforming: mortgage.conforming,
      constructionCash: mortgage.constructionCash,
      constructionCost: mortgage.constructionCost,
      deadlineToAcceptDate: mortgage.deadlineToAcceptDate,
      discount: mortgage.discount,
      firstRegularPaymentDate: mortgage.firstRegularPaymentDate,
      fundingDate: mortgage.fundingDate,
      prepaymentType: mortgage.prepaymentType,
      includePremiumInMortgage: mortgage.includePremiumInMortgage,
      interestAdjustmentDate: mortgage.interestAdjustmentDate,
      insuranceAccountNum: mortgage.insuranceAccountNum,
      insuranceAmount: mortgage.insuranceAmount,
      insurancePaidBy: mortgage.insurancePaidBy,
      insurancePremium: mortgage.insurancePremium,
      insurancePremiumProgram: mortgage.insurancePremiumProgram,
      insurer: mortgage.insurer,
      interestAdjustmentAmount: mortgage.interestAdjustmentAmount,
      lender: mortgage.lender,
      loanAmount: mortgage.loanAmount,
      loanType: mortgage.loanType,
      maturityDate: mortgage.maturityDate,
      monthlyPayment: mortgage.monthlyPayment,
      mortgageBalance: mortgage.mortgageBalance,
      mortgageNum: mortgage.mortgageNum,
      mortgageType: mortgage.mortgageType,
      netRate: mortgage.netRate,
      partnerLoanEQPortion: mortgage.partnerLoanEQPortion,
      partnerLoanName: mortgage.partnerLoanName,
      partnerLoanPortion: mortgage.partnerLoanPortion,
      partnerLoanYield: mortgage.partnerLoanYield,
      payoff: mortgage.payoff,
      payoffPaydown: mortgage.payoffPaydown,
      payoutBalance: mortgage.payoutBalance,
      paymentAmount: mortgage.paymentAmount,
      paymentFrequency: mortgage.paymentFrequency,
      prepaymentAmount: mortgage.prepaymentAmount,
      prepaymentAmountInPayments: mortgage.prepaymentAmountInPayments,
      prepaymentPenaltyPeriod: mortgage.prepaymentPenaltyPeriod,
      programCode: mortgage.programCode,
      pst: mortgage.pst,
      purchaseValue: mortgage.purchaseValue,
      purpose: mortgage.purpose,
      rateHoldDate: mortgage.rateHoldDate,
      rateType: mortgage.rateType,
      repaymentType: mortgage.repaymentType,
      security: mortgage.security,
      securityType: mortgage.securityType,
      termMonths: mortgage.termMonths,
      termType: mortgage.termType,
      totalLoanAmount: mortgage.totalLoanAmount,
      isLenderLiability: mortgage.isLenderLiability,
    }));
  }

  @Selector([MortgagesV2State.requestedMortgages])
  static closingDateAfterRateHoldDateByMortgage(
    requestedMortgages: Mortgage[],
  ): Record<string, boolean> {
    const byMortgage: Record<string, boolean> = {};
    requestedMortgages.forEach((requestedMortgage) => {
      const closingDate = requestedMortgage.closingDate;
      const rateHoldDate = requestedMortgage.rateHoldDate;
      if (!closingDate || !rateHoldDate) {
        byMortgage[requestedMortgage.id] = false;
      } else {
        const after = new Date(closingDate) > new Date(rateHoldDate);
        byMortgage[requestedMortgage.id] = after;
      }
    });
    return byMortgage;
  }

  @Selector([MortgagesV2State.requestedMortgages])
  static appliedProductByMortgage(
    requestedMortgages: Mortgage[],
  ): Record<string, AdvancedProduct | undefined> {
    const byMortgage: Record<string, AdvancedProduct | undefined> = {};
    requestedMortgages.forEach((requestedMortgage) => {
      const product = requestedMortgage.applicationAdvancedProduct?.advancedProductSnapshot;
      byMortgage[requestedMortgage.id] = product;
    });

    return byMortgage;
  }

  @Selector([MortgagesV2State.requestedMortgages])
  static rateHoldActiveByMortgage(requestedMortgages: Mortgage[]): Record<string, boolean> {
    const byMortgage: Record<string, boolean> = {};
    requestedMortgages.forEach((requestedMortgage) => {
      const rateHoldDate = requestedMortgage.rateHoldDate;
      const rateHoldActive = rateHoldDate ? new Date(rateHoldDate) > new Date() : false;
      byMortgage[requestedMortgage.id] = rateHoldActive;
    });

    return byMortgage;
  }

  @Selector([MortgagesV2State.requestedMortgages])
  static servicingCurrentMortgage(requestedMortgages: Mortgage[]) {
    return requestedMortgages?.[0];
  }

  @Selector([MortgagesV2State.servicingMortgages])
  static servicingNewMortgage(servicingMortgages: Mortgage[]) {
    return servicingMortgages?.[0];
  }

  static getBlendedRate(currentMortgageId: string, newMortgageId: string) {
    return createSelector([MortgagesV2State.mortgages], (mortgages) => {
      const currentMortgage = mortgages.find((mortgage) => mortgage.id === currentMortgageId);
      const newMortgage = mortgages.find((mortgage) => mortgage.id === newMortgageId);
      if (!currentMortgage || !newMortgage) {
        return null;
      }
      const containerChild = Container.initialize(null)
        .createChildContainer()
        .registerInstance(BlendedRateRenewalCalculationModel, {
          newMortgage,
          currentMortgage,
        });
      const blendedRate = containerChild
        .resolve<IBlendedRateRenewalCalculator>(TYPES.IBlendedRateRenewalCalculatorToken)
        .getBlendRate();
      return blendedRate;
    });
  }
  static totalActualPaymentAmount(mortgageId: string) {
    return createSelector([MortgagesV2State.mortgages], (mortgages: Mortgage[]) => {
      const mortgage = mortgages.find((x) => x.id === mortgageId);

      if (!mortgage) {
        return;
      }

      const calculator = new TotalActualPaymentCalculator({ mortgage });

      return calculator.getTotalActualPayment();
    });
  }

  @Action(SetMortgages) setMortgages(ctx: StateContext<MortgagesStateModel>, action: SetMortgages) {
    ctx.patchState({
      mortgages: action.mortgages,
    });
  }

  @Action(ApplicationResetState) resetState(ctx: StateContext<MortgagesStateModel>) {
    ctx.patchState({
      mortgages: [],
    });
  }

  @Action(CreateMortgage) createMortgage(
    ctx: StateContext<MortgagesStateModel>,
    action: CreateMortgage,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageServices.postMortgages(action.applicationId, [action.mortgage]).pipe(
      tap((createdMortgages) => {
        const state = ctx.getState();
        ctx.patchState({
          mortgages: [...state.mortgages, ...createdMortgages],
        });
      }),
      switchMap((createdMortgages) => {
        if (!createdMortgages) {
          return EMPTY;
        }

        return forkJoin([
          ...createdMortgages.map((mortgage) => {
            // update mortgage payment
            if (mortgage.type === MortgageType.REQUESTED) {
              return this.store.dispatch(new UpdateRequestedMortgagePaymentIfNeeded(mortgage.id));
            }
            // update existing LOC mortgage payments
            if (mortgage.type === MortgageType.EXISTING) {
              return this.store.dispatch(new UpdateExistingLOCMortgagePayments(mortgage.id));
            }

            return of(null);
          }),
          // update compounding
          ...createdMortgages.map((requestedMortgage) =>
            this.store.dispatch(
              new RateTypeChanged(requestedMortgage.id, requestedMortgage.rateType),
            ),
          ),
        ]);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateMortgage) updateMortgage(
    ctx: StateContext<MortgagesStateModel>,
    action: UpdateMortgage,
  ) {
    const state = ctx.getState();
    const currentMortgage = state.mortgages.find((m) => m.id === action.mortgageId);

    if (!currentMortgage) {
      return;
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));

    const mortgageToUpdate = {
      ...action.mortgage,
      type: action.mortgage.type ?? currentMortgage.type,
      totalRegularPayment: undefined, // field only set by EQ on loan update response
    };

    return this.mortgageServices
      .patchMortgage(
        action.applicationId,
        action.mortgageId,
        mortgageToUpdate,
        action.hasValueChanged,
      )
      .pipe(
        tap((patchedMortgage) => {
          const state = ctx.getState();
          const currentMortgageSnapshot = state.mortgages.find((a) => a.id === action.mortgageId);

          ctx.patchState({
            mortgages: [
              ...state.mortgages.filter((m) => m.id !== action.mortgageId),
              {
                ...currentMortgageSnapshot,
                ...patchedMortgage,
              },
            ],
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(UpdateRequestedMortgagePaymentIfNeeded)
  updateMortgagePaymentIfNeeded(
    ctx: StateContext<MortgagesStateModel>,
    action: UpdateRequestedMortgagePaymentIfNeeded,
  ) {
    const state = ctx.getState();
    const mortgage = state.mortgages.find((x) => x.id === action.mortgageId);
    const totalLoanAmount = this.store.selectSnapshot(
      MortgagesV2State.totalLoanAmount(action.mortgageId),
    );
    const mortgageCalculationAutomationSettings = this.store.selectSnapshot(
      MortgagesV2State.mortgageCalculationAutomationSettings,
    );

    let monthlyPayment =
      !mortgageCalculationAutomationSettings ||
      !mortgageCalculationAutomationSettings[action.mortgageId]?.isMonthlyPaymentDisabled
        ? FundmoreCalculator.getPayment(
            FundmoreCalculator.computeMortgageMonthlyPayment(
              mortgage,
              totalLoanAmount,
              mortgage?.netRate,
            ),
            mortgage,
          ) ?? 0
        : mortgage?.monthlyPayment;

    let paymentAmount =
      !mortgageCalculationAutomationSettings ||
      !mortgageCalculationAutomationSettings[action.mortgageId]?.isPaymentAmountDisabled
        ? FundmoreCalculator.getPayment(
            FundmoreCalculator.computeMortgagePaymentAmount(
              mortgage,
              totalLoanAmount,
              mortgage?.netRate,
            ),
            mortgage,
          ) ?? 0
        : mortgage?.paymentAmount;

    const locParams = this.store.selectSnapshot(AppFeaturesState.locCalculatorParameters);

    const amortizedMonthlyPaymentSnapshot: AmortizedMonthlyPaymentSnapshot = {
      repaymentType: RepaymentType.PRINCIPAL_AND_INTEREST,
      paymentFrequency: PaymentFrequency.MONTHLY,
      compounding: CompoundPeriod.MONTHLY,
      amortizationMonths: locParams?.amortizationMonths,
      loanAmount: mortgage?.loanAmount,
      totalLoanAmount: totalLoanAmount,
      netRate: mortgage?.netRate ?? undefined,
      termMonths: 1,
    };

    const mortgageForAmortizedPaymentCalculation = {
      ...mortgage,
      ...amortizedMonthlyPaymentSnapshot,
    };
    const isLOC =
      mortgageForAmortizedPaymentCalculation?.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
      mortgageForAmortizedPaymentCalculation?.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX;

    let amortizedMonthlyPayment;
    if (
      amortizedMonthlyPaymentSnapshot.amortizationMonths &&
      amortizedMonthlyPaymentSnapshot.totalLoanAmount &&
      amortizedMonthlyPaymentSnapshot.netRate &&
      isLOC
    ) {
      amortizedMonthlyPayment = FundmoreCalculator.getPayment(
        FundmoreCalculator.computeMortgageMonthlyPayment(
          mortgageForAmortizedPaymentCalculation,
          mortgageForAmortizedPaymentCalculation?.totalLoanAmount ?? undefined,
          mortgageForAmortizedPaymentCalculation?.netRate,
        ),
        mortgageForAmortizedPaymentCalculation,
      );
    }

    if (monthlyPayment) {
      monthlyPayment = Math.round(monthlyPayment * 100) / 100;
    }

    if (paymentAmount) {
      paymentAmount = Math.round(paymentAmount * 100) / 100;
    }

    if (amortizedMonthlyPayment) {
      amortizedMonthlyPayment = Math.round(amortizedMonthlyPayment * 100) / 100;
    }

    if (
      !mortgage ||
      monthlyPayment === undefined ||
      paymentAmount === undefined ||
      (monthlyPayment === mortgage.monthlyPayment &&
        paymentAmount === mortgage.paymentAmount &&
        amortizedMonthlyPayment === mortgage.amortizedMonthlyPayment)
    ) {
      return EMPTY;
    }

    return ctx.dispatch(
      new UpdateMortgage(mortgage.applicationId, action.mortgageId, {
        monthlyPayment,
        paymentAmount,
        amortizedMonthlyPayment,
        amortizedMonthlyPaymentSnapshot,
      }),
    );
  }

  @Action(UpdateExistingLOCMortgagePayments)
  updateExistingLOCMortgagePayments(
    ctx: StateContext<MortgagesStateModel>,
    action: UpdateExistingLOCMortgagePayments,
  ) {
    const state = ctx.getState();
    const mortgage = state.mortgages.find((x) => x.id === action.mortgageId);

    if (!mortgage) {
      return of(null);
    }

    const properties: Property[] | undefined = this.store.selectSnapshot(
      PropertiesState.propertiesList,
    );

    const propertyType = properties?.find((x) => x.id === mortgage?.propertyId)?.type;

    const locCalculatorParameters = this.store.selectSnapshot(
      AppFeaturesState.locCalculatorParameters,
    );

    const { monthlyPayment } = computeExistingLOCMortgagePayments(
      mortgage,
      propertyType,
      locCalculatorParameters,
    );

    return ctx.dispatch(
      new PatchMortgage(mortgage.applicationId, mortgage.id, {
        monthlyPayment,
      }),
    );
  }

  @Action(UpdateMortgageTotalLoanAmountIfNeeded)
  updateMortgageTotalLoanAmountIfNeeded(
    ctx: StateContext<MortgagesStateModel>,
    action: UpdateMortgageTotalLoanAmountIfNeeded,
  ) {
    const requestedMortgages = action.requestedMortgages;
    const fees = action.fees;
    const capFeesMaxPercentage = action.capFeesMaxPercentage;
    requestedMortgages?.forEach((mortgage: Mortgage) => {
      const totalLoanAmountForMortgage = FundmoreCalculator.computeTotalLoanAmount(
        mortgage,
        fees ?? [],
        mortgage?.insuranceAmount,
        capFeesMaxPercentage,
      );

      if (totalLoanAmountForMortgage !== mortgage.totalLoanAmount && totalLoanAmountForMortgage) {
        return this.store.dispatch(
          new UpdateMortgage(mortgage.applicationId, mortgage.id, {
            totalLoanAmount: totalLoanAmountForMortgage,
          }),
        );
      }

      return EMPTY;
    });
  }

  @Action(ClosingDateChanged)
  closingDateChanged(ctx: StateContext<MortgagesStateModel>, action: ClosingDateChanged) {
    const state = ctx.getState();
    const mortgage = state.mortgages.find((m) => m.id === action.mortgageId);

    if (!mortgage) {
      return of(null);
    }

    const mortgageCalculationAutomationSettings = this.store.selectSnapshot(
      MortgagesV2State.mortgageCalculationAutomationSettings,
    );
    const iadByFpdEnabled = this.store.selectSnapshot(AppFeaturesState.iadByFpdEnabled);

    const { firstRegularPaymentDate, interestAdjustmentDate, maturityDate } =
      this.mortgageServicesV1.addDefaultConditionDatesIfMissing(
        mortgage,
        mortgageCalculationAutomationSettings,
        iadByFpdEnabled,
        {
          forceFirstRegularPaymentDateRecalculation: true,
          forceIADRecalculation: true,
          forceMaturityDateRecalculation: true,
        },
      );
    return ctx.dispatch(
      new PatchMortgage(mortgage.applicationId, mortgage.id, {
        firstRegularPaymentDate: firstRegularPaymentDate ?? null,
        interestAdjustmentDate: interestAdjustmentDate ?? null,
        maturityDate: maturityDate ?? null,
      }),
    );
  }

  @Action(IADDateChanged)
  iadDateChanged(ctx: StateContext<MortgagesStateModel>, action: IADDateChanged) {
    const state = ctx.getState();
    const mortgage = state.mortgages.find((m) => m.id === action.mortgageId);

    if (!mortgage) {
      return of(null);
    }
    const mortgageCalculationAutomationSettings = this.store.selectSnapshot(
      MortgagesV2State.mortgageCalculationAutomationSettings,
    );
    const iadByFpdEnabled = this.store.selectSnapshot(AppFeaturesState.iadByFpdEnabled);
    const { maturityDate } = this.mortgageServicesV1.addDefaultConditionDatesIfMissing(
      mortgage,
      mortgageCalculationAutomationSettings,
      iadByFpdEnabled,
      {
        forceFirstRegularPaymentDateRecalculation: true,
        forceIADRecalculation: false,
        forceMaturityDateRecalculation: true,
      },
    );

    const interestAdjustmentAmount = this.getCurrentInterestAdjustmentAmount(mortgage);

    return ctx.dispatch(
      new PatchMortgage(mortgage.applicationId, mortgage.id, {
        maturityDate: maturityDate ?? null,
        interestAdjustmentAmount: interestAdjustmentAmount ?? null,
      }),
    );
  }

  @Action(FirstRegularPaymentDateChanged)
  firstRegularPaymentDateChanged(
    ctx: StateContext<MortgagesStateModel>,
    action: FirstRegularPaymentDateChanged,
  ) {
    const state = ctx.getState();
    const mortgage = state.mortgages.find((m) => m.id === action.mortgageId);

    if (!mortgage) {
      return of(null);
    }
    const mortgageCalculationAutomationSettings = this.store.selectSnapshot(
      MortgagesV2State.mortgageCalculationAutomationSettings,
    );
    const iadByFpdEnabled = this.store.selectSnapshot(AppFeaturesState.iadByFpdEnabled);

    const { interestAdjustmentDate } = this.mortgageServicesV1.addDefaultConditionDatesIfMissing(
      mortgage,
      mortgageCalculationAutomationSettings,
      iadByFpdEnabled,
      {
        forceFirstRegularPaymentDateRecalculation: false,
        forceMaturityDateRecalculation: false,
        forceIADRecalculation: true,
      },
    );

    return ctx.dispatch(
      new PatchMortgage(mortgage.applicationId, mortgage.id, {
        interestAdjustmentDate: interestAdjustmentDate ?? null,
      }),
    );
  }

  @Action(PaymentFrequencyChanged)
  paymentFrequencyChanged(ctx: StateContext<MortgagesStateModel>, action: PaymentFrequencyChanged) {
    const state = ctx.getState();
    const mortgage = state.mortgages.find((m) => m.id === action.mortgageId);

    if (!mortgage) {
      return of(null);
    }

    const mortgageCalculationAutomationSettings = this.store.selectSnapshot(
      MortgagesV2State.mortgageCalculationAutomationSettings,
    );
    const iadByFpdEnabled = this.store.selectSnapshot(AppFeaturesState.iadByFpdEnabled);

    const { firstRegularPaymentDate, interestAdjustmentDate, maturityDate } =
      this.mortgageServicesV1.addDefaultConditionDatesIfMissing(
        mortgage,
        mortgageCalculationAutomationSettings,
        iadByFpdEnabled,
        {
          forceFirstRegularPaymentDateRecalculation: true,
          forceIADRecalculation: true,
          forceMaturityDateRecalculation: true,
        },
      );

    return ctx.dispatch(
      new PatchMortgage(mortgage.applicationId, mortgage.id, {
        firstRegularPaymentDate: firstRegularPaymentDate ?? null,
        interestAdjustmentDate: interestAdjustmentDate ?? null,
        maturityDate: maturityDate ?? null,
      }),
    );
  }

  @Action(TermChanged)
  termChanged(ctx: StateContext<MortgagesStateModel>, action: TermChanged) {
    const state = ctx.getState();
    const mortgage = state.mortgages.find((m) => m.id === action.mortgageId);

    if (!mortgage) {
      return of(null);
    }

    const mortgageCalculationAutomationSettings = this.store.selectSnapshot(
      MortgagesV2State.mortgageCalculationAutomationSettings,
    );
    const iadByFpdEnabled = this.store.selectSnapshot(AppFeaturesState.iadByFpdEnabled);

    const { firstRegularPaymentDate, maturityDate } =
      this.mortgageServicesV1.addDefaultConditionDatesIfMissing(
        mortgage,
        mortgageCalculationAutomationSettings,

        iadByFpdEnabled,
        {
          forceFirstRegularPaymentDateRecalculation: false,
          forceIADRecalculation: false,
          forceMaturityDateRecalculation: true,
        },
      );

    return ctx.dispatch(
      new PatchMortgage(mortgage.applicationId, mortgage.id, {
        firstRegularPaymentDate: firstRegularPaymentDate ?? null,
        maturityDate: maturityDate ?? null,
      }),
    );
  }

  @Action(RateTypeChanged)
  rateTypeChanged(ctx: StateContext<MortgagesStateModel>, action: RateTypeChanged) {
    const state = ctx.getState();
    const mortgage = state.mortgages.find((m) => m.id === action.mortgageId);

    if (!mortgage || !action.rateType) {
      return;
    }

    const isAutomaticCompoundingEnabled = this.store.selectSnapshot(
      AppFeaturesState.isAutomaticCompoundingEnabled,
    );

    if (!isAutomaticCompoundingEnabled) {
      return;
    }

    let compounding = mortgage.compounding;

    if (isAutomaticCompoundingEnabled) {
      const automaticCompoundingMappings = this.store.selectSnapshot(
        AppFeaturesState.automaticCompoundingMappings,
      );
      if (!automaticCompoundingMappings) {
        return;
      }

      const mappedCompounding = automaticCompoundingMappings[action.rateType];

      if (!mappedCompounding) {
        return;
      }
      compounding = mappedCompounding as CompoundPeriod;
    }

    return ctx.dispatch(
      new PatchMortgage(mortgage.applicationId, mortgage.id, {
        compounding,
      }),
    );
  }

  @Action(RateTypeChangedForPaymentFrequency)
  rateTypeChangedForPaymentFrequency(
    ctx: StateContext<MortgagesStateModel>,
    action: RateTypeChangedForPaymentFrequency,
  ) {
    const state = ctx.getState();
    const mortgage = state.mortgages.find((m) => m.id === action.mortgageId);

    if (!mortgage || !action.rateType) {
      return;
    }

    const paymentFrequencyByRateType = this.store.selectSnapshot(
      AppFeaturesState.paymentFrequencyByRateType,
    );

    if (!paymentFrequencyByRateType) {
      return;
    }

    let paymentFrequency = mortgage.paymentFrequency;

    if (paymentFrequencyByRateType[action.rateType] !== null) {
      const mappedPaymentFrequency = paymentFrequencyByRateType[
        action.rateType
      ] as PaymentFrequency;

      if (!mappedPaymentFrequency) {
        return;
      }
      paymentFrequency = mappedPaymentFrequency;
    }

    return ctx.dispatch(
      new PatchMortgage(mortgage.applicationId, mortgage.id, {
        paymentFrequency,
      }),
    );
  }

  @Action(UpdateRequestedMortgage) updateRequestedMortgage(
    ctx: StateContext<MortgagesStateModel>,
    action: UpdateRequestedMortgage,
  ) {
    const state = ctx.getState();
    const requestedMortgageSnapshot = state.mortgages.find((m) => m.id === action.mortgage.id);
    const mortgageToUpdate: Mortgage = Object.assign(
      {},
      requestedMortgageSnapshot,
      action.mortgage,
    );
    const hasValueChangedMap = {
      baseRate: mortgageToUpdate.baseRate !== requestedMortgageSnapshot?.baseRate,
      buyDownRate: mortgageToUpdate.buyDownRate !== requestedMortgageSnapshot?.buyDownRate,
      discount: mortgageToUpdate.discount !== requestedMortgageSnapshot?.discount,
      prepaymentPenaltyPeriod:
        mortgageToUpdate.prepaymentPenaltyPeriod !==
        requestedMortgageSnapshot?.prepaymentPenaltyPeriod,
      rateType:
        !!mortgageToUpdate.rateType &&
        mortgageToUpdate.rateType !== requestedMortgageSnapshot?.rateType,
      termMonths:
        !!mortgageToUpdate.termMonths &&
        mortgageToUpdate.termMonths !== requestedMortgageSnapshot?.termMonths,
      loanAmount:
        !!mortgageToUpdate.loanAmount &&
        mortgageToUpdate.loanAmount !== requestedMortgageSnapshot?.loanAmount,
    };
    const insurance = this.store.selectSnapshot(InsuranceState.insurance(mortgageToUpdate.id));

    if (insurance) {
      mortgageToUpdate.insurer = insurance.insurer;
      mortgageToUpdate.insurancePremiumProgram = insurance.insurancePremiumProgram;
      mortgageToUpdate.insurancePremium = insurance.insurancePremium;
      mortgageToUpdate.insuranceAmount = insurance.insuranceAmount;
      mortgageToUpdate.insurancePremiumSurchargeApplied =
        insurance.insurancePremiumSurchargeApplied;
      mortgageToUpdate.insurancePremiumSurcharge = insurance.insurancePremiumSurchargeAmount;
    }

    mortgageToUpdate.pst = this.getCurrentPST(mortgageToUpdate.insuranceAmount);
    mortgageToUpdate.amountToBeAdvanced = this.getCurrentAmountToBeAdvanced(mortgageToUpdate);
    mortgageToUpdate.interestAdjustmentAmount =
      this.getCurrentInterestAdjustmentAmount(mortgageToUpdate);

    return ctx
      .dispatch(
        new UpdateMortgage(
          action.applicationId,
          mortgageToUpdate.id,
          {
            ...mortgageToUpdate,
            totalLoanAmount: undefined,
            monthlyPayment: undefined,
            paymentAmount: undefined,
          }, // we don't want to update these fields because they are computed
          hasValueChangedMap,
        ),
      )
      .pipe(
        switchMap(() => {
          const flipMortgage: FlipMortgageDetails = {
            loanAmount: mortgageToUpdate.loanAmount,
            netRate: mortgageToUpdate.netRate,
          };
          if (
            mortgageToUpdate.loanAmount &&
            mortgageToUpdate.loanAmount !== requestedMortgageSnapshot?.loanAmount
          ) {
            flipMortgage.loanAmount = mortgageToUpdate.loanAmount;
          }
          if (
            mortgageToUpdate.netRate &&
            mortgageToUpdate.netRate !== requestedMortgageSnapshot?.netRate
          ) {
            flipMortgage.netRate = mortgageToUpdate.netRate;
          }
          return ctx.dispatch(new FlipUpdateMortgageFields(flipMortgage));
        }),
      );
  }

  @Action(PatchMortgage) patchMortgage(
    ctx: StateContext<MortgagesStateModel>,
    action: PatchMortgage,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageServices
      .patchMortgage(action.applicationId, action.mortgageId, action.mortgage)
      .pipe(
        tap((patchedMortgage) => {
          const state = ctx.getState();
          const currentMortgageSnapshot = state.mortgages.find((a) => a.id === action.mortgageId);

          ctx.patchState({
            mortgages: [
              ...state.mortgages.filter((m) => m.id !== action.mortgageId),
              {
                ...currentMortgageSnapshot,
                ...patchedMortgage,
              },
            ],
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(UpdateMortgageBaseRate) updateMortgageBaseRate(
    ctx: StateContext<MortgagesStateModel>,
    action: UpdateMortgageBaseRate,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageServices
      .updateMortgageBaseRate(action.applicationId, action.mortgageId, action.baseRate)
      .pipe(
        tap((patchedMortgage) => {
          const state = ctx.getState();
          const currentMortgageSnapshot = state.mortgages.find((a) => a.id === action.mortgageId);

          ctx.patchState({
            mortgages: [
              ...state.mortgages.filter((m) => m.id !== action.mortgageId),
              {
                ...currentMortgageSnapshot,
                ...patchedMortgage,
              },
            ],
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(UpdateMortgageBuyDownRate) updateMortgageBuyDownRate(
    ctx: StateContext<MortgagesStateModel>,
    action: UpdateMortgageBuyDownRate,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageServices
      .updateMortgageBuyDownRate(action.applicationId, action.mortgageId, action.buyDownRate)
      .pipe(
        tap((patchedMortgage) => {
          const state = ctx.getState();
          const currentMortgageSnapshot = state.mortgages.find((a) => a.id === action.mortgageId);

          ctx.patchState({
            mortgages: [
              ...state.mortgages.filter((m) => m.id !== action.mortgageId),
              {
                ...currentMortgageSnapshot,
                ...patchedMortgage,
              },
            ],
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(MortgageRefreshRateHoldDate) refreshMortgageRateHoldDate(
    ctx: StateContext<MortgagesStateModel>,
    action: MortgageRefreshRateHoldDate,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageServices.refreshMortgageRateHoldDate(action.mortgageId).pipe(
      tap(({ rateHoldDate }: Pick<Mortgage, 'rateHoldDate'>) => {
        const state = ctx.getState();
        const currentMortgageSnapshot = state.mortgages.find((a) => a.id === action.mortgageId);

        if (!currentMortgageSnapshot) {
          return;
        }

        ctx.patchState({
          mortgages: [
            ...state.mortgages.filter((m) => m.id !== action.mortgageId),
            {
              ...currentMortgageSnapshot,
              rateHoldDate,
            },
          ],
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(UpdateMortgageDiscount) updateMortgageDiscount(
    ctx: StateContext<MortgagesStateModel>,
    action: UpdateMortgageDiscount,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageServices
      .updateMortgageDiscount(action.applicationId, action.mortgageId, action.discount)
      .pipe(
        tap((patchedMortgage) => {
          const state = ctx.getState();
          const currentMortgageSnapshot = state.mortgages.find((a) => a.id === action.mortgageId);

          ctx.patchState({
            mortgages: [
              ...state.mortgages.filter((m) => m.id !== action.mortgageId),
              {
                ...currentMortgageSnapshot,
                ...patchedMortgage,
              },
            ],
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(DeleteMortgage) deleteMortgage(
    ctx: StateContext<MortgagesStateModel>,
    action: DeleteMortgage,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const state = ctx.getState();
    const lastRequestedMortgage =
      this.store.selectSnapshot(MortgagesV2State.requestedMortgages).length === 1;
    const mortgageToDelete = state.mortgages.find((m) => m.id === action.mortgageId);

    if (
      mortgageToDelete &&
      mortgageToDelete.type === MortgageType.REQUESTED &&
      lastRequestedMortgage
    ) {
      this.dialog.open(DialogsComponent, {
        data: {
          title: $localize`Cannot delete this mortgage!`,
          message: $localize`An application must have at least one requested mortgage.`,
          buttonText: {
            no: $localize`:@@action.cancel:Cancel`,
          },
          declineModalClass: true,
        },
        minWidth: '400px',
        maxWidth: '400px',
      });

      ctx.dispatch(new LoadingEnd(this.constructor.name));

      return;
    }

    return this.mortgageServices
      .deleteMortgage(action.applicationId, action.mortgageId, action.comment)
      .pipe(
        tap(() => {
          const state = ctx.getState();
          ctx.patchState({
            mortgages: [...state.mortgages.filter((m) => m.id !== action.mortgageId)],
          });
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(ApplyRecommendation)
  public applyRecommendation(ctx: StateContext<MortgagesStateModel>, action: ApplyRecommendation) {
    const requestedMortgages = this.store.selectSnapshot(MortgagesV2State.requestedMortgages);

    switch (action.recommendation.type) {
      case FundmoreRecommendationType.ChangeProduct: {
        if (action.recommendation.changeToProductId) {
          return ctx.dispatch(
            new PreviewMortgageAdvancedProductSelection(
              action.recommendation.changeToProductId,
              action.applicationId,
              action.recommendation.mortgageId ?? '',
            ),
          );
        }
        return EMPTY;
      }
      default:
        return merge(
          requestedMortgages.map((requestedMortgage) =>
            this.applyRecommendationByRecommendationType(
              action.recommendation,
              ctx,
              action.applicationId,
              requestedMortgage,
            ),
          ),
        );
    }
  }

  @Action(RecomputeLoanAmountOnRequestedMortgage)
  public recomputeLoanAmountOnRequestedMortgage() {
    const applicationIsCombo = this.store.selectSnapshot(
      MortgageApplicationState.applicationIsCombo,
    );

    if (applicationIsCombo) {
      return of(null);
    }

    const application = this.store.selectSnapshot(MortgageApplicationState.application);
    const primaryProperty = this.store.selectSnapshot(PropertiesState.primaryProperty);

    switch (application.purpose) {
      case ApplicationPurposeType.PURCHASE:
        // Purchase Price changed and Application Purpose is Purchase
        // Update Loan Amount to Purchase Price - Total Down Payment, minimum 0
        return this.computeAndUpdateLoanAmount(primaryProperty?.purchasePrice ?? 0);
      case ApplicationPurposeType.SWITCH_TRANSFER:
      case ApplicationPurposeType.REFINANCE:
        return of(null);
      default:
        // Property Value changed and Application Purpose is not Purchase
        // Update Loan Amount to Property Value - Total Down Payment, minimum 0
        return this.computeAndUpdateLoanAmount(primaryProperty?.estimatedValue ?? 0);
    }
  }

  private computeAndUpdateLoanAmount(value: number) {
    const requestedMortgage = this.store.selectSnapshot(
      MortgagesV2State.firstFoundRequestedMortgage,
    );

    if (!requestedMortgage) {
      return of(null);
    }

    const totalDownPayment = this.store.selectSnapshot(DownPaymentState.totalDownPayment());

    const currentTotalDownPayment = totalDownPayment ?? 0;

    const computedLoanAmount = value - currentTotalDownPayment;

    const loanAmount = Math.max(Math.round(computedLoanAmount * 100) / 100, 0);
    const mortgage = {
      loanAmount,
      id: requestedMortgage.id,
    } as Mortgage;

    return this.store.dispatch(
      new PatchMortgage(requestedMortgage?.applicationId, requestedMortgage.id, mortgage),
    );
  }

  @Action(UpdatePSTOnRequestedMortgageIfNeeded)
  public updatePSTOnRequestedMortgageIfNeeded(
    ctx: StateContext<MortgagesStateModel>,
    action: UpdatePSTOnRequestedMortgageIfNeeded,
  ) {
    const state = ctx.getState();
    const requestedMortgage = state.mortgages.find((x) => x.id === action.mortgageId);

    if (!requestedMortgage) {
      return of(null);
    }

    const computedPST = FundmoreCalculator.calculatePST(
      requestedMortgage.insuranceAmount,
      action.province,
    );

    const pst = computedPST ? Math.max(Math.round(computedPST * 100) / 100, 0) : null;
    const mortgage = {
      pst,
      id: requestedMortgage.id,
    } as Mortgage;

    if (requestedMortgage.pst === pst) {
      return EMPTY;
    }

    return this.store.dispatch(
      new PatchMortgage(requestedMortgage.applicationId, requestedMortgage.id, mortgage),
    );
  }

  @Action(UpdateMortgagePrepaymentAmountIfNeeded)
  public updateMortgagePrepaymentAmountIfNeeded(
    ctx: StateContext<MortgagesStateModel>,
    action: UpdateMortgagePrepaymentAmountIfNeeded,
  ) {
    const requestedMortgage = action.mortgage;

    if (!requestedMortgage) {
      return of(null);
    }

    const computedPrepaymentAmount =
      FundmoreCalculator.calculatePrepaymentAmount(requestedMortgage) ?? 0;

    const mortgage = {
      id: requestedMortgage.id,
      prepaymentAmount: computedPrepaymentAmount,
      prepaymentAmountInPayments: requestedMortgage.prepaymentAmountInPayments,
    } as Mortgage;

    if (requestedMortgage.prepaymentAmount === computedPrepaymentAmount) {
      return EMPTY;
    }

    return this.store.dispatch(
      new PatchMortgage(requestedMortgage.applicationId, requestedMortgage.id, mortgage),
    );
  }

  @Action(UpdateMortgagePropertyId)
  public updateMortgagePropertyId(
    ctx: StateContext<MortgagesStateModel>,
    action: UpdateMortgagePropertyId,
  ) {
    const state = ctx.getState();
    const mortgage = state.mortgages.find((x) => x.id === action.mortgageId);

    if (!mortgage) {
      return of(null);
    }

    const mortgageToUpdate = {
      propertyId: action.propertyId,
      id: mortgage.id,
    };

    return this.store.dispatch(
      new PatchMortgage(mortgage.applicationId, mortgage.id, mortgageToUpdate),
    );
  }

  @Action(UpdateMortgageStatus)
  public updateMortgageStatus(
    ctx: StateContext<MortgagesStateModel>,
    action: UpdateMortgageStatus,
  ) {
    const state = ctx.getState();
    const mortgage = state.mortgages.find((x) => x.id === action.mortgageId);

    if (!mortgage) {
      return of(null);
    }

    const mortgageToUpdate = {
      status: action.status,
      state: null,
      stateChangeComment: null,
      stateChangeDate: null,
      id: mortgage.id,
    };

    return this.store.dispatch(
      new PatchMortgage(mortgage.applicationId, mortgage.id, mortgageToUpdate),
    );
  }

  @Action(UpdateMortgageState)
  public updateMortgageState(ctx: StateContext<MortgagesStateModel>, action: UpdateMortgageState) {
    const state = ctx.getState();
    const mortgage = state.mortgages.find((x) => x.id === action.mortgageId);

    if (!mortgage) {
      return of(null);
    }

    const mortgageToUpdate = {
      state: action.state,
      stateChangeComment: action.comment,
      stateChangeDate: new Date(),
      id: mortgage.id,
    };

    return this.store.dispatch(
      new PatchMortgage(mortgage.applicationId, mortgage.id, mortgageToUpdate),
    );
  }

  private applyRecommendationByRecommendationType(
    recommendation: FundmoreRecommendationModel,
    ctx: StateContext<MortgagesStateModel>,
    applicationId: string,
    requestedMortgage: Mortgage,
  ) {
    switch (recommendation.type) {
      case FundmoreRecommendationType.DecreaseMortgageAmount:
        return ctx.dispatch(
          new UpdateRequestedMortgage(applicationId, {
            id: requestedMortgage.id,
            loanAmount: recommendation.changeToNumber,
          }),
        );
      case FundmoreRecommendationType.IncreaseDownPayment: {
        const addLocalDownPayment = this.store.dispatch(
          new AddLocalDownPayment(
            applicationId,
            {
              amount: recommendation.changeToNumber,
              source: DownPaymentType.OTHER,
            },
            {
              updateFlip: true,
            },
          ),
        );

        if (!requestedMortgage?.loanAmount || !recommendation.changeToNumber) {
          return addLocalDownPayment;
        }

        const upsertRequestedMortgage = ctx.dispatch(
          new UpdateRequestedMortgage(applicationId, {
            id: requestedMortgage.id,
            loanAmount: requestedMortgage.loanAmount - recommendation.changeToNumber,
          }),
        );
        return merge(addLocalDownPayment, upsertRequestedMortgage);
      }
      case FundmoreRecommendationType.ChangeRequestedMortgageMaturityDate:
        return ctx.dispatch(
          new UpdateRequestedMortgage(applicationId, {
            id: requestedMortgage.id,
            termMonths: (recommendation.changeToNumber ?? 0) * 12,
          }),
        );
      case FundmoreRecommendationType.UploadCreditReport:
      case FundmoreRecommendationType.SwitchToRefinance:
      case FundmoreRecommendationType.RefinanceSecondMortgage:
        return EMPTY;
      case FundmoreRecommendationType.ChangeProduct:
        return EMPTY;
      default:
        assertUnreachable(recommendation.type, 'Unhandled RecommendationType');
        return EMPTY;
    }
  }

  private getCurrentPST(insuranceAmount: number | null) {
    const primaryProperty = this.store.selectSnapshot(PropertiesState.primaryProperty);
    const pst = FundmoreCalculator.calculatePST(
      insuranceAmount,
      primaryProperty?.propertyAddressExpanded?.province,
    );

    return pst;
  }

  private getCurrentAmountToBeAdvanced(mortgage: Mortgage) {
    const pst = mortgage.pst;
    const fees = this.store.selectSnapshot(FeesState.feesList);
    const multipleRequestedMortgages =
      this.store.selectSnapshot(MortgagesV2State.activeRequestedMortgages).length > 1;

    const prepaymentAmount = FundmoreCalculator.calculatePrepaymentAmount(mortgage) ?? 0;
    return (
      FundmoreCalculator.computeAmountToAdvance(
        mortgage,
        fees || [],
        pst,
        prepaymentAmount,
        multipleRequestedMortgages,
      ) ?? 0
    );
  }

  private getCurrentInterestAdjustmentAmount(mortgage: Mortgage) {
    const mortgageCalculationAutomationSettings = this.store.selectSnapshot(
      MortgagesV2State.mortgageCalculationAutomationSettings,
    );
    const servicingEffectiveDate = this.store.selectSnapshot(MortgageApplicationState.application)
      ?.ApplicationServicingData?.effectiveDate;
    const date =
      mortgage.type === MortgageType.SERVICING ? servicingEffectiveDate : mortgage?.closingDate;

    if (date) {
      if (
        !mortgageCalculationAutomationSettings ||
        !mortgageCalculationAutomationSettings[mortgage?.id]?.isInterestAdjustmentAmountDisabled
      ) {
        return FundmoreCalculator.interestAdjustmentAmount(mortgage, servicingEffectiveDate) ?? 0;
      } else {
        return mortgage.interestAdjustmentAmount;
      }
    }
    return null;
  }

  @Action(UpdateMortgagesLoanNumberLocal)
  updateMortgagesLoanNumberLocal(
    ctx: StateContext<MortgagesStateModel>,
    action: UpdateMortgagesLoanNumberLocal,
  ) {
    const updateItems = [];
    for (const mortgage of action.mortgages) {
      updateItems.push(
        updateItem<Mortgage>(
          (m) => m.id === mortgage.id,
          patch({
            loanNumber: mortgage.loanNumber,
            expandedLoanNumber: mortgage.expandedLoanNumber,
            mortgageProductId: mortgage.mortgageProductId,
          }),
        ),
      );
    }
    ctx.setState(
      patch({
        mortgages: compose(...updateItems),
      }),
    );
  }

  @Action(UpdateMortgageIncrement)
  updateMortgageIncrement(ctx: StateContext<MortgagesStateModel>, action: UpdateMortgageIncrement) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageServices.patchMortgageIncrement(action.mortgageId, action.mortgage).pipe(
      tap((mortgageIncrementData) => {
        const update = updateItem<Mortgage>(
          (m) => m.id === action.mortgageId,
          patch(mortgageIncrementData),
        );
        ctx.setState(
          patch({
            mortgages: update,
          }),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpsertMortgageCustomIncrement)
  updateMortgageCustomIncrement(
    ctx: StateContext<MortgagesStateModel>,
    action: UpsertMortgageCustomIncrement,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageServices
      .upsertCustomMortgageIncrement(action.mortgageId, action.payload)
      .pipe(
        tap((data) => {
          const update = updateItem<Mortgage>(
            (m) => m.id === action.mortgageId,
            patch({ ...data, customIncrementIsSet: true }),
          );
          ctx.setState(
            patch({
              mortgages: update,
            }),
          );
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(UpdateMortgageDecrement)
  updateMortgageDecrement(ctx: StateContext<MortgagesStateModel>, action: UpdateMortgageDecrement) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageServices.updateMortgageDecrement(action.mortgageId, action.payload).pipe(
      tap((mortgageUpdate) => {
        const update = updateItem<Mortgage>(
          (m) => m.id === action.mortgageId,
          patch(mortgageUpdate),
        );
        ctx.setState(patch({ mortgages: update }));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(PatchMortgageBrokerCommission)
  patchMortgageBrokerCommission(
    ctx: StateContext<MortgagesStateModel>,
    action: PatchMortgageBrokerCommission,
  ) {
    const state = ctx.getState();
    const currentMortgageSnapshot = state.mortgages.find((a) => a.id === action.mortgageId);
    if (!currentMortgageSnapshot) {
      return;
    }

    ctx.patchState({
      mortgages: [
        ...state.mortgages.filter((m) => m.id !== action.mortgageId),
        {
          ...currentMortgageSnapshot,
          brokerCommission: action.brokerCommission,
        },
      ],
    });
  }

  @Action(UpdateMortgageDiscretion)
  updateMortgageDiscretion(
    ctx: StateContext<MortgagesStateModel>,
    action: UpdateMortgageDiscretion,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageServices
      .updateMortgageDiscretion(action.mortgageId, action.discretion)
      .pipe(
        tap((mortgageUpdate) => {
          const update = updateItem<Mortgage>(
            (m) => m.id === action.mortgageId,
            patch(mortgageUpdate),
          );
          ctx.setState(patch({ mortgages: update }));
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(AddUploadedStatusToRequestedMortgages)
  addUploadedStatusToRequestedMortgages(ctx: StateContext<MortgagesStateModel>) {
    const state = ctx.getState();
    const requestedMortgages = state.mortgages.filter((m) => m.type === MortgageType.REQUESTED);

    const updatedMortgages = requestedMortgages.map((mortgage) => {
      return {
        ...mortgage,
        status: mortgage.status
          ? [...mortgage.status, MortgageStatus.UPLOADED]
          : [MortgageStatus.UPLOADED],
      };
    });

    ctx.patchState({
      mortgages: [
        ...state.mortgages.filter((m) => m.type !== MortgageType.REQUESTED),
        ...updatedMortgages,
      ],
    });
  }

  @Action(RefreshProjectedBalance)
  refreshProjectedBalance(ctx: StateContext<MortgagesStateModel>, action: RefreshProjectedBalance) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageServices.refreshProjectedBalance(action.applicationId).pipe(
      tap((data: { projectedBalance: number | null }) => {
        const updatedServicingMortgage = {
          ...this.store.selectSnapshot(MortgagesV2State.servicingNewMortgage),
          projectedBalance: data.projectedBalance,
        };

        const update = updateItem<Mortgage>(
          (m) => m.id === updatedServicingMortgage.id,
          patch(updatedServicingMortgage),
        );

        ctx.setState(patch({ mortgages: update }));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(SetProjectedBalance)
  setProjectedBalance(ctx: StateContext<MortgagesStateModel>, action: SetProjectedBalance) {
    const updatedServicingMortgage = {
      ...this.store.selectSnapshot(MortgagesV2State.servicingNewMortgage),
      projectedBalance: action.projectedBalance,
    };

    const update = updateItem<Mortgage>(
      (m) => m.id === updatedServicingMortgage.id,
      patch(updatedServicingMortgage),
    );

    ctx.setState(patch({ mortgages: update }));
  }

  @Action(ClearPropertyLinksAndDropLinkedExistingMortgages)
  clearPropertyLinksAndDropLinkedExistingMortgages(
    ctx: StateContext<MortgagesStateModel>,
    action: ClearPropertyLinksAndDropLinkedExistingMortgages,
  ) {
    const state = ctx.getState();
    const updatedMortgages = state.mortgages
      .filter((m) => !(m.type === MortgageType.EXISTING && m.propertyId === action.propertyId))
      .map((m) => {
        if (m.propertyId === action.propertyId) {
          return {
            ...m,
            propertyId: null,
          };
        }
        return m;
      });

    ctx.patchState({
      mortgages: updatedMortgages,
    });
  }
}
