import { AgentContactType } from '@fundmoreai/models';

export enum BrokerTableKey {
  VERIFIED = 'verified',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  CELL_PHONE_NUMBER = 'cellPhoneNumber',
  EMAIL = 'email',
  LICENSE_REGISTRATION_NO = 'licenseRegistrationNumber',
  FIRM_NAME = 'firmName',
  RISK_SCORE = 'riskScore',
  STATUS = 'status',
}

export const BrokerTableKeyRecord: Record<Partial<BrokerTableKey>, string> = {
  [BrokerTableKey.VERIFIED]: $localize`:@@brokerTableKey.verified:Verified`,
  [BrokerTableKey.FIRST_NAME]: $localize`:@@brokerTableKey.firstName:First Name`,
  [BrokerTableKey.LAST_NAME]: $localize`:@@brokerTableKey.lastName:Last Name`,
  [BrokerTableKey.CELL_PHONE_NUMBER]: $localize`:@@brokerTableKey.cellPhoneNumber:Cell Phone Number`,
  [BrokerTableKey.EMAIL]: $localize`:@@brokerTableKey.email:Email`,
  // eslint-disable-next-line max-len
  [BrokerTableKey.LICENSE_REGISTRATION_NO]: $localize`:@@brokerTableKey.licenseRegistrationNumber:License Registration Number`,
  [BrokerTableKey.FIRM_NAME]: $localize`:@@brokerTableKey.firmName:Firm Name`,
  [BrokerTableKey.RISK_SCORE]: $localize`:@@brokerTableKey.riskScore:Risk Score`,
  [BrokerTableKey.STATUS]: $localize`:@@brokerTableKey.status:Status`,
};

export enum LawyerTableKey {
  VERIFIED = 'verified',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  CELL_PHONE_NUMBER = 'cellPhoneNumber',
  EMAIL_ADDRESS = 'emailAddress',
  COMPANY = 'company',
  RISK_SCORE = 'riskScore',
  STATUS = 'status',
}

export const LawyerTableKeyRecord: Record<Partial<LawyerTableKey>, string> = {
  [LawyerTableKey.VERIFIED]: $localize`:@@lawyerTableKey.verified:Verified`,
  [LawyerTableKey.FIRST_NAME]: $localize`:@@lawyerTableKey.firstName:First Name`,
  [LawyerTableKey.LAST_NAME]: $localize`:@@lawyerTableKey.lastName:Last Name`,
  [LawyerTableKey.CELL_PHONE_NUMBER]: $localize`:@@lawyerTableKey.cellPhoneNumber:Cell Phone Number`,
  [LawyerTableKey.EMAIL_ADDRESS]: $localize`:@@lawyerTableKey.emailAddress:Email`,
  [LawyerTableKey.COMPANY]: $localize`:@@lawyerTableKey.company:Company`,
  [LawyerTableKey.RISK_SCORE]: $localize`:@@lawyerTableKey.riskScore:Risk Score`,
  [LawyerTableKey.STATUS]: $localize`:@@lawyerTableKey.status:Status`,
};

export const AgentContactTypeString: Record<AgentContactType, string> = {
  [AgentContactType.email]: $localize`Email`,
  [AgentContactType.cellPhoneNumber]: $localize`Cell Phone`,
  [AgentContactType.workPhoneNumber]: $localize`Work Phone`,
  [AgentContactType.homePhoneNumber]: $localize`Home Phone`,
  [AgentContactType.fax]: $localize`Fax Number`,
};

const EMAIL_PATTERN = '^\\w+([\\.+-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,})+$';
const NUMBER_PATTERN = '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$';

export const PatternContactMap = {
  [AgentContactType.email]: EMAIL_PATTERN,
  [AgentContactType.cellPhoneNumber]: NUMBER_PATTERN,
  [AgentContactType.homePhoneNumber]: NUMBER_PATTERN,
  [AgentContactType.workPhoneNumber]: NUMBER_PATTERN,
  [AgentContactType.fax]: NUMBER_PATTERN,
};
