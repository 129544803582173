import { MMSMilestoneCodeID } from '../../enums';

export interface MMSMilestone {
  milestoneCodeID: MMSMilestoneCodeID;
  milestoneValue: boolean;
  datePublished?: string | undefined;
}

export interface MMSSendMilestoneResponse {
  successcode: string;
  successMessage: string | { message: string }[]; // on error returns an array of message
  completedDateTime: string;
}
