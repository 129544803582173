import { RestrictedDate } from '@fundmoreai/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationRestrictedDatesService {
  private base = `${environment.fundmore_v2_api_url}/restricted-date`;

  constructor(private http: HttpClient) {}

  getAll(): Observable<RestrictedDate[]> {
    return this.http.get<RestrictedDate[]>(this.base);
  }
}
