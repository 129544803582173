import { Applicant, ApplicantV2, NetworthContext } from './applicant';
import { ApplicationNote } from './applicationNote';
import { FinancialAsset, FinancialAssetV2 } from './asset';
import { ApplicationBrokerCommission } from './brokerCommission';
import { ApplicationDecisionEngineFlags } from './decisionEngine/applicationDecisionEngineFlags';
import { DownPayment, DownPaymentV2 } from './downPayment';
import {
  ApplicationCreateProductsState,
  ApplicationCreditCardFulfillState,
  ApplicationStatusType,
  ApplicationType,
  MortgageClassificationType,
  SourceOfBusinessType,
} from './enums';
import { EQClosingService, LendingTierType } from './eqMaps';
import { ExternalDeal } from './externalDeal';
import { Fee, FeeV2 } from './fee';
import { Fund } from './fund';
import { InsuranceQuote } from './insurance/insuranceQuote';
import { FinancialLiability, FinancialLiabilityV2 } from './liability';
import { DrawSchedule, ExpandedLoanNumber, Mortgage } from './mortgage';
import { MortgageComputedData } from './mortgageComputedData';
import { ApplicationOtherIncome, ApplicationOtherIncomeV2 } from './otherIncome';
import { Property, PropertyV2 } from './property';
import { ApplicationAdvancedProduct } from './rateMatrix/applicationAdvancedProduct';
import { ServicingType } from './servicing';
import { Summary } from './summary';
import { Role } from './user';

export interface Application {
  id: string;
  ezidoxApplicationId: number | null;
  tenantId: string;
  name: string | null;
  code: string | null;
  applicationDate: string | null;
  priority?: PriorityType | null;
  status: DocumentRequestStatus | null;
  createdAt: string;
  updatedAt: string;
  unread?: boolean;
  currentStage?: ApplicationStage | null;
  previousStage?: ApplicationStage | null;
  docsDueDate: string | null;
  declineComment: string | null;
  declineReasonId: string | null;
  declineDate?: string | null;
  declineSource?: DeclineSource | null;
  source: ApplicationSource | null;
  archived: boolean;
  fundId: string | null;
  clonedApplicationId: string | null;
  purpose: ApplicationPurposeType | null;
  mortgageClassification?: MortgageClassificationType | null;
  locked: boolean;
  metadata: ApplicationMetadata | null;
  filogixDealId: string | null;
  externalDealId: string | null;
  dealType: ApplicationType | null;
  externalApplicationId: string | null;
  externalApplicationGUID?: string | null;
  sentToIntellifi: boolean;
  createdBy: string | null;
  archivedByUserId?: string | null;
  lineOfBusinessId?: string | null;
  brokerNotes: string | null; // Deprecated
  brokerNotesDate: Date | null;
  closestUncompletedTask: ClosestUncompletedTask | null;
  isCombo: boolean | null;
  closingService: EQClosingService | null;
  lendingTier: LendingTierType | null;
  dmNoteCount: number | null;
  ezidoxStatusId?: number | null;
  ezidoxStatusString?: string | null;
  originalData?: string | null;
  commitmentIssueDate?: string | null;
  commitmentExpiryDate?: string | null;
  quickCloseFlag?: boolean | null;
  rateHoldExpiryDate?: string | null;
  restricted: boolean;
  sourceOfBusiness?: SourceOfBusinessType | null;
  securedAggregate?: number | null;
  unsecuredAggregate?: number | null;
  mortgageSecuredAggregate?: number | null;
  cashSecuredAggregate?: number | null;
  bridgeAggregate?: number | null;
  purposeCode?: PurposeCode | null;
  totalBusinessConnection?: number | null;
  isLatestSubmission: boolean;
  productMemberOptions?: { [key: string]: string[] }[] | null;
  createProductsState: ApplicationCreateProductsState;
  createProductsError?: string | null;
  creditCardFulfillState: ApplicationCreditCardFulfillState;
  creditCardFulfillError?: string | null;
  isServicing: boolean | undefined;
  ApplicationDecisionEngineFlags: ApplicationDecisionEngineFlags;
  ApplicationDolphinIntegrations: ApplicationDolphinIntegration[];
  ApplicationVerifications: ApplicationVerification[];
  ApplicationAssignedUsers: ApplicationAssignedUser[];
  ApplicationStatus: ApplicationStatus;
  ConstructionSchedules: DrawSchedule[];
  applicants: Applicant[];
  OtherIncomes: ApplicationOtherIncome[];
  DownPayments: DownPayment[];
  FinancialAssets: FinancialAsset[];
  FinancialLiabilities: FinancialLiability[];
  Mortgages: Mortgage[];
  Properties: Property[];
  Fees: Fee[];
  customFields: { [key: string]: string };
  warnings?: ApplicationWarningKeys[] | null;
  ApplicationAdvancedProducts: ApplicationAdvancedProduct[];
  uiAbstractPermissions?: ApplicationPermissions;
  ExternalDeal?: ExternalDeal;
  ApplicationComputedData?: ApplicationComputedData | null;
  MortgageComputedData?: MortgageComputedData[] | null;
  ApplicationNotes?: ApplicationNote[];
  InsuranceQuotes?: InsuranceQuote[];
  restrictedPartyMember?: boolean | null;
  staffMember?: boolean | null;
  connectedParty?: boolean | null;
  applicationLoanNumber?: string | null;
  applicationExpandedLoanNumber?: ExpandedLoanNumber | null;
  ApplicationBrokerCommissions?: ApplicationBrokerCommission[] | null;
  Fund?: Fund | null;
  ApplicationServicingData?: ApplicationServicingData | null;
  applicationSourceForOther?: string | null;
}

export interface ApplicationV2
  extends Omit<
    Application,
    | 'applicationDate'
    | 'Properties'
    | 'closestUncompletedTask'
    | 'restricted'
    | 'customFields'
    | 'ApplicationStatus'
    | 'ApplicationComputedData'
    | 'ApplicationDecisionEngineFlags'
    | 'Fund'
    | 'ApplicationVerifications'
    | 'ApplicationAssignedUsers'
    | 'applicants'
    | 'Fees'
    | 'FinancialAssets'
    | 'OtherIncomes'
    | 'DownPayments'
    | 'FinancialLiabilities'
  > {
  Properties: PropertyV2[];
  customFields: { [key: string]: string } | null;
  ApplicationStatus: ApplicationStatusV2 | null;
  ApplicationComputedData: ApplicationComputedDataV2 | null;
  ApplicationDecisionEngineFlags: ApplicationDecisionEngineFlags | null;
  Fund: {
    shortName: string | null;
  } | null;
  ApplicationVerifications: ApplicationVerificationV2[];
  ApplicationAssignedUsers: ApplicationAssignedUserV2[];
  applicants: ApplicantV2[];
  Fees: FeeV2[];
  FinancialAssets: FinancialAssetV2[];
  OtherIncomes: ApplicationOtherIncomeV2[];
  DownPayments: DownPaymentV2[];
  FinancialLiabilities: FinancialLiabilityV2[];
}

export enum DeclineSource {
  DECLINE_APPLICATION = 'DECLINE_APPLICATION',
  CANCEL_APPLICATION = 'CANCEL_APPLICATION',
}

export interface ApplicationServicingData {
  id: string;
  servicingType: ServicingType[];
  effectiveDate?: string | null;
  initialServicingApplicationId?: string | null;
  mcuProductId?: string | null;
  paymentSub?: string | null;
  productSub?: number | null;
}

export interface NetWorth {
  currentNetWorth: NetworthContext;
  futureNetWorth: NetworthContext;
}

export interface ApplicationComputedData {
  id: string;
  applicationId: string;
  calculation: Summary;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  score: any; // FundmoreScoreOutput, it's from score-octopus
  netWorth: NetWorth;
}
export interface ApplicationComputedDataV2
  extends Omit<ApplicationComputedData, 'id' | 'calculation' | 'netWorth'> {
  calculation: Summary | null;
  netWorth: NetWorth | null;
}

export enum ApplicationComputedDataJSONKey {
  LTI = 'lti',
  GDS = 'gds',
  TDS = 'tds',
  QGDS = 'qgds',
  QTDS = 'qtds',
  LTV = 'ltv',
  LTVBySecurity = 'ltvBySecurity',
  LTIBySecurity = 'ltiBySecurity',
}

export enum MortgageComputedDataJSONKey {
  GDS = 'gds',
  TDS = 'tds',
  QGDS = 'qgds',
  QTDS = 'qtds',
  LTV = 'ltv',
  QUALIFYING_RATE = 'qualifyingRate',
}

export enum ApplicationNetWorthJSONKey {
  currentNetWorth = 'currentNetWorth',
  futureNetWorth = 'futureNetWorth',
}

export interface ApplicationMetadata {
  mergeIds: string[];
}

export interface ApplicationPermissions {
  hierarchyUserIds: string[];
  applicationStageMovePermissionsMap:
    | {
        [key: string]: ApplicationStage[] | null;
      }
    | undefined;
  canAssignBroker: boolean;
  canAddApplicant: boolean;
  canAssignUsers: Partial<Role>[];
  canBypassTasks: boolean;
  canChat: boolean;
  canCompleteAssignedTasks: boolean;
  canCompleteTasks: boolean;
  canCreateTasks: boolean;
  canUpdateApplicant: boolean;
  canManageLawyerAndThirdParty: boolean;
  canDeleteApplicant: boolean;
  canEdit: boolean;
  canEditAssignedTasks: boolean;
  canEditGeneratedDocument: boolean;
  canEditPriority: boolean;
  canEditBaseRate: boolean;
  canEditBuyDownRate: boolean;
  canEditDiscount: boolean;
  canEditPrepaymentPeriod: boolean;
  canEditTasks: boolean;
  canFund: boolean;
  canIncludeInAPR: boolean;
  canMoveStage: MoveStageModel[];
  canRevertDecision: boolean;

  canArchiveApplication: boolean;
  canDecline: boolean;
  canDeleteApplication: { stage: ApplicationStage[] | null }[];

  canUploadConditionDocument: boolean;
  canEditConditionDocument: boolean;
  canReviewConditionDocument: boolean;
  canCommentOnConditionDocument: boolean;
  canDeleteConditionDocument: boolean;

  canAddApplicationCondition: boolean;
  canReviewApplicationCondition: boolean;
  canDeleteApplicationCondition: boolean;

  canSendToServicing: boolean;
  canPullEquifaxReport: boolean;
  canCreateApplicationNotes: boolean;

  canCreateAndEditApplicationRiskFlag: boolean;
  canResolveFailApplicationRiskFlag: boolean;
  canResolveManualReviewApplicationRiskFlag: boolean;

  canAdjudicateRestrictedPartyApplications: boolean;
  canAdjudicateStaffMemberApplications: boolean;

  canSubmitToDecisionEngine: boolean;

  canUpdateMortgageIncrements: boolean;
  canCreateCustomIncrements: boolean;

  canUpdateMortgageDecrements: boolean;

  canUpdateMortgageDiscretion: boolean;

  canSendToCoreBanking: boolean;
}

export interface ApplicationDetails {
  id: string;
  applicationName: string;
  primaryApplicantName: string;
  coborrowerNames: string[];
  currentStage?: ApplicationStage;
  brokerNames: string[];
  primaryPropertyAddress: string;
  mortgagePurpose: string;
  underwriterName: string;
  updatedAt: string;
}

export interface ArchiveStage {
  enabled?: boolean;
  dayInterval?: string;
}

export interface AutomaticArchivingConfig {
  [ApplicationStage.DECLINED]?: ArchiveStage;
  [ApplicationStage.FUNDED]?: ArchiveStage;
  other: ArchiveStage;
}

export enum NonPostFundingApplicationStage {
  NEW_APPLICATION = 'NEW_APPLICATION',
  UNDERWRITING = 'UNDERWRITING',
  ADJUDICATION = 'ADJUDICATION',
  DEAL_ACCEPTANCE = 'DEAL_ACCEPTANCE',
  DOCUMENT_REVIEW = 'DOCUMENT_REVIEW',
  OPERATIONS_FULFILLMENT = 'OPERATIONS_FULFILLMENT',
  PRESENT_DEAL = 'PRESENT_DEAL',
  PROPOSE_DEAL = 'PROPOSE_DEAL',
  DEAL_SIGNED = 'DEAL_SIGNED',
  COMPLIANCE = 'COMPLIANCE',
  FINAL_APPROVAL = 'FINAL_APPROVAL',
  PRE_FUND = 'PRE_FUND',
  LAWYER_INSTRUCTED = 'LAWYER_INSTRUCTED',
  FINAL_REVIEW = 'FINAL_REVIEW',
  FUNDED = 'FUNDED',
  DECLINED = 'DECLINED',
}

export enum PostFundingApplicationStage {
  FORECLOSURE = 'FORECLOSURE',
  PAID_OUT = 'PAID_OUT',
  PAST_DUE_UNDER_90 = 'PAST_DUE_UNDER_90',
  PAST_DUE_OVER_90 = 'PAST_DUE_OVER_90',
  PAID_IN_FULL = 'PAID_IN_FULL',
  RENEWAL = 'RENEWAL',
}

export enum ServicingApplicationStage {
  OPEN = 'OPEN',
  WAITING_FOR_SIGNATURES = 'WAITING_FOR_SIGNATURES',
  READY_FOR_UPLOAD = 'READY_FOR_UPLOAD',
  QUEUED_FOR_UPLOAD = 'QUEUED_FOR_UPLOAD',
  UPLOADED_COMPLETED = 'UPLOADED_COMPLETED',
  CANCELED = 'CANCELED',
}

export const UnderwritingApplicationStage = {
  ...NonPostFundingApplicationStage,
  ...PostFundingApplicationStage,
} as const;

export type UnderwritingApplicationStage =
  | NonPostFundingApplicationStage
  | PostFundingApplicationStage;

export const ApplicationStage = {
  ...NonPostFundingApplicationStage,
  ...PostFundingApplicationStage,
  ...ServicingApplicationStage,
} as const;

export type ApplicationStage =
  | NonPostFundingApplicationStage
  | PostFundingApplicationStage
  | ServicingApplicationStage;

export const stagesOrder: Record<ApplicationStage, number> = {
  [NonPostFundingApplicationStage.NEW_APPLICATION]: 1,
  [NonPostFundingApplicationStage.UNDERWRITING]: 2,
  [NonPostFundingApplicationStage.ADJUDICATION]: 3,
  [NonPostFundingApplicationStage.DEAL_ACCEPTANCE]: 4,
  [NonPostFundingApplicationStage.DOCUMENT_REVIEW]: 5,
  [NonPostFundingApplicationStage.OPERATIONS_FULFILLMENT]: 6,
  [NonPostFundingApplicationStage.PRESENT_DEAL]: 7,
  [NonPostFundingApplicationStage.PROPOSE_DEAL]: 8,
  [NonPostFundingApplicationStage.DEAL_SIGNED]: 9,
  [NonPostFundingApplicationStage.COMPLIANCE]: 10,
  [NonPostFundingApplicationStage.FINAL_APPROVAL]: 11,
  [NonPostFundingApplicationStage.PRE_FUND]: 12,
  [NonPostFundingApplicationStage.LAWYER_INSTRUCTED]: 13,
  [NonPostFundingApplicationStage.FINAL_REVIEW]: 14,
  [NonPostFundingApplicationStage.FUNDED]: 15,
  [NonPostFundingApplicationStage.DECLINED]: 16,
  [PostFundingApplicationStage.FORECLOSURE]: 17,
  [PostFundingApplicationStage.PAID_OUT]: 18,
  [PostFundingApplicationStage.PAST_DUE_UNDER_90]: 19,
  [PostFundingApplicationStage.PAST_DUE_OVER_90]: 20,
  [PostFundingApplicationStage.PAID_IN_FULL]: 21,
  [PostFundingApplicationStage.RENEWAL]: 22,
  [ServicingApplicationStage.OPEN]: 30,
  [ServicingApplicationStage.WAITING_FOR_SIGNATURES]: 31,
  [ServicingApplicationStage.READY_FOR_UPLOAD]: 32,
  [ServicingApplicationStage.QUEUED_FOR_UPLOAD]: 33,
  [ServicingApplicationStage.UPLOADED_COMPLETED]: 34,
  [ServicingApplicationStage.CANCELED]: 35,
};

export enum ServicingApplicationPurpose {
  PAYMENT_CHANGE = 'PAYMENT_CHANGE',
  SERVICING_RENEWAL = 'SERVICING_RENEWAL',
  COST_OF_BORROWING = 'COST_OF_BORROWING',
}

export enum UnderwritingApplicationPurpose {
  PURCHASE = 'PURCHASE',
  REFINANCE = 'REFINANCE',
  ETO = 'ETO',
  SWITCH_TRANSFER = 'SWITCH_TRANSFER',
  RENEWAL = 'RENEWAL',
  CONSTRUCTION = 'CONSTRUCTION',
  BRIDGE_FINANCING = 'BRIDGE_FINANCING',
  OTHER = 'OTHER',
  DEFICIENCY_SALE = 'DEFICIENCY_SALE',
  WORKOUT = 'WORKOUT',
}

export const ApplicationPurposeType = {
  ...UnderwritingApplicationPurpose,
  ...ServicingApplicationPurpose,
};

export type ApplicationPurposeType = UnderwritingApplicationPurpose | ServicingApplicationPurpose;

export enum PurchaseMortgageClassification {
  PURCHASE = 'PURCHASE',
  PURCHASE_PLUS_IMPROVEMENT = 'PURCHASE_PLUS_IMPROVEMENT',
  REVERSE_MORTGAGE = 'REVERSE_MORTGAGE',
  FLIP = 'FLIP',
  PURCHASE_WITH_HELOC = 'PURCHASE_WITH_HELOC',
  PORT = 'PORT',
  ASSUMPTION = 'ASSUMPTION',
  NEW_BUILD = 'NEW_BUILD',
  PRIVATE = 'PRIVATE',
  RENT_TO_OWN = 'RENT_TO_OWN',
}

export enum RefinanceMortgageClassification {
  REFINANCE = 'REFINANCE',
  REVERSE_MORTGAGE = 'REVERSE_MORTGAGE',
  HELOC = 'HELOC',
}

export enum ETOMortgageClassification {
  ETO = 'ETO',
  HELOC = 'HELOC',
}

export enum DeficiencySaleMortgageClassification {
  DEFICIENCY_SALE = 'DEFICIENCY_SALE',
}

export enum WorkoutMortgageClassification {
  WORKOUT = 'WORKOUT',
}

export enum SwitchTransferClassification {
  SWITCH_TRANSFER_COLLATERAL = 'SWITCH_TRANSFER_COLLATERAL',
  SWITCH_TRANSFER_STANDARD = 'SWITCH_TRANSFER_STANDARD',
}

export enum RenewalClassification {
  RENEWAL = 'RENEWAL',
}

export enum ConstructionClassification {
  CONSTRUCTION = 'CONSTRUCTION',
  CONSTRUCTION_NEW_BUILD = 'CONSTRUCTION_NEW_BUILD',
  CONSTRUCTION_EXISTING_PROPERTY = 'CONSTRUCTION_EXISTING_PROPERTY',
  CONSTRUCTION_TAKEOVER = 'CONSTRUCTION_TAKEOVER',
}

export enum BridgeFinancingClassification {
  BRIDGE_FINANCING_WITH_PURCHASE = 'BRIDGE_FINANCING_WITH_PURCHASE',
  BRIDGE_FINANCING_WITHOUT_PURCHASE = 'BRIDGE_FINANCING_WITHOUT_PURCHASE',
}

export enum OtherClassification {
  OTHER = 'OTHER',
}

export enum TaskUrgency {
  MINOR = 'MINOR',
  URGENT = 'URGENT',
  OVERDUE = 'OVERDUE',
}

export interface ClosestUncompletedTask {
  time?: string;
  urgency?: TaskUrgency;
}

export interface ApplicationDolphinIntegration {
  databaseCode: string;
  createdAt: Date;
  updatedAt: Date;
  dolphinServicingData: unknown;
}

export interface EqMapsHistory {
  applicationId: string;
  operation: MapsSyncOperation;
}

export enum MapsSyncOperation {
  LOAN_DECISION = 'LOAN_DECISION',
  LOAN_UPDATE = 'LOAN_UPDATE',
}

export enum MapsSyncOperationStatus {
  LOAN_UPDATE_STARTED = 'LOAN_UPDATE_STARTED',
  LOAN_UPDATE_COMPLETED = 'LOAN_UPDATE_COMPLETED',
  LOAN_UPDATE_FAILED = 'LOAN_UPDATE_FAILED',
  LOAN_DECISION_STARTED = 'LOAN_DECISION_STARTED',
  LOAN_DECISION_COMPLETED = 'LOAN_DECISION_COMPLETED',
  LOAN_DECISION_FAILED = 'LOAN_DECISION_FAILED',
}

export interface ApplicationVerificationMeta {
  document: {
    name: string;
    category: string;
  };
  confidence: string;
}

export interface ApplicationVerification {
  id: string;
  tenantId: string;
  applicationId: string;
  applicantId: string;
  field: string;
  source: string;
  createdAt: string;
  updatedAt: string;
  meta: ApplicationVerificationMeta;
}

export interface ApplicationVerificationV2
  extends Omit<ApplicationVerification, 'applicantId' | 'source' | 'meta'> {
  applicantId: string | null;
  source: string | null;
  meta: ApplicationVerificationMeta | null;
}

export interface ApplicationAssignedUser {
  userId: string;
  roleId: string;
}

export interface ApplicationAssignedUserV2
  extends Omit<ApplicationAssignedUser, 'userId' | 'roleId'> {
  userId: string | null;
  roleId: string | null;
}

export enum ApplicationAssignedUserKey {
  Id = 'id',
  APPLICATION_ID = 'applicationId',
  USER_ID = 'userId',
  ROLE_ID = 'roleId',
}

export interface ApplicationStatus {
  applicationId: string;
  status: ApplicationStatusType;
  underwriterId?: string | null;
  lenderNotes?: string | null;
  content:
    | ApplicationApprovedContent
    | ApplicationDeclinedContent
    | ApplicationPendingContent
    | null
    | undefined;
  isCommitted: boolean;
  isCreditCardFulfilled: boolean;
  isAwaitingDocs: boolean;
  updatedAt: string;
}

export interface ApplicationStatusV2 extends Omit<ApplicationStatus, 'status'> {
  status: ApplicationStatusType | null;
}

export enum ApplicationStatusKey {
  APPLICATION_ID = 'applicationId',
  STATUS = 'status',
  UNDERWRITER_ID = 'underwriterId',
  LENDESK_NOTES = 'lenderNotes',
  DOCUMENT_NAMES = 'documentNames',
  UPDATED_AT = 'updatedAt',
}

export enum ApplicationAdvancedProductKey {
  NAME = 'name',
  LOCATIONS = 'locations',
  LOC = 'isLOC',
  EDGE = 'isEdge',
  COMBO = 'isCombo',
  INSURED = 'isInsured',
  REAPPLY = 'isReapply',
  INFO = 'info',
}

export interface ApplicationApprovedContent {
  documentId: string;
}

export interface ApplicationDeclinedContent {
  declineReasonId: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ApplicationPendingContent {}

export enum ApplicationKey {
  NAME = 'name',
  APPLICATION_NOTES = 'applicationNotes',
  APPLICATION_DATE = 'applicationDate',
  DOWN_PAYMENT_TOTAL = 'downPaymentTotal',
  DOCS_DUE_DATE = 'docsDueDate',
  DECLINE_COMMENT = 'declineComment',
  PURPOSE = 'purpose',
  MORTGAGE_CLASSIFICATION = 'mortgageClassification',
  CURRENT_STAGE = 'currentStage',
  DECLINE_REASON = 'declineReasonId',
  TYPE = 'dealType',
  EXTERNAL_APPLICATION_ID = 'externalApplicationId',
  IS_COMBO = 'isCombo',
  CLOSING_SERVICE = 'closingService',
  LENDING_TIER = 'lendingTier',
  COMMITMENT_ISSUE_DATE = 'commitmentIssueDate',
  COMMITMENT_EXPIRY_DATE = 'commitmentExpiryDate',
  QUICK_CLOSE_FLAG = 'quickCloseFlag',
  RATE_HOLD_EXPIRY_DATE = 'rateHoldExpiryDate',
  SOURCE_OF_BUSINESS = 'sourceOfBusiness',
  PURPOSE_CODE = 'purposeCode',
  TOTAL_BUSINESS_CONNECTION = 'totalBusinessConnection',
  APPLICATION_LOAN_NUMBER = 'applicationLoanNumber',
  FUND_ID = 'fundId',
  EFFECTIVE_DATE = 'effectiveDate',
  SERVICING_TYPE = 'servicingType',
}

export enum ApplicationWarningKeys {
  NO_UNDERWRITER_AUTO_ASSIGNED_WARNING = 'no_underwriter_auto_assigned_warning',
  LOAN_NUMBER_GENERATION_FAILED_WARNING = 'loan_number_generation_failed_warning',
  LOS_APPROVED_WARNING = 'los_approved_failed_warning',
  LOS_PENDING_WARNING = 'los_pending_failed_warning',
  LOS_DECLINED_WARNING = 'los_declined_failed_warning',
  PRODUCT_CHANGE_WARNING = 'product_change_warning',
  SERVICING_DUPLICATE_SUBMISSION_WARNING = 'servicing_duplicate_submission_warning',
  PROJECTED_BALANCE_FAILED_WARNING = 'projected_balance_failed_warning',
}

export enum WarningActions {
  ADDED = 'added',
  RESOLVED = 'resolved',
}

export enum CloseOptions {
  DELETE = 'Delete',
  ARCHIVE = 'Archive',
}

export interface MoveStageModel {
  from: ApplicationStage[] | null;
  to: ApplicationStage[] | null;
}

export enum ApplicationSource {
  DOLPHIN = 'DOLPHIN',
  UPLOAD = 'UPLOAD',
  MANUAL = 'MANUAL',
  COPY = 'COPY',
  VELOCITY = 'VELOCITY',
  VELOCITY_MOR_WEB = 'VELOCITY_MOR_WEB',
  VELOCITY_EXPERT = 'VELOCITY_EXPERT',
  VELOCITY_SCARLETT = 'VELOCITY_SCARLETT',
  VELOCITY_CIBC_EXPERT = 'VELOCITY_CIBC_EXPERT',
  VELOCITY_LENDESK = 'VELOCITY_LENDESK',
  VELOCITY_DOORR = 'VELOCITY_DOORR',
  VELOCITY_BOSS = 'VELOCITY_BOSS',
  VELOCITY_FINMO = 'VELOCITY_FINMO',
  FILOGIX = 'FILOGIX',
  LENDESK = 'LENDESK',
  MERGE = 'MERGE',
  EQ_MAPS_VELOCITY = 'EQ_MAPS_VELOCITY',
  EQ_MAPS_FILOGIX = 'EQ_MAPS_FILOGIX',
  EQ_MAPS_LENDESK = 'EQ_MAPS_LENDESK',
  EQ_MAPS_MBOSS = 'EQ_MAPS_MBOSS',
}

export enum DocumentRequestStatus {
  PROVISIONING = 'PROVISIONING',
  IN_PROGRESS = 'IN PROGRESS',
}

export enum PriorityType {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum ApplicationDefaultValues {
  COMMITMENT_EXPIRY_DATE_OFFSET_DAYS = 10,
  RATE_HOLD_EXPIRY_DATE_OFFSET_DAYS = 120,
  RATE_HOLD_EXPIRY_DATE_OFFSET_DAYS_QUICK_CLOSE = 60,
}

export enum PurposeCode {
  CONSOLIDATION_REFINANCE = 'CONSOLIDATION_REFINANCE',
  AUTO_USED_REPAIRS_INSURANCE = 'AUTO_USED_REPAIRS_INSURANCE',
  NEW_AUTO = 'NEW_AUTO',
  RECREATIONAL_VEHICLES = 'RECREATIONAL_VEHICLES',
  BOATS = 'BOATS',
  VACATIONS = 'VACATIONS',
  FURNITURE = 'FURNITURE',
  HOME_REPAIRS_RENOVATIONS = 'HOME_REPAIRS_RENOVATIONS',
  TAXES = 'TAXES',
  BRIDGE_FINANCING = 'BRIDGE_FINANCING',
  INVESTMENT = 'INVESTMENT',
  CONSTRUCTION_MULTIPLE_DRAWS = 'CONSTRUCTION_MULTIPLE_DRAWS',
  PURCHASE_HOUSING_RENTAL = 'PURCHASE_HOUSING_RENTAL',
  PURCHASE_HOUSING_OWNER_OCCUPIED = 'PURCHASE_HOUSING_OWNER_OCCUPIED',
  PURCHASE_REAL_ESTATE_VACANT_LAND = 'PURCHASE_REAL_ESTATE_VACANT_LAND',
  PURCHASE_REAL_ESTATE_OTHER_VACATION = 'PURCHASE_REAL_ESTATE_OTHER_VACATION',
  MANUFACTURED_HOME_NEW = 'MANUFACTURED_HOME_NEW',
  MISCELLANEOUS = 'MISCELLANEOUS',
  RRSP_PURCHASE = 'RRSP_PURCHASE',
  LETTER_OF_CREDIT = 'LETTER_OF_CREDIT',
  MORTGAGE_ASSIGNMENTS = 'MORTGAGE_ASSIGNMENTS',
  FIRST_HOME = 'FIRST_HOME',
  CONDITIONAL_SALES_CONTRACT = 'CONDITIONAL_SALES_CONTRACT',
  RESTRUCTURE = 'RESTRUCTURE',
  EQUITY_DEAL = 'EQUITY_DEAL',
  MOBILE_HOME = 'MOBILE_HOME',
  MERITLINE = 'MERITLINE',
  CREDITLINE = 'CREDITLINE',
  FARM_PURCHASE = 'FARM_PURCHASE',
  FARM_OPERATING = 'FARM_OPERATING',
  FARM_EQUIPMENT = 'FARM_EQUIPMENT',
  COMMERCIAL_PROPERTY = 'COMMERCIAL_PROPERTY',
  COMMERCIAL_OPERATING = 'COMMERCIAL_OPERATING',
  COMMERCIAL_EQUIPMENT = 'COMMERCIAL_EQUIPMENT',
  OTHER_EQUIPMENT = 'OTHER_EQUIPMENT',
  POSTAL_LETTER_OF_GUARANTEE = 'POSTAL_LETTER_OF_GUARANTEE',
  MORTGAGE_ASSUMPTIONS = 'MORTGAGE_ASSUMPTIONS',
  VARIABLE_RATE_DEALER_SPECIAL = 'VARIABLE_RATE_DEALER_SPECIAL',
  FIXED_RATE_AUTO = 'FIXED_RATE_AUTO',
  LOAN_SALE = 'LOAN_SALE',
  PURCHASE_BUILDER_HOME_UPON_COMPLETION = 'PURCHASE_BUILDER_HOME_UPON_COMPLETION',
  RESTORATION_CREDIT = 'RESTORATION_CREDIT',
  NEW_VENTURES = 'NEW_VENTURES',
  SBIL = 'SBIL',
  LIFE_LEASE = 'LIFE_LEASE',
  LEASEHOLD = 'LEASEHOLD',
  FARM_PLUS = 'FARM_PLUS',
  SYNDICATED_LOAN = 'SYNDICATED_LOAN',
  GRAPE_PLANTING = 'GRAPE_PLANTING',
  CORPORATE_OFFICE_APPROVED = 'CORPORATE_OFFICE_APPROVED',
  EXTENDED_AMORTIZATION = 'EXTENDED_AMORTIZATION',
  HYBRID_PROGRAM = 'HYBRID_PROGRAM',
  MEZZANINE_FINANCING = 'MEZZANINE_FINANCING',
  BULK_LEASING = 'BULK_LEASING',
  GREEN_AUTO = 'GREEN_AUTO',
  GREEN_LOAN = 'GREEN_LOAN',
  SPECIAL_CREDIT_EXP_LOCS = 'SPECIAL_CREDIT_EXP_LOCS',
  NON_MANAGEMENT_STAFF = 'NON_MANAGEMENT_STAFF',
  MANAGEMENT_STAFF = 'MANAGEMENT_STAFF',
  NON_PERFORMING_LOAN_STATUS = 'NON_PERFORMING_LOAN_STATUS',
  BANKRUPT_LOAN_STATUS = 'BANKRUPT_LOAN_STATUS',
  CASH_FLOW_SENIOR_TERM = 'CASH_FLOW_SENIOR_TERM',
  HASCAP = 'HASCAP',
  HIGH_RATIO_OWNER_OCCUPIED_COMMERCIAL = 'HIGH_RATIO_OWNER_OCCUPIED_COMMERCIAL',
  FRANCHISING = 'FRANCHISING',
  CEBA_LOAN = 'CEBA_LOAN',
  CEBA_CONVERSION = 'CEBA_CONVERSION',
  BCAP_BDC_CO_LENDING = 'BCAP_BDC_CO_LENDING',
  REFINANCED_CEBA_LOAN = 'REFINANCED_CEBA_LOAN',
  BDC_ACCELERATOR_GUARANTEE_PROGRAM = 'BDC_ACCELERATOR_GUARANTEE_PROGRAM',
  TRAILERS_SNOWMOBILES_ATVS = 'TRAILERS_SNOWMOBILES_ATVS',
  BUILD_A_PROPERTY = 'BUILD_A_PROPERTY',
  BUSINESS_PURPOSES = 'BUSINESS_PURPOSES',
  CONSOLIDATION_OF_EXTERNAL_DEBT = 'CONSOLIDATION_OF_EXTERNAL_DEBT',
  CONSOLIDATION_OF_INTERNAL_DEBT = 'CONSOLIDATION_OF_INTERNAL_DEBT',
  DOWN_PAYMENT_FOR_PROPERTY_PURCHASE = 'DOWN_PAYMENT_FOR_PROPERTY_PURCHASE',
  FUTURE_USE = 'FUTURE_USE',
  MOTORCYCLE = 'MOTORCYCLE',
  PROPERTY_PURCHASE_FROM_EXISTING_EQUITY = 'PROPERTY_PURCHASE_FROM_EXISTING_EQUITY',
  RECREATIONAL_HOME_OR_MOTORHOME = 'RECREATIONAL_HOME_OR_MOTORHOME',
  SECONDARY_RESIDENCE = 'SECONDARY_RESIDENCE',
  TUITION = 'TUITION',
  USED_VEHICLE = 'USED_VEHICLE',
}

export const VELOCITY_APPLICATION_SOURCE = Object.freeze([
  ApplicationSource.VELOCITY,
  ApplicationSource.VELOCITY_MOR_WEB,
  ApplicationSource.VELOCITY_EXPERT,
  ApplicationSource.VELOCITY_SCARLETT,
  ApplicationSource.VELOCITY_CIBC_EXPERT,
  ApplicationSource.VELOCITY_LENDESK,
  ApplicationSource.VELOCITY_DOORR,
  ApplicationSource.VELOCITY_BOSS,
  ApplicationSource.VELOCITY_FINMO,
]);
