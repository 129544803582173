/* eslint-disable max-len */

import {
  createSelector,
  Selector,
  State,
  Store,
  Action,
  StateContext,
  Actions,
  ofActionDispatched,
} from '@ngxs/store';
import { map, tap, catchError, finalize, switchMap, takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MortgageApplicationService } from './mortgage-application.service';
import {
  Application,
  ApplicationAssignedUser,
  MortgageClassificationOptions,
  UserAccount,
} from '../shared';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { GetEzidoxUrl } from './states/ezidox.state.actions';
import { InitializeInsurances } from './insurances.state';
import { UserAccountsState } from './user-accounts.state';
import { ApplicationResetState } from '../shared/state.model';
import {
  TasksUpdateAssignedUsers,
  TasksBulkInsert,
  GetTasks,
} from '../features/tasks/tasks.actions';
import { AddToLockHistory, FetchLockHistory } from './application-lock-history.state';
import { FlipGetApplicationFlip, FlipSetEnableFlipModule } from './flip/flip.state.action';
import { AppFeaturesState } from '../shared/app-features.state';
import {
  AddressExpanded,
  ApplicationAdvancedProduct,
  ApplicationPermissions,
  ApplicationServicingData,
  ApplicationStage,
  ApplicationStatusType,
  ApplicationWarningKeys,
  DeclineApplicationError,
  DocumentManagementType,
  LoanType,
  Mortgage,
  MortgageType,
  OneTimeLinkGenerationStrategy,
  PipelineApplication,
  PurposeCode,
  ServicingType,
  stagesOrder,
} from '@fundmoreai/models';
import { ApplicationStatus } from '../shared/model';
import { CreditReportRequestGetAllExistingRequests } from '../features/shared/credit-report/request-credit-report/credit-report-requests.state';
import { FetchAppraisals } from './property-appraisals/property-appraisals.state';
import { Role, RoleState } from '../features/shared/user';
import { SetIncomeList } from '../features/application/widgets/income/income.state';
import {
  SetMortgages,
  SetProjectedBalance,
  UpdateMortgagesLoanNumberLocal,
} from './mortgages-v2/mortgages-v2.actions';
import { SetAddressesExpanded } from './states/address-expanded.state';
import { SetFinancialAssets } from '../features/application/widgets/networth/financial-assets.actions';
import { UpdateApplication } from '../features/pipeline/pipeline.actions';
import { SetApplicationVerifications } from './application-verification.actions';
import {
  InitializeConstructionState,
  SetConstructionModuleEnabled,
} from './construction/construction.actions';
import { SetFees } from './fees.actions';
import { SetProperties } from './properties.actions';
import { FetchApplicantsCreditCardOffers, SetApplicants } from './applicants.actions';
import { SetApplicationComputedData } from './application-computed-data.actions';
import { SetCreditFinancialLiabilities } from '../features/application/widgets/credit/financial-liability.actions';
import { SetDownPayments } from './downpayments.actions';
import {
  GetApplication,
  RefreshApplication,
  SetApplication,
  MoveToStage,
  DeclineApplication,
  CancelApplication,
  PatchApplication,
  SetArchived,
  SetLocked,
  UpdateDeclineDate,
  UpdateEzidoxDocsDueDate,
  UpdateFund,
  DeleteApplication,
  AssignApplicationUser,
  AssignUserRole,
  ApproveApplication,
  PendingApplication,
  LeaveApplication,
  CleanApplication,
  GetDocumentRequestStatus,
  SetDocumentRequestDialogCloseCallback,
  ResetDocumentRequestDialogCloseCallback,
  CloseDocumentRequestDialog,
  SetApplicationMemberFlags,
  RefreshApplicationRestrictedStatus,
  UpdateApplicationPriority,
  RefreshDecisionEngine,
  ApplicationPurposeChanged,
  ApplicationUpdateLoanNumber,
  MarkApplicationAsCommitted,
  MarkApplicationAsAwaitingDocs,
  RefreshApplicationRestrictedStatusAndCreditOffers,
  PatchApplicationStatus,
  PatchApplicationServicingData,
  RecomputeMortgageIncrements,
} from './mortgage-application.actions';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import { ApplicationChatState } from '../features/application/application-chat/application-chat.state';
import {
  FetchMessages,
  FetchUnreadCount,
} from '../features/application/application-chat/application-chat.actions';
import { GetAllApplicationConditions } from '../features/application/widgets/conditions/application-conditions.state.actions';
import { FetchApplicationConditionDocuments } from './states/application-condition-documents.state.actions';
import { FetchNotes } from '../features/application/notes/notes.actions';
import { FetchApplicationApprovals } from '../features/application/approvals/application-approval.actions';
import { FetchInsurances } from './property-insurance/property-insurances.actions';
import {
  DELAY_TIME_MS,
  PresenceOnApplication,
  SubscribeApplication,
  SubscribeChat,
  UnsubscribeApplication,
  UnsubscribeChat,
} from '../pubnub/pubnub.state';
import { MortgageInsuranceApplicationQuotesGet } from '../features/application/boxes/mortgage-insurance/mortgage-insurance.state';
import { EzidoxApplicationState } from './states/ezidox.state';
import { FetchEqMessages } from './eq-core.state';
import { FetchPrivateDMDocuments } from './states/actions/private-dm-documents.action';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDetailsDialogComponent } from '../features/application/sidebar/dialogs/error-details-dialog/error-details-dialog.component';
import { DeclineApplicationErrorMessageRecord } from '../shared/enum-records';
import { AuthState } from '../auth/auth.state';
import { FetchApplicationRiskFlags } from '../features/application/application-risk-flags/application-risk-flags.actions';
import { SetMortgageComputedData } from './mortgage-computed-data.actions';
import {
  SetDecisionEngineFlags,
  SubmitToDecisionEngineOnStageTransition,
} from '../features/application/decision-engine/decision-engine.actions';
import { FetchDocumentRequests } from '../features/application/widgets/conditions/document-request.actions';
import { FetchRequestedDocumentNotes } from '../features/application/widgets/conditions/requested-documents-notes/requested-documents-note.actions';
import { patch } from '@ngxs/store/operators';
import { FetchApplicationSubmissions } from './states/actions/application-submissions.action';
import { SetApplicationBrokerCommissions } from './broker-commission/broker-commission.actions';
import { MortgagesV2State } from './mortgages-v2/mortgages-v2.state';
import { SetMCUState } from './mcu.state';
import { FetchApplicationAggregates } from '../features/application/widgets/loan-details/application-aggregates/application-aggregates.action';
import { GetDecrements } from './decrements/decrements.action';
import { FetchClosingInstructions } from './closing-instruction/closing-instruction.state';
import { RouterSelectors } from '../router-state/router.selectors';

export interface MortgageApplicationStateModel {
  application: Application;
  allMortgageClassificationOptions: MortgageClassificationOptions | undefined;
  ezidoxUrl: string;
  mortgageClassificationOptions: MortgageClassificationOptions;
  documentRequestShared: boolean;
  documentRequestInProgressDialogCloseCallback?: () => void;
}
const defaults = {
  application: {} as Application,
  allMortgageClassificationOptions: undefined,
  ezidoxUrl: '',
  mortgageClassificationOptions: undefined,
  documentRequestShared: false,
};
@State<MortgageApplicationStateModel>({
  name: 'mortgageApplication',
  defaults: { ...defaults },
})
@Injectable()
export class MortgageApplicationState {
  private localizedCancelledText = $localize`Application was canceled`;
  private localizedDeclinedText = $localize`Application was declined`;
  private localizedConditionallyApprovedText = $localize`Application was marked as Conditionally Approved`;
  private localizedPendingText = $localize`Application was marked as Pending`;
  private localizedCommentText = $localize`Comment`;

  constructor(
    private applicationService: MortgageApplicationService,
    private store: Store,
    private dialog: MatDialog,
    private actions$: Actions,
  ) {}

  @Selector()
  static warnings(state: MortgageApplicationStateModel) {
    return state.application?.warnings;
  }

  @Selector([MortgageApplicationState.warnings])
  static hasDuplicateServicingSubmissionWarning(warnings: ApplicationWarningKeys[] | undefined) {
    return (
      warnings?.includes(ApplicationWarningKeys.SERVICING_DUPLICATE_SUBMISSION_WARNING) ?? false
    );
  }

  @Selector([MortgageApplicationState.warnings])
  static hasProjectedBalanceFailedWarning(warnings: ApplicationWarningKeys[] | undefined) {
    return warnings?.includes(ApplicationWarningKeys.PROJECTED_BALANCE_FAILED_WARNING) ?? false;
  }

  @Selector()
  static initialServicingApplicationId(state: MortgageApplicationStateModel) {
    return state.application?.ApplicationServicingData?.initialServicingApplicationId ?? null;
  }

  @Selector()
  static isSentToIntellifi(state: MortgageApplicationStateModel) {
    return state.application?.sentToIntellifi;
  }

  @Selector()
  static appliedProducts(state: MortgageApplicationStateModel) {
    return state.application?.ApplicationAdvancedProducts?.filter((ap) => ap.isCurrentlyApplied);
  }

  @Selector([MortgagesV2State.activeRequestedMortgages, MortgageApplicationState.appliedProducts])
  static insuranceRequestDisableReason(
    activeMortgages: Mortgage[],
    appliedProducts: ApplicationAdvancedProduct[],
  ) {
    const requestedMortgages = activeMortgages?.filter(
      (m) => m.type === MortgageType.REQUESTED && m.loanType === LoanType.MORTGAGE,
    );

    const insuredMortgages = requestedMortgages?.filter((rm: Mortgage) =>
      appliedProducts?.some(
        (aap: ApplicationAdvancedProduct) =>
          aap.mortgageId === rm.id &&
          aap.advancedProductSnapshot &&
          aap.advancedProductSnapshot.parameters &&
          aap.advancedProductSnapshot.parameters.isInsured,
      ),
    );

    if (!insuredMortgages || insuredMortgages.length === 0) {
      return $localize`System couldn’t find a product with the following criteria:\n- Loan Type: Mortgage\n- Selected Product with Feature: Insured`;
    }

    if (insuredMortgages.length > 1) {
      return $localize`System found more than one product with the following criteria:\n- Loan Type: Mortgage\n- Selected Product with Feature: Insured`;
    }

    return null;
  }

  @Selector() static applicationIsHELOC(state: MortgageApplicationStateModel) {
    const requestedMortgageWithLOCExists =
      state?.application?.Mortgages?.find(
        (m) =>
          m.type === MortgageType.REQUESTED &&
          (m.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
            m.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX),
      ) && true;

    return state.application.isCombo && requestedMortgageWithLOCExists;
  }

  @Selector() static applicationIsSHELOC(state: MortgageApplicationStateModel) {
    const requestedMortgageWithLOCExists =
      state?.application?.Mortgages?.find(
        (m) =>
          m.type === MortgageType.REQUESTED &&
          (m.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
            m.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX),
      ) && true;

    return !state.application.isCombo && requestedMortgageWithLOCExists;
  }

  @Selector() static applicationIsCombo(state: MortgageApplicationStateModel) {
    return state.application.isCombo;
  }

  @Selector([
    MortgageApplicationState.application,
    EzidoxApplicationState.url,
    AppFeaturesState.dmOneTimeLinkGenerationStrategy,
    EzidoxApplicationState.updateRequestInProgress,
  ])
  static applicationHasEzidoxRequestAndLinkGenerated(
    application: Application,
    ezidoxUrl: string,
    dmOneTimeLinkGenerationStrategy: OneTimeLinkGenerationStrategy,
    updateRequestInProgress: boolean,
  ) {
    return (
      !updateRequestInProgress &&
      application.ezidoxApplicationId != null &&
      ((ezidoxUrl != null &&
        ezidoxUrl.trim() !== '' &&
        dmOneTimeLinkGenerationStrategy === OneTimeLinkGenerationStrategy.CACHED) ||
        dmOneTimeLinkGenerationStrategy === OneTimeLinkGenerationStrategy.ON_DEMAND)
    );
  }

  @Selector() static application(state: MortgageApplicationStateModel) {
    return state.application;
  }

  @Selector() static applicationCurrentStage(state: MortgageApplicationStateModel) {
    return state.application.currentStage;
  }

  @Selector() static applicationPermissions(state: MortgageApplicationStateModel) {
    return state.application.uiAbstractPermissions;
  }

  @Selector([MortgageApplicationState.applicationPermissions])
  static canEditMortgageIncrement(permissions: ApplicationPermissions | undefined) {
    return permissions?.canEdit && permissions?.canUpdateMortgageIncrements;
  }

  @Selector([MortgageApplicationState.applicationPermissions])
  static canCreateCustomIncrements(permissions: ApplicationPermissions) {
    return permissions?.canEdit && permissions?.canCreateCustomIncrements;
  }

  @Selector([MortgageApplicationState.applicationPermissions])
  static canUpdateMortgageDecrements(permissions: ApplicationPermissions) {
    return permissions?.canEdit && permissions.canUpdateMortgageDecrements;
  }

  @Selector([MortgageApplicationState.applicationPermissions])
  static canUpdateMortgageDiscretion(permissions: ApplicationPermissions) {
    return permissions?.canEdit && permissions.canUpdateMortgageDiscretion;
  }

  @Selector([MortgageApplicationState.application])
  static applicationValuesForProductReapplyTriggers(application: Application) {
    return {
      purpose: application.purpose,
      source: application.source,
    };
  }

  @Selector()
  static allMortgageClassificationOptions(state: MortgageApplicationStateModel) {
    return state.allMortgageClassificationOptions;
  }

  @Selector()
  static mortgageClassification(state: MortgageApplicationStateModel) {
    return state.mortgageClassificationOptions;
  }

  @Selector()
  static ezidoxUrl(state: MortgageApplicationStateModel) {
    return state.ezidoxUrl;
  }

  static applicationEditable(
    includeApplicationLocked: boolean = true,
    disableForServicing: boolean = false,
    disableForServicingNotPassingInitialSetup: boolean = false,
  ) {
    return createSelector(
      [
        MortgageApplicationState.applicationCanBeEdited,
        MortgageApplicationState.applicationIsLocked,
        RouterSelectors.servicing,
        MortgageApplicationState.servicingApplicationPassedInitialSetup,
      ],
      (
        applicationCanBeEdited: boolean,
        applicationIsLocked: boolean,
        isServicingEnabled: boolean | undefined,
        servicingApplicationPassedInitialSetup: boolean,
      ) => {
        let applicationEditable = applicationCanBeEdited;

        if (includeApplicationLocked) {
          applicationEditable = applicationEditable && !applicationIsLocked;
        }

        if (isServicingEnabled) {
          if (disableForServicing) {
            return false;
          }

          if (disableForServicingNotPassingInitialSetup) {
            return applicationEditable && servicingApplicationPassedInitialSetup;
          }
        }

        return applicationEditable;
      },
    );
  }

  @Selector([MortgageApplicationState.application])
  private static applicationIsLocked(application: Application) {
    return application.locked;
  }

  @Selector([MortgageApplicationState.application])
  private static applicationCanBeEdited(application: Application) {
    return !!(application.uiAbstractPermissions && application.uiAbstractPermissions.canEdit);
  }

  @Selector() static canPullEquifaxReport(state: MortgageApplicationStateModel) {
    const application = state.application;
    return (
      !application.locked &&
      application.uiAbstractPermissions &&
      application.uiAbstractPermissions.canPullEquifaxReport
    );
  }

  @Selector() static isDocumentRequestShared(state: MortgageApplicationStateModel) {
    return state.documentRequestShared;
  }

  @Selector([MortgageApplicationState.application])
  static applicationRestrictedPartyMember(application: Application) {
    return application?.restrictedPartyMember;
  }

  @Selector([MortgageApplicationState.application])
  static applicationConnectedParty(application: Application) {
    return application?.connectedParty;
  }

  @Selector([MortgageApplicationState.application])
  static applicationStaffMember(application: Application) {
    return application?.staffMember;
  }

  @Selector([MortgageApplicationState.application])
  static isApplicationStageAfterOperationsFullfillment(application: Application) {
    return (
      application.currentStage &&
      stagesOrder[application.currentStage] >= stagesOrder[ApplicationStage.OPERATIONS_FULFILLMENT]
    );
  }

  /**
   * Check if the application is after the given stage in the pipeline
   *
   * If the application has the same stage as the given stage, it will return FALSE
   *
   * @param stage The stage to check if the application is after
   * @returns boolean
   */
  static isApplicationStageAfter(stage: ApplicationStage) {
    return createSelector([MortgageApplicationState.application], (application: Application) => {
      return application.currentStage && stagesOrder[application.currentStage] > stagesOrder[stage];
    });
  }

  static assignedRoles() {
    return createSelector(
      [MortgageApplicationState, UserAccountsState.userAccountsList, RoleState.allAssignableRoles],
      (state: MortgageApplicationStateModel, userAccounts: UserAccount[], roles: Role[]) => {
        return MortgageApplicationState.getAssignedRoles(state.application, userAccounts, roles);
      },
    );
  }

  @Selector() static applicationIsConditionallyApproved(state: MortgageApplicationStateModel) {
    return state.application?.ApplicationStatus?.status === ApplicationStatusType.APPROVED;
  }

  @Selector([MortgageApplicationState.application])
  static servicingApplicationPassedInitialSetup(
    application: Pick<Application, 'ApplicationServicingData' | 'isServicing' | 'purpose'>,
  ): boolean | undefined {
    return MortgageApplicationService.servicingApplicationPassedInitialSetup(application);
  }

  @Selector([MortgageApplicationState.application])
  static isServicingApplication(application: Application): boolean | undefined {
    return application.isServicing;
  }

  @Action(SetApplication)
  setApplication(
    ctx: StateContext<MortgageApplicationStateModel>,
    { application }: SetApplication,
  ) {
    if (!application) {
      return;
    }

    let mortgageClassificationOptions;
    let allMortgageClassificationOptions;
    const applicationPurpose = (application as Application).purpose;

    if (applicationPurpose) {
      mortgageClassificationOptions =
        this.applicationService.getMortgageClassificationOption(applicationPurpose);
      allMortgageClassificationOptions = this.applicationService.getMortgageClassificationOption(
        applicationPurpose,
        true,
      );
    }

    ctx.patchState({
      application: application as Application,
      allMortgageClassificationOptions: allMortgageClassificationOptions ?? {},
      mortgageClassificationOptions: mortgageClassificationOptions ?? {},
    });

    ctx.dispatch(new SetApplicants(application.applicants));
  }

  @Action(ApplicationResetState)
  resetApplication(ctx: StateContext<MortgageApplicationStateModel>) {
    ctx.patchState({ ...defaults });
  }

  @Action(LeaveApplication)
  leaveApplication(ctx: StateContext<MortgageApplicationStateModel>, action: LeaveApplication) {
    return ctx.dispatch([new UnsubscribeChat(), new UnsubscribeApplication(action.applicationId)]);
  }

  @Action(RefreshApplication)
  refreshApplication(ctx: StateContext<MortgageApplicationStateModel>, action: RefreshApplication) {
    const application = ctx.getState().application;

    if (application.id !== action.applicationId) {
      return EMPTY;
    }

    return ctx.dispatch(new GetApplication(action.applicationId, true));
  }

  @Action(CleanApplication)
  cleanApplication(ctx: StateContext<MortgageApplicationStateModel>) {
    return ctx.dispatch(new ApplicationResetState());
  }
  @Action(GetApplication)
  getApplication(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId, skipIsApplicationIdChanged }: GetApplication,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const isServicingEnabled = this.store.selectSnapshot(RouterSelectors.servicing);

    return this.applicationService.getApplication(applicationId, isServicingEnabled).pipe(
      switchMap((application) => {
        if (skipIsApplicationIdChanged) {
          const application = ctx.getState().application;

          if (application.id !== applicationId) {
            return EMPTY;
          }
        }

        return of(application);
      }),
      switchMap((application) =>
        ctx.dispatch(new SetApplication(application)).pipe(map(() => application)),
      ),
      switchMap((a) => {
        const addressesExpanded: AddressExpanded[] = [];

        for (const applicant of a.applicants) {
          if (applicant.ApplicantAddressesExpanded) {
            for (const addressExpanded of applicant.ApplicantAddressesExpanded) {
              addressesExpanded.push(addressExpanded);
            }
          }
        }

        const sideEffectActions: unknown[] = [
          new SetMortgages(a.Mortgages),
          new SetProperties(a.Properties),
          new SetFinancialAssets(a.FinancialAssets),
          new SetDownPayments(a.DownPayments),
          new SetCreditFinancialLiabilities(a.FinancialLiabilities),
          new SetApplicationVerifications(a.ApplicationVerifications),
          new SetFees(a.Fees),
          new SetAddressesExpanded(addressesExpanded),
          new InitializeInsurances(a.Mortgages),
          new SetIncomeList(a),
          new FetchAppraisals(a.id),
          new CreditReportRequestGetAllExistingRequests(applicationId),
          new FetchLockHistory(applicationId),
          new FetchUnreadCount(applicationId),
          new GetAllApplicationConditions(applicationId),
          new FetchApplicationConditionDocuments(applicationId),
          new FetchNotes(applicationId),
          new FetchApplicationApprovals(applicationId),
          new FetchInsurances(applicationId),
          new GetTasks(applicationId),
          new MortgageInsuranceApplicationQuotesGet(applicationId),
          new GetDocumentRequestStatus(applicationId),
          new FetchApplicationRiskFlags(applicationId),
          new SetDecisionEngineFlags(a.ApplicationDecisionEngineFlags),
          new SetApplicationBrokerCommissions(a.ApplicationBrokerCommissions),
          new SetMCUState(a),
          new FetchClosingInstructions(applicationId),
        ];

        if (
          this.store.selectSnapshot(AppFeaturesState.documentManagementType) ===
          DocumentManagementType.EZIDOX
        ) {
          sideEffectActions.push(new FetchPrivateDMDocuments(applicationId));
        }

        if (this.store.selectSnapshot(AppFeaturesState.eqMapsEnabled)) {
          sideEffectActions.push(new FetchEqMessages(applicationId));
        }

        if (a.ApplicationComputedData) {
          sideEffectActions.push(new SetApplicationComputedData(a.ApplicationComputedData));
        }

        if (a.MortgageComputedData) {
          sideEffectActions.push(new SetMortgageComputedData(a.MortgageComputedData));
        }

        if (a.externalApplicationId) {
          sideEffectActions.push(new FetchApplicationSubmissions(applicationId));
        }

        if (
          a.ezidoxApplicationId &&
          a.ezidoxApplicationId > 0 &&
          this.store.selectSnapshot(AppFeaturesState.isFundmoreDMEnabled)
        ) {
          sideEffectActions.push(new GetEzidoxUrl(applicationId));
        }
        const { isConstructionEnabled, isFlipEnabled } = this.flipAndConstructionFeatureFlag(a);
        if (isConstructionEnabled) {
          sideEffectActions.push(new InitializeConstructionState(a));
          sideEffectActions.push(new SetConstructionModuleEnabled(isConstructionEnabled));
        }
        if (isFlipEnabled) {
          sideEffectActions.push(new FlipSetEnableFlipModule(isFlipEnabled));
          sideEffectActions.push(new FlipGetApplicationFlip(applicationId));
        }

        if (this.store.selectSnapshot(ApplicationChatState.isOpen)) {
          sideEffectActions.push(new FetchMessages(applicationId));
        }

        if (this.store.selectSnapshot(AppFeaturesState.isDecisionEngineIntegrationEnabled)) {
          sideEffectActions.push(new FetchApplicantsCreditCardOffers(applicationId));
        }

        if (this.store.selectSnapshot(AppFeaturesState.isIqDocumentRequestsEnabled)) {
          sideEffectActions.push(new FetchDocumentRequests(applicationId));
          sideEffectActions.push(new FetchRequestedDocumentNotes(applicationId));
        }
        if (this.store.selectSnapshot(AppFeaturesState.decrementsEnabled)) {
          sideEffectActions.push(new GetDecrements());
        }

        // delay presence on the application
        setTimeout(() => {
          if (
            !a.ApplicationAssignedUsers?.some(
              (a) => a.userId === this.store.selectSnapshot(AuthState.currentUser)?.user?.id,
            )
          ) {
            this.store.dispatch(new SubscribeApplication(applicationId));
          }
          if (a.uiAbstractPermissions?.canChat) {
            this.store.dispatch(new SubscribeChat(applicationId));
          }
          this.store.dispatch(new PresenceOnApplication(applicationId));
        }, DELAY_TIME_MS);

        if (this.store.selectSnapshot(AppFeaturesState.isExpandedLendingLimitsEnabled)) {
          sideEffectActions.push(new FetchApplicationAggregates(applicationId));
        }

        return ctx.dispatch(sideEffectActions).pipe(map(() => a));
      }),
      takeUntil(this.actions$.pipe(ofActionDispatched(CleanApplication))),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(RefreshApplicationRestrictedStatus)
  refreshApplicationRestrictedStatus(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId }: RefreshApplicationRestrictedStatus,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const isServicingEnabled = this.store.selectSnapshot(RouterSelectors.servicing);

    // TODO get minimal needed application data
    return this.applicationService.getApplication(applicationId, isServicingEnabled).pipe(
      switchMap((application) =>
        ctx
          .dispatch(
            new SetApplicationMemberFlags(
              application.restrictedPartyMember,
              application.staffMember,
              application.connectedParty,
            ),
          )
          .pipe(map(() => application)),
      ),

      takeUntil(this.actions$.pipe(ofActionDispatched(CleanApplication))),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(RefreshApplicationRestrictedStatusAndCreditOffers)
  refreshApplicationRestrictedStatusAndCreditOffers(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId }: RefreshApplicationRestrictedStatusAndCreditOffers,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const isServicingEnabled = this.store.selectSnapshot(RouterSelectors.servicing);

    return this.applicationService.getApplication(applicationId, isServicingEnabled).pipe(
      switchMap((application) =>
        ctx
          .dispatch(
            new SetApplicationMemberFlags(
              application.restrictedPartyMember,
              application.staffMember,
              application.connectedParty,
            ),
          )
          .pipe(map(() => application)),
      ),
      switchMap((application) => {
        return ctx.dispatch(new SetApplicants(application.applicants)).pipe(
          switchMap(() => {
            return this.store.selectSnapshot(AppFeaturesState.isDecisionEngineIntegrationEnabled)
              ? ctx.dispatch(new FetchApplicantsCreditCardOffers(application.id))
              : of(null);
          }),
        );
      }),

      takeUntil(this.actions$.pipe(ofActionDispatched(CleanApplication))),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(RefreshDecisionEngine)
  refreshDecisionEngine(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId }: RefreshDecisionEngine,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationService.getApplicationDecisionEngine(applicationId).pipe(
      switchMap((applicationDecisionEngineFlags) =>
        ctx.dispatch(new SetDecisionEngineFlags(applicationDecisionEngineFlags)),
      ),
      takeUntil(this.actions$.pipe(ofActionDispatched(CleanApplication))),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  private flipAndConstructionFeatureFlag(a: Application) {
    const hasConstructionModule =
      (a?.Mortgages ?? []).filter(
        (m) =>
          m.applicationAdvancedProduct?.advancedProductSnapshot.parameters?.modules
            ?.constructionModule,
      ).length != 0;
    const constructionModuleTenantSettingEnable = this.store.selectSnapshot(
      AppFeaturesState.isConstructionModuleEnabled,
    );
    const isConstructionEnabled =
      (hasConstructionModule && constructionModuleTenantSettingEnable) ?? false;

    const hasFlipModule =
      (a?.Mortgages ?? []).filter(
        (m) =>
          m.applicationAdvancedProduct?.advancedProductSnapshot.parameters?.modules?.flipModule,
      ).length != 0;
    const flipModuleTenantSettingEnabled = this.store.selectSnapshot(
      AppFeaturesState.flipModuleEnabled,
    );
    const isFlipEnabled = (hasFlipModule && flipModuleTenantSettingEnabled) ?? false;
    return { isConstructionEnabled, isFlipEnabled };
  }

  @Action(MoveToStage)
  moveToStage(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId, newStage, locked }: MoveToStage,
  ) {
    const initState = ctx.getState();
    if (locked ?? initState.application.locked) {
      return EMPTY;
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.applicationService.moveToStage(applicationId, newStage).pipe(
      switchMap(({ applicationData, newApplicationTasks }) => {
        const state = ctx.getState();
        const updatedApplication = Object.assign({}, state.application, {
          currentStage: applicationData.currentStage,
          previousStage: applicationData.previousStage,
          locked: applicationData.locked,
          ApplicationStatus:
            applicationData.ApplicationStatus ?? state.application.ApplicationStatus,
        });

        const sideEffectActions: unknown[] = [
          new SetApplication(updatedApplication),
          new UpdateApplication(applicationId, applicationData as Partial<PipelineApplication>),
          new SubmitToDecisionEngineOnStageTransition(applicationId),
        ];

        if (newApplicationTasks && newApplicationTasks.length > 0) {
          sideEffectActions.push(new TasksBulkInsert(newApplicationTasks));
        }

        const visibleStages =
          this.store.selectSnapshot(AppFeaturesState.tenantStages) ?? Object.keys(ApplicationStage);

        if (
          applicationData.currentStage &&
          applicationData.previousStage &&
          visibleStages.indexOf(applicationData.previousStage) >
            visibleStages.indexOf(applicationData.currentStage)
        ) {
          sideEffectActions.push(new GetTasks(applicationId));
        }

        return ctx.dispatch(sideEffectActions);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(DeclineApplication)
  declineApplication(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId, declineReasonId, declineComment, declineDate, locked }: DeclineApplication,
  ) {
    const initState = ctx.getState();
    if (locked ?? initState.application.locked) {
      return EMPTY;
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));
    const reasonObject = { declineReasonId, declineComment, declineDate } as Partial<Application>;
    return this.applicationService
      .declineApplication(
        applicationId,
        reasonObject,
        this.localizedDeclinedText,
        this.localizedCommentText,
      )
      .pipe(
        switchMap(({ declineApplicationResult, newApplicationTasks }) => {
          const state = ctx.getState();
          const application = state.application;
          if (applicationId !== application.id) {
            return EMPTY;
          }
          const updatedApplication = Object.assign({}, state.application, {
            currentStage: declineApplicationResult.currentStage,
            previousStage: declineApplicationResult.previousStage,
            declineReasonId,
            declineComment,
            declineDate,
            locked: declineApplicationResult.locked,
            ApplicationStatus: declineApplicationResult.ApplicationStatus,
          });

          const sideEffectActions: unknown[] = [
            new SetApplication(updatedApplication),
            new FetchLockHistory(applicationId),
            new UpdateApplication(
              applicationId,
              declineApplicationResult as Partial<PipelineApplication>,
            ),
            new FetchNotes(applicationId),
          ];

          if (newApplicationTasks && newApplicationTasks.length > 0) {
            sideEffectActions.push(new TasksBulkInsert(newApplicationTasks));
          }

          return ctx.dispatch(sideEffectActions);
        }),
        switchMap(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
        catchError((error) => {
          console.error('moveToStageDecline', error);
          ctx.dispatch(new LoadingEnd(this.constructor.name));
          return throwError(() => error);
        }),
      );
  }

  @Action(CancelApplication)
  cancelApplication(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId, declineReasonId, cancelComment, cancelDate, locked }: CancelApplication,
  ) {
    const initState = ctx.getState();
    if (locked ?? initState.application.locked) {
      return EMPTY;
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));
    const reasonObject = { declineReasonId, cancelComment, cancelDate } as Partial<Application>;
    return this.applicationService
      .cancelApplication(applicationId, reasonObject, this.localizedCancelledText)
      .pipe(
        switchMap(({ cancelApplicationResult, newApplicationTasks }) => {
          const state = ctx.getState();
          const application = state.application;
          if (applicationId !== application.id) {
            return EMPTY;
          }
          const updatedApplication = Object.assign({}, state.application, {
            currentStage: cancelApplicationResult.currentStage,
            previousStage: cancelApplicationResult.previousStage,
            declineReasonId: cancelApplicationResult.declineReasonId,
            declineComment: cancelApplicationResult.declineComment,
            declineDate: cancelApplicationResult.declineDate,
            locked: cancelApplicationResult.locked,
            ApplicationStatus: cancelApplicationResult.ApplicationStatus,
          });

          const sideEffectActions: unknown[] = [
            new SetApplication(updatedApplication),
            new FetchLockHistory(applicationId),
            new UpdateApplication(
              applicationId,
              cancelApplicationResult as Partial<PipelineApplication>,
            ),
            new FetchNotes(applicationId),
          ];

          if (newApplicationTasks && newApplicationTasks.length > 0) {
            sideEffectActions.push(new TasksBulkInsert(newApplicationTasks));
          }

          return ctx.dispatch(sideEffectActions);
        }),
        switchMap(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
        catchError((error) => {
          console.error('moveToStageDecline', error);
          ctx.dispatch(new LoadingEnd(this.constructor.name));

          if (error?.error?.message === DeclineApplicationError.NOT_LODGED) {
            this.dialog.open(ErrorDetailsDialogComponent, {
              data: {
                message: DeclineApplicationErrorMessageRecord[DeclineApplicationError.NOT_LODGED],
              },
              width: '400px',
              height: '300px',
            });

            return throwError(() => null);
          }
          return throwError(() => error);
        }),
      );
  }

  @Action(PatchApplication)
  patchApplication(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId, application }: PatchApplication,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationService.patchApplication(applicationId, application).pipe(
      switchMap(() => {
        const state = ctx.getState();
        const updatedApplication = Object.assign({}, state.application, application);
        return ctx.dispatch(new SetApplication(updatedApplication));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateApplicationPriority)
  updateApplicationPriority(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId, priority }: UpdateApplicationPriority,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationService.updateApplicationPriority(applicationId, priority).pipe(
      map(() => {
        const state = ctx.getState();

        ctx.patchState({
          application: {
            ...state.application,
            priority,
          },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(SetArchived)
  setArchived(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId, application, updateApplication }: SetArchived,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationService.patchApplicationSetArchived(applicationId, application).pipe(
      switchMap(() => {
        if (updateApplication) {
          const state = ctx.getState();
          const updatedApplication = Object.assign({}, state.application, application);
          return ctx.dispatch(new SetApplication(updatedApplication));
        }
        return EMPTY;
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(SetLocked)
  setLocked(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId, application, lockDetails }: SetLocked,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationService
      .patchApplicationSetLock(applicationId, application, lockDetails)
      .pipe(
        switchMap((locked) => {
          const state = ctx.getState();
          const updatedApplication = Object.assign({}, state.application, application);
          return ctx.dispatch([
            new SetApplication(updatedApplication),
            new AddToLockHistory(locked),
          ]);
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(UpdateEzidoxDocsDueDate)
  updateEzidoxDocsDueDate(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId, ezidoxApplicationId, docsDueDate }: UpdateEzidoxDocsDueDate,
  ): Observable<void> {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationService
      .updateApplicationDocsDueDate(applicationId, ezidoxApplicationId, docsDueDate)
      .pipe(
        switchMap(() => {
          const state = ctx.getState();
          const updatedApplication = Object.assign({}, state.application, {
            docsDueDate: docsDueDate,
          });
          return ctx.dispatch(new SetApplication(updatedApplication));
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(UpdateFund)
  updateFund(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId, fundId }: UpdateFund,
  ): Observable<void> {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationService.updateFund(applicationId, fundId).pipe(
      switchMap(() => {
        const state = ctx.getState();
        const updatedApplication = Object.assign({}, state.application, { fundId: fundId });
        return ctx.dispatch(new SetApplication(updatedApplication));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateDeclineDate)
  updateDeclineDate(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId, declineDate }: UpdateDeclineDate,
  ): Observable<void> {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.applicationService.updateDeclineDate(applicationId, declineDate).pipe(
      switchMap(() => {
        const state = ctx.getState();
        const updatedApplication = Object.assign({}, state.application, {
          declineDate: declineDate,
        });
        return ctx.dispatch(new SetApplication(updatedApplication));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(DeleteApplication)
  deleteApplication(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId }: DeleteApplication,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationService.deleteApplication(applicationId).pipe(
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(AssignUserRole)
  assignUserRole(
    ctx: StateContext<MortgageApplicationStateModel>,
    { users, role }: AssignUserRole,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();
    const userIds = users?.map((a) => a.id) ?? [];
    return this.applicationService.assignUser(state.application.id, userIds, role).pipe(
      switchMap((res) =>
        ctx.dispatch([
          new TasksUpdateAssignedUsers(state.application.id, res.updatedTasks),
          new GetDocumentRequestStatus(state.application.id),
        ]),
      ),
      switchMap(() => ctx.dispatch(new AssignApplicationUser(users, role))),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(ApproveApplication)
  approveApplication(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationApprove }: ApproveApplication,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.applicationService
      .approveApplication(
        applicationApprove,
        this.localizedConditionallyApprovedText,
        this.localizedCommentText,
      )
      .pipe(
        switchMap((applicationStatus: ApplicationStatus) => {
          const state = ctx.getState();
          const updatedApplication = Object.assign({}, state.application, {
            ApplicationStatus: applicationStatus,
          });
          return ctx.dispatch([
            new SetApplication(updatedApplication),
            new FetchNotes(applicationApprove.applicationId),
          ]);
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(PendingApplication)
  pendingApplication(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId, applicationPending }: PendingApplication,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.applicationService
      .pendingApplication(
        applicationId,
        applicationPending,
        this.localizedPendingText,
        this.localizedCommentText,
      )
      .pipe(
        tap((applicationStatus: ApplicationStatus) => {
          const state = ctx.getState();
          const updatedApplication = Object.assign({}, state.application, {
            ApplicationStatus: applicationStatus,
          });
          return ctx.dispatch([
            new SetApplication(updatedApplication),
            new FetchNotes(applicationId),
          ]);
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(AssignApplicationUser)
  assignApplicationUser(
    ctx: StateContext<MortgageApplicationStateModel>,
    { users, role }: AssignApplicationUser,
  ) {
    const state = ctx.getState();

    if (!state.application.ApplicationAssignedUsers) {
      return;
    }

    const applicationUsers = users?.map(
      (a) =>
        <ApplicationAssignedUser>{
          // id: `${a.id}not-used`,
          applicationId: state.application.id,
          userId: a.id,
          roleId: role.id,
        },
    );
    const updatedAssignedUsers: ApplicationAssignedUser[] = [
      ...(state.application.ApplicationAssignedUsers?.filter(
        (applicationAssignedUser) => applicationAssignedUser?.roleId !== role.id,
      ) ?? []),
      ...(applicationUsers ?? []),
    ];

    const updatedApplication = Object.assign({}, state.application, {
      ApplicationAssignedUsers: updatedAssignedUsers,
    });

    const actions: unknown[] = [new SetApplication(updatedApplication)];

    if (
      updatedAssignedUsers.some(
        (a) => a.userId === this.store.selectSnapshot(AuthState.currentUser)?.user?.id,
      )
    ) {
      actions.push(new UnsubscribeApplication(state.application.id));
    } else {
      actions.push(new SubscribeApplication(state.application.id));
    }

    return ctx.dispatch(actions);
  }

  @Action(GetDocumentRequestStatus) getDocumentRequestStatus(
    ctx: StateContext<MortgageApplicationStateModel>,
    { applicationId }: GetDocumentRequestStatus,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationService.getDocumentRequestStatus(applicationId).pipe(
      tap(({ documentRequestShared }) => {
        ctx.patchState({
          documentRequestShared,
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(SetDocumentRequestDialogCloseCallback) setDocumentRequestDialogCloseCallback(
    ctx: StateContext<MortgageApplicationStateModel>,
    action: SetDocumentRequestDialogCloseCallback,
  ) {
    ctx.patchState({
      documentRequestInProgressDialogCloseCallback: action.callback,
    });
  }

  @Action(ResetDocumentRequestDialogCloseCallback) resetDocumentRequestDialogCloseCallback(
    ctx: StateContext<MortgageApplicationStateModel>,
  ) {
    ctx.patchState({
      documentRequestInProgressDialogCloseCallback: undefined,
    });
  }

  @Action(CloseDocumentRequestDialog) closeDocumentRequestDialog(
    ctx: StateContext<MortgageApplicationStateModel>,
  ) {
    const state = ctx.getState();

    if (state.documentRequestInProgressDialogCloseCallback) {
      state.documentRequestInProgressDialogCloseCallback();
    }

    return ctx.dispatch(new ResetDocumentRequestDialogCloseCallback());
  }

  @Action(SetApplicationMemberFlags)
  setApplicationStaffMemberAndRestrictedAccessMember(
    ctx: StateContext<MortgageApplicationStateModel>,
    action: SetApplicationMemberFlags,
  ) {
    const state = ctx.getState();

    ctx.patchState({
      application: {
        ...state.application,
        restrictedPartyMember: action.restrictedPartyMember,
        staffMember: action.staffMember,
        connectedParty: action.connectedParty,
      },
    });
  }

  private static getAssignedRoles(
    application: Application,
    userAccounts: UserAccount[] | undefined,
    roles: Role[],
  ) {
    const assignedUsers = application.ApplicationAssignedUsers;

    if (!assignedUsers) {
      return {};
    }

    const applicationAssignedRoles = roles.reduce(
      (result: { [key: string]: UserAccount[] }, role) => {
        const assignedUsersForRole = assignedUsers.filter((x) => x.roleId === role.id);

        result[role.id] =
          userAccounts?.filter((useAccount) =>
            assignedUsersForRole.some((x) => x.userId === useAccount.user.id),
          ) ?? [];

        return result;
      },
      {},
    );

    return applicationAssignedRoles;
  }

  @Action(ApplicationPurposeChanged)
  applicationPurposeChanged(ctx: StateContext<MortgageApplicationStateModel>) {
    const state = ctx.getState();
    const application = state.application;

    if (!application || !application.purpose) {
      return;
    }

    const purposeCodeByApplicationPurpose = this.store.selectSnapshot(
      AppFeaturesState.purposeCodeByApplicationPurpose,
    );

    if (!purposeCodeByApplicationPurpose) {
      return;
    }

    let purposeCode = application.purposeCode;

    if (purposeCodeByApplicationPurpose[application.purpose] !== null) {
      const mappedPurposeCode = purposeCodeByApplicationPurpose[application.purpose] as PurposeCode;

      if (!mappedPurposeCode) {
        return;
      }
      purposeCode = mappedPurposeCode;
    }

    return ctx.dispatch(
      new PatchApplication(application.id, {
        purposeCode,
      }),
    );
  }

  @Action(ApplicationUpdateLoanNumber)
  updateApplicationLoanNumber(
    ctx: StateContext<MortgageApplicationStateModel>,
    action: ApplicationUpdateLoanNumber,
  ) {
    const application = ctx.getState().application;
    const expandedLoanNumber = {
      number: 0,
      prefix: '',
      suffix: '',
      ...application.applicationExpandedLoanNumber,
    };
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.applicationService
      .updateApplicationLoanNumber(application.id, {
        ...expandedLoanNumber,
        ...action.expandedLoanNumber,
      })
      .pipe(
        tap((loanNumberData) => {
          ctx.setState(
            patch({
              application: patch({
                applicationLoanNumber: loanNumberData.loanNumber,
                applicationExpandedLoanNumber: loanNumberData.expandedLoanNumber,
              }),
            }),
          );
        }),
        switchMap((loanNumberData) =>
          ctx.dispatch(new UpdateMortgagesLoanNumberLocal(loanNumberData.mortgages)),
        ),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(MarkApplicationAsCommitted)
  markApplicationAsCommitted(
    ctx: StateContext<MortgageApplicationStateModel>,
    action: MarkApplicationAsCommitted,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.applicationService.markApplicationAsCommitted(action.applicationId).pipe(
      switchMap((applicationStatus: ApplicationStatus) => {
        const state = ctx.getState();
        const updatedApplication = Object.assign({}, state.application, {
          ApplicationStatus: applicationStatus,
        });
        return ctx.dispatch([
          new SetApplication(updatedApplication),
          new FetchNotes(action.applicationId),
        ]);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(PatchApplicationStatus)
  patchApplicationStatus(
    ctx: StateContext<MortgageApplicationStateModel>,
    action: PatchApplicationStatus,
  ) {
    const state = ctx.getState();
    const updatedApplication = Object.assign({}, state.application, {
      ApplicationStatus: action.applicationStatus,
    });
    return ctx.dispatch(new SetApplication(updatedApplication));
  }

  @Action(RecomputeMortgageIncrements) recomputeMortgageIncrements(
    ctx: StateContext<MortgageApplicationStateModel>,
    action: RecomputeMortgageIncrements,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationService
      .recomputeMortgageIncrements(action.applicationId)
      .pipe(finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))));
  }

  @Action(MarkApplicationAsAwaitingDocs)
  markApplicationAsAwaitingDocs(
    ctx: StateContext<MortgageApplicationStateModel>,
    action: MarkApplicationAsAwaitingDocs,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.applicationService.markApplicationAsAwaitingDocs(action.applicationId).pipe(
      switchMap((applicationStatus: ApplicationStatus) => {
        const state = ctx.getState();
        const updatedApplication = Object.assign({}, state.application, {
          ApplicationStatus: applicationStatus,
        });
        return ctx.dispatch([
          new SetApplication(updatedApplication),
          new FetchNotes(action.applicationId),
        ]);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(PatchApplicationServicingData)
  patchApplicationServicingData(
    ctx: StateContext<MortgageApplicationStateModel>,
    action: PatchApplicationServicingData,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationService
      .patchApplicationServicingData(
        action.applicationId,
        action.servicingType,
        action.effectiveDate,
      )
      .pipe(
        switchMap(
          (applicationServicingData: ApplicationServicingData & { projectedBalance?: number }) => {
            const state = ctx.getState();
            const updatedApplication = Object.assign({}, state.application, {
              ApplicationServicingData: applicationServicingData,
            });
            return ctx.dispatch([
              new SetApplication(updatedApplication),
              new FetchNotes(action.applicationId),
              new SetProjectedBalance(applicationServicingData.projectedBalance ?? null),
            ]);
          },
        ),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }
}
