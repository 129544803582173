import { Applicant, FinancialLiability, FinancialLiabilityRecordType } from '@fundmoreai/models';
import { CreditReportUpdate } from 'src/app/features/shared/credit-report/request-credit-report/credit-report-requests.state';

export class AddFinancialLiability {
  static readonly type = '@financialLiability.AddFinancialLiability';

  constructor(
    public financialLiability: Partial<FinancialLiability>,
    public recordType: string | undefined,
  ) {}
}

export class DeleteFinancialLiability {
  static readonly type = '@financialLiability.DeleteFinancialLiability';

  constructor(public applicationId: string, public financialLiabilityId: string) {}
}

export class PatchLiabilities {
  static readonly type = '@financialLiability.PatchLiabilities';
  constructor(public financialLiabilities: FinancialLiability[]) {}
}

export class SetCreditFinancialLiabilities {
  static readonly type = '@financialLiability.setCreditFinancialLiabilities';

  constructor(public financialLiabilities: FinancialLiability[]) {}
}

export class FetchLiabilityOverrides {
  static readonly type = '@financialLiability.FetchLiabilityOverrides';
}

export class SetFilterIndex {
  static readonly type = '@financialLiability.SetFilterIndex';

  constructor(public filterIndex: number) {}
}

export class UpdateApplicantCreditInfo {
  static readonly type = '@financialLiability.UpdateApplicantCreditInfo';

  constructor(public applicant: Partial<Applicant>, public actionToDispatch?: CreditReportUpdate) {}
}

export class UpdateFinancialLiability {
  static readonly type = '@financialLiability.UpdateFinancialLiability';

  constructor(public applicationId: string, public financialLiability: FinancialLiability) {}
}

export class UpdateFinancialLiabilityRecordType {
  static readonly type = '@financialLiability.UpdateFinancialLiabilityRecordType';

  constructor(
    public financialLiabilityId: string,
    public recordType: FinancialLiabilityRecordType,
  ) {}
}

export class CreateFinancialLiabilityRecordType {
  static readonly type = '@financialLiability.CreateFinancialLiabilityRecordType';

  constructor(
    public applicationId: string,
    public financialLiabilityId: string,
    public financialLiabilityRecordType: string,
  ) {}
}

export class UpdateFinancialLiabilityAssignedApplicants {
  static readonly type = '@financialLiability.UpdateFinancialLiabilityAssignedApplicants';

  constructor(public applicationId: string, public financialLiability: FinancialLiability) {}
}

export class FetchFinancialLiabilities {
  static readonly type = '@financialLiability.FetchFinancialLiabilities';

  constructor(public applicationId: string) {}
}
