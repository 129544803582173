import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import { LawyersService } from './lawyers.service';
import { patch, updateItem } from '@ngxs/store/operators';
import { AgentAction, Lawyer } from '@fundmoreai/models';
import {
  LawyerTableKey,
  LawyerTableKeyRecord,
} from '../features/manager-portal/lawyer-broker-shared/lawyer-broker-details/model';

export class UpdateLawyer {
  static readonly type = '@Lawyers.updateLawyer';
  constructor(public Lawyer: Partial<Lawyer>) {}
}

export class DeleteLawyer {
  static readonly type = '@Lawyers.deleteLawyer';
  constructor(public id: string) {}
}

export class CreateLawyer {
  static readonly type = '@Lawyers.createLawyer';
  constructor(public Lawyer: Lawyer) {}
}

export class FetchLawyers {
  static readonly type = '@Lawyers.fetchLawyers';
}

export class BulkActionLawyer {
  static readonly type = '@Lawyers.BulkActionLawyer';
  constructor(public ids: string[], public action: AgentAction) {}
}

export class DeleteBulkLawyer {
  static readonly type = '@Lawyers.DeleteBulkLawyer';
  constructor(public ids: string[]) {}
}

export const MANAGE_LAWYERS_DEFAULT_DISPLAY_COLUMNS = Object.values(LawyerTableKey).map(
  (value: LawyerTableKey, index) => {
    return {
      field: value,
      name: LawyerTableKeyRecord[value],
      isSelected: index < 5,
      isFrozen: value === LawyerTableKey.VERIFIED,
    };
  },
);

@State<Lawyer[]>({
  name: 'Lawyers',
  defaults: [],
})
@Injectable()
export class LawyersState {
  constructor(private lawyersService: LawyersService) {}

  @Selector()
  static lawyers(state: Lawyer[]) {
    return state ?? [];
  }

  @Selector()
  static verifiedLawyers(state: Lawyer[]) {
    return state?.filter((lawyer) => lawyer.verified);
  }

  static getById(id: string) {
    return createSelector([LawyersState.lawyers], (lawyers: Lawyer[]) => {
      return lawyers.find((x) => x.id === id);
    });
  }

  @Action(UpdateLawyer)
  updateLawyer(ctx: StateContext<Lawyer[]>, action: UpdateLawyer) {
    const id = action.Lawyer.id;
    if (!id) {
      return;
    }
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.lawyersService.patchLawyer(id, { ...action.Lawyer, id: undefined }).pipe(
      tap(() => {
        const update = updateItem<Lawyer>((lawyer) => lawyer?.id === id, patch(action.Lawyer));
        ctx.setState(update);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(DeleteLawyer)
  deleteLawyer(ctx: StateContext<Lawyer[]>, action: DeleteLawyer) {
    const state = ctx.getState();
    const prevId = action.id;
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.lawyersService.deleteLawyer(prevId).pipe(
      tap(() => ctx.setState([...state.filter((f) => f.id !== prevId)])),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreateLawyer)
  createLawyer(ctx: StateContext<Lawyer[]>, action: CreateLawyer) {
    const state = ctx.getState();
    const prevId = action.Lawyer.id;
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.lawyersService.addLawyer({ ...action.Lawyer, id: undefined }).pipe(
      tap((lawyer) => {
        return ctx.setState([...state.filter((f) => f.id !== prevId), lawyer]);
      }),
      catchError((e) => {
        ctx.setState([...state.filter((f) => f.id !== prevId)]);
        throw e;
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(FetchLawyers)
  getLawyers(ctx: StateContext<Lawyer[]>) {
    ctx.setState([]);

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.lawyersService.getLawyers().pipe(
      tap((lawyers) => {
        ctx.setState(
          lawyers.sort((a, b) => {
            const lawyerOne = a.province ?? '';
            const lawyerTwo = b.province ?? '';
            const provinceCompare = lawyerTwo.localeCompare(lawyerOne);
            if (provinceCompare === 0) {
              const nameOne = a.firstName + a.lastName ?? '';
              const nameTwo = b.firstName + b.lastName ?? '';
              return nameTwo.localeCompare(nameOne);
            }

            return provinceCompare;
          }),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(BulkActionLawyer)
  bulkActionBrokers(ctx: StateContext<Lawyer[]>, action: BulkActionLawyer) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.lawyersService.bulkActionLawyer(action.ids, action.action).pipe(
      switchMap(() => {
        return ctx.dispatch(new FetchLawyers());
      }),
    );
  }
  @Action(DeleteBulkLawyer)
  deleteBulkBrokers(ctx: StateContext<Lawyer[]>, action: DeleteBulkLawyer) {
    return this.lawyersService.deleteBulkLawyer(action.ids).pipe(
      tap(() => {
        let lawyers = ctx.getState().map((lawyer) => ({
          ...lawyer,
        }));

        for (const lawyer of lawyers) {
          if (action.ids.includes(lawyer.id)) {
            lawyers = lawyers.filter((b) => b.id !== lawyer.id);
          }
        }
        ctx.setState(lawyers);
      }),
    );
  }
}
