/* eslint-disable max-len */
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, throwError } from 'rxjs';
import { map, retry, switchMap } from 'rxjs/operators';
import { PdfOptions } from 'src/app/shared';
import {
  ApplicationDocumentActionHistory,
  BucketConfig,
  DocumentDraft,
  DocumentActions,
} from 'src/app/shared/model';
import { environment } from 'src/environments/environment';
import { ExpandedDocumentModel } from '../generate-documents/state/generate-documents-packages-v2.state';
import { DocumentContext } from '../shared/documents';

export interface OutDocumentResponse {
  id: string;
  applicationId: string;
  applicationDocumentId: string;
  type: string;
  name: string;
  contentType: string;
  createdAt: string;
}

export interface OutDocument {
  id: string;
  applicationId: string;
  type: string;
  name: string;
  applicationDocumentId: string;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private http: HttpClient, private httpBackend: HttpBackend) {}

  getReviewLink({
    applicationId,
    collectorId,
    ezidoxApplicationId,
    documentId,
  }: {
    applicationId: string;
    collectorId: string;
    ezidoxApplicationId: string;
    documentId: string;
  }): Observable<string> {
    return this.http.get<string>(
      `${environment.api_url}/ezidox/document/${applicationId}/link?collectorId=${collectorId}&ezidoxApplicationId=${ezidoxApplicationId}&documentId=${documentId}`,
    );
  }

  /**
   * Upload application document to ezidox as attachment trough S3 - AI upload
   * @param html html content compiled
   * @param options pdf options
   * @param applicationId
   * @param code Enum key value
   */
  uploadApplicationAttachmentDocument(
    html: string,
    options: PdfOptions,
    applicationId: string,
    code: string,
    displayName: string,
  ) {
    const content: OobObjectUpload = {
      html: html,
      options: options,
    };

    return this.oobToS3(content).pipe(
      switchMap((oob) =>
        this.http.post<void>(
          `${environment.api_url}/applications/${applicationId}/attachmentDocument`,
          {
            code,
            displayName,
            contentUploadKey: oob.key,
          },
        ),
      ),
    );
  }

  /**
   * Upload application document content to S3 and create outdocument
   * @param html html content compiled
   * @param options pdf options
   * @param applicationId
   * @param code type key value
   * @param documentName document name value
   */
  uploadApplicationOutDocument(
    html: string,
    options: PdfOptions,
    applicationId: string,
    code: string,
    documentName: string,
  ) {
    const content: OobObjectUpload = {
      html: html,
      options: options,
    };
    return this.oobToS3(content).pipe(
      switchMap((oob) =>
        this.http.post<OutDocumentResponse>(
          `${environment.api_url}/outdocuments?applicationId=${applicationId}`,
          {
            type: code,
            name: documentName,
            contentUploadKey: oob.key,
          },
        ),
      ),
    );
  }

  generatePdf(html: string, options: PdfOptions, filename: string): Observable<Blob> {
    const content: OobObjectUpload = {
      html: html,
      options: options,
    };
    const httpClientWithoutInterceptors = new HttpClient(this.httpBackend);

    return this.oobToS3(content).pipe(
      switchMap((oob) => {
        const url = `${environment.api_url}/pdf/download?contentUploadKey=${encodeURIComponent(
          oob.key,
        )}&filename=${encodeURIComponent(filename)}`;
        return this.http.get<{ url: string }>(url);
      }),
      switchMap((r) =>
        httpClientWithoutInterceptors.get<Blob>(r.url, {
          responseType: 'blob' as 'json',
        }),
      ),
    );
  }

  /**
   * Get application documents
   * @param {string} applicationId - The application id.
   * @param {string} documentType - The document type.
   * @return {Observable<DocumentDraft[]>} A list of documents.
   * @example getApplicationDocumentList('4b8941ed-4b4b-4df0-8178-d9433e28bb54', 'commitmentTerms', 'Calvert Commitment Terms');
   */
  public getApplicationDocumentList(
    applicationId: string,
    documentType: string,
  ): Observable<DocumentDraft[]> {
    const url = `${environment.api_url}/applicationDocument/${applicationId}/list?documentType=${documentType}`;
    return this.http
      .get<DocumentDraft[]>(url)
      .pipe(
        map((keys) =>
          (keys ?? []).sort(
            (a, b) => new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime(),
          ),
        ),
      );
  }

  /**
   * Get commitment terms
   * @param {string} applicationId - The application id.
   * @return {Observable<DocumentDraft[]>} A list of documents.
   * @example getApplicationDocumentList('4b8941ed-4b4b-4df0-8178-d9433e28bb54', 'commitmentTerms', 'Calvert Commitment Terms');
   */
  public getCommitmentTerms(applicationId: string): Observable<DocumentDraft[]> {
    const url = `${environment.fundmore_api_url}/draftDocumentFile/${applicationId}/commitmentTerms`;
    return this.http
      .get<DocumentDraft[]>(url)
      .pipe(
        map((keys) =>
          (keys ?? []).sort(
            (a, b) => new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime(),
          ),
        ),
      );
  }

  public getApplicationDocumentContent(documentId: string): Observable<DocumentDraftUpload> {
    // keeping this for backwards compatibility or if we need to get the content of a document
    // const url = `${environment.api_url}/applicationDocument/${documentId}/content`;
    // return this.http.get<DocumentDraftUpload>(url);
    return this.getApplicationDocumentContentUrl(documentId);
  }

  private getApplicationDocumentContentUrl(documentId: string): Observable<DocumentDraftUpload> {
    const url = `${environment.api_url}/applicationDocument/${documentId}/content/url`;
    const httpClientWithoutInterceptors = new HttpClient(this.httpBackend);
    return this.http
      .get<{ url: string }>(url)
      .pipe(switchMap((a) => httpClientWithoutInterceptors.get<DocumentDraftUpload>(a.url)));
  }

  async saveApplicationDocumentDraftFromManagedTemplate(
    applicationId: string,
    item: ExpandedDocumentModel,
    context: DocumentContext,
    name: string,
    documentType: string,
    documentName: string,
  ) {
    const { tinymceHtmlContent: html } = item;

    if (!context) {
      return throwError(() => new Error(`Missing draft context!`));
    }
    if (!html) {
      return throwError(
        () => new Error(`Could not save ${item.document.name}. Missing document content!`),
      );
    }
    const lastSaved = new Date().toISOString();
    const contentType = 'application/json';
    const s3Content: DocumentDraftUpload = {
      html,
      type: documentType,
      name,
      context,
      lastSaved,
    };

    const dbContent = {
      type: documentType,
      name,
      context,
      lastSaved,
      contentType,
      bucketConfig: BucketConfig.DRAFTS,
    };
    const url = `${environment.fundmore_api_url}/applicationDocument/${applicationId}/url`;
    let headers = new HttpHeaders({
      // decode manually in the backend
      'Content-Type': 'application/octet-stream',
      // 'Content-Type': 'application/json',
      // 'Content-Encoding': 'gzip',
      // 'x-compression': 'true',
    });
    let bodyData: unknown = dbContent;
    try {
      bodyData = await compressData(dbContent);
    } catch (error) {
      // default to the initial json data when compression fails
      console.warn('Failed to compress data', error);
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    }
    let s3BodyEncoded: unknown = null;
    try {
      s3BodyEncoded = await compressData(s3Content);
    } catch (error) {
      // default to the initial json data when compression fails
      console.warn('Failed to compress s3Content', error);
    }

    return this.http.post<OobUpload>(url, bodyData, { headers }).pipe(
      switchMap((oob) =>
        s3BodyEncoded
          ? this.uploadToS3Encoded(oob.url, contentType, s3BodyEncoded).pipe(
              retry(1),
              map(() => oob),
            )
          : this.uploadToS3(oob.url, contentType, s3Content).pipe(
              retry(1),
              map(() => oob),
            ),
      ),
      switchMap((oob) =>
        this.updateDocumentStatusToUploadCompleted(oob.applicationDocumentId).pipe(map(() => oob)),
      ),
      switchMap((oob) =>
        this.createDraftDocumentFile(oob.applicationDocumentId, item.document.id).pipe(
          map(() => oob),
        ),
      ),
      switchMap((oob) =>
        this.logApplicationDocumentAction(oob.applicationDocumentId, {
          action: DocumentActions.SAVE,
          applicationDocumentId: oob.applicationDocumentId,
          applicationId: applicationId,
          originalDocumentType: documentType,
          originalDocumentName: documentName,
          actionData: {
            saveAsName: name,
            options: item.document.options,
          },
        }).pipe(map(() => oob)),
      ),
    );
  }

  /**
   * Generate a presigned key and upload to S3
   * @param oobObject OutOfBand Object
   * @returns outofband key
   */
  private oobToS3(oobObject: OobObjectUpload): Observable<OobUpload> {
    const contentType = 'application/json';
    return this.http
      .get<OobUpload>(`${environment.api_url}/oobuploads/url?contentType=${contentType}`)
      .pipe(
        switchMap((oob) => this.uploadToS3(oob.url, contentType, oobObject).pipe(map(() => oob))),
      );
  }

  private uploadToS3(url: string, contentType: string, content: OobObjectUpload) {
    const httpClientWithoutInterceptors = new HttpClient(this.httpBackend);
    return httpClientWithoutInterceptors.put(url, content, {
      headers: new HttpHeaders({ 'Content-Type': contentType }),
    });
  }

  private uploadToS3Encoded(url: string, contentType: string, content: unknown) {
    const httpClientWithoutInterceptors = new HttpClient(this.httpBackend);
    return httpClientWithoutInterceptors.put(url, content, {
      headers: new HttpHeaders({ 'Content-Type': contentType, 'Content-Encoding': 'gzip' }),
    });
  }

  sendApplicationOutDocument(
    applicantId: string,
    applicantName: string,
    outDocuments: OutDocument[],
    optionalMessage: string,
  ) {
    return this.http
      .post<void>(`${environment.api_url}/outdocuments/send`, {
        applicantId,
        outDocumentIds: outDocuments.map((a) => a.id),
        optionalMessage,
      })
      .pipe(
        switchMap(() =>
          combineLatest(
            outDocuments.map((outDoc) =>
              this.logApplicationDocumentAction(outDoc.applicationDocumentId, {
                action: DocumentActions.SEND,
                applicationDocumentId: outDoc.applicationDocumentId,
                applicationId: outDoc.applicationId,
                originalDocumentType: outDoc.type,
                originalDocumentName: outDoc.name,
                actionData: {
                  message: optionalMessage,
                  applicantId,
                  applicantName,
                },
              }),
            ),
          ),
        ),
      );
  }

  updateDocumentStatusToUploadCompleted(applicationDocumentId: string) {
    return this.http.put<void>(
      `${environment.api_url}/applicationDocument/${applicationDocumentId}/updateDocumentStatusToUploadCompleted`,
      null,
    );
  }

  createDraftDocumentFile(applicationDocumentId: string, documentTemplateId: string) {
    return this.http.put<void>(
      `${environment.fundmore_api_url}/draftDocumentFile/createDraftDocumentFile`,
      { applicationDocumentId, documentTemplateId },
    );
  }

  logApplicationDocumentAction(
    applicationDocumentId: string,
    data: Partial<ApplicationDocumentActionHistory>,
  ) {
    return this.http.post<ApplicationDocumentActionHistory>(
      `${environment.fundmore_api_url}/applicationDocument/${applicationDocumentId}/logAction`,
      data,
    );
  }
}

export interface OobUpload {
  url: string;
  key: string;
  applicationDocumentId: string;
}

export interface OobObjectUpload {
  html: string;
  options?: PdfOptions;
}

export interface DocumentDraftUpload {
  html: string;
  type: string;
  name: string;
  context?: DocumentContext;
  lastSaved?: string;
}

async function compressData(data: unknown) {
  const jsonData = JSON.stringify(data);
  const textEncoder = new TextEncoder();
  const encodedData = textEncoder.encode(jsonData);

  // Create a GZIP Compression Stream
  const gzipStream = new CompressionStream('gzip');

  // Convert the Uint8Array (encoded data) to a ReadableStream
  const readableStream = new ReadableStream({
    start(controller) {
      controller.enqueue(encodedData);
      controller.close();
    },
  });

  // Pipe the readable stream into the compression stream
  const compressedStream = readableStream.pipeThrough(gzipStream);

  // Read the compressed stream as a Blob
  const compressedData = await new Response(compressedStream).arrayBuffer();

  return compressedData;
}
