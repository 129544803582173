import { ApplicationPurposeType } from './application';

export interface Ava {
  id: string;
  name: string;
  description: string;
  createdAt: Date;
}

export interface AvaChat {
  id: string;
  applicationId: string;
  userId: string;
  vertexContextId: string;
  isContextReady: boolean;
  isThinking: boolean;
  isShared: boolean;
  messages: AvaChatMessage[];
  createdAt: Date;
}

export interface AvaSuggestion {
  id: string;
  content: string;
  metadata?: AvaSuggestionMetadata;
  createdAt: Date;
}

// TODO: example metadata filter, only show this type of suggestion for a specific purpose
export interface AvaSuggestionMetadata {
  applicablePurpose?: ApplicationPurposeType;
}

export interface AvaChatMessage {
  id: string;
  applicationId: string;
  userId: string;
  avaChatId: string;
  content: string;
  source: AvaChatMessageSource;
  metadata?: AvaChatMessageMetadata;
  createdAt: Date;
}

export interface AvaChatMessageMetadata {
  forcedFunctionCalls?: string[];
  additionalAction?: AvaMessageAction;
  actionLabeling?: AvaMessageActionLabeling;
  actionType?: AvaActionType;
  result?: 'confirm' | 'deny';
}

export interface AvaMessageActionLabeling {
  confirmButton?: string;
  cancelButton?: string;
  confirmMessage?: string;
  denyMessage?: string;
}

export interface GoogleCloudConfig {
  projectId: string;
  location: string;
  clientEmail: string;
  privateKey: string;
  dataStoreId: string;
}

export interface AvaConfig {
  googleCloud: GoogleCloudConfig;
  user: AvaUserConfig;
  tenant: AvaTenantConfig;
}

export interface AvaUserConfig {
  prompt?: string;
}

export interface AvaTenantConfig {
  type?: LLMModelType;
  model?: string;
  prompt?: string;
}

export enum AvaActionType {
  CONFIRMATION_REQUEST = 'CONFIRMATION_REQUEST',
}

export enum LLMModelType {
  GEMINI = 'GEMINI',
}

export enum GeminiModel {
  GEMINI_1_5_FLASH_001 = 'gemini-1.5-flash-001',
  GEMINI_1_5_PRO_001 = 'gemini-1.5-pro-001',
  GEMINI_1_0_PRO_001 = 'gemini-1.0-pro-001',
}

export enum AvaEvents {
  PROCESS_MESSAGE = 'PROCESS_MESSAGE',
}

export interface AvaMessageAction {
  name?: string;
  args?: any;
}

export enum AvaChatMessageSource {
  USER = 'USER',
  AVA = 'AVA',
}

export enum AvaFunctionNames {
  GET_APPLICATION_CONTEXT = 'getApplicationContext',
  GET_TASKS_CONTEXT = 'getTasksContext',
  GET_CONDITIONS_CONTEXT = 'getConditionsContext',
  GET_DOCUMENTS_CONTEXT = 'getDocumentsContext',
  MARK_TASKS_AS_COMPLETED = 'markTasksAsCompleted',
}

export const DefaultAVAConfig: AvaConfig = Object.freeze({
  googleCloud: {
    projectId: 'INVALID_DEFAULT',
    location: 'INVALID_DEFAULT',
    clientEmail: 'INVALID_DEFAULT',
    privateKey: 'INVALID_DEFAULT',
    dataStoreId: 'INVALID_DEFAULT',
  },
  user: {
    prompt:
      'The user is an underwriter for the mortgage application. Answers should be relevant to underwriters only.',
  },
  tenant: {
    type: LLMModelType.GEMINI,
    model: 'gemini-1.5-pro-002',
    prompt:
      // eslint-disable-next-line max-len
      `You are a chat bot created by Fundmore. You are here to help users with their queries about the mortgage application they are currently viewing. If you need to format your response, use HTML tags. Don't use any markdown, only HTML. When answering questions the primary source of truth should be any functions that have been called. Application data can change between messages, so don't reference your previous messages for factual information.`,
  },
});
