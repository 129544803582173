import {
  ClosingInstruction,
  MMSCreateRequest,
  MMSError,
  MMSFieldMetadata,
  MMSLenderProduct,
  MMSMessageCounters,
  MMSNote,
  MMSResponse,
  MMSUpdateRequest,
  MMSUpdateResponse,
  isMMSSuccessResponse,
  isMMSUpdateRequest,
} from '@fundmoreai/models';
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { FctMMSService } from './fct-mms.service';
import { Injectable } from '@angular/core';
import { ApplicationResetState } from '../../../shared/state.model';
import { LoadingEnd, LoadingStart } from '../../../core/loading.state';
import { EMPTY, catchError, finalize, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AddClosingInstruction,
  AddMMSClosingInstructionNote,
  UpdateClosingInstructionResponseMetadata,
} from '../closing-instruction.state';
import { format } from 'date-fns';
import { AuthState } from 'src/app/auth/auth.state';
import { patch } from '@ngxs/store/operators';
export class CreateFctMMSRequest {
  static readonly type = '@fct.createFctMMSRequest';
  constructor() {}
}

export class FetchFctMMSRequestMetadata {
  static readonly type = '@fct.fetchFctMMSRequestMetadata';
  constructor() {}
}

export class ResetFctMMSRequestState {
  static readonly type = '@fct.resetFctMMSRequestState';
  constructor() {}
}

export class UpdateMMSRequest {
  static readonly type = '@fctMMS.updateMMSRequest';
  constructor(public request: Partial<MMSCreateRequest | MMSUpdateRequest>) {}
}

export class FetchMMSLenderProducts {
  static readonly type = '@fctMMS.fetchMMSLenderProducts';
  constructor() {}
}

export class CreateMMSNote {
  static readonly type = '@fctMMS.createMMSNote';
  constructor(public fctURN: string, public noteMessage: string) {}
}

export class UpdateInstructionMMSRequest {
  static readonly type = '@fct.updateInstructionMMSRequest';
  constructor() {}
}

export class ToggleMMSMetadataChecked {
  static readonly type = '@fctMMS.toggleMMSMetadataChecked';
  constructor(public fctURN: string, public metadata: MMSFieldMetadata) {}
}

export class SetMMSNotificationCounters {
  static readonly type = '@fctMMS.setMMSCounters';
  constructor(public counters: MMSMessageCounters) {}
}

export class ClearMMSNewDocumentsCount {
  static readonly type = '@fctMMS.clearMMSNewDocumentsCount';
  constructor(public fctURN: string) {}
}

export class ClearMMSNewUpdatesCount {
  static readonly type = '@fctMMS.clearMMSNewUpdatesCount';
  constructor(public fctURN: string) {}
}

export class ClearMMSNewNotesCount {
  static readonly type = '@fctMMS.clearMMSNewNotesCount';
  constructor(public fctURN: string) {}
}

export class ClearMMSNewDisbursementsCount {
  static readonly type = '@fctMMS.clearMMSNewDisbursementsCount';
  constructor(public fctURN: string) {}
}

interface FctMMSStateModel {
  request?: MMSCreateRequest | MMSUpdateRequest;
  metadata?: MMSFieldMetadata[];
  response?: FctMMSRequestResponse;
  lenderProducts?: MMSLenderProduct[];
  counters: MMSMessageCounters[];
}

export interface FctMMSRequestResponse {
  status: FctMMSRequestResponseStatus;
  data?: ClosingInstruction | MMSResponse | MMSUpdateResponse | MMSError[] | string[];
}

export enum FctMMSRequestResponseStatus {
  SUCCESS = 'SUCCESS',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  ERROR = 'ERROR',
}

@State<FctMMSStateModel>({
  name: 'mms',
  defaults: {
    request: undefined,
    metadata: [],
    response: undefined,
    lenderProducts: [],
    counters: [],
  },
})
@Injectable()
export class FctMMSState {
  constructor(private fctMMSService: FctMMSService, private store: Store) {}

  @Selector()
  static metadata(state: FctMMSStateModel) {
    return state.metadata;
  }

  @Selector()
  static response(state: FctMMSStateModel) {
    return state.response;
  }

  @Selector()
  static lenderProducts(state: FctMMSStateModel) {
    return state.lenderProducts;
  }

  @Selector()
  static counters(state: FctMMSStateModel) {
    return state.counters;
  }

  static counter(fctURN?: string) {
    return createSelector([FctMMSState.counters], (counters: MMSMessageCounters[]) => {
      return counters.find((x) => x.fctURN === fctURN);
    });
  }

  @Action(ResetFctMMSRequestState)
  @Action(ApplicationResetState)
  reset(ctx: StateContext<FctMMSStateModel>) {
    ctx.setState({
      request: undefined,
      metadata: [],
      response: undefined,
      lenderProducts: [],
      counters: [],
    });
  }

  @Action(CreateFctMMSRequest)
  createFctMMSRequest(ctx: StateContext<FctMMSStateModel>) {
    const state = ctx.getState();
    if (state.request) {
      ctx.dispatch(new LoadingStart(this.constructor.name));
      return this.fctMMSService
        .createMMSRequest({
          ...state.request,
        })
        .pipe(
          tap((response) => {
            ctx.patchState({
              response: {
                status: FctMMSRequestResponseStatus.SUCCESS,
                data: response,
              },
            });
            ctx.dispatch(new AddClosingInstruction(response as ClosingInstruction));
          }),
          catchError((err: HttpErrorResponse) => {
            if (err.status === 400) {
              ctx.patchState({
                response: {
                  status: FctMMSRequestResponseStatus.VALIDATION_ERROR,
                  data: err.error.message ?? err.error?.error,
                },
              });
            } else {
              ctx.patchState({
                response: {
                  status: FctMMSRequestResponseStatus.ERROR,
                  data: err.error,
                },
              });
            }
            return EMPTY;
          }),
          finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
        );
    }

    return null;
  }

  @Action(UpdateInstructionMMSRequest)
  updateInstructionMMSRequest(ctx: StateContext<FctMMSStateModel>) {
    const state = ctx.getState();

    if (state.request && isMMSUpdateRequest(state.request)) {
      ctx.dispatch(new LoadingStart(this.constructor.name));
      return this.fctMMSService.updateInstructionMMSRequest(state.request).pipe(
        tap((response) => {
          ctx.patchState({
            response: {
              status: FctMMSRequestResponseStatus.SUCCESS,
              data: response,
            },
          });

          ctx.dispatch(new AddClosingInstruction(response as ClosingInstruction));
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 400) {
            ctx.patchState({
              response: {
                status: FctMMSRequestResponseStatus.VALIDATION_ERROR,
                data: err.error.message,
              },
            });
          } else {
            ctx.patchState({
              response: {
                status: FctMMSRequestResponseStatus.ERROR,
                data: err.error,
              },
            });
          }
          return EMPTY;
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
    }

    return null;
  }

  @Action(FetchFctMMSRequestMetadata)
  fetchFctMMSRequestMetadata(ctx: StateContext<FctMMSStateModel>) {
    const state = ctx.getState();
    ctx.patchState({
      metadata: [],
    });

    if (state.request) {
      ctx.dispatch(new LoadingStart(this.constructor.name));
      return this.fctMMSService.fetchMMSMetadata(state.request).pipe(
        tap((metadata) => {
          ctx.patchState({
            metadata,
          });
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
    }

    return null;
  }

  @Action(UpdateMMSRequest)
  updateMMSRequest(ctx: StateContext<FctMMSStateModel>, action: UpdateMMSRequest) {
    const state = ctx.getState();

    if (
      action.request &&
      action.request.mortgageId &&
      action.request.lawyerId &&
      action.request.propertyId &&
      action.request.applicationId
    ) {
      if (isMMSUpdateRequest(action.request)) {
        ctx.patchState({
          request: {
            ...state.request,
            applicationId: action.request.applicationId,
            mortgageId: action.request.mortgageId,
            lawyerId: action.request.lawyerId,
            productId: action.request.productId,
            lenderRemarks: action.request.lenderRemarks,
            propertyId: action.request.propertyId,
            registeredAmount: action.request.registeredAmount,
            fctURN: action.request.fctURN,
          },
        });
      } else {
        ctx.patchState({
          request: {
            ...state.request,
            applicationId: action.request.applicationId,
            mortgageId: action.request.mortgageId,
            lawyerId: action.request.lawyerId,
            productId: action.request.productId,
            lenderRemarks: action.request.lenderRemarks,
            propertyId: action.request.propertyId,
            registeredAmount: action.request.registeredAmount,
          },
        });
      }
    }
  }

  @Action(FetchMMSLenderProducts)
  fetchMMSLenderProducts(ctx: StateContext<FctMMSStateModel>) {
    ctx.patchState({
      lenderProducts: [],
    });

    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.fctMMSService.fetchMMSLenderProducts().pipe(
      tap((lenderProducts) => {
        ctx.patchState({
          lenderProducts,
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(SetMMSNotificationCounters)
  setMMSNotificationCounters(
    ctx: StateContext<FctMMSStateModel>,
    action: SetMMSNotificationCounters,
  ) {
    const state = ctx.getState();
    const otherCounters = state.counters.filter((x) => x.fctURN !== action.counters.fctURN);

    if (otherCounters) {
      ctx.setState(
        patch({
          counters: [...otherCounters, action.counters],
        }),
      );
    }
  }

  @Action(ClearMMSNewDisbursementsCount)
  clearMMSNewDisbursementsCount(
    ctx: StateContext<FctMMSStateModel>,
    action: ClearMMSNewDisbursementsCount,
  ) {
    const state = ctx.getState();
    const currentCounter = state.counters.find((x) => x.fctURN === action.fctURN);
    const otherCounters = state.counters.filter((x) => x.fctURN !== action.fctURN);

    if (currentCounter && otherCounters) {
      const newCounter = MMSMessageCounters.from(currentCounter);
      newCounter.clearDisbursements();
      ctx.setState(
        patch({
          counters: [...otherCounters, newCounter],
        }),
      );
    }
  }

  @Action(ClearMMSNewNotesCount)
  clearMMSNewNotesCount(ctx: StateContext<FctMMSStateModel>, action: ClearMMSNewNotesCount) {
    const state = ctx.getState();
    const currentCounter = state.counters.find((x) => x.fctURN === action.fctURN);
    const otherCounters = state.counters.filter((x) => x.fctURN !== action.fctURN);

    if (currentCounter && otherCounters) {
      const newCounter = MMSMessageCounters.from(currentCounter);
      newCounter.clearNotes();
      ctx.setState(
        patch({
          counters: [...otherCounters, newCounter],
        }),
      );
    }
  }

  @Action(ClearMMSNewDocumentsCount)
  clearMMSNewDocumentsCount(
    ctx: StateContext<FctMMSStateModel>,
    action: ClearMMSNewDocumentsCount,
  ) {
    const state = ctx.getState();
    const currentCounter = state.counters.find((x) => x.fctURN === action.fctURN);
    const otherCounters = state.counters.filter((x) => x.fctURN !== action.fctURN);

    if (currentCounter && otherCounters) {
      const newCounter = MMSMessageCounters.from(currentCounter);
      newCounter.clearDocuments();
      ctx.setState(
        patch({
          counters: [...otherCounters, newCounter],
        }),
      );
    }
  }

  @Action(ClearMMSNewUpdatesCount)
  clearMMSNewUpdatesCount(ctx: StateContext<FctMMSStateModel>, action: ClearMMSNewUpdatesCount) {
    const state = ctx.getState();
    const currentCounter = state.counters.find((x) => x.fctURN === action.fctURN);
    const otherCounters = state.counters.filter((x) => x.fctURN !== action.fctURN);

    if (currentCounter && otherCounters) {
      const newCounter = MMSMessageCounters.from(currentCounter);
      newCounter.clearUpdates();
      ctx.setState(
        patch({
          counters: [...otherCounters, newCounter],
        }),
      );
    }
  }

  @Action(CreateMMSNote)
  createMMSNote(ctx: StateContext<FctMMSStateModel>, action: CreateMMSNote) {
    if (!action.fctURN) {
      throw new Error('FCT URN is missing');
    }
    if (!action.noteMessage) {
      throw new Error('Note message is missing');
    }
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const currentUser = this.store.selectSnapshot(AuthState.currentUser);
    const note = {
      note: action.noteMessage,
      userName:
        currentUser?.userData?.firstName && currentUser?.userData?.firstName
          ? currentUser?.userData?.firstName + ' ' + currentUser?.userData?.lastName
          : 'You',
      datePublished: new Date().toISOString(),
    };
    return this.fctMMSService.createMMSNote(action.fctURN, note).pipe(
      tap(({ response, note: newNote }) => {
        if (isMMSSuccessResponse(response)) {
          ctx.dispatch(new AddMMSClosingInstructionNote(action.fctURN, newNote));
        }
      }),
      catchError((err: HttpErrorResponse) => {
        throw err;
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(ToggleMMSMetadataChecked)
  toggleMMSMetadataChecked(ctx: StateContext<FctMMSStateModel>, action: ToggleMMSMetadataChecked) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.fctMMSService.updateMetadata(action.fctURN, action.metadata).pipe(
      tap(() => {
        this.store.dispatch(
          new UpdateClosingInstructionResponseMetadata(action.fctURN, action.metadata),
        );
      }),

      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }
}
