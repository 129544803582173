import { injectable } from 'tsyringe';
import { LiabilityType, SecurityType } from '@fundmoreai/models';

interface Liability {
  liability?: string;
  securityType?: SecurityType | undefined | null;
}

export interface ILiabilityIsLoanCalculator {
  isLiabilityLoan(liability: Liability): boolean;
}

@injectable()
export class LiabilityIsLoanCalculator implements ILiabilityIsLoanCalculator {
  isLiabilityLoan(liability: Liability): boolean {
    if (
      liability.liability === LiabilityType.AUTO_LOAN ||
      liability.liability === LiabilityType.PERSONAL_LOAN ||
      liability.liability === LiabilityType.STUDENT_LOAN ||
      liability.liability === LiabilityType.MORTGAGE ||
      (liability.securityType === SecurityType.MORTGAGE_SECURED &&
        liability.liability !== LiabilityType.SECURED_LINE_OF_CREDIT &&
        liability.liability !== LiabilityType.UNSECURED_LINE_OF_CREDIT)
    ) {
      return true;
    }
    return false;
  }
}
