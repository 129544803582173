import { ServicingType } from '@fundmoreai/models';

type ServicingPaymentChangeType =
  | ServicingType.INCREASE_PAYMENT
  | ServicingType.DECREASE_PAYMENT
  | ServicingType.CHANGE_PAYMENT_FREQUENCY
  | ServicingType.CHANGE_PAYMENT_DATE;

type ServicingRenewalType = ServicingType.RENEWAL_ON_MATURITY | ServicingType.RENEWAL_EARLY;

export const ServicingPaymentChangeTypeRecord: Record<ServicingPaymentChangeType, string> = {
  [ServicingType.INCREASE_PAYMENT]: $localize`Increase Payment`,
  [ServicingType.DECREASE_PAYMENT]: $localize`Decrease Payment`,
  [ServicingType.CHANGE_PAYMENT_FREQUENCY]: $localize`Change Frequency`,
  [ServicingType.CHANGE_PAYMENT_DATE]: $localize`Change Payment Date`,
};

export const ServicingRenewalTypeRecord: Record<ServicingRenewalType, string> = {
  [ServicingType.RENEWAL_ON_MATURITY]: $localize`On Maturity`,
  [ServicingType.RENEWAL_EARLY]: $localize`Early`,
};
