import 'reflect-metadata';
import { inject, injectable } from 'tsyringe';
import { FeeCalculation } from '@fundmoreai/models';
import { ApplicationCalculationModel } from '../models/application-calculation.model';

export interface IFeeCalculator {
  getTotalFees(): number;
  getCappedFees(): number;
}

@injectable()
export class FeeCalculator implements IFeeCalculator {
  private readonly capFeesMaxPercentage = 100;
  private fees: FeeCalculation[];
  private calculatedTotalFees?: number;
  private calculatedCappedFees?: number;

  constructor(
    @inject(ApplicationCalculationModel)
    applicationCalculationModel: Pick<ApplicationCalculationModel, 'fees'>,
  ) {
    this.fees = applicationCalculationModel.fees || [];
  }

  private validFee(fee: FeeCalculation): boolean {
    return fee.amount !== undefined && fee.amount !== null && fee.amount >= 0;
  }

  private computeTotalFees(): number {
    return this.fees.filter((fee) => this.validFee(fee)).reduce((acc, fee) => acc + fee.amount, 0);
  }

  private computeCappedFees(): number {
    return this.fees
      .filter((fee) => this.validFee(fee) && fee.subtractFromPrincipal === false)
      .reduce((acc, fee) => {
        const capFeesPercentage = fee.capFeesPercentage || this.capFeesMaxPercentage;
        return acc + (fee.amount * capFeesPercentage) / 100;
      }, 0);
  }

  public getTotalFees(): number {
    if (this.calculatedTotalFees === undefined) {
      this.calculatedTotalFees = this.computeTotalFees();
    }
    return this.calculatedTotalFees;
  }

  public getCappedFees(): number {
    if (this.calculatedCappedFees === undefined) {
      this.calculatedCappedFees = this.computeCappedFees();
    }
    return this.calculatedCappedFees;
  }
}
