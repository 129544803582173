import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { UserAccountsService } from 'src/app/portal/user-accounts.service';
import { User, UserAccount } from 'src/app/shared';
import { RoleService } from './role.service';
import { Role, RolePermission } from './role.model';
import { FetchGeneralPermissions } from 'src/app/auth/permissions.state';
import { LoadingEnd, LoadingStart } from '../../../core/loading.state';
import { ExternalAuthSystemType } from '@fundmoreai/models';
import { RouterSelectors } from 'src/app/router-state/router.selectors';

export interface RoleStateModel {
  permissions: RolePermission[];
  roles: {
    [key: string]: Role;
  };
  rolePermissions: {
    [key: string]: RolePermission[];
  };
  users: {
    [key: string]: User;
  };
}

export class AddRole {
  static readonly type = '@role.AddRole';

  constructor(
    public role: {
      description: string;
      name: string;
      assignable: boolean;
      notifyOnAssignment: boolean;
      isServicing: boolean;
    },
  ) {}
}

export class AddRolePermission {
  static readonly type = '@role.AddRolePermission';

  constructor(public roleId: string, public permissionId: string) {}
}

export class AssignUsersToRole {
  static readonly type = '@role.AssignUsersToRole';

  constructor(public role: Role, public users: User[]) {}
}

export class CloneRole {
  static readonly type = '@roleManagement.CloneRole';

  constructor(public role: Role) {}
}

export class DeleteRole {
  static readonly type = '@roleManagement.DeleteRole';

  constructor(public roleId: string) {}
}

export class FetchPermissions {
  static readonly type = '@role.FetchPermissions';
}

export class FetchRoles {
  static readonly type = '@role.FetchRoles';
}

export class FetchRolePermissions {
  static readonly type = '@role.FetchRolePermissions';

  constructor(public roleId: string) {}
}

export class FetchUsers {
  static readonly type = '@role.FetchUsers';
}

export class RemoveRolePermission {
  static readonly type = '@role.RemoveRolePermission';

  constructor(public roleId: string, public permissionId: string) {}
}

export class RemoveRoleFromUser {
  static readonly type = '@role.RemoveRoleFromUser';

  constructor(public role: Role, public user: User) {}
}

export class UpdateRole {
  static readonly type = '@role.UpdateRole';

  constructor(public role: Role) {}
}

@State<RoleStateModel>({
  name: 'role',
  defaults: { permissions: [], roles: {}, rolePermissions: {}, users: {} },
})
@Injectable()
export class RoleState {
  constructor(
    private roleService: RoleService,
    private store: Store,
    private userAccountsService: UserAccountsService,
  ) {}

  @Selector()
  static permissions(state: RoleStateModel) {
    return state.permissions;
  }

  @Selector()
  static roles(state: RoleStateModel) {
    return Object.values(state.roles);
  }

  @Selector()
  static allAssignableRoles(state: RoleStateModel) {
    return Object.values(state.roles)
      .filter((role) => role.assignable)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  @Selector([RoleState.roles, RouterSelectors.servicing])
  static assignableRoles(roles: Role[], isServicingEnabled: boolean | undefined) {
    return roles
      .filter(
        (role) => role.assignable && (isServicingEnabled ? role.isServicing : !role.isServicing),
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  static rolePermissions(roleId: string) {
    return createSelector([RoleState], (state: RoleStateModel): RolePermission[] | undefined => {
      return state.rolePermissions[roleId];
    });
  }

  static role(roleId: string) {
    return createSelector([RoleState], (state: RoleStateModel): Role | undefined => {
      return state.roles[roleId];
    });
  }

  static usersAssignedToRole(roleId: string) {
    return createSelector([RoleState], (state: RoleStateModel): User[] => {
      const users = Object.values(state.users);

      return users.filter((user) => user.roles.some((x) => x.id === roleId));
    });
  }

  static usersNotAssignedToRole(roleId: string) {
    return createSelector([RoleState], (state: RoleStateModel): User[] => {
      const users = Object.values(state.users);

      return users.filter(
        (user) =>
          !user.roles.find((x) => x.id === roleId) &&
          user.externalAuthSystemType !== ExternalAuthSystemType.API_KEY,
      );
    });
  }

  @Action(AddRole)
  addRole(ctx: StateContext<RoleStateModel>, action: AddRole) {
    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.roleService.add(action.role).pipe(
      tap((role) => {
        ctx.patchState({
          roles: {
            ...state.roles,
            [role.id]: role,
          },
        });

        return role;
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(AssignUsersToRole)
  assignUsersToRole(ctx: StateContext<RoleStateModel>, action: AssignUsersToRole) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.roleService.assignUsersToRole(action.users, action.role).pipe(
      tap(() => {
        const state = ctx.getState();
        const users = { ...state.users };

        action.users.forEach((user) => {
          users[user.id] = {
            ...users[user.id],
            roles: [...users[user.id].roles, { ...action.role }],
          };
        });

        ctx.patchState({
          users,
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(AddRolePermission)
  addRolePermission(ctx: StateContext<RoleStateModel>, action: AddRolePermission) {
    let state = ctx.getState();
    const rolePermission = state.permissions.find((x) => x.id === action.permissionId);

    if (!rolePermission) {
      return;
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));

    ctx.patchState({
      rolePermissions: {
        ...state.rolePermissions,
        [action.roleId]: [...state.rolePermissions[action.roleId], rolePermission],
      },
    });

    return this.roleService.addRolePermission(action.roleId, action.permissionId).pipe(
      tap(() => this.reloadGeneralPermissions()),
      catchError((e) => {
        // revert changes
        state = ctx.getState();
        const rolePermissions = [...state.rolePermissions[action.roleId]];

        rolePermissions.splice(
          rolePermissions.findIndex((x) => x.id === action.permissionId),
          1,
        );

        ctx.patchState({
          rolePermissions: {
            ...state.rolePermissions,
            [action.roleId]: rolePermissions,
          },
        });

        throw e;
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(CloneRole)
  cloneRole(ctx: StateContext<RoleStateModel>, action: CloneRole) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.roleService
      .add({
        ...action.role,
        name: `${action.role.name} - clone`,
        assignable: action.role.assignable ?? false,
        ezidoxCollectorType: action.role.ezidoxCollectorType,
        notifyOnAssignment: action.role.notifyOnAssignment,
        emailAddressToNotify: action.role.emailAddressToNotify,
        isServicing: action.role.isServicing,
        stages: action.role.stages,
      })
      .pipe(
        switchMap((createdRole: Role) =>
          this.roleService.getRolePermissions(action.role.id).pipe(
            map((rolePermissions) => ({ createdRole, rolePermissions })),
            tap(() => {
              const state = ctx.getState();

              ctx.patchState({
                roles: {
                  ...state.roles,
                  [createdRole.id]: createdRole,
                },
              });
            }),
          ),
        ),
        switchMap(({ createdRole, rolePermissions }) => {
          return this.roleService
            .bulkAddRolePermissions(
              createdRole.id,
              rolePermissions.map((r) => r.id),
            )
            .pipe(
              tap(() => {
                const state = ctx.getState();

                ctx.patchState({
                  rolePermissions: {
                    ...state.rolePermissions,
                    [createdRole.id]: rolePermissions,
                  },
                });
              }),
            );
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(DeleteRole)
  deleteRole(ctx: StateContext<RoleStateModel>, action: DeleteRole) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.roleService.delete(action.roleId).pipe(
      tap(() => {
        const state = ctx.getState();
        const roles = { ...state.roles };
        const rolePermissions = { ...state.rolePermissions };

        delete roles[action.roleId];
        delete rolePermissions[action.roleId];

        ctx.patchState({
          roles,
          rolePermissions,
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(FetchPermissions)
  getPermissions(ctx: StateContext<RoleStateModel>) {
    ctx.patchState({ permissions: [] });

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.roleService.getPermissions().pipe(
      tap((permissions: RolePermission[]) => {
        ctx.patchState({ permissions });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(FetchRoles)
  getRoles(ctx: StateContext<RoleStateModel>) {
    ctx.patchState({ roles: {} });

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.roleService.getAll().pipe(
      tap((roles: Role[]) => {
        const roleEntities = roles.reduce((entities: { [key: string]: Role }, role) => {
          entities[role.id] = role;

          return entities;
        }, {});

        ctx.patchState({ roles: roleEntities });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(FetchRolePermissions)
  getRolePermissions(ctx: StateContext<RoleStateModel>, action: FetchRolePermissions) {
    const state = ctx.getState();

    if (state.rolePermissions[action.roleId]) {
      // role permissions already loaded
      return;
    }

    ctx.patchState({ rolePermissions: {} });

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.roleService.getRolePermissions(action.roleId).pipe(
      tap((rolePermissions: RolePermission[]) => {
        ctx.patchState({
          rolePermissions: {
            ...state.rolePermissions,
            [action.roleId]: rolePermissions,
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(FetchUsers)
  getUsers(ctx: StateContext<RoleStateModel>) {
    ctx.patchState({ users: {} });

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.userAccountsService.getUserAccounts().pipe(
      tap((users: UserAccount[]) => {
        const userEntities = users.reduce((entities: { [key: string]: User }, item) => {
          entities[item.user.id] = item.user;

          return entities;
        }, {});

        ctx.patchState({ users: userEntities });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(RemoveRolePermission)
  removeRolePermission(ctx: StateContext<RoleStateModel>, action: RemoveRolePermission) {
    let state = ctx.getState();
    const rolePermissions = [...state.rolePermissions[action.roleId]];

    rolePermissions.splice(
      rolePermissions.findIndex((x) => x.id === action.permissionId),
      1,
    );

    ctx.dispatch(new LoadingStart(this.constructor.name));

    ctx.patchState({
      rolePermissions: {
        ...state.rolePermissions,
        [action.roleId]: rolePermissions,
      },
    });

    return this.roleService.removeRolePermission(action.roleId, action.permissionId).pipe(
      tap(() => this.reloadGeneralPermissions()),
      catchError((e) => {
        // revert changes
        state = ctx.getState();

        const rolePermission = state.permissions.find((x) => x.id === action.permissionId);

        if (rolePermission) {
          ctx.patchState({
            rolePermissions: {
              ...state.rolePermissions,
              [action.roleId]: [...state.rolePermissions[action.roleId], rolePermission],
            },
          });
        }

        throw e;
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(RemoveRoleFromUser)
  removeRoleFromUser(ctx: StateContext<RoleStateModel>, action: RemoveRoleFromUser) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.roleService.removeUserFromRole(action.user, action.role).pipe(
      tap(() => {
        const state = ctx.getState();
        const user = state.users[action.user.id];
        const userRoles = [...user.roles];

        userRoles.splice(
          userRoles.findIndex((x) => x.id === action.role.id),
          1,
        );

        ctx.patchState({
          users: {
            ...state.users,
            [action.user.id]: {
              ...user,
              roles: userRoles,
            },
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(UpdateRole)
  updateRole(ctx: StateContext<RoleStateModel>, action: UpdateRole) {
    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.roleService.update(action.role).pipe(
      tap(() => {
        ctx.patchState({
          roles: {
            ...state.roles,
            [action.role.id]: action.role,
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  private reloadGeneralPermissions() {
    this.store.dispatch(new FetchGeneralPermissions());
  }
}
