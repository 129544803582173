import { ApplicationConditionDocumentRejectReasons, ApplicationConditionDocumentStatus } from '..';

export interface ApplicationConditionDocumentFile {
  id: string;
  applicationId: string;
  stakeholderId: string;
  applicationDocumentId: string;
  documentTypeId: string | null;
  status: ApplicationConditionDocumentStatus | null;
  rejectReason: ApplicationConditionDocumentRejectReasons | null;
  comment: string | null;
  verifiedOn: Date | null;
  verifiedBy: string | null;
  displayName: string;
  applicationDocument: {
    id: string;
    applicationId: string;
    type: string;
    source: string;
    gsBucket?: string;
    gsKey?: string;
    context: {
      name: string | null;
      type: string | null;
    };
    status: string | null;
    createdAt: Date;
  };
  comments: ApplicationConditionDocumentFileComment[];
  sharedExternally: boolean;
  stage: string;
  attached: boolean;
  signedWithDocuSign: boolean;
  createdAt: Date;
}

export interface ApplicationConditionDocumentFileStatusUpdate {
  status: ApplicationConditionDocumentStatus;
  rejectReason?: ApplicationConditionDocumentRejectReasons;
  comment?: string;
}

export interface ApplicationConditionDocumentFileComment {
  id: string;
  applicationId: string;
  applicationConditionDocumentFileId: string;
  reviewStatus: string | null;
  reviewReason: string | null;
  reviewComment: string | null;
  text: string | null;
  shareExternally?: boolean;
  commentBy: string;
  seenHistory: ApplicationConditionDocumentFileCommentSeenHistory[];
  createdAt: Date;
  authorName?: string;
}

export interface ApplicationConditionDocumentFileCommentSeenHistory {
  id: string;
  applicationConditionDocumentFileCommentId: string;
  seenBy: string;
  seenAt: Date;
  metadata?: ApplicationConditionDocumentFileCommentSeenHistoryMetadata;
}

export interface ApplicationConditionDocumentFileCommentSeenHistoryMetadata {
  seenFromURL?: string;
}

export enum ApplicationConditionDocumentFileKey {
  ID = 'id',
  APPLICATION_ID = 'applicationId',
  STAKEHOLDER_ID = 'stakeholderId',
  APPLICATION_DOCUMENT_ID = 'applicationDocumentId',
  DOCUMENT_TYPE_ID = 'documentTypeId',
  STATUS = 'status',
  REJECT_REASON = 'rejectReason',
  COMMENT = 'comment',
  VERIFIED_ON = 'verifiedOn',
  VERIFIED_BY = 'verifiedBy',
  DISPLAY_NAME = 'displayName',
  STAGE = 'stage',
}

export enum ApplicationConditionDocumentFileCommentKey {
  TEXT = 'text',
}

export enum ApplicationConditionDocumentFileSource {
  AI = 'AI',
  IQ = 'IQ',
}
