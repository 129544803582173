import {
  CalculationItemType,
  CalculationResult,
  Fee,
  Mortgage,
  Property,
} from '@fundmoreai/models';
import { computeExistingMortgageAmount } from './existing-mortgage-amount.calculator';
import { computeLoanAmountForLTV } from './loan-amount-for-ltv.calculator';
import { computePropertyValue } from './property-value.calculator';

export function computeLTV(
  propertyValue: number | undefined,
  existingMortgageAmount: number,
  loanAmount: number | undefined,
): CalculationResult {
  let ltvValue = 0;

  if (propertyValue && loanAmount) {
    ltvValue = ((loanAmount + existingMortgageAmount) / propertyValue) * 100;
  }

  const data: CalculationResult = {
    formula:
      '(Total Loan Amount + Existing Mortgage Amount) / MIN(Property Value, Purchase Price) * 100',
    items: [
      {
        text: 'Total Loan Amount',
        value: loanAmount?.toString(),
        type: CalculationItemType.CURRENCY,
      },
      {
        text: 'Existing Mortgage Amount',
        value: existingMortgageAmount?.toString(),
        type: CalculationItemType.CURRENCY,
      },
      {
        text: 'MIN(Property Value, Purchase Price)',
        value: propertyValue?.toString(),
        type: CalculationItemType.CURRENCY,
      },
    ],
    result: ltvValue,
  };
  return data;
}

export function computeLTVFromObjects(
  property: Property,
  existingMortgages: Mortgage[],
  requestedMortgages: Mortgage[],
  fees: Fee[],
  capFeesMaxPercentage: number | undefined,
): CalculationResult {
  const propertyValue = computePropertyValue(property);

  const existingMortgageAmount = computeExistingMortgageAmount(
    existingMortgages?.filter((x) => x.propertyId === property.id),
  );

  const loanAmount = computeLoanAmountForLTV(requestedMortgages, fees, capFeesMaxPercentage);

  const ltv = computeLTV(propertyValue, existingMortgageAmount, loanAmount);

  return ltv;
}
