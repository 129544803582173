import { PaymentFrequency } from '@fundmoreai/models';
import { paymentFrequencyMap } from './mortgage-payment.calculator';

export function computeNegativeAmortizationRate(
  loanAmount: number | null,
  paymentAmount: number | null,
  paymentFrequency: PaymentFrequency,
): number {
  let annualPayment;

  switch (paymentFrequency) {
    case PaymentFrequency.ANNUALLY:
    case PaymentFrequency.DAILY:
    case PaymentFrequency.MONTHLY:
    case PaymentFrequency.QUARTERLY:
    case PaymentFrequency.SEMI_ANNUALLY:
    case PaymentFrequency.SEMI_MONTHLY:
      annualPayment = paymentAmount * paymentFrequencyMap.get(paymentFrequency);
      break;

    case PaymentFrequency.ACCELERATED_BI_WEEKLY:
    case PaymentFrequency.BI_WEEKLY:
      annualPayment = (paymentAmount / 14) * 365;
      break;

    case PaymentFrequency.ACCELERATED_WEEKLY:
    case PaymentFrequency.WEEKLY:
      annualPayment = (paymentAmount / 7) * 365;
      break;
  }

  const negativeAmortizationRate = (annualPayment / loanAmount) * 100;

  return negativeAmortizationRate;
}
