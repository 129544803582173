export enum MMSFileFormat {
  PDF = 0,
  // TIFF = 1, deprecated
  TIF = 2,
  DOC = 3,
  DOCX = 4,
  // JPEG = 5, deprecated
  // PNG = 6, deprecated
  BMP = 7,
  GIF = 8,
  JPG = 9,
}
