import {
  CalculationResult,
  Application,
  MortgageType,
  PropertyType,
  CalculationItemType,
  RentalOffsetType,
  Mortgage,
  MortgageCalculationAutomationSettings,
  Property,
  ComputedRatioRateType,
  LoanType,
} from '@fundmoreai/models';
import { FundmoreCalculator } from '..';
import { computeExistingMortgagePayments } from './existing-mortgage-payments.calculator';
import {
  ComputeRentalIncomePropertyInput,
  computeRentalIncomeValues,
} from './rental-offset.calculator';
import { computeTotalMonthlyIncomeWithoutPropertyRentalIncome } from './total-income.calculator';
import { computeAnnualRentalPropertyExpenses } from './property-expenses.calculator';

export type ExistingMortgageForMonthlyCostToCarry = Pick<
  Partial<Mortgage>,
  'propertyId' | 'payoffPaydown' | 'monthlyPayment'
>;

export type PropertyInput = Pick<
  Property,
  | 'annualTaxes'
  | 'condoFees'
  | 'condoFeesIncludeHeating'
  | 'condoFeesPercentInCalculation'
  | 'includeExpensesInTDS'
  | 'heatingCost'
  | 'rpeGeneralExpenses'
  | 'rpeHydro'
  | 'rpeInsurance'
  | 'rpeInterestCharges'
  | 'rpeManagementExpenses'
  | 'rpeRepairs'
  | 'rpeRentalOffsetOption'
  | 'rpeOffset'
>;

export function computeGDS(
  requestedMortgageMonthlyPayment: number | undefined | null,
  primaryProperty: PropertyInput | undefined,
  otherProperties: ComputeRentalIncomePropertyInput[] | undefined,
  existingMortgages: ExistingMortgageForMonthlyCostToCarry[],
  existingMortgagePayments: number,
  totalIncomeWithoutPropertyRental: number,
  principal: number,
  interest: number,
  mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
  computedRatioRateType = ComputedRatioRateType.NET,
  isLOC = false,
): CalculationResult {
  if (mortgageCalculationAutomationSettings?.ANY_MORTGAGE?.setGDSToZero) {
    return {
      formula: 'GDS is 0 because there are errors on update to P+',
      items: [],
      result: 0,
    };
  }

  const primaryResidence = otherProperties?.find((property) => property.isPrimaryResidence);
  const propertyForGds = primaryResidence ?? primaryProperty;

  const annualExpenses = FundmoreCalculator.computeAnnualExpensesForGDS(propertyForGds);
  const rentalOffset = FundmoreCalculator.computeRentalOffset(propertyForGds);

  let monthlyPayment;
  let interestBasedOnPrimaryResidence;
  let principalBasedOnPrimaryResidence;

  if (primaryResidence) {
    const existingMortgagesLinkedToPrimaryResidence = existingMortgages.filter(
      (x) => x.propertyId === primaryResidence?.id,
    );

    monthlyPayment = computeExistingMortgagePayments(existingMortgagesLinkedToPrimaryResidence);
  } else {
    monthlyPayment = requestedMortgageMonthlyPayment;
    interestBasedOnPrimaryResidence = interest;
    principalBasedOnPrimaryResidence = principal;
  }

  const costToCarry = FundmoreCalculator.computeCostToCarryForGDS(propertyForGds, monthlyPayment);

  const gdsValue = computeGDSValue(
    totalIncomeWithoutPropertyRental,
    propertyForGds?.rpeRentalOffsetOption,
    costToCarry,
    existingMortgagePayments,
    rentalOffset,
    otherProperties,
    existingMortgages,
  );

  const data = computeGDSFormula(
    existingMortgagePayments,
    costToCarry,
    principalBasedOnPrimaryResidence,
    interestBasedOnPrimaryResidence,
    annualExpenses,
    propertyForGds,
    totalIncomeWithoutPropertyRental,
    gdsValue,
    rentalOffset,
    propertyForGds?.rpeRentalOffsetOption,
    otherProperties,
    existingMortgages,
    monthlyPayment,
    computedRatioRateType,
    isLOC,
  );

  return data;
}

function computeGDSFormula(
  existingMortgagePayments: number,
  costToCarry: number,
  principal: number,
  interest: number,
  annualExpenses: number,
  property,
  totalIncomeWithoutPropertyRental: number,
  gdsValue: number,
  primaryPropertyRentalOffset: number,
  primaryPropertyRentalOffsetOption: string,
  otherProperties: ComputeRentalIncomePropertyInput[] | undefined,
  existingMortgages: ExistingMortgageForMonthlyCostToCarry[],
  monthlyPayment: number | undefined,
  computedRatioRateType: ComputedRatioRateType,
  isLOC = false,
) {
  const { totalRentalIncome: totalRentalIncomeOtherProperties } = computeRentalIncomeValues(
    otherProperties,
    existingMortgages,
  );

  const paymentPrefixText = (() => {
    switch (computedRatioRateType) {
      case ComputedRatioRateType.NET:
      default:
        return '';
      case ComputedRatioRateType.QUALIFYING:
        return 'Qualifying ';
      case ComputedRatioRateType.BENCHMARK:
        return 'Benchmark ';
    }
  })();
  const monthlyPaymentText = `${paymentPrefixText}${isLOC ? 'Amortized ' : ''}Monthly Payment`;

  const totalIncomeIncludingRentalFromOtherProperties =
    totalIncomeWithoutPropertyRental + totalRentalIncomeOtherProperties;
  const monthlyExpenses = annualExpenses ? annualExpenses / 12 : null;
  const data: CalculationResult = {
    formula: '(Principal + Interest + Property Taxes + Heat + 50% Condo Fees) / Total Income',
    items: [
      {
        text: 'Existing Mortgage Payments',
        value: existingMortgagePayments?.toString(),
        type: CalculationItemType.CURRENCY,
      },
      {
        text: 'Monthly Cost to Carry',
        value: costToCarry?.toString(),
        type: CalculationItemType.CURRENCY,
        subitems: [
          {
            text: monthlyPaymentText,
            value: monthlyPayment?.toString(),
            type: CalculationItemType.CURRENCY,
            subitems: [
              {
                text: 'Principal',
                value: principal?.toString(),
                type: CalculationItemType.CURRENCY,
              },
              {
                text: 'Interest',
                value: interest?.toString(),
                type: CalculationItemType.CURRENCY,
              },
            ],
          },
          {
            text: 'Total Monthly Expenses',
            value: monthlyExpenses?.toString(),
            type: CalculationItemType.CURRENCY,
            subitems: [
              {
                text: 'Total Taxes (Annually)',
                value: property?.annualTaxes?.toString(),
                type: CalculationItemType.CURRENCY,
              },
              {
                text: 'Total Heat (Annually)',
                value: property?.heatingCost?.toString(),
                type: CalculationItemType.CURRENCY,
              },
              {
                text: 'Total Condo Fees (Annually)',
                value: property?.condoFees?.toString(),
                type: CalculationItemType.CURRENCY,
              },
            ],
          },
        ],
      },
      {
        text: 'Total Income',
        value: totalIncomeIncludingRentalFromOtherProperties?.toString(),
        type: CalculationItemType.CURRENCY,
      },
    ],

    result: gdsValue,
  };

  if (
    primaryPropertyRentalOffset &&
    primaryPropertyRentalOffsetOption &&
    primaryPropertyRentalOffsetOption != RentalOffsetType.NONE
  ) {
    data.items = [
      ...data.items,
      {
        text: 'Rental Offset',
        value: primaryPropertyRentalOffset?.toString(),
        type: CalculationItemType.CURRENCY,
      },
      {
        text: 'Rental Offset Option',
        value: primaryPropertyRentalOffsetOption,
        type: CalculationItemType.TEXT,
      },
    ];
    if (primaryPropertyRentalOffsetOption == RentalOffsetType.ADD_PERCENTAGE_TO_GROSS_INCOME) {
      data.items = [
        ...data.items,
        {
          text: 'Total Income with Rental',
          value: (
            totalIncomeIncludingRentalFromOtherProperties + primaryPropertyRentalOffset
          )?.toString(),
          type: CalculationItemType.CURRENCY,
        },
      ];
      data.formula =
        '(Principal + Interest + Property Taxes + Heat + 50% Condo Fees) / Total Income with Rental';
    }
    if (primaryPropertyRentalOffsetOption == RentalOffsetType.REDUCE_RENTAL_EXPENSES) {
      if (gdsValue <= 0) {
        data.formula = '0%; Positive cashflow on primary property';
      } else {
        data.formula =
          '((Principal + Interest + Property Taxes + Heat + 50% Condo Fees) - Rental Offset) / Total Income';
      }
    }
  } else {
    data.formula = '(Principal + Interest + Property Taxes + Heat + 50% Condo Fees) / Total Income';
  }
  return data;
}

function computeGDSValue(
  totalIncomeWithoutPropertyRental: number,
  primaryPropertyRentalOffsetOption: RentalOffsetType,
  costToCarry: number,
  existingMortgagePayments: number,
  primaryPropertyRentalOffset: number,
  otherProperties: ComputeRentalIncomePropertyInput[] | undefined,
  existingMortgages: ExistingMortgageForMonthlyCostToCarry[],
) {
  let gdsValue = 0;

  const { totalRentalIncome: totalRentalIncomeOtherProperties } = computeRentalIncomeValues(
    otherProperties,
    existingMortgages,
  );

  const totalIncomeIncludingRentalFromOtherProperties =
    totalIncomeWithoutPropertyRental + totalRentalIncomeOtherProperties;

  if (totalIncomeIncludingRentalFromOtherProperties !== 0) {
    if (
      !primaryPropertyRentalOffsetOption ||
      primaryPropertyRentalOffsetOption == RentalOffsetType.NONE
    ) {
      gdsValue =
        ((costToCarry + existingMortgagePayments) * 100) /
        totalIncomeIncludingRentalFromOtherProperties;
    }
    if (primaryPropertyRentalOffsetOption == RentalOffsetType.REDUCE_RENTAL_EXPENSES) {
      const propertyBalance = existingMortgagePayments + costToCarry - primaryPropertyRentalOffset;
      if (propertyBalance > 0) {
        gdsValue = (propertyBalance / totalIncomeIncludingRentalFromOtherProperties) * 100;
      }
    }
    if (primaryPropertyRentalOffsetOption == RentalOffsetType.ADD_PERCENTAGE_TO_GROSS_INCOME) {
      gdsValue =
        ((costToCarry + existingMortgagePayments) * 100) /
        (totalIncomeIncludingRentalFromOtherProperties + primaryPropertyRentalOffset);
    }
  }
  return Math.round(gdsValue * 100) / 100;
}

export function computeGDSFromApplication(
  application: Application,
  mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
): Partial<CalculationResult> {
  const existingMortgages = application.Mortgages.filter((x) => x.type == MortgageType.EXISTING);
  // TODO consider all requested mortgages for rate matrix?
  const requestedMortgage = application.Mortgages.find((x) => x.type == MortgageType.REQUESTED);
  const totalLoanAmount = FundmoreCalculator.computeTotalLoanAmountFromApplication(application);
  const primaryProperty = application.Properties.find((x) => x.type == PropertyType.PRIMARY);
  const otherProperties = application.Properties.filter((x) => x.type == PropertyType.OTHER);
  const isLOC =
    requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
    requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX;

  const mortgagePayments = isLOC
    ? requestedMortgage.amortizedMonthlyPayment
    : !mortgageCalculationAutomationSettings ||
      !mortgageCalculationAutomationSettings[requestedMortgage.id]?.isMonthlyPaymentDisabled
    ? FundmoreCalculator.computeMortgageMonthlyPayment(
        requestedMortgage,
        totalLoanAmount,
        requestedMortgage.netRate,
      )
    : requestedMortgage.monthlyPayment;
  const totalMonthlyIncome = computeTotalMonthlyIncomeWithoutPropertyRentalIncome(
    application.applicants,
    application.OtherIncomes,
  );
  const existingMortgagePayments = computeExistingMortgagePayments(existingMortgages);

  const { interest, principal } = FundmoreCalculator.computePrincipalAndInterest(requestedMortgage);

  const gds = computeGDS(
    mortgagePayments,
    primaryProperty,
    otherProperties,
    existingMortgages,
    existingMortgagePayments,
    totalMonthlyIncome,
    principal,
    interest,
    mortgageCalculationAutomationSettings,
  );
  return gds;
}
