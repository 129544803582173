import { PdfOptions } from 'src/app/shared';

export enum ScoreProcessingType {
  Lender = 'lender',
}

export enum DocumentPackageServiceType {
  Default,
  Gentai,
  Calvert,
  EQ,
}

import {
  DatesProcessingType,
  DocumentManagementType,
  OpenDocumentsStrategy,
  OneTimeLinkGenerationStrategy,
  ApplicationStage,
  AutomaticArchivingConfig,
  CustomIntegrationFields,
  AIFeatures,
  LOCCalculatorParametersModel,
  IncrementsParametersModel,
  OtherRateConfigurations,
  WidgetViewPermission,
  LTISettings,
} from '@fundmoreai/models';
import { EzidoxStatusLocalizationOptions } from 'src/app/shared/enums';
import { RestrictCBLiabilityEditing } from '../app/shared/model';
import { IncrementTypeLocalizationOptions } from '../app/shared/increments/increments-records';

export type StageConfig = {
  // commitment section config (documents to be generated)
  generateDocuments?: {
    // hide commitment tab under generate documents
    hideCommitment?: boolean;
    conditionsTitle?: string;
    buildPackageTitle?: string;
  };
};

export interface FeatureConfig {
  scoreProcessingType: ScoreProcessingType;
  pdfOptions?: PdfOptions;
  demo?: boolean;
  stageString?: Partial<Record<ApplicationStage, string>>;
  tenantStages?: ApplicationStage[]; // First and Last stages will be visible by default
  tenantStagesServicing?: ApplicationStage[];
  finalStages?: ApplicationStage[];
  // Define Tenant special configs
  stageConfig?: Partial<Record<ApplicationStage, StageConfig>>;
  dolphinRead?: string;
  dolphinServicing?: string;
  intellifiServicing?: boolean;
  hasCustomScore?: boolean;
  reportingModule?: boolean;
  enableConstructionModule?: boolean;
  enableFundmoreScore?: boolean;
  enable5Cs?: boolean;
  flipModuleEnabled?: boolean;
  equifaxEnabled?: boolean;
  sagenEnabled?: boolean;
  cmhcEnabled?: boolean;
  cgEnabled?: boolean;
  mortgageInsuranceEnabled?: boolean;
  datesProcessingType?: DatesProcessingType;
  fundmoreDMEnabled?: boolean;
  createCollectorAccount?: boolean;
  enableAutomaticAssignment?: boolean;
  enableExpandedLendingLimits?: boolean;
  enableAdvancedAutomaticAssignment?: boolean;
  automaticCompoundingEnabled?: { [key: string]: string };
  disabledPropertyDetailsTabs?: string[];
  enableCloneDeal?: boolean;
  documentPackageService: DocumentPackageServiceType;
  fnfEnabled?: boolean;
  fctEnabled?: boolean;
  eqMapsEnabled?: boolean;
  fctIntegrationEnabled?: boolean;
  optionalFields?: CustomIntegrationFields[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  partnerLoanOptions?: any;
  restrictCBLiabilityEditing?: RestrictCBLiabilityEditing;
  applyCreditReportsOnIngestion?: boolean;
  documentManagementType?: DocumentManagementType;
  dmOpenDocumentsStrategy?: OpenDocumentsStrategy;
  dmOneTimeLinkGenerationStrategy?: OneTimeLinkGenerationStrategy;
  blockConditionalApprovalBasedOnDLA?: boolean;
  blockConditionalApprovalWhenUnresolvedApprovals?: boolean;
  blockConditionalApprovalWhenUnresolvedRiskFlags?: boolean;
  blockSendToServicingWhenUnresolvedApprovals?: boolean;
  clientIdGenerationEnabled?: boolean;
  timezone?: string;
  enableOOOReplacementAutomation?: boolean;
  blockApplyingProductsWhenWarnings?: boolean;
  checkProductApplyOnStageTransitionEnabled?: boolean;
  delegateRemovalEnabled?: boolean;
  insuranceAutomationEnabled?: boolean;
  helpRedirectLink?: string;
  automaticArchivingSettings?: AutomaticArchivingConfig;
  assigneeDLALimitEnabled?: boolean;
  automaticLoanAmountCalculationDisabled?: boolean;
  aiFeatures?: {
    [key in AIFeatures]: boolean;
  };
  commitmentExpiryDateOffset?: number;
  additionalSecuritiesDisabled?: boolean;
  enableFraudIq?: boolean;
  memberIdIntegrationEnabled?: boolean;
  decisionEngineIntegrationEnabled?: boolean;
  enableBlocklistScan?: boolean;
  locCalculatorParameters?: LOCCalculatorParametersModel;
  iqDocumentRequestsEnabled?: boolean;
  paymentFrequencyByRateType?: { [key: string]: string };
  condoFeesPercentageByOccupancy?: { [key: string]: number };
  declineApplicationWhenBrokerBlockedOnIngest?: { [key: string]: number };
  decisionEngineSubmitStageBasedBehaviour?: {
    autoSubmitOnStages?: ApplicationStage[];
    disabledSubmitActionOnStage?: ApplicationStage;
  };
  isPrimaryResidenceEnabled?: boolean;
  purposeCodeByApplicationPurpose?: { [key: string]: string };
  enableBlanket?: boolean;
  iadByFpdEnabled?: boolean;
  enableProductStatus?: boolean;
  blockApplicationActionsStage?: BlockApplicationActionsUntilStage;
  incrementsEnabled?: boolean;
  incrementsAutomationEnabled?: boolean;
  decrementsEnabled?: boolean;
  enableProductDefaultValueOverride?: boolean;
  rateChangeNotificationsEnabled?: boolean;
  enableClamavFileScanning?: boolean;
  discretionEnabled?: boolean;
  signPaymentChangeFormTaskAutomationEnabled?: boolean;
  reissueCOBTaskAutomationEnabled?: boolean;
  blockMovingWithoutApproveStartingWithStage?: ApplicationStage;
  enableAggregateCalculations?: boolean;
  excludeExtraPaymentInTerm?: boolean;
  incrementsParameters?: IncrementsParametersModel;
  isServicingEnabled?: boolean;
  preventManualAgentCreation?: boolean;
  fctMmsIntegrationEnabled?: boolean;
  isBlendedRateOnMCUEnabled?: boolean;
  // enabled when missing, intended to be used to enable the old streetview component
  googleMapsEmbedded?: boolean;
  isAVAEnabled?: boolean;
  takeFullFirstPayment?: boolean;
  otherRateConfigurations?: OtherRateConfigurations;
  widgetViewPermissions?: WidgetViewPermission[];
  ltiSettings?: LTISettings;
}

export interface BlockApplicationActionsUntilStage {
  pending?: ApplicationStage;
  approve?: ApplicationStage;
  cancel?: ApplicationStage;
  commit?: ApplicationStage;
  awaitingDocs?: ApplicationStage;
}

export enum EditableTenantSettings {
  enableAutomaticAssignment = 'enableAutomaticAssignment',
  enableExpandedLendingLimits = 'enableExpandedLendingLimits',
  enableAdvancedAutomaticAssignment = 'enableAdvancedAutomaticAssignment',
  documentManagementType = 'documentManagementType',
  dmOpenDocumentsStrategy = 'dmOpenDocumentsStrategy',
  dmOneTimeLinkGenerationStrategy = 'dmOneTimeLinkGenerationStrategy',
  blockConditionalApprovalBasedOnDLA = 'blockConditionalApprovalBasedOnDLA',
  blockConditionalApprovalWhenUnresolvedApprovals = 'blockConditionalApprovalWhenUnresolvedApprovals',
  // eslint-disable-next-line max-len
  blockConditionalApprovalWhenUnresolvedRiskFlags = 'blockConditionalApprovalWhenUnresolvedRiskFlags',
  blockSendToServicingWhenUnresolvedApprovals = 'blockSendToServicingWhenUnresolvedApprovals',
  clientIdGenerationEnabled = 'clientIdGenerationEnabled',
  enableOOOReplacementAutomation = 'enableOOOReplacementAutomation',
  createCollectorAccount = 'createCollectorAccount',
  delegateRemovalEnabled = 'delegateRemovalEnabled',
  automaticArchivingSettings = 'automaticArchivingSettings',
  assigneeDLALimitEnabled = 'assigneeDLALimitEnabled',
  automaticLoanAmountCalculationDisabled = 'automaticLoanAmountCalculationDisabled',
  enableFraudIq = 'enableFraudIq',
  locCalculatorParameters = 'locCalculatorParameters',
  iqDocumentRequestsEnabled = 'iqDocumentRequestsEnabled',
  iadByFpdEnabled = 'iadByFpdEnabled',
  enableProductDefaultValueOverride = 'enableProductDefaultValueOverride',
  incrementsParameters = 'incrementsParameters',
  otherRateConfigurations = 'otherRateConfigurations',
  ltiSettings = 'ltiSettings',
}

const config: { [key: string]: FeatureConfig } = {
  'Fundmore Demo': {
    documentPackageService: DocumentPackageServiceType.Default,
    scoreProcessingType: ScoreProcessingType.Lender,
    tenantStages: [
      ApplicationStage.NEW_APPLICATION,
      ApplicationStage.UNDERWRITING,
      ApplicationStage.DOCUMENT_REVIEW,
      ApplicationStage.LAWYER_INSTRUCTED,
      ApplicationStage.FINAL_REVIEW,
      ApplicationStage.FUNDED,
      ApplicationStage.DECLINED,
    ],
    tenantStagesServicing: [
      ApplicationStage.OPEN,
      ApplicationStage.WAITING_FOR_SIGNATURES,
      ApplicationStage.READY_FOR_UPLOAD,
      ApplicationStage.QUEUED_FOR_UPLOAD,
      ApplicationStage.UPLOADED_COMPLETED,
      ApplicationStage.CANCELED,
    ],
    stageConfig: {
      [ApplicationStage.LAWYER_INSTRUCTED]: {
        generateDocuments: {
          hideCommitment: true,
          conditionsTitle: 'Funding Conditions',
          buildPackageTitle: 'Build Instruction Package',
        },
      },
      [ApplicationStage.FINAL_REVIEW]: {
        generateDocuments: {
          hideCommitment: true,
          conditionsTitle: 'Funding Conditions',
          buildPackageTitle: 'Build Instruction Package',
        },
      },
      [ApplicationStage.FUNDED]: {
        generateDocuments: {
          hideCommitment: true,
          conditionsTitle: 'Funding Conditions',
          buildPackageTitle: 'Build Instruction Package',
        },
      },
    },
  },
  'Sales Demo': {
    documentPackageService: DocumentPackageServiceType.Default,
    scoreProcessingType: ScoreProcessingType.Lender,
    tenantStages: [
      ApplicationStage.NEW_APPLICATION,
      ApplicationStage.UNDERWRITING,
      ApplicationStage.DOCUMENT_REVIEW,
      ApplicationStage.OPERATIONS_FULFILLMENT,
      ApplicationStage.FUNDED,
      ApplicationStage.DECLINED,
    ],
    stageString: {
      [ApplicationStage.NEW_APPLICATION]: $localize`Application`,
      [ApplicationStage.UNDERWRITING]: $localize`Underwriting`,
      [ApplicationStage.DOCUMENT_REVIEW]: $localize`Document Review`,
      [ApplicationStage.OPERATIONS_FULFILLMENT]: $localize`Operations Fulfillment`,
      [ApplicationStage.FUNDED]: $localize`Funded`,
      [ApplicationStage.DECLINED]: $localize`Declined/Cancelled`,
    },
  },
  'Fundmore Demo Dev': {
    documentPackageService: DocumentPackageServiceType.Default,
    scoreProcessingType: ScoreProcessingType.Lender,
    tenantStages: [
      ApplicationStage.NEW_APPLICATION,
      ApplicationStage.UNDERWRITING,
      ApplicationStage.DOCUMENT_REVIEW,
      ApplicationStage.LAWYER_INSTRUCTED,
      ApplicationStage.FINAL_REVIEW,
      ApplicationStage.FUNDED,
      ApplicationStage.DECLINED,
    ],
  },
  'Fundmore': {
    documentPackageService: DocumentPackageServiceType.Default,
    scoreProcessingType: ScoreProcessingType.Lender,
    tenantStages: [
      ApplicationStage.NEW_APPLICATION,
      ApplicationStage.UNDERWRITING,
      ApplicationStage.DOCUMENT_REVIEW,
      ApplicationStage.LAWYER_INSTRUCTED,
      ApplicationStage.FINAL_REVIEW,
      ApplicationStage.FUNDED,
      ApplicationStage.DECLINED,
    ],
  },
  'Calvert': {
    documentPackageService: DocumentPackageServiceType.Calvert,
    scoreProcessingType: ScoreProcessingType.Lender,
    pdfOptions: {
      format: 'Letter',
      displayHeaderFooter: true,
      printBackground: true,
      margin: {
        bottom: 100,
        left: 40,
        right: 40,
        top: 50,
      },
    },
    stageString: {
      [ApplicationStage.NEW_APPLICATION]: $localize`Inquiry`,
      [ApplicationStage.UNDERWRITING]: $localize`Qualify Lead`,
      [ApplicationStage.ADJUDICATION]: $localize`Adjudication`,
      [ApplicationStage.DEAL_ACCEPTANCE]: $localize`Deal Acceptance`,
      [ApplicationStage.OPERATIONS_FULFILLMENT]: $localize`Operations Fulfillment`,
      [ApplicationStage.PROPOSE_DEAL]: $localize`Propose Deal`,
      [ApplicationStage.DEAL_SIGNED]: $localize`Deal Signed`,
      [ApplicationStage.COMPLIANCE]: $localize`Compliance`,
      [ApplicationStage.FINAL_APPROVAL]: $localize`Final Approval`,
      [ApplicationStage.PRE_FUND]: $localize`Pre-Fund`,
      [ApplicationStage.LAWYER_INSTRUCTED]: $localize`Instructed`,
      [ApplicationStage.FINAL_REVIEW]: $localize`Final Approval`,
      [ApplicationStage.FUNDED]: $localize`Funded`,
      [ApplicationStage.DECLINED]: $localize`Declined`,
      [ApplicationStage.FORECLOSURE]: $localize`Foreclosure`,
      [ApplicationStage.PAID_OUT]: $localize`Paid Out`,
      [ApplicationStage.PAST_DUE_UNDER_90]: $localize`Past due < 90 days`,
      [ApplicationStage.PAST_DUE_OVER_90]: $localize`Past Due > 90 days`,
      [ApplicationStage.PAID_IN_FULL]: $localize`Paid in Full`,
      [ApplicationStage.RENEWAL]: $localize`Renewal`,
    },
    tenantStages: [
      ApplicationStage.NEW_APPLICATION,
      ApplicationStage.UNDERWRITING,
      ApplicationStage.PROPOSE_DEAL,
      ApplicationStage.DEAL_SIGNED,
      ApplicationStage.LAWYER_INSTRUCTED,
      ApplicationStage.FINAL_REVIEW,
      ApplicationStage.FUNDED,
      ApplicationStage.DECLINED,
      ApplicationStage.FORECLOSURE,
      ApplicationStage.PAID_OUT,
    ],
    finalStages: [ApplicationStage.FORECLOSURE, ApplicationStage.PAID_OUT],
    datesProcessingType: DatesProcessingType.Calvert,
  },
  'Gentai': {
    documentPackageService: DocumentPackageServiceType.Gentai,
    scoreProcessingType: ScoreProcessingType.Lender,
    pdfOptions: {
      format: 'A4',
      displayHeaderFooter: true,
      printBackground: true,
      margin: {
        bottom: 170,
        left: 20,
        right: 0,
        top: 110,
      },
    },
    stageString: {
      [ApplicationStage.NEW_APPLICATION]: $localize`Initiating`,
      [ApplicationStage.UNDERWRITING]: $localize`Underwriting`,
      [ApplicationStage.ADJUDICATION]: $localize`Adjudication`,
      [ApplicationStage.DEAL_ACCEPTANCE]: $localize`Deal Acceptance`,
      [ApplicationStage.OPERATIONS_FULFILLMENT]: $localize`Operations Fulfillment`,
      [ApplicationStage.DOCUMENT_REVIEW]: $localize`Approving`,
      [ApplicationStage.PRESENT_DEAL]: $localize`Present Deal`,
      [ApplicationStage.PROPOSE_DEAL]: $localize`Propose Deal`,
      [ApplicationStage.DEAL_SIGNED]: $localize`Deal Signed`,
      [ApplicationStage.COMPLIANCE]: $localize`Compliance`,
      [ApplicationStage.FINAL_APPROVAL]: $localize`Final Approval`,
      [ApplicationStage.PRE_FUND]: $localize`Pre-Fund`,
      [ApplicationStage.LAWYER_INSTRUCTED]: $localize`Fulfilling`,
      [ApplicationStage.FINAL_REVIEW]: $localize`Funding`,
      [ApplicationStage.FUNDED]: $localize`Funded`,
      [ApplicationStage.DECLINED]: $localize`Declined`,
      [ApplicationStage.FORECLOSURE]: $localize`Foreclosure`,
      [ApplicationStage.PAID_OUT]: $localize`Paid Out`,
      [ApplicationStage.PAST_DUE_UNDER_90]: $localize`Past due < 90 days`,
      [ApplicationStage.PAST_DUE_OVER_90]: $localize`Past Due > 90 days`,
      [ApplicationStage.PAID_IN_FULL]: $localize`Paid in Full`,
      [ApplicationStage.RENEWAL]: $localize`Renewal`,
    },
    tenantStages: [
      ApplicationStage.NEW_APPLICATION,
      ApplicationStage.UNDERWRITING,
      ApplicationStage.DOCUMENT_REVIEW,
      ApplicationStage.LAWYER_INSTRUCTED,
      ApplicationStage.FINAL_REVIEW,
      ApplicationStage.FUNDED,
      ApplicationStage.DECLINED,
    ],
  },
  'EQ': {
    documentPackageService: DocumentPackageServiceType.EQ,
    scoreProcessingType: ScoreProcessingType.Lender,
    pdfOptions: {
      format: 'Letter',
      displayHeaderFooter: true,
      printBackground: true,
      margin: {
        bottom: 120,
        left: 20,
        right: 20,
        top: 120,
      },
    },
    stageString: {
      [ApplicationStage.NEW_APPLICATION]: $localize`New`,
      [ApplicationStage.UNDERWRITING]: $localize`Underwriting`,
      [ApplicationStage.ADJUDICATION]: $localize`Adjudication`,
      [ApplicationStage.DEAL_ACCEPTANCE]: $localize`Deal Acceptance`,
      [ApplicationStage.DOCUMENT_REVIEW]: $localize`Committed`,
      [ApplicationStage.OPERATIONS_FULFILLMENT]: $localize`Operations Fulfillment`,
      [ApplicationStage.PRESENT_DEAL]: $localize`Present Deal`,
      [ApplicationStage.PROPOSE_DEAL]: $localize`Propose Deal`,
      [ApplicationStage.DEAL_SIGNED]: $localize`Fulfillment`,
      [ApplicationStage.COMPLIANCE]: $localize`Compliance`,
      [ApplicationStage.FINAL_APPROVAL]: $localize`Final Approval`,
      [ApplicationStage.PRE_FUND]: $localize`Pre-Fund`,
      [ApplicationStage.LAWYER_INSTRUCTED]: $localize`Instructing`,
      [ApplicationStage.FINAL_REVIEW]: $localize`Funding`,
      [ApplicationStage.FUNDED]: $localize`Funded`,
      [ApplicationStage.DECLINED]: $localize`Cancelled/Declined`,
      [ApplicationStage.FORECLOSURE]: $localize`Foreclosure`,
      [ApplicationStage.PAID_OUT]: $localize`Paid Out`,
      [ApplicationStage.PAST_DUE_UNDER_90]: $localize`Past due < 90 days`,
      [ApplicationStage.PAST_DUE_OVER_90]: $localize`Past Due > 90 days`,
      [ApplicationStage.PAID_IN_FULL]: $localize`Paid in Full`,
      [ApplicationStage.RENEWAL]: $localize`Renewal`,
    },
    tenantStages: [
      ApplicationStage.NEW_APPLICATION,
      ApplicationStage.UNDERWRITING,
      ApplicationStage.DOCUMENT_REVIEW,
      ApplicationStage.DEAL_SIGNED,
      ApplicationStage.LAWYER_INSTRUCTED,
      ApplicationStage.FINAL_REVIEW,
      ApplicationStage.FUNDED,
      ApplicationStage.DECLINED,
    ],
  },
  'Rocket': {
    documentPackageService: DocumentPackageServiceType.Default,
    scoreProcessingType: ScoreProcessingType.Lender,
    pdfOptions: {
      format: 'A4',
      displayHeaderFooter: true,
      printBackground: true,
      margin: {
        bottom: 160,
        left: 20,
        right: 20,
        top: 110,
      },
    },
    stageString: {
      [ApplicationStage.NEW_APPLICATION]: $localize`New Application`,
      [ApplicationStage.UNDERWRITING]: $localize`Underwriting`,
      [ApplicationStage.ADJUDICATION]: $localize`Adjudication`,
      [ApplicationStage.DEAL_ACCEPTANCE]: $localize`Deal Acceptance`,
      [ApplicationStage.DOCUMENT_REVIEW]: $localize`Document Review`,
      [ApplicationStage.OPERATIONS_FULFILLMENT]: $localize`Operations Fulfillment`,
      [ApplicationStage.PRESENT_DEAL]: $localize`Present Deal`,
      [ApplicationStage.PROPOSE_DEAL]: $localize`Propose Deal`,
      [ApplicationStage.DEAL_SIGNED]: $localize`Deal Signed`,
      [ApplicationStage.COMPLIANCE]: $localize`Compliance`,
      [ApplicationStage.FINAL_APPROVAL]: $localize`Final Approval`,
      [ApplicationStage.PRE_FUND]: $localize`Pre-Fund`,
      [ApplicationStage.LAWYER_INSTRUCTED]: $localize`Closer Review/Lawyer Instructed`,
      [ApplicationStage.FINAL_REVIEW]: $localize`Final Review`,
      [ApplicationStage.FUNDED]: $localize`Funded`,
      [ApplicationStage.DECLINED]: $localize`Declined/Cancelled`,
      [ApplicationStage.FORECLOSURE]: $localize`Foreclosure`,
      [ApplicationStage.PAID_OUT]: $localize`Paid Out`,
      [ApplicationStage.PAST_DUE_UNDER_90]: $localize`Past due < 90 days`,
      [ApplicationStage.PAST_DUE_OVER_90]: $localize`Past Due > 90 days`,
      [ApplicationStage.PAID_IN_FULL]: $localize`Paid in Full`,
      [ApplicationStage.RENEWAL]: $localize`Renewal`,
    },
    tenantStages: [
      ApplicationStage.NEW_APPLICATION,
      ApplicationStage.UNDERWRITING,
      ApplicationStage.DOCUMENT_REVIEW,
      ApplicationStage.FINAL_APPROVAL,
      ApplicationStage.PRE_FUND,
      ApplicationStage.LAWYER_INSTRUCTED,
      ApplicationStage.FUNDED,
      ApplicationStage.DECLINED,
      ApplicationStage.PAST_DUE_UNDER_90,
      ApplicationStage.PAST_DUE_OVER_90,
      ApplicationStage.PAID_IN_FULL,
      ApplicationStage.RENEWAL,
    ],
    finalStages: [
      ApplicationStage.PAST_DUE_UNDER_90,
      ApplicationStage.PAST_DUE_OVER_90,
      ApplicationStage.PAID_IN_FULL,
      ApplicationStage.RENEWAL,
    ],
  },
  'MCU': {
    documentPackageService: DocumentPackageServiceType.Default,
    scoreProcessingType: ScoreProcessingType.Lender,
    pdfOptions: {
      format: 'Legal',
      displayHeaderFooter: true,
      printBackground: true,
      margin: {
        bottom: 100,
        left: 40,
        right: 40,
        top: 50,
      },
    },
    stageString: {
      [ApplicationStage.NEW_APPLICATION]: $localize`Application`,
      [ApplicationStage.UNDERWRITING]: $localize`Underwriting`,
      [ApplicationStage.ADJUDICATION]: $localize`Adjudication`,
      [ApplicationStage.DEAL_ACCEPTANCE]: $localize`Deal Acceptance`,
      [ApplicationStage.DOCUMENT_REVIEW]: $localize`Document Review`,
      [ApplicationStage.OPERATIONS_FULFILLMENT]: $localize`Operations Fulfillment`,
      [ApplicationStage.PRESENT_DEAL]: $localize`Present Deal`,
      [ApplicationStage.PROPOSE_DEAL]: $localize`Propose Deal`,
      [ApplicationStage.DEAL_SIGNED]: $localize`Deal Signed`,
      [ApplicationStage.COMPLIANCE]: $localize`Compliance`,
      [ApplicationStage.FINAL_APPROVAL]: $localize`Final Approval`,
      [ApplicationStage.PRE_FUND]: $localize`Pre-Fund`,
      [ApplicationStage.LAWYER_INSTRUCTED]: $localize`Closer Review/Lawyer Instructed`,
      [ApplicationStage.FINAL_REVIEW]: $localize`Final Review`,
      [ApplicationStage.FUNDED]: $localize`Funded`,
      [ApplicationStage.DECLINED]: $localize`Declined/Cancelled`,
      [ApplicationStage.FORECLOSURE]: $localize`Foreclosure`,
      [ApplicationStage.PAID_OUT]: $localize`Paid Out`,
      [ApplicationStage.PAST_DUE_UNDER_90]: $localize`Past due < 90 days`,
      [ApplicationStage.PAST_DUE_OVER_90]: $localize`Past Due > 90 days`,
      [ApplicationStage.PAID_IN_FULL]: $localize`Paid in Full`,
      [ApplicationStage.RENEWAL]: $localize`Renewal`,
      [ApplicationStage.OPEN]: $localize`Open`,
      [ApplicationStage.WAITING_FOR_SIGNATURES]: $localize`Waiting for Signatures`,
      [ApplicationStage.READY_FOR_UPLOAD]: $localize`Ready for Upload`,
      [ApplicationStage.QUEUED_FOR_UPLOAD]: $localize`Queued for Upload`,
      [ApplicationStage.UPLOADED_COMPLETED]: $localize`Uploaded Completed`,
      [ApplicationStage.CANCELED]: $localize`Canceled`,
    },
    tenantStages: [
      ApplicationStage.NEW_APPLICATION,
      ApplicationStage.UNDERWRITING,
      ApplicationStage.ADJUDICATION,
      ApplicationStage.DEAL_ACCEPTANCE,
      ApplicationStage.DOCUMENT_REVIEW,
      ApplicationStage.OPERATIONS_FULFILLMENT,
      ApplicationStage.FUNDED,
      ApplicationStage.DECLINED,
    ],
    tenantStagesServicing: [
      ApplicationStage.OPEN,
      ApplicationStage.WAITING_FOR_SIGNATURES,
      ApplicationStage.READY_FOR_UPLOAD,
      ApplicationStage.QUEUED_FOR_UPLOAD,
      ApplicationStage.UPLOADED_COMPLETED,
      ApplicationStage.CANCELED,
    ],
  },

  'default': {
    documentPackageService: DocumentPackageServiceType.Default,
    scoreProcessingType: ScoreProcessingType.Lender,
    tenantStages: [
      ApplicationStage.NEW_APPLICATION,
      ApplicationStage.UNDERWRITING,
      ApplicationStage.DOCUMENT_REVIEW,
      ApplicationStage.LAWYER_INSTRUCTED,
      ApplicationStage.FINAL_REVIEW,
      ApplicationStage.FUNDED,
      ApplicationStage.DECLINED,
    ],
    tenantStagesServicing: [
      ApplicationStage.OPEN,
      ApplicationStage.WAITING_FOR_SIGNATURES,
      ApplicationStage.READY_FOR_UPLOAD,
      ApplicationStage.QUEUED_FOR_UPLOAD,
      ApplicationStage.UPLOADED_COMPLETED,
      ApplicationStage.CANCELED,
    ],
    stageConfig: {
      [ApplicationStage.LAWYER_INSTRUCTED]: {
        generateDocuments: {
          hideCommitment: true,
          conditionsTitle: 'Funding Conditions',
          buildPackageTitle: 'Build Instruction Package',
        },
      },
      [ApplicationStage.FINAL_REVIEW]: {
        generateDocuments: {
          hideCommitment: true,
          conditionsTitle: 'Funding Conditions',
          buildPackageTitle: 'Build Instruction Package',
        },
      },
      [ApplicationStage.FUNDED]: {
        generateDocuments: {
          hideCommitment: true,
          conditionsTitle: 'Funding Conditions',
          buildPackageTitle: 'Build Instruction Package',
        },
      },
    },
  },
};

export const environment = {
  features: {
    config: config,
  },
  build: {
    codeBuildBuildNumber: 'CODEBUILD_BUILD_NUMBER',
    codeBuildSourceVersion: 'CODEBUILD_SOURCE_VERSION',
  },
  release: {
    sentry: 'SENTRY_RELEASE_VERSION',
  },
  pendo: undefined,
  ezidoxNameOverride: 'DM',
  ezidoxStatusRecordOverride: EzidoxStatusLocalizationOptions.DEFAULT,
  incrementTypeLocalizationOverride: IncrementTypeLocalizationOptions.DEFAULT,
};
