import { PropertyAddressDetails } from '../../../property';
import {
  FCTServiceType,
  FCTSecondaryServiceType,
  FCTAddOnProduct,
  FCTRentalType,
} from '../../enums';
import { FCTBorrowerInfo } from '../borrowerInfo';
import { FCTDealInfo } from '../dealInfo';
import { FCTLenderAttributes } from '../lenderAttributes';
import { FCTLenderInfo } from '../lenderInfo';
import { FCTPropertyInfo } from '../propertyInfo';

export interface FCTRequest {
  ServiceType: FCTServiceType;
  SecondaryServiceType?: FCTSecondaryServiceType;
  AddOnProduct?: FCTAddOnProduct[];
  PropertyInfoDetails: FCTPropertyInfo;
  BorrowerInfoDetails: FCTBorrowerInfo[];
  LenderInfoDetails: FCTLenderInfo;
  DealInfoDetails: FCTDealInfo;
  LenderAttributes?: FCTLenderAttributes;
}

export interface FCTCreateRequestCommand {
  propertyId: string;
  propertyAddressExpanded: FCTPropertyAddressExpanded;
  fctProduct: FCTServiceType;
  rushFlag: boolean;
  marketRentIndicator: boolean;
  rentalType: FCTRentalType | null;
  tenantCode: string;
  userEmail: string;
}

export interface FCTPropertyAddressExpanded extends PropertyAddressDetails {
  rollNumber?: string;
}
