import { CanadianProvinceCode, RateType, TermType } from './enums';

export enum IncrementCategory {
  STANDARD_INCREMENT = 'STANDARD_INCREMENT',
  NON_INCOME_QUALIFYING = 'NON_INCOME_QUALIFYING',
  HORIZON_CREDIT_PROGRAM = 'HORIZON_CREDIT_PROGRAM',
  BRIDGE_LOAN = 'BRIDGE_LOAN',
  LOAN_TO_INCOME_RATIO = 'LOAN_TO_INCOME_RATIO',
}

export enum IncrementType {
  REFINANCE = 'REFINANCE',
  AMORTIZATION_GREATER_THAN_25_YEARS = 'AMORTIZATION_GREATER_THAN_25_YEARS',
  SECOND_MORTGAGE = 'SECOND_MORTGAGE',
  NON_OWNER_OCCUPIED = 'NON_OWNER_OCCUPIED',
  STRESS_TEST = 'STRESS_TEST',
  BEACON_550_TO_599 = 'BEACON_550_TO_599',
  BEACON_600_TO_679 = 'BEACON_600_TO_679',
  BEACON_640_TO_679 = 'BEACON_640_TO_679',
  BEACON_600_TO_639 = 'BEACON_600_TO_639',
  BEACON_680_TO_749 = 'BEACON_680_TO_749',
  BEACON_GREATER_THAN_EQUAL_TO_750 = 'BEACON_GREATER_THAN_EQUAL_TO_750',
  BEACON_LESS_THAN_EQUAL_TO_549 = 'BEACON_LESS_THAN_EQUAL_TO_549',
  LTI_GREATER_THAN_MAX = 'LTI_GREATER_THAN_MAX',

  // EQ Increment Types
  FICO_SCORE_GREATER_THAN_EQUAL_TO_575 = 'FICO_SCORE_GREATER_THAN_EQUAL_TO_575',
  FICO_SCORE_LESS_THAN_575 = 'FICO_SCORE_LESS_THAN_575',
  LTV_MORE_THAN_7_PERCENT = 'LTV_MORE_THAN_7_PERCENT',
  LOAN_MORE_THAN_500000$ = 'LOAN_MORE_THAN_500000$',
  LOAN_MORE_THAN_750000$ = 'LOAN_MORE_THAN_750000$',
  LOAN_MORE_THAN_1000000$ = 'LOAN_MORE_THAN_1000000$',
  LOAN_MORE_THAN_2000000$ = 'LOAN_MORE_THAN_2000000$',
  PEARL_OR_AMBER_REFINANCE = 'PEARL_OR_AMBER_REFINANCE',
  REFINANCE_WITH_ETO_LESS_THAN_75000$ = 'REFINANCE_WITH_ETO_LESS_THAN_75000$',
  LTV_MORE_THAN_75_EXCLUDED_CAPPED_FEES = 'LTV_MORE_THAN_75_EXCLUDED_CAPPED_FEES',
  WITHIN_PAST_6_TO_12_MONTHS = 'WITHIN_PAST_6_TO_12_MONTHS',
  PROPERTIES_OWNED_GREATER_THAN_OR_EQUAL_TO_5 = 'PROPERTIES_OWNED_GREATER_THAN_OR_EQUAL_TO_5',
  EXCEEDS_65_PERCENT = 'EXCEEDS_65_PERCENT',
  ANY_CLASSIFICATION = 'ANY_CLASSIFICATION',
  NON_OWNER_OCCUPIED_ATLANTIC_TIER_1 = 'NON_OWNER_OCCUPIED_ATLANTIC_TIER_1',
  NON_OWNER_OCCUPIED_ATLANTIC_TIER_2_AND_3 = 'NON_OWNER_OCCUPIED_ATLANTIC_TIER_2_AND_3',
  ['RATIO_3.5_TO_4.49_TIMES'] = 'RATIO_3.5_TO_4.49_TIMES',
  ['RATIO_4.5_PLUS_TIMES'] = 'RATIO_4.5_PLUS_TIMES',
}

export enum IncrementMortgageTerm {
  SIX_MONTHS = 6,
  ONE_YEAR = 12,
  TWO_YEAR = 24,
  THREE_YEAR = 36,
  FOUR_YEAR = 48,
  FIVE_YEAR = 60,
  SEVEN_YEAR = 84,
  TEN_YEAR = 120,
}

export interface Increment {
  id: string;
  type: IncrementType;
  category: IncrementCategory;
  amount: number;
  incomeStatus?: IncrementIncomeStatus;
  mortgageTerm?: IncrementMortgageTerm; // number of months
  mortgageTermType?: TermType;
  mortgageRateType?: RateType;
  ratioCharacteristics?: IncrementRatioCharacteristics;
  provinces?: CanadianProvinceCode[];
}

export interface MortgageIncrement extends Increment {
  isChecked: boolean;
}

export enum IncrementIncomeStatus {
  QUALIFYING_INCOME = 'QUALIFYING_INCOME',
  NON_QUALIFYING_INCOME = 'NON_QUALIFYING_INCOME',
}

export enum IncrementRatioCharacteristics {
  ALL_LTV = 'ALL_LTV',
}

export interface Decrement {
  id: string;
  name: string;
  status: DecrementStatus;
  amount: number;
}

export enum DecrementStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface MortgageDecrement extends Decrement {
  isChecked: boolean;
}

export enum DiscretionStatus {
  VALID = 'VALID',
  NEED_APPROVAL = 'NEED_APPROVAL',
}
