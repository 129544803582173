import {
  ApplicationPurposeType,
  ApplicationStage,
  MortgageKey,
  MortgageState,
  MortgageStatus,
  PipelineApplication,
  PriorityType,
} from '@fundmoreai/models';
import { DateRangeFilter } from './model';
import {
  MortgageStatusOrderRecord,
  NoMortgageStatusKey,
  NoMortgageStatusOption,
} from 'src/app/shared/enum-records';

export function filterByStage(
  application: PipelineApplication,
  stageFilter: ApplicationStage[] | null | undefined,
) {
  if (!stageFilter || stageFilter.length === 0 || !application.currentStage) {
    return true;
  }

  return stageFilter.includes(application.currentStage);
}

export function filterByMortgageStateStatus(
  application: PipelineApplication,
  mortgageStateStatusFilter:
    | (NoMortgageStatusKey | MortgageStatus | MortgageState)[]
    | null
    | undefined,
) {
  // ANY filter
  if (!mortgageStateStatusFilter || mortgageStateStatusFilter.length === 0) {
    return true;
  }

  for (const mortgage of application.requestedMortgages) {
    if (
      mortgageStateStatusFilter.includes(NoMortgageStatusOption.key) &&
      !mortgage.state &&
      (!mortgage.status || mortgage.status.length === 0)
    ) {
      return true;
    }

    if (mortgage.state) {
      if (mortgageStateStatusFilter.includes(mortgage.state)) {
        return true;
      }
    } else {
      const latestStatus = (mortgage.status ?? [])
        .filter((s) => !!s)
        .sort((a, b) => MortgageStatusOrderRecord[b] - MortgageStatusOrderRecord[a])
        .find((s) => s);

      if (latestStatus && mortgageStateStatusFilter.includes(latestStatus)) {
        return true;
      }
    }
  }
  return false;
}

export function filterByPriority(
  application: PipelineApplication,
  priorityFilter: PriorityType[] | null | undefined,
) {
  if (!priorityFilter || priorityFilter.length === 0) {
    return true;
  }

  if (!application.priority) {
    return false;
  }

  return priorityFilter.includes(application.priority);
}

export function filterByPurpose(
  application: PipelineApplication,
  purposeFilter: ApplicationPurposeType[] | null | undefined,
) {
  if (!purposeFilter || purposeFilter.length === 0) {
    return true;
  }

  if (!application.purpose) {
    return false;
  }

  return purposeFilter.includes(application.purpose);
}

export function filterByProduct(
  application: PipelineApplication,
  productFilter: string[] | null | undefined,
) {
  if (!productFilter || productFilter.length === 0) {
    return true;
  }

  if (
    !application.applicationAdvancedProductIds ||
    application.applicationAdvancedProductIds.length === 0
  ) {
    return false;
  }

  return application.applicationAdvancedProductIds.some(
    (aap) => aap && productFilter.includes(aap),
  );
}

export function filterByAnyMortgageDate(
  application: PipelineApplication,
  dateSelector: MortgageKey.CLOSING_DATE | MortgageKey.DEADLINE | MortgageKey.COF_DATE,
  dateFilter: DateRangeFilter | null | undefined,
) {
  let isAnyDateInRage = false;

  for (const mortgage of application.requestedMortgages || []) {
    isAnyDateInRage = isAnyDateInRage || filterByDate(mortgage[dateSelector], dateFilter);
  }

  return isAnyDateInRage;
}

export function filterByDate(
  dateString: string | null | undefined,
  dateFilter: DateRangeFilter | null | undefined,
) {
  // If no date filter or filter has neither start nor end date, include all dates
  if (!dateFilter || (!dateFilter.startDate && !dateFilter.endDate)) {
    return true;
  }

  const date: Date | null = dateString ? new Date(dateString) : null;

  if (!date) {
    return false;
  }

  const startDate = dateFilter.startDate ? new Date(dateFilter.startDate) : null;
  const endDate = dateFilter.endDate ? new Date(dateFilter.endDate) : null;

  // If there is no endDate, only compare with startDate
  if (!endDate) {
    return startDate ? date >= startDate : true;
  }

  // Adjust endDate to be inclusive by adding one day
  const endDayDate = new Date(endDate);
  endDayDate.setDate(endDayDate.getDate() + 1);

  return (!startDate || date >= startDate) && date < endDayDate;
}
