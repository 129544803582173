export const phoneNumberRegexString =
  '^[+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}(\\.\\d+)?$';
export const phoneNumberRegex = new RegExp(phoneNumberRegexString);

/**
 * Function to test if a phone number matches the regex
 * @param phoneNumber - The phone number to test
 * @returns boolean - true if the phone number is valid, false otherwise
 */
export function isValidPhoneNumber(phoneNumber: string): boolean {
  return phoneNumberRegex.test(phoneNumber);
}
