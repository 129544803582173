import { ApplicationDocument } from '../../../../applicationDocument';
import { MMSDocumentSource, MMSFileFormat } from '../../../enums';
import { MMSDocumentUploadStatus } from '../../../fctMMSClosingInstruction';

export interface MMSSubmitDocumentResponse {
  documentId: number;
  version: string;
  content: string; // base64 encoded
  fileFormat: MMSFileFormat;
  source: MMSDocumentSource;
  submissionDate: string; // date-time
}

export interface MMSGetDocumentResponse {
  documentId: number;
  documentType: string; // should be found in MMSDocumentConfig table
  version: number;
  documentFormat: MMSFileFormat;
  source: MMSDocumentSource;
  submissionDate: string; // date-time
}

export interface MMSGetDocumentByIdResponse {
  documentId: number;
  documentType: string; // should be found in MMSDocumentConfig table
  version: string;
  content: string; // base64 encoded
  fileFormat: MMSFileFormat;
  documentName: string; // same as documentType
  source: MMSDocumentSource;
  submissionDate: string; // date-time
}

export interface FundmoreMMSDocument {
  id: string;
  applicationDocumentId?: string | null;
  applicationId: string;
  mmsRequestId: string;
  mmsDocumentId?: number | null;
  mmsDocumentType?: number | null;
  mmsDocumentVersion?: string | null;
  mmsFileFormat: MMSFileFormat;
  mmsSubmissionDate?: string | null;
  mmsDocumentSource?: MMSDocumentSource | null;
  status: MMSDocumentUploadStatus;
  createdAt: string;
  applicationDocument: ApplicationDocument;
  applicationConditionDocumentFileId?: string;
}
