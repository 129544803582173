import { SagenFieldErrorMessage } from './sagenInsuranceQuoteResponse';

export interface SagenFieldMetadata {
  field: string;
  name?: string;
  value?: string;
  required?: boolean;
  isMissing?: boolean;
  options?: string;
  message?: string;
}

export interface ApplicationInfo {
  metadata: SagenFieldMetadata[];
}

export interface BorrowerInfo {
  borrowerFirstName: string;
  borrowerLastName: string;
  borrowerType: string;
  metadata: SagenFieldMetadata[];
}

export interface PropertyInfo {
  metadata: SagenFieldMetadata[];
}

export interface LoanInfo {
  metadata: SagenFieldMetadata[];
}

export interface ApplicationData {
  forceSagenUnderwriterReview: boolean;
  notPrivateSale: boolean;
  certiFaxInd: boolean;
  genworthSpecialProgramCode?: string;
  advanceType?: SagenAdvanceType;
  insuranceIndicator?: SagenInsuranceIndicator;
  comments: string[];
  progressAdvanceType?: string;
  inspectionFlag: boolean;
  genworthReferenceNumber?: string;
}

export interface SagenApplicationData {
  applicationInfo: ApplicationInfo;
  borrowerInfo: BorrowerInfo[];
  propertyInfo: PropertyInfo;
  loanInfo: LoanInfo;
  fieldErrorMessages: SagenFieldErrorMessage[];
}

export interface SagenInsuranceRequest {
  applicationData: ApplicationData;
  sagenApplicationData: SagenApplicationData;
}

export enum SagenInsuranceIndicator {
  I = 'I', // Mortgage Insurance request
  // U = 'U', // Low-Ratio Underwriting request - DEPRECATED
  B = 'B', // LRU+ request / Bulk Insurance Pre-Approval
}

export enum SagenAdvanceType {
  MultipleAdvance = '01',
  SingleAdvance = '02',
}

export enum SagenServiceType {
  FullService = 'F',
  StandardService = 'S',
}
