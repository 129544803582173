import 'reflect-metadata';
import { injectable } from 'tsyringe';

import {
  ApplicationCalculation,
  FeeCalculation,
  RequestedMortgageCalculation,
  SubjectPropertyCalculation,
} from '@fundmoreai/models';

@injectable()
export class ApplicationCalculationModel implements ApplicationCalculation {
  applicationId: string;
  tenantId: string;
  subjectProperty: SubjectPropertyCalculation;
  requestedMortgages: RequestedMortgageCalculation[] | null;
  fees: FeeCalculation[] | null;
}
