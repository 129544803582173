import { ApplicationStage } from './application';

export interface WidgetViewPermission {
  key: WidgetViewKey;
  permissions?: {
    [stage: ApplicationStage | string]: ViewPermission | undefined;
  };
  subWidgets?: WidgetViewPermission[];
}

export interface ViewPermission {
  canUpdate?: boolean;
  canAdd?: boolean;
  canDelete?: boolean;
}

export type WidgetViewKey = 'downPayment' | 'income' | 'financialLiability';
