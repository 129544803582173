import { MMSDealSource } from '../../enums';
import { MMSBridgeLoan } from '../bridgeLoan';
import { MMSClosingConfirmation } from '../closingConfirmation';
import { MMSContact } from '../contact';
import { MMSGuarantor } from '../guarantor';
import { MMSInterestRate } from '../interestRate';
import { MMSLawyer } from '../lawyer';
import { MMSMortgage } from '../mortgage';
import { MMSMortgageDates } from '../mortgageDates';
import { MMSMortgagor } from '../mortgagor';
import { MMSPayment } from '../payment';
import { MMSProperty } from '../property';
import { MMSSaleProperty } from '../saleProperty';

export interface MMSRequest {
  dealSource: MMSDealSource;
  lenderReferenceNumber?: string; //required
  fctURN?: string; // Required only for UPDATE
  lenderName?: string; // required
  lenderCode?: string; // required
  lenderRemarks?: string; // required
  lenderContacts?: MMSContact[]; // max 4 required
  brokerContact?: MMSContact[]; // max 4 required
  mortgagors?: MMSMortgagor[]; // required
  guarantors?: MMSGuarantor[]; // required
  property: MMSProperty;
  lawyer?: MMSLawyer; // required
  mortgage: MMSMortgage;
  payment?: MMSPayment;
  interestRate?: MMSInterestRate;
  mortgageDates?: MMSMortgageDates;
  bridgeLoan?: MMSBridgeLoan;
  saleProperty?: MMSSaleProperty;
  closingConfirmation?: MMSClosingConfirmation;
}
