import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AdvancedProduct,
  AdvancedProductPaymentChangeType,
  Application,
  MortgageKey,
  MortgageType,
  PreviewMortgageProductsTempChanges,
  PreviewProductResponse,
  SupportedCustomEntity,
} from '@fundmoreai/models';
import { Store } from '@ngxs/store';
import { Observable, forkJoin, of, switchMap, tap } from 'rxjs';
import { AdvancedProductService } from 'src/app/portal/advanced-products/advanced-product.service';
import { ApplyMortgageAdvancedProduct } from 'src/app/portal/advanced-products/advanced-product.state';
import { AppFeaturesState } from 'src/app/shared/app-features.state';
import { DialogsComponent } from '../../features/application/sidebar/dialogs/dialogs.component';
import { MortgagesV2State } from '../mortgages-v2/mortgages-v2.state';
import { CloseSidenav } from '../states/application.state';
import { LoadingEnd, LoadingStart } from 'src/app/core/loading.state';
import { FieldMetadataState } from '../../shared/custom-fields/field-metadata.state';
import { MortgageApplicationState } from '../mortgage-application.state';
import { UpdateRequestedMortgage } from '../mortgages-v2/mortgages-v2.actions';
import { RouterSelectors } from 'src/app/router-state/router.selectors';

@Injectable({
  providedIn: 'root',
})
export class PreviewApplyProductChangesService {
  constructor(
    private store: Store,
    private advancedProductService: AdvancedProductService,
    private zone: NgZone,
    private dialog: MatDialog,
  ) {}

  public handleMortgageProductPreviewDialog(
    preview: PreviewProductResponse,
    applicationId: string,
    mortgageAdvancedProductParam: { [key: string]: AdvancedProduct } | null = null,
    applyForMortgagesWithProductChanges = false,
    actionsToDispatch?: unknown[] | null,
    shouldCloseSidenav?: boolean | null,
  ) {
    const shouldDispatchAction = !!actionsToDispatch && actionsToDispatch.length > 0;

    const APPLYING = $localize`Applying this product will turn`;
    const FLIP_MODULE = $localize`Flip module`;
    const CONSTRUCTION_MODULE = $localize`Construction module`;
    const OFF = $localize`off`;
    const ON = $localize`on`;

    const isFlipModuleEnabled = this.store.selectSnapshot(AppFeaturesState.flipModuleEnabled);
    const isConstructionModuleEnabled = this.store.selectSnapshot(
      AppFeaturesState.isConstructionModuleEnabled,
    );
    const isServicingEnabled = this.store.selectSnapshot(RouterSelectors.servicing);
    const mortgages = isServicingEnabled
      ? this.store.selectSnapshot(MortgagesV2State.servicingMortgages)
      : this.store.selectSnapshot(MortgagesV2State.requestedMortgages);

    const FLIP_MODULE_TEXT = isFlipModuleEnabled
      ? `${FLIP_MODULE} ${preview.modules?.flipModule ? ON : OFF}`
      : '';
    const CONSTRUCTION_MODULE_TEXT = isConstructionModuleEnabled
      ? `${CONSTRUCTION_MODULE} ${preview.modules?.constructionModule ? ON : OFF}`
      : ``;
    const ADD_COMMA_IF_NEEDED = isFlipModuleEnabled && isConstructionModuleEnabled ? `, ` : '';

    const applyMessage =
      preview.modules && (isFlipModuleEnabled || isConstructionModuleEnabled)
        ? `${APPLYING} ${FLIP_MODULE_TEXT} ${ADD_COMMA_IF_NEEDED} ${CONSTRUCTION_MODULE_TEXT}.`
        : null;

    const message = [applyMessage, ...(preview.info ?? [])].filter((m) => m).join('\n\n');

    const paymentFrequencyRecord = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.MORTGAGE,
        MortgageKey.PAYMENT_FREQUENCY,
      ),
    );

    return this.zone.run(() => {
      let title;
      if (shouldDispatchAction) {
        title = $localize`Are you sure you want to commit changes and re-apply this product?`;
      } else {
        if (mortgageAdvancedProductParam) {
          title = $localize`Are you sure you want to apply this product?`;
        } else {
          title = $localize`Are you sure you want to re-apply this product?`;
        }
      }

      const df = this.dialog.open(DialogsComponent, {
        data: {
          title,
          message,
          buttonText: {
            yes: $localize`:@@action.apply:Apply`,
            no: $localize`:@@action.cancel:Cancel`,
          },
          declineModalClass: false,
        },
        minWidth: '400px',
        maxWidth: '400px',
      });

      const mortgageAdvancedProduct = mortgageAdvancedProductParam ?? {};

      return df.afterClosed().pipe(
        switchMap((result) => {
          if (!result) {
            return of(null);
          }

          const applicationIsConditionallyApproved = this.store.selectSnapshot(
            MortgageApplicationState.applicationIsConditionallyApproved,
          );
          const rateHoldActiveByMortgage = this.store.selectSnapshot(
            MortgagesV2State.rateHoldActiveByMortgage,
          );

          const mortgageIds = Object.keys(preview.paymentChangesPerMortgage);
          // for each mortgageId, open up another dialog to confirm the payment changes
          const paymentChangesDialogs = mortgageIds.map((mortgageId) => {
            if (
              preview.paymentChangesPerMortgage[mortgageId].changeType ===
              AdvancedProductPaymentChangeType.NONE
            ) {
              return of(false);
            }
            const paymentChanges = preview.paymentChangesPerMortgage[mortgageId];
            const product = mortgageAdvancedProduct[mortgageId];
            if (!applicationIsConditionallyApproved || !rateHoldActiveByMortgage[mortgageId]) {
              return of(false);
            }
            const alreadyAppliedProduct = this.store.selectSnapshot(
              MortgagesV2State.appliedProductByMortgage,
            )?.[mortgageId];

            if (
              (paymentChanges.adjustPaymentAmount.paymentAmount.previous || 0) <=
                (paymentChanges.adjustPaymentAmount.paymentAmount.new || 0) ||
              alreadyAppliedProduct?.id !== product?.id
            ) {
              return of(false);
            }

            // eslint-disable-next-line max-len
            const paymentChangesMessage = $localize`${
              paymentFrequencyRecord[paymentChanges.paymentFrequency]
            } payment will change for the selected product.\nProduct: ${
              product.name
            }\nNet Rate: <b>${paymentChanges.netRate.previous}%</b> → <b>${
              paymentChanges.netRate.new
            }%</b>\n\nCurrent ${paymentFrequencyRecord[paymentChanges.paymentFrequency]} Payment: ${
              paymentChanges.adjustPaymentAmount.paymentAmount.previous
            }\nNew ${paymentFrequencyRecord[paymentChanges.paymentFrequency]} Payment: ${
              paymentChanges.adjustPaymentAmount.paymentAmount.new
            }`;

            const dialogResponse$: Observable<boolean> = this.dialog
              .open(DialogsComponent, {
                data: {
                  title: $localize`Update Monthly Payment Amount`,
                  messageHTML: paymentChangesMessage,
                  buttonText: {
                    yes:
                      $localize`:@@action.keepAmount:Keep Amount: $` +
                      paymentChanges.adjustPaymentAmount.paymentAmount.previous,
                    no:
                      $localize`:@@action.updateAmount:Update Amount: $` +
                      paymentChanges.adjustPaymentAmount.paymentAmount.new,
                  },
                  declineModalClass: false,
                  modalIcon: 'update',
                  alignButtonsCenter: true,
                },
                minWidth: '500px',
                maxWidth: '500px',
              })
              .afterClosed();

            return dialogResponse$.pipe(
              tap((result) => {
                if (result === true) {
                  preview.paymentChangesPerMortgage[mortgageId].changeType =
                    AdvancedProductPaymentChangeType.ADJUST_AMORTIZATION;
                }
              }),
            );
          });

          return (paymentChangesDialogs.length ? forkJoin(paymentChangesDialogs) : of([])).pipe(
            switchMap(() => {
              return (
                shouldDispatchAction
                  ? this.store.dispatch([...actionsToDispatch]).pipe(
                      tap(() => {
                        if (shouldCloseSidenav) {
                          this.store.dispatch(new CloseSidenav());
                        }
                      }),
                    )
                  : of(null)
              ).pipe(
                switchMap(() => {
                  const mortgageToProduct = mortgages
                    // restring scope to mortgages in question
                    .filter(
                      (rm) =>
                        !mortgageAdvancedProductParam || !!mortgageAdvancedProductParam[rm.id],
                    )
                    .filter((rm) => rm.applicationAdvancedProduct?.advancedProductSnapshot)
                    .map((rm) => {
                      return [rm.id, rm.applicationAdvancedProduct?.advancedProductSnapshot.id];
                    });

                  if (!mortgageToProduct || mortgageToProduct.length === 0) {
                    return of(null);
                  }

                  return forkJoin(
                    mortgageToProduct.map(([mId, pId]) =>
                      forkJoin([
                        of(mId as string),
                        pId ? this.advancedProductService.getAdvancedProductById(pId) : of(null),
                      ]),
                    ),
                  );
                }),
                switchMap((mToP) => {
                  mToP?.forEach(([mortgageId, advancedProduct]) => {
                    if (!mortgageAdvancedProduct[mortgageId] && advancedProduct) {
                      if (applyForMortgagesWithProductChanges) {
                        const productHasChanges =
                          advancedProduct.updatedAt !==
                          mortgages.find((x) => x.id === mortgageId)?.applicationAdvancedProduct
                            ?.advancedProductSnapshot.updatedAt;

                        if (productHasChanges) {
                          mortgageAdvancedProduct[mortgageId] = advancedProduct;
                        }
                      } else {
                        mortgageAdvancedProduct[mortgageId] = advancedProduct;
                      }
                    }
                  });

                  return this.store.dispatch(
                    new ApplyMortgageAdvancedProduct(
                      mortgageAdvancedProduct,
                      preview.metadataForMortgage,
                      applicationId,
                      preview.diff,
                      preview.modules,
                      preview.info,
                      preview.paymentChangesPerMortgage,
                    ),
                  );
                }),
              );
            }),
          );
        }),
      );
    });
  }

  public handleProductErrorDialog(errors: string[]) {
    if (!errors || errors.length === 0) {
      return of(null);
    }
    const ERRORS = $localize`The following errors need to be resolved`;

    this.zone.run(() => {
      this.dialog.open(DialogsComponent, {
        data: {
          title: $localize`Unfortunately, the requested product could not be applied!`,
          message: `${ERRORS}: \n\n ${errors.join('\n\n')}`,
          buttonText: {
            no: $localize`:@@action.close:Close`,
          },
          declineModalClass: true,
        },
        minWidth: '400px',
        maxWidth: '400px',
      });
    });

    return of(null);
  }

  public handlePreviewResponseWithActionDispatch = (
    application: Application,
    applyForMortgagesWithProductChanges: boolean,
    actionsToDispatch?: unknown[] | null,
    previewProductBody?: PreviewMortgageProductsTempChanges | null,
  ) => {
    this.store.dispatch(new LoadingStart(this.constructor.name));

    const mortgageAdvancedProduct: { [key: string]: AdvancedProduct } = {};

    const requestedMortgages = application.Mortgages.filter((m) =>
      application.isServicing
        ? m.type === MortgageType.SERVICING
        : m.type === MortgageType.REQUESTED,
    );
    const mortgageToProduct = requestedMortgages
      .filter((rm) => rm.applicationAdvancedProduct?.advancedProductSnapshot)
      .map((rm) => {
        return [rm.id, rm.applicationAdvancedProduct?.advancedProductSnapshot.id];
      });

    if (!mortgageToProduct.length) {
      // no product applied on any mortgage
      this.store.dispatch(new LoadingEnd(this.constructor.name));

      return of(true);
    }

    const result = forkJoin(
      mortgageToProduct.map(([mId, pId]) =>
        forkJoin([
          of(mId as string),
          pId ? this.advancedProductService.getAdvancedProductById(pId) : of(null),
        ]),
      ),
    ).pipe(
      switchMap((mToP) => {
        mToP.forEach(([mortgageId, advancedProduct]) => {
          if (advancedProduct) {
            const productHasChanges =
              advancedProduct.updatedAt !==
              requestedMortgages.find((x) => x.id === mortgageId)?.applicationAdvancedProduct
                ?.advancedProductSnapshot.updatedAt;
            if (!applyForMortgagesWithProductChanges || productHasChanges) {
              mortgageAdvancedProduct[mortgageId] = advancedProduct;
            }
          }
        });

        return this.advancedProductService.getMortgageAdvancedProductPreview(
          mortgageAdvancedProduct,
          application.id,
          previewProductBody,
        );
      }),
      switchMap((preview) => {
        return this.handlePreviewResponse(
          preview,
          application.id,
          actionsToDispatch,
          applyForMortgagesWithProductChanges,
        );
      }),
    );
    return result;
  };

  public handlePreviewResponse = (
    preview: PreviewProductResponse,
    applicationId: string,
    actions?: unknown[] | null,
    applyForMortgagesWithProductChanges: boolean = false,
    shouldCloseSidenav: boolean | null = null,
  ) => {
    const isBlockApplyingProductsWhenWarningsEnabled = this.store.selectSnapshot(
      AppFeaturesState.isBlockApplyingProductsWhenWarningsEnabled,
    );

    this.store.dispatch(new LoadingEnd(this.constructor.name));
    if (
      (preview.errors && preview.errors.length > 0) ||
      (isBlockApplyingProductsWhenWarningsEnabled &&
        preview.warnings &&
        preview.warnings.length != 0)
    ) {
      return this.handleProductErrorDialog([
        ...new Set([...(preview?.warnings ?? []), ...(preview?.errors ?? [])]),
      ]);
    }

    const applicationSnapshot = this.store.selectSnapshot(MortgageApplicationState.application);

    const requestedMortgages = applicationSnapshot?.Mortgages?.filter((m) =>
      applicationSnapshot.isServicing
        ? m.type === MortgageType.SERVICING
        : m.type === MortgageType.REQUESTED,
    );
    const hasMortgageUpdateAction = actions?.some(
      (action) => action instanceof UpdateRequestedMortgage,
    );

    const allCreditTiersMatch = requestedMortgages
      .filter((rm) => rm.applicationAdvancedProduct?.metadata)
      .every(
        (mortgage) =>
          preview?.metadataForMortgage?.metadataPerMortgage?.[mortgage.id]?.creditTier?.id ===
          mortgage.applicationAdvancedProduct?.metadata?.creditTier?.id,
      );

    // If there are no changes on TERM/RATE
    // AND all credit tiers match (no changes on credit tier match)
    // then we save the application data changes and don't apply product
    if (!hasMortgageUpdateAction && allCreditTiersMatch) {
      if (!actions) {
        return of(true);
      }
      return this.store.dispatch([...actions]).pipe(
        tap(() => {
          if (shouldCloseSidenav) {
            this.store.dispatch(new CloseSidenav());
          }
        }),
      );
    }

    if (!preview.diff || this.isEmptyObject(preview.diff)) {
      return (actions ? this.store.dispatch([...actions]) : of(true)).pipe(
        switchMap(() =>
          this.store.dispatch(
            new ApplyMortgageAdvancedProduct(
              {},
              preview.metadataForMortgage,
              applicationId,
              preview.diff,
              preview.modules,
              preview.info,
              preview.paymentChangesPerMortgage,
            ),
          ),
        ),
        tap(() => {
          if (shouldCloseSidenav) {
            this.store.dispatch(new CloseSidenav());
          }
        }),
      );
    }

    return this.handleMortgageProductPreviewDialog(
      preview,
      applicationId,
      null,
      applyForMortgagesWithProductChanges,
      actions,
      shouldCloseSidenav,
    );
  };

  public isEmptyObject(obj: object) {
    for (const _ in obj) {
      return false;
    }
    return true;
  }
}
