export interface Summary {
  lti: CalculationResult;
  gds: CalculationResult;
  tds: CalculationResult;
  ltv: CalculationResult;
  qgds: CalculationResult;
  qtds: CalculationResult;
  bgds: CalculationResult;
  btds: CalculationResult;
  cLtv: CalculationResult;
  qualifyingRate: number;
  bocRate: number;
  ltvBySecurity?: {
    [securityId: string]: CalculationResult;
  };
  ltiBySecurity?: {
    [securityId: string]: CalculationResult;
  };
  apr?: number;
}

export interface CalculationResult {
  formula: string;
  items: CalculationItem[];
  result: number;
}

export interface CalculationItem {
  text: string;
  value?: string;
  type?: CalculationItemType;
  info?: string;
  subitems?: CalculationItem[];
}

export enum CalculationItemType {
  TEXT = 'Text',
  NUMBER = 'Number',
  CURRENCY = 'Currency',
  PERCENT = 'Percentage',
  DATE = 'Date',
  BOOLEAN = 'Boolean',
  DURATION_MONTHS = 'Duration Months',
  NUMBER_7DECIMALS = 'Number with 7 decimals',
}
