import { MMSMortgagorTitle, MMSMortgagorType } from '../enums';
import { MMSAddress } from './address';
import { MMSCommunication, MMSCompanyName, MMSIndividualName } from './contact';

export interface MMSMortgagor {
  mortgagorType?: MMSMortgagorType; //required
  title?: MMSMortgagorTitle;
  mortgagorName?: MMSIndividualName | MMSCompanyName; //required
  communication?: MMSCommunication; //required
  companyName?: string;
  mortgagorAddress?: MMSAddress; //required
  consentingSpouse?: boolean;
  spouseName?: MMSIndividualName;
  signatoryName?: MMSIndividualName;
  consentingSpouseGuarantor?: boolean;
}
