import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StandardRatesModel } from '../shared/model';
import { StandardRatesService } from '../shared/services/standard-rates.service';
import { InitializeStandardRates } from './standard-rates.actions';

@State<StandardRatesModel>({
  name: 'standardRates',
})
@Injectable()
export class StandardRatesState {
  constructor(private standardRatesService: StandardRatesService) {}

  @Selector() static standardRates(state: StandardRatesModel) {
    return state;
  }

  @Action(InitializeStandardRates)
  initialize(ctx: StateContext<StandardRatesModel>): Observable<StandardRatesModel> {
    return this.standardRatesService.getStandardRates().pipe(
      tap((standardRatesValues) => {
        ctx.setState(standardRatesValues);
      }),
    );
  }
}
