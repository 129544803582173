import { AssetType, ExcludeAssetFromCalculationReasonType } from './enums';

export enum FinancialAssetKey {
  ID = 'id',
  APPLICATION_ID = 'applicationId',
  ASSET = 'asset',
  CREATED_AT = 'createdAt',
  DESCRIPTION = 'description',
  VALUE = 'value',
  UPDATED_AT = 'updatedAt',
  USE_AS_DOWN_PAYMENT = 'useAsDownPayment',
  APPLICANT_FINANCIAL_ASSET = 'ApplicantFinancialAssets',
  PURCHASE_PRICE = 'purchasePrice',
  EXTERNAL_SEQUENCE_NUMBER = 'externalSequenceNumber',
  EXCLUDE_FROM_CALCULATION = 'excludeFromCalculation',
}

export interface ApplicantFinancialAssets {
  applicantId: string;
  financialAssetId: string;
}

export interface FinancialAsset {
  [FinancialAssetKey.ID]: string;
  [FinancialAssetKey.APPLICATION_ID]: string;
  [FinancialAssetKey.ASSET]: AssetType;
  [FinancialAssetKey.CREATED_AT]: string;
  [FinancialAssetKey.DESCRIPTION]: string;
  [FinancialAssetKey.VALUE]: number;
  [FinancialAssetKey.UPDATED_AT]: string;
  [FinancialAssetKey.USE_AS_DOWN_PAYMENT]: boolean;
  [FinancialAssetKey.APPLICANT_FINANCIAL_ASSET]: ApplicantFinancialAssets[];
  [FinancialAssetKey.PURCHASE_PRICE]: number;
  [FinancialAssetKey.EXTERNAL_SEQUENCE_NUMBER]: number | null;
  [FinancialAssetKey.EXCLUDE_FROM_CALCULATION]: ExcludeAssetFromCalculationReasonType | null;
}

export interface FinancialAssetV2
  extends Omit<
    FinancialAsset,
    | FinancialAssetKey.ASSET
    | FinancialAssetKey.DESCRIPTION
    | FinancialAssetKey.PURCHASE_PRICE
    | FinancialAssetKey.VALUE
  > {
  [FinancialAssetKey.ASSET]: AssetType | null;
  [FinancialAssetKey.DESCRIPTION]: string | null;
  [FinancialAssetKey.PURCHASE_PRICE]: number | null;
  [FinancialAssetKey.VALUE]: number | null;
}
