export interface MMSNoteSendRequest {
  note: string;
  sentDate: string; // date-time
}

export interface MMSNotesFetchResponse {
  notes: {
    note: string;
    datePublished: string; // date-time
    userName: string;
  }[];
}

export interface MMSNote {
  note: string;
  datePublished: string; // date-time
  datePublishedISOFormat: string;
  userName: string;
}
