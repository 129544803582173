import 'reflect-metadata';
import { inject, injectable } from 'tsyringe';

import { CalculationErrorCode, CalculationNumberResult } from '../result.calculation';
import { ISubjectPropertyCalculator } from './subject-property.calculator';
import { ITotalLoanAmountCalculator } from './total-loan-amount.calculator';
import { Finance } from '@fundmore/finance';
import { TYPES } from '../container/types';

export interface ILTVCalculator {
  getLTVValue(): CalculationNumberResult;
}

@injectable()
export class LTVCalculator implements ILTVCalculator {
  private ltvValue?: CalculationNumberResult;

  private subjectPropertyCalculator: ISubjectPropertyCalculator;
  private totalLoanAmountCalculator: ITotalLoanAmountCalculator;

  constructor(
    @inject(TYPES.ISubjectPropertyCalculatorToken)
    subjectPropertyCalculator: ISubjectPropertyCalculator,
    @inject(TYPES.ITotalLoanAmountCalculatorToken)
    totalLoanAmountCalculator: ITotalLoanAmountCalculator,
  ) {
    this.subjectPropertyCalculator = subjectPropertyCalculator;
    this.totalLoanAmountCalculator = totalLoanAmountCalculator;
  }

  private computeLTV(): CalculationNumberResult {
    const subjectPropertyValue = this.subjectPropertyCalculator.getPropertyValue();
    if (subjectPropertyValue === null || subjectPropertyValue === undefined) {
      return {
        value: null,
        hasError: true,
        errorCode: CalculationErrorCode.INVALID_SUBJECT_PROPERTY_VALUE,
      };
    }

    const subjectPropertyExistingMortgagesTotalBalance =
      this.subjectPropertyCalculator.getExistingMortgagesTotalBalance();

    const requestedMortgagesTotalLoanAmountWithoutPremium =
      this.totalLoanAmountCalculator.getApplicationTotalLoanAmountWithoutPremium();

    const ltvValue = new Finance().computeLTV(
      requestedMortgagesTotalLoanAmountWithoutPremium +
        subjectPropertyExistingMortgagesTotalBalance,
      subjectPropertyValue,
    );

    return {
      value: ltvValue,
      hasError: false,
      errorCode: null,
    };
  }

  public getLTVValue(): CalculationNumberResult {
    if (this.ltvValue === undefined) {
      this.ltvValue = this.computeLTV();
    }
    return this.ltvValue;
  }
}
