import { Mortgage } from '@fundmoreai/models';
import { injectable } from 'tsyringe';

export interface IBlendedRateRenewalCalculationModel {
  currentMortgage: Mortgage;
  newMortgage: Mortgage;
}

@injectable()
export class BlendedRateRenewalCalculationModel implements IBlendedRateRenewalCalculationModel {
  newMortgage: Mortgage;
  currentMortgage: Mortgage;
}
