import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DefaultFee } from './model';

@Injectable({
  providedIn: 'root',
})
export class FeesService {
  constructor(private http: HttpClient) {}

  getDefaultFees(): Observable<DefaultFee[]> {
    return this.http.get<DefaultFee[]>(`${environment.api_url}/applicationDefaultFees`);
  }

  addDefaultFee(fee: Partial<DefaultFee>): Observable<DefaultFee> {
    return this.http.post<DefaultFee>(`${environment.api_url}/applicationDefaultFees`, fee);
  }

  updateDefaultFee(feeId: string, fee: Partial<DefaultFee>): Observable<DefaultFee> {
    return this.http.patch<DefaultFee>(
      `${environment.api_url}/applicationDefaultFees/${feeId}`,
      fee,
    );
  }

  deleteDefaultFee(feeId: string) {
    return this.http.delete<void>(`${environment.api_url}/applicationDefaultFees/${feeId}`);
  }
}
