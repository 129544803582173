export class Finance {
  public computeLTV(totalLoanAmount: number, propertyValue: number): number {
    if (propertyValue <= 0) {
      throw new Error('Property value must be greater than 0');
    }

    const ltvValue = (totalLoanAmount / propertyValue) * 100;

    return ltvValue;
  }
}
