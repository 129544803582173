import 'reflect-metadata';
import { inject, injectable } from 'tsyringe';
import { PayoffPaydownType, SubjectPropertyCalculation } from '@fundmoreai/models';
import { ApplicationCalculationModel } from '../models/application-calculation.model';

export interface ISubjectPropertyCalculator {
  getPropertyValue(): number | null;
  getExistingMortgagesTotalBalance(): number;
}

@injectable()
export class SubjectPropertyCalculator implements ISubjectPropertyCalculator {
  private property: SubjectPropertyCalculation;
  private calculatedPropertyValue?: number | null;
  private calculatedTotalExistingMortgagesBalance?: number;

  constructor(
    @inject(ApplicationCalculationModel)
    application: Pick<ApplicationCalculationModel, 'subjectProperty'>,
  ) {
    this.property = application.subjectProperty;
  }

  private computePropertyValue(): number | null {
    if (!this.property.estimatedValue && !this.property.purchasePrice) {
      return null;
    }

    if (!this.property.estimatedValue) {
      return this.property.purchasePrice;
    }

    if (!this.property.purchasePrice) {
      return this.property.estimatedValue;
    }

    return Math.min(this.property.estimatedValue, this.property.purchasePrice);
  }

  private computeExistingMortgagesTotalBalance(): number {
    if (!this.property.existingMortgages || this.property.existingMortgages.length === 0) {
      return 0;
    }

    const mortgages = this.property.existingMortgages
      .filter(
        (mortgage) => !mortgage.payoffPaydown || mortgage.payoffPaydown === PayoffPaydownType.NONE,
      )
      .filter((mortgage) => mortgage.mortgageBalance);

    return mortgages.reduce((acc, mortgage) => acc + (mortgage.mortgageBalance ?? 0), 0);
  }

  public getPropertyValue(): number | null {
    if (this.calculatedPropertyValue === undefined) {
      this.calculatedPropertyValue = this.computePropertyValue();
    }
    return this.calculatedPropertyValue;
  }

  public getExistingMortgagesTotalBalance(): number {
    if (this.calculatedTotalExistingMortgagesBalance === undefined) {
      this.calculatedTotalExistingMortgagesBalance = this.computeExistingMortgagesTotalBalance();
    }
    return this.calculatedTotalExistingMortgagesBalance;
  }
}
