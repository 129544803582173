import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Mortgage } from '../shared';
import { environment } from '../../environments/environment';
import {
  DatesProcessingType,
  MortgageCalculationAutomationSettings,
  RateType,
} from '@fundmoreai/models';
import { AppFeaturesState } from '../shared/app-features.state';
import { ForceDateRecalculationParams, FundmoreCalculator } from '@fundmoreai/calculator';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class MortgagesServices {
  constructor(private store: Store, private http: HttpClient) {}

  getMortgages(applicationId: string): Observable<Mortgage[]> {
    return this.http.get<Mortgage[]>(
      `${environment.api_url}/applications/${applicationId}/mortgages`,
    );
  }

  postMortgages(applicationId: string, mortgages: Mortgage[]): Observable<Mortgage[]> {
    const mortgagePayload = mortgages.map((m) => ({
      ...m,
      loanNumber: undefined,
      expandedLoanNumber: undefined,
    }));
    return this.http.post<Mortgage[]>(
      `${environment.api_url}/applications/${applicationId}/mortgages`,
      mortgagePayload,
    );
  }

  patchMortgage(
    applicationId: string,
    mortgageId: string,
    mortgage: Mortgage,
    hasValueChanged?: { [key: string]: boolean },
  ): Observable<Mortgage> {
    if (hasValueChanged?.netRate) {
      return this.http.patch<Mortgage>(
        `${environment.api_url}/applications/${applicationId}/mortgages/${mortgageId}/netRate`,
        { netRate: mortgage.netRate },
      );
    }

    if (hasValueChanged?.prepaymentPenaltyPeriod) {
      return this.http.patch<Mortgage>(
        `${environment.api_url}/applications/${applicationId}/mortgages/${mortgageId}/prepaymentPenaltyPeriod`,
        { prepaymentPenaltyPeriod: mortgage.prepaymentPenaltyPeriod },
      );
    }

    return this.http.patch<Mortgage>(
      `${environment.api_url}/applications/${applicationId}/mortgages/${mortgageId}`,
      {
        ...mortgage,
        baseRate: undefined,
        buyDownRate: undefined,
        discount: undefined,
        prepaymentPenaltyPeriod: undefined,
        loanNumber: undefined,
        expandedLoanNumber: undefined,
        mortgageProductId: undefined,
        incrementCategoryApplied: undefined,
        increments: undefined,
        customIncrementIsSet: undefined,
        customIncrementAmount: undefined,
        customIncrementNote: undefined,
        decrements: undefined,
        decrementTotal: undefined,
        discretion: undefined,
      },
    );
  }

  deleteMortgage(applicationId: string, mortgageId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.api_url}/applications/${applicationId}/mortgages/${mortgageId}`,
    );
  }

  addDefaultConditionDatesIfMissing(
    requestedMortgage: Mortgage,
    mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
    iadByFpdEnabled: boolean,
    forceDateRecalculationParams: ForceDateRecalculationParams | null = null,
  ) {
    if (
      this.store.selectSnapshot(AppFeaturesState.processingDates) === DatesProcessingType.Calvert
    ) {
      return FundmoreCalculator.calvertMortgageDates(
        requestedMortgage,
        mortgageCalculationAutomationSettings,
        forceDateRecalculationParams,
      );
    } else {
      return FundmoreCalculator.defaultMortgageDate(
        requestedMortgage,
        mortgageCalculationAutomationSettings,
        iadByFpdEnabled,
        forceDateRecalculationParams,
      );
    }
  }

  toCommitmentMortgage(
    mortgage: Mortgage | undefined,
    amountToAdvance: number | null,
    mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
    iadByFpdEnabled: boolean,
  ) {
    const requestedMortgage = this.addDefaultConditionDatesIfMissing(
      Object.assign({}, mortgage),
      mortgageCalculationAutomationSettings,
      iadByFpdEnabled,
    );

    return Object.assign({}, mortgage, this.setPaymentLiftOnMortgage(requestedMortgage), {
      amountToAdvance,
      dateOfAdvance: mortgage?.closingDate,
    });
  }

  private setPaymentLiftOnMortgage(mortgage: Mortgage): Mortgage {
    const liftForVariableRate = this.store.selectSnapshot(
      AppFeaturesState.otherRateConfigurations,
    ).liftForVariableRate;
    if (
      mortgage.rateType === RateType.VARIABLE &&
      mortgage.useLiftPayment &&
      mortgage.paymentAmountWithLift &&
      liftForVariableRate
    ) {
      mortgage.paymentAmount = mortgage.paymentAmountWithLift;
      mortgage.netRate = mortgage.netRate + liftForVariableRate;
    }
    return mortgage;
  }
}
