import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Mortgage } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import { IncrementCategory, MortgageDecrement, MortgageIncrement } from '@fundmoreai/models';

interface UpdateMortgageDiscretionResponse {
  discretion: number | null;
  netRate: number;
}

interface UpdateMortgageDecrementResponse {
  decrementTotal: number | null;
  decrements: MortgageDecrement[] | null;
  netRate: number;
}

interface PatchMortgageIncrementResponse {
  incrementCategoryApplied?: IncrementCategory;
  increments: MortgageIncrement[];
  incrementTotal: number | null;
}

interface SetCustomMortgageIncrementResponse {
  customIncrementAmount: number;
  customIncrementNote: string;
  netRate: number;
  incrementTotal: number | null;
}

@Injectable({
  providedIn: 'root',
})
export class MortgagesV2Services {
  constructor(private http: HttpClient) {}

  getMortgages(applicationId: string): Observable<Mortgage[]> {
    return this.http.get<Mortgage[]>(
      `${environment.api_url}/applications/${applicationId}/mortgages`,
    );
  }

  postMortgages(applicationId: string, mortgages: Partial<Mortgage>[]): Observable<Mortgage[]> {
    const mortgagePayload = mortgages.map((m) => ({
      ...m,
      loanNumber: undefined,
      expandedLoanNumber: undefined,
    }));
    return this.http.post<Mortgage[]>(
      `${environment.api_url}/applications/${applicationId}/mortgages`,
      mortgagePayload,
    );
  }

  patchMortgage(
    applicationId: string,
    mortgageId: string,
    mortgage: Partial<Mortgage>,
    hasValueChanged?: { [key: string]: boolean },
  ): Observable<Mortgage> {
    if (hasValueChanged?.baseRate) {
      return this.http.patch<Mortgage>(
        `${environment.fundmore_api_url}/mortgages/${mortgageId}/baseRate`,
        { baseRate: mortgage.baseRate, applicationId },
      );
    }
    if (hasValueChanged?.buyDownRate) {
      return this.http.patch<Mortgage>(
        `${environment.fundmore_api_url}/mortgages/${mortgageId}/buyDownRate`,
        { buyDownRate: mortgage.buyDownRate, applicationId },
      );
    }
    if (hasValueChanged?.discount) {
      return this.http.patch<Mortgage>(
        `${environment.fundmore_api_url}/mortgages/${mortgageId}/discount`,
        { discount: mortgage.discount, applicationId },
      );
    }

    if (hasValueChanged?.prepaymentPenaltyPeriod) {
      return this.http.patch<Mortgage>(
        `${environment.api_url}/applications/${applicationId}/mortgages/${mortgageId}/prepaymentPenaltyPeriod`,
        { prepaymentPenaltyPeriod: mortgage.prepaymentPenaltyPeriod },
      );
    }
    return this.http.patch<Mortgage>(
      `${environment.api_url}/applications/${applicationId}/mortgages/${mortgageId}`,
      {
        ...mortgage,
        baseRate: undefined,
        buyDownRate: undefined,
        discount: undefined,
        prepaymentPenaltyPeriod: undefined,
        loanNumber: undefined,
        expandedLoanNumber: undefined,
        mortgageProductId: undefined,
        incrementCategoryApplied: undefined,
        increments: undefined,
        customIncrementIsSet: undefined,
        customIncrementAmount: undefined,
        customIncrementNote: undefined,
        decrements: undefined,
        decrementTotal: undefined,
        discretion: undefined,
      },
    );
  }

  updateMortgageBaseRate(
    applicationId: string,
    mortgageId: string,
    baseRate: number | null | undefined,
  ): Observable<Mortgage> {
    return this.http.patch<Mortgage>(
      `${environment.fundmore_api_url}/mortgages/${mortgageId}/baseRate`,
      { baseRate, applicationId },
    );
  }

  updateMortgageBuyDownRate(
    applicationId: string,
    mortgageId: string,
    buyDownRate: number | null | undefined,
  ): Observable<Mortgage> {
    return this.http.patch<Mortgage>(
      `${environment.fundmore_api_url}/mortgages/${mortgageId}/buyDownRate`,
      { buyDownRate, applicationId },
    );
  }

  updateMortgageDiscount(
    applicationId: string,
    mortgageId: string,
    discount: number | null | undefined,
  ): Observable<Mortgage> {
    return this.http.patch<Mortgage>(
      `${environment.fundmore_api_url}/mortgages/${mortgageId}/discount`,
      { discount, applicationId },
    );
  }

  refreshMortgageRateHoldDate(mortgageId: string): Observable<Pick<Mortgage, 'rateHoldDate'>> {
    return this.http.put<Pick<Mortgage, 'rateHoldDate'>>(
      `${environment.fundmore_v2_api_url}/mortgages/${mortgageId}/rate-hold-date`,
      { mortgageId },
    );
  }

  deleteMortgage(
    applicationId: string,
    mortgageId: string,
    deleteComment?: string,
  ): Observable<void> {
    return this.http.delete<void>(
      `${environment.api_url}/applications/${applicationId}/mortgages/${mortgageId}`,
      {
        body: {
          deleteComment,
        },
      },
    );
  }

  patchMortgageIncrement(
    mortgageId: string,
    mortgage: {
      increments: MortgageIncrement[];
    },
  ) {
    return this.http.patch<PatchMortgageIncrementResponse>(
      `${environment.fundmore_v2_api_url}/mortgages/${mortgageId}/increments`,
      mortgage,
    );
  }

  upsertCustomMortgageIncrement(
    mortgageId: string,
    payload: { customIncrementNote: string; customIncrementAmount: number },
  ) {
    return this.http.post<SetCustomMortgageIncrementResponse>(
      `${environment.fundmore_v2_api_url}/mortgages/${mortgageId}/custom-increments`,
      payload,
    );
  }

  updateMortgageDecrement(mortgageId: string, payload: { decrements: MortgageDecrement[] | null }) {
    return this.http.put<UpdateMortgageDecrementResponse>(
      `${environment.fundmore_v2_api_url}/mortgages/${mortgageId}/decrements`,
      payload,
    );
  }

  updateMortgageDiscretion(mortgageId: string, discretion: number | null) {
    return this.http.patch<UpdateMortgageDiscretionResponse>(
      `${environment.fundmore_v2_api_url}/mortgages/${mortgageId}/discretion`,
      { discretion },
    );
  }

  refreshProjectedBalance(applicationId: string) {
    return this.http.post<{ projectedBalance: number | null }>(
      `${environment.fundmore_v2_api_url}/ovation/refresh-projected-balance`,
      { applicationId },
    );
  }
}
