// eslint-disable-next-line max-classes-per-file
import { FraudIqEvaluation } from './fraudIqEvaluation';
import { User } from './user';

export enum RiskFlagSeverity {
  FAIL = 'FAIL',
  MANUAL_REVIEW = 'MANUAL_REVIEW',
}

export const RiskFlagSeverityRecord: Record<RiskFlagSeverity, string> = {
  [RiskFlagSeverity.FAIL]: `Fail`,
  [RiskFlagSeverity.MANUAL_REVIEW]: `Manual Review`,
};

export enum ApplicationRiskFlagStatus {
  NONE = 'NONE',
  RESOLVED = 'RESOLVED',
  ERROR = 'ERROR',
}

export enum ApplicationRiskFlagType {
  STALE_CREDIT_REPORT = 'Stale Credit Report',
}

export interface ApplicationRiskFlagPayload {
  type: ApplicationRiskFlagType | string;
  description?: string | null;
  severity: RiskFlagSeverity | string;
  reportedByDisplayName: string;
}

export interface ApplicationRiskFlag {
  id: string;
  tenantId: string;
  applicationId: string;
  type: ApplicationRiskFlagType | string;
  severity: RiskFlagSeverity;
  description?: string | null;
  status: ApplicationRiskFlagStatus;
  reportedById?: string | null;
  clearedById?: string | null;
  clearedOn?: string | null;
  createdAt: Date;
  updatedAt?: Date | null;
  deletedAt?: Date | null;
  ApplicationRiskFlagMessages?: ApplicationRiskFlagMessage[] | null;
  FraudIqEvaluations?: FraudIqEvaluation[] | null;
  clearedBy?: User | null;
  reportedBy?: User | null;
}

export interface ApplicationRiskFlagMessage {
  id: string;
  tenantId: string;
  applicationId: string;
  applicationRiskFlagId: string;
  message: string;
  createdById: string;
  createdAt: Date;
  ApplicationRiskFlag?: ApplicationRiskFlag | null;
}

export enum ApplicationRiskFlagKey {
  ID = 'id',
  TENANT_ID = 'tenantId',
  APPLICATION_ID = 'applicationId',
  TYPE = 'type',
  DESCRIPTION = 'description',
  STATUS = 'status',
  SEVERITY = 'severity',
  REPORTED_BY_ID = 'reportedById',
  CLEARED_BY_ID = 'clearedById',
  CLEARED_ON = 'clearedOn',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}

export enum ApplicationRiskFlagMessageKey {
  ID = 'id',
  TENANT_ID = 'tenantId',
  APPLICATION_ID = 'applicationId',
  APPLICATION_RISK_FLAG_ID = 'applicationRiskFlagId',
  MESSAGE = 'message',
  CREATED_BY_ID = 'createdById',
  CREATED_AT = 'createdAt',
  APPLICATION_RISK_FLAG = 'ApplicationRiskFlag',
}
