import { Applicant } from '../applicant';
import { PaymentFrequency, RateType } from '../enums';
import { Job } from '../job';
import { ResultNarrative } from '../octopus';
import { ApplicationOtherIncome } from '../otherIncome';
import { AdvancedProduct } from './advancedProduct';
import { AdvancedProductModulesActivation } from './advancedProductParameters';
import { Limit, LimitCondition, LimitTargetField, Operator } from './conditions';
import { CreditTier } from './creditTier';
import { Rate } from './rate';

export interface ApplicationAdvancedProduct {
  id?: string;
  tenantId?: string;
  applicationId: string;
  mortgageId: string;
  advancedProductId: string;
  advancedProductSnapshot: AdvancedProduct;
  metadata?: ApplicationAdvancedProductMetadata | null;
  isCurrentlyApplied: boolean;
  createdAt?: Date | null;
  updatedAt?: Date | null;
  deletedAt?: Date | null;
}

export interface ApplicationAdvancedProductMetadata {
  creditTier?: CreditTier;
  rate?: Rate;
  modifier?: number;
  info?: string[];
  isReapply?: boolean;
  specialRate?: Rate;
  specialRateModifier?: number;
  standardCommission?: number;
}

export interface PreviewProductQuery {
  advancedProduct: AdvancedProduct;
  applicationId: string;
}

export enum ActionType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface MortgagePatchTempChanges {
  mortgageId: string;
  rateType?: RateType;
  termMonths?: number | null;
  rateHoldDate?: string | null;
  actionType: ActionType;
}

export interface ApplicantPatchTempChanges {
  applicantId?: string;
  ficoScoreOverride?: boolean;
  crScore?: number | null;
  crScoreCode?: string | null;
  newApplicantCreated?: Partial<Applicant>;
  actionType: ActionType;
}

export interface JobPatchTempChanges {
  jobId?: string;
  applicantId?: string;
  isCurrent?: boolean | null;
  unableToVerify?: boolean | null;
  annualIncome?: number;
  newJobCreated?: Partial<Job>;
  actionType: ActionType;
}

export interface OtherIncomePatchTempChanges {
  otherIncomeId?: string;
  applicantIds?: string[];
  isCurrent?: boolean | null;
  unableToVerify?: boolean | null;
  amount?: number;
  period?: string;
  newOtherIncomeCreated?: Partial<ApplicationOtherIncome>;
  actionType: ActionType;
}

export interface PreviewMortgageProductsTempChanges {
  mortgagePatchTempChanges?: MortgagePatchTempChanges;
  applicantPatchTempChanges?: ApplicantPatchTempChanges;
  jobPatchTempChanges?: JobPatchTempChanges;
  otherIncomePatchTempChanges?: OtherIncomePatchTempChanges;
}

export interface PreviewMortgageProductsQuery {
  applicationId: string;
  mortgagesAdvancedProducts: { [key: string]: AdvancedProduct };
  previewMortgageProductsTempChanges: PreviewMortgageProductsTempChanges;
  noCheck?: boolean;
}

export interface PreviewProductResponse {
  errors?: string[];
  warnings?: string[];
  info?: string[];
  diff: any;
  metadataForMortgage: ApplicationAdvancedProductMetadataPerMortgage;
  modules: AdvancedProductModulesActivation;
  conditionsEvaluationResult: ConditionsEvaluationResult;
  paymentChangesPerMortgage: { [key: string]: ApplicationAdvancedProductPaymentChangesPerMortgage };
}

export interface ApplicationAdvancedProductPaymentChangesPerMortgage {
  changeType: AdvancedProductPaymentChangeType;

  // used for display purposes
  paymentFrequency: PaymentFrequency;

  // used for display purposes
  netRate: Change<number>;

  // changes to apply to the mortgage to adjust the payment amount
  adjustPaymentAmount: {
    paymentAmount: Change<number>;
    monthlyPayment: Change<number>;
  };

  // changes to apply to the mortgage to adjust the amortization, keeping the payment amount
  adjustAmortization: {
    amortizationMonths: Change<number>;
  };
}

export interface Change<T> {
  previous?: T;
  new?: T;
}

export enum AdvancedProductPaymentChangeType {
  ADJUST_PAYMENT_AMOUNT = 'ADJUST_PAYMENT_AMOUNT', // default
  ADJUST_AMORTIZATION = 'ADJUST_AMORTIZATION',
  NONE = 'NONE',
}
export interface ConditionsEvaluationResult {
  allPassing: boolean;
  passing: Limit[];
  notPassing: Limit[];
  narratives: ResultNarrative[];
  warnings: string[];
  flattenedResults: LimitEvaluation[];
}

export interface LimitEvaluation {
  field?: LimitTargetField;
  actualValue?: number | string | undefined;
  creditTierLimits?: CreditTierLimitEvaluation[];
  orLimits?: LimitConditionEvaluation[];
  result: LimitEvaluationStatusResult;
}

export interface CreditTierLimitEvaluation {
  creditTierName: string;
  creditTierRange: string;
}

export interface LimitConditionEvaluation {
  operator: Operator | string;
  value: number | string;
  conditions?: LimitCondition[];
  result: LimitEvaluationStatusResult;
}

export interface MortgageProductOffering {
  advancedProductsOffering: {
    [key: string]: { limitEvaluationOutput: LimitEvaluation[]; applicableRate?: number };
  };
}

export enum LimitEvaluationStatusResult {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  CONDITIONS_NOT_APPLICABLE = 'CONDITIONS_NOT_APPLICABLE',
  MISSING_APPLICANT_CREDIT_SCORE = 'MISSING_APPLICANT_CREDIT_SCORE',
  NO_RATE_MATRIX_DEFINED = 'NO_RATE_MATRIX_DEFINED',
  NO_RATE_MATRIX_DEFINED_INCLUDING_APPLICANT_CREDIT_SCORE = 'NO_RATE_MATRIX_DEFINED_INCLUDING_APPLICANT_CREDIT_SCORE',
  UNKNOWN = 'UNKNOWN',
}

export interface ApplicationAdvancedProductMetadataPerMortgage {
  metadataPerMortgage?: { [key: string]: MetadataPerMortgage };
  info?: string[];
  isReapply?: boolean;
}

export interface ApplyMortgageProductsCommand {
  mortgagesAdvancedProducts: { [key: string]: AdvancedProduct };
  applicationId: string;
  diff: any;
  modules?: AdvancedProductModulesActivation;
  metadataForMortgage: ApplicationAdvancedProductMetadataPerMortgage;
  paymentChangesPerMortgage: { [key: string]: ApplicationAdvancedProductPaymentChangesPerMortgage };
  isServicingApply?: boolean;
  noCheck?: boolean;
}

export interface ApplyProductCommand {
  advancedProduct: AdvancedProduct;
  applicationId: string;
  diff: any;
  modules?: AdvancedProductModulesActivation;
}

export interface ClearMortgageProductCommand {
  applicationId: string;
  mortgageId: string;
}

export interface ApplyProductResponse {
  errors?: string[];
  warnings?: string[];
}

export interface MetadataPerMortgage {
  creditTier: CreditTier | undefined;
  rate?: Rate;
  modifier?: number;
  specialRate?: Rate;
  specialRateModifier?: number;
  standardCommission?: number;
}
