import { Applicant, ContactType } from './applicant';
import { FinancialAsset } from './asset';
import { FinancialLiability } from './liability';
import { Mortgage } from './mortgage';
import { ApplicationOtherIncome } from './otherIncome';
import { Property, PropertyAddressDetails } from './property';
import { CreditReportReference } from './creditReport';
import {
  AgentSource,
  ApplicationType,
  MortgageClassificationType,
  MortgageType,
  SourceOfBusinessType,
} from './enums';
import { ApplicationMetadata, ApplicationSource, PurposeCode } from './application';
import { Fee } from './fee';
import { DownPayment } from './downPayment';
import { EQClosingService, LendingTierType } from './eqMaps';
import { AddressExpanded } from './addressExpanded';
import { ApplicationNoteMetadata, ApplicationNoteStatus } from './applicationNote';

export interface PropertyData extends Omit<Property, 'propertyAddressExpanded'> {
  docsDueDate: string;
  propertyAddressExpanded: Partial<PropertyAddressDetails>;
}

export interface ApplicantData extends Omit<Applicant, 'ApplicantAddressesExpanded'> {
  docsDueDate: string;
  ApplicantOtherIncomes?: ApplicationOtherIncome[];
  ApplicantFinancialLiabilities?: Partial<FinancialLiability>[];
  ApplicantProperties?: Partial<ApplicantProperty>[];
  ApplicantFinancialAssets?: FinancialAsset[];
  ApplicantCreditReports?: CreditReportReference[];
  ApplicantAddressesExpanded: Partial<AddressExpanded>[];
}

export interface BrokerApplicantData extends ApplicantData {
  firmCode: string;
  preferredContactMethod: ContactType;
  userType: string;
  licenseRegistrationNumber: string;
  profileStatus: string | null;
  externalId: { [key in ApplicationSource]?: string };
  source?: AgentSource;
  notes?: string | null;
}

export interface DownPaymentData extends DownPayment {
  tableData: unknown;
}

export interface FinancialAssetData extends FinancialAsset {
  tableData: unknown;
}

export interface FinancialLiabilityData extends FinancialLiability {
  tableData: unknown;
}

export interface OtherIncomeData extends ApplicationOtherIncome {
  tableData: unknown;
}

export interface ApplicantProperty {
  propertyId: string;
  mappedProperty: Partial<Property>;
}

export interface CreateApplicationData {
  name?: string;
  broker?: string;
  brokerNotes?: string;
  brokerNotesDate?: string;
  applicationNotes?: ApplicationNoteCreate[];
  code?: string;
  applicationDate?: string;
  purpose?: string;
  mortgageClassification?: MortgageClassificationType;
  filogixDealId?: string;
  externalDealId?: string;
  source?: ApplicationSource;
  originalData?: any;
  fundId?: string;
  dealType?: ApplicationType;
  closingService?: EQClosingService;
  lendingTier?: LendingTierType;
  clonedApplicationId?: string;
  metadata?: ApplicationMetadata;
  lineOfBusinessId?: string;
  lineOfBusiness?: any;
  advancedProductId?: string;
  externalApplicationId?: string;
  externalApplicationGUID?: string;
  isCombo?: boolean;
  commitmentIssueDate?: string;
  commitmentExpiryDate?: string;
  quickCloseFlag?: boolean;
  sourceOfBusiness?: SourceOfBusinessType;
  purposeCode?: PurposeCode;
  applicationLoanNumber?: string;
  isServicing?: boolean;
  effectiveDate?: string;
  productSub?: number;
  paymentSub?: string;
  productNumber?: number;
  primaryMemberNumber?: number;
  mcuOriginalAppId?: string;
  submissionApplicationId?: string;
  mcuProductId?: string;

  financialAssets?: Partial<FinancialAssetData>[];
  otherIncomes?: Partial<OtherIncomeData>[];
  liabilities?: Partial<FinancialLiabilityData>[];
  servicingMortgage?: Partial<Mortgage>;
  requestedMortgage?: Partial<Mortgage>;
  helocRequestedMortgage?: Partial<Mortgage>;
  existingMortgage?: Partial<Mortgage>[];
  refinanceMortgage?: Partial<Mortgage>[];
  properties?: Partial<PropertyData>[];
  downPayments?: Partial<DownPaymentData>[];
  primaryApplicant?: Partial<ApplicantData>;
  otherApplicants?: Partial<ApplicantData>[];
  brokerApplicant?: Partial<BrokerApplicantData>;
  brokerApplicants?: Partial<BrokerApplicantData>[];
  contactsEmails?: {
    email: string;
    externalEmailSequenceNumber?: number;
  }[];
  lenderSubmission?: LenderSubmissionCreate;
  Fees?: Partial<Fee>[];
  mortgageCalculationAutomationSettings?: MortgageCalculationAutomationSettings;
  applicationSourceForOther?: string | null;
}

export type MortgageCalculationAutomationSettings =
  | Record<string, CalculationAutomationSettings>
  | null
  | undefined;

export enum CalculationAutomationSettingsKeys {
  isPaymentAmountDisabled = 'isPaymentAmountDisabled',
  isMonthlyPaymentDisabled = 'isMonthlyPaymentDisabled',
  isInterestAdjustmentDateDisabled = 'isInterestAdjustmentDateDisabled',
  isInterestAdjustmentAmountDisabled = 'isInterestAdjustmentAmountDisabled',
  isMaturityDateDisabled = 'isMaturityDateDisabled',
  excludeExtraPaymentInTerm = 'excludeExtraPaymentInTerm',
  setTDSToZero = 'setTDSToZero',
  setGDSToZero = 'setGDSToZero',
}

export class CalculationAutomationSettings {
  [CalculationAutomationSettingsKeys.isPaymentAmountDisabled]: boolean | null | undefined;
  [CalculationAutomationSettingsKeys.isMonthlyPaymentDisabled]: boolean | null | undefined;
  [CalculationAutomationSettingsKeys.isInterestAdjustmentDateDisabled]: boolean | null | undefined;
  [CalculationAutomationSettingsKeys.isInterestAdjustmentAmountDisabled]:
    | boolean
    | null
    | undefined;
  [CalculationAutomationSettingsKeys.isMaturityDateDisabled]: boolean | null | undefined;
  [CalculationAutomationSettingsKeys.excludeExtraPaymentInTerm]: boolean | null | undefined;
  [CalculationAutomationSettingsKeys.setTDSToZero]: boolean | null | undefined;
  [CalculationAutomationSettingsKeys.setGDSToZero]: boolean | null | undefined;
}

export interface LenderSubmissionCreate {
  routingSender?: string | null;
  routingReceiver?: string | null;
  language?: string | null;
  submitRequestTime?: string | null;
  lenderProfile?: {
    linkId?: string | null;
    registrationName?: string | null;
    lenderCode?: string | null;
    lenderName?: string | null;
    useLegacyUserId?: string | null;
  } | null;
}

export interface MortgageCreate {
  id: string;
  applicationId: string;
  propertyId: string | null;
  lender?: string;
  productName?: string;
  loanType?: string;
  purpose?: string;
  closingDate?: Date | null;
  fundingDate?: Date | null;
  mortgageType: string;
  paymentFrequency?: string;
  purchaseValue: number | null;
  insuranceAmount: number | null;
  insurancePremium: number | null;
  loanAmount: number | null;
  monthlyPayment: number | null;
  netRate: number | null;
  originalNetRate: number | null;
  termMonths?: number | null;
  amortizationMonths?: number | null;
  repaymentType?: string;
  type: MortgageType;
  rateType?: string;
  termType?: string;
  maturityDate?: string;
  mortgageBalance: number | null;
  compounding: string | null;
  externalLoanNumber?: string | null;
  loanNumber?: string;
  deadlineToAcceptDate?: string;
  interestAdjustmentDate?: Date | null;
  interestAdjustmentAmount: number | null;
  borrowingLimit?: number | null;
  firstRegularPaymentDate?: string | null;
  insurer?: string | null;
  insuranceAccountNum?: string | null;
  mortgageNum: number | null;
  includePremiumInMortgage: boolean | null;
}

export interface ApplicationNoteCreate {
  applicationId?: string;
  status: ApplicationNoteStatus;
  authorNames?: string[];
  authorRoles?: string[];
  editableById?: string;
  content: string;
  lastModifiedAt?: Date | string;
  metadata?: ApplicationNoteMetadata;
}
