import { CardDetails } from './applicantCreditCardOffer';
export interface ApplicationDecisionEngineFlags {
  id: string;
  applicationId: string;
  decisionEnginePollingSource?: DecisionEnginePollingSource | null;
  mortgageDecisionResponses?: MortgageDecision[] | null;
  applicationDecisionResponse?: ApplicationDecision | null;
  creditCardDecisionResponse?: CreditCardDecision[] | null;
  applicantDecisionResponse?: DecisionResponseModel | null;
  lastPollingDate: Date;
  numberOfSubmissions: number;
  requestObject?: unknown;
}

export interface MortgageDecision extends DecisionResponseModel {
  mortgageId: string;
}

export interface ApplicationDecision extends DecisionResponseModel {
  applicationId: string;
}

export interface CreditCardDecision extends DecisionResponseModel {
  statusReason: string;
  monthlyDisposableIncome: string;
  approvedCreditLimitUSD: string;
  approvedCreditLimtCAD: string;
  decisionPath: string;
  maximumApplicantScore: string;
  CardTypes: CardType[];
  cardTypesList: CardDetails[];
}

export enum DecisionEnginePollingSource {
  PROVENIR = 'PROVENIR',
}

export enum DecisionEngineStatus {
  DECLINED = 'DECLINED',
  MANUAL_REVIEW = 'MANUAL_REVIEW',
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
}

export interface ErrorResponseModel {
  message: string;
  reason: string;
  reasonCode: string;
}

export interface DecisionResponseModel {
  status: DecisionEngineStatus;
  Errors: ErrorResponseModel[];
}

export class CardType {
  Platinum: {
    status: string;
    currency: string;
    approvedLoanAmount: string;
    subType: string;
  };
  Secured: {
    status: string;
    currency: string;
    message: string;
    subType: string;
    maxLoanAmount: string;
  };
  USDollarCard: {
    status: string;
    approvedLoanAmount: string;
    currency: string;
    subType: string;
  };
  Infinite: {
    status: string;
    approvedLoanAmount: string;
    currency: string;
    subType: string;
  };
  CashBack: {
    status: string;
    approvedLoanAmount: string;
    currency: string;
    subType: string;
  };
}

export enum DecisionEngineKey {
  APPLICATION_DECISION_RESPONSE = 'applicationDecisionResponse',
  CREDIT_CARD_DECISION_RESPONSE = 'creditCardDecisionResponse',
  MORTGAGE_DECISION_RESPONSES = 'mortgageDecisionResponses',
}
