export function stringTemplateParser(expression: string, valueObj: object) {
  const templateMatcher = /\${\s?([^{}\s]*)\s?}/g;
  const text = expression.replace(templateMatcher, (_substring, value) => {
    return deep_value(valueObj, value);
  });
  return text;
}

export function deep_value(obj: object, path: string) {
  return path
    .replace(/\[|\]\.?/g, '.')
    .split('.')
    .filter((s) => s)
    .reduce((acc, val) => acc && acc[val], obj);
}

const jsApplicationHeader = `
  'use strict';
  let application = this.application;
  let lenders = this.lenders;
  let propertyLocation = this.application.propertyAddressEnhanced;
  let context = this.application.context;
`;

export function buildSecureIfFunctionWithContext(fn: string) {
  return new Function(`
      ${jsApplicationHeader}
      return (${fn});
    `);
}
