import { MMSInsurer, MMSMortgagePriority, MMSMortgageType, MMSTransactionType } from '../enums';

export interface MMSMortgage {
  closingDate?: string; // dateonly required
  mortgageNumber?: string; // required
  mortgageType?: MMSMortgageType; // required
  mortgageProduct?: string; // required
  transactionType?: MMSTransactionType; // required
  mortgagePriority?: MMSMortgagePriority; // required
  term?: number; //months
  amortization?: number; //years
  rentAssignment?: boolean;
  insurer?: MMSInsurer;
  mortgageAmount?: string; // decimal(17,2) required
  registeredAmount?: string; // decimal(15,2) required
  loanAmount?: string; // decimal(17,2)
  totalInterest?: string; // decimal(17,2)
  BalanceOwingAtMaturity?: string; // decimal(17,2)
  cashbackPercentage?: string; // decimal(6,4)
  cashbackValue?: string; // decimal(17,2)
  downPayment?: string; // decimal(17,2)
  salePrice?: string; // decimal(17,2)
  loanToValue?: string; // decimal(9, 5)
  investor?: string;
}
