import { MMSProvince } from '../enums';

export interface MMSAddress {
  unitNumber?: string | null;
  streetNumber?: string | null;
  streetName?: string | null; //required
  streetName2?: string | null;
  city?: string | null; //required
  province?: MMSProvince | null; //required
  postalCode?: string | null;
}
