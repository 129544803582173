export interface DocumentRequestNote {
  id?: string | null;
  applicationId?: string | null;
  authorNames?: string[];
  authorRoles?: string[];
  editableById?: string | null;
  status?: DocumentRequestNoteStatus | null;
  content?: string | null;
  metadata?: any;
  lastModifiedAt?: string | null;
  external?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
}

export enum DocumentRequestNoteStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
}

export enum DocumentRequestNoteKey {
  ID = 'id',
  APPLICATION_ID = 'applicationId',
  AUTHOR_NAMES = 'authorNames',
  AUTHOR_ROLES = 'authorRoles',
  EDITABLE_BY_ID = 'editableById',
  STATUS = 'status',
  CONTENT = 'content',
  METADATA = 'metadata',
  LAST_MODIFIED_AT = 'lastModifiedAt',
  EXTERNAL = 'external',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}
