import {
  Applicant,
  ApplicationAssignedUser,
  ApplicationStage,
  ApplicationStatus,
  ApplicationWarningKeys,
  CustomerType,
  PriorityType,
  InsuranceQuote,
  PropertyType,
  ApplicationPurposeType,
  MortgageStatus,
  MortgageState,
} from '..';

export interface ReportingPropertyAddressesObject {
  id: string;
  address: string;
  type: PropertyType;
  addressShort: string;
}

export interface RMCReportingSubmissionsMortgage {
  loanAmount?: number | null;
  purpose: string;
  closingDate: string;
  deadlineToAcceptDate?: string | null;
  cofDate?: string | null;
  loanNumber?: string | null;
  status?: MortgageStatus[] | null;
  state?: MortgageState | null;
  stateChangeComment?: string | null;
  stateChangeDate?: Date | null;
  propertyId?: string | null;
  loanType?: string | null;
  propertyAddress?: ReportingPropertyAddressesObject | undefined;
}

export interface RMCReportingSubmissions {
  requestedMortgages: RMCReportingSubmissionsMortgage[];
  applicants: Applicant[];
  applicationAssignedUser: ApplicationAssignedUser[] | null;
  applicationDate: string;
  code: string;
  createdAt: string;
  id: string;
  name: string;
  primaryApplicantName?: string | undefined;
  primaryApplicantCustomerType?: CustomerType | undefined;
  otherApplicants?: Applicant[];
  currentStage?: ApplicationStage | undefined;
  previousStage?: ApplicationStage | undefined;
  priority?: PriorityType | null;
  purpose?: ApplicationPurposeType | null;
  status?: string | null;
  updatedAt: string;
  applicationAdvancedProductIds?: string[] | null;
  createdBy?: RMCReportingSubmissionsUser | null;
  dateLabel?: string | undefined;
  declineComment?: string;
  declineReasonId?: string;
  archived: boolean;
  locked: boolean;
  applicationStatus: ApplicationStatus;
  source: string;
  properties: ReportingPropertyAddressesObject[];
  primaryPropertyAddress?: ReportingPropertyAddressesObject | undefined;
  externalPropertiesAddresses?: ReportingPropertyAddressesObject[] | undefined;
  warnings: ApplicationWarningKeys[];
  canEdit?: boolean;
  externalApplicationId: string | null;
  latestInsuranceQuote: InsuranceQuote | null;
}

export interface RMCReportingSubmissionsUser {
  id: string;
}
