import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AgentAction, Lawyer } from '@fundmoreai/models';

@Injectable({
  providedIn: 'root',
})
export class LawyersService {
  constructor(private http: HttpClient) {}

  getLawyers(): Observable<Lawyer[]> {
    return this.http.get<Lawyer[]>(`${environment.api_url}/lawyers`);
  }

  addLawyer(lawyer: Partial<Lawyer>) {
    return this.http.post<Lawyer>(`${environment.api_url}/lawyers`, lawyer);
  }

  patchLawyer(lawyerId: string, lawyer: Partial<Lawyer>): Observable<void> {
    return this.http.patch<void>(`${environment.api_url}/lawyers/${lawyerId}`, lawyer);
  }

  deleteLawyer(lawyerId: string) {
    return this.http.delete<void>(`${environment.api_url}/lawyers/${lawyerId}`);
  }

  bulkActionLawyer(ids: string[], action: AgentAction) {
    return this.http.patch<Lawyer[]>(`${environment.fundmore_v2_api_url}/lawyers/bulkAction`, {
      ids,
      action,
    });
  }
  deleteBulkLawyer(ids: string[]) {
    return this.http.post(`${environment.fundmore_v2_api_url}/lawyers/bulkDelete`, {
      ids,
    });
  }
}
