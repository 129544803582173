import { AppraisalStatus } from '../../propertyAppraisal';

export enum FCTRequestStatus {
  IN_PROCESS = '001',
  READY = '002',
  DELIVERED = '003',
}

export const fctRequestStatusToAppraisalStatus: Record<FCTRequestStatus, AppraisalStatus> = {
  [FCTRequestStatus.IN_PROCESS]: AppraisalStatus.IN_PROCESS,
  [FCTRequestStatus.READY]: AppraisalStatus.READY,
  [FCTRequestStatus.DELIVERED]: AppraisalStatus.DELIVERED,
};
