import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { LoadingEnd, LoadingStart } from 'src/app/core/loading.state';
import { DocumentTemplatesService } from '../services/document-templates.service';
import {
  AddDocumentTemplateContentModel,
  AddDocumentTemplateModel,
  DocumentTemplate,
  DocumentTemplateContent,
  UpdateDocumentTemplateContentModel,
  UpdateDocumentTemplateModel,
} from '../../../manager-portal/documents-management/model';
import { Scope } from '@fundmoreai/models';

interface DocumentTemplateModel {
  documentTemplates: {
    [key: string]: DocumentTemplate;
  };
  documentTemplatesContent: {
    [key: string]: DocumentTemplateContent[];
  };
}

export class AddDocumentTemplate {
  static readonly type = '@documentTemplates.addDocumentTemplate';

  constructor(public documentTemplate: AddDocumentTemplateModel) {}
}

export class AddDocumentTemplateContent {
  static readonly type = '@documentTemplates.addDocumentTemplateContent';

  constructor(public content: AddDocumentTemplateContentModel) {}
}

export class DeleteDocumentTemplate {
  static readonly type = '@documentTemplates.deleteDocumentTemplate';

  constructor(public documentTemplateId: string) {}
}

export class FetchDocumentTemplates {
  static readonly type = '@documentTemplates.fetchDocumentTemplates';

  constructor(public documentScope: Scope = Scope.ORIGINATION) {}
}

export class FetchDocumentTemplateContent {
  static readonly type = '@documentTemplates.fetchDocumentTemplateContent';

  constructor(public documentTemplateId: string) {}
}

export class UpdateDocumentTemplate {
  static readonly type = '@documentTemplates.updateDocumentTemplate';

  constructor(
    public documentTemplateId: string,
    public documentTemplate: UpdateDocumentTemplateModel,
  ) {}
}

export class UpdateDocumentTemplateContent {
  static readonly type = '@documentTemplates.updateDocumentTemplateContent';

  constructor(public contentId: string, public content: UpdateDocumentTemplateContentModel) {}
}

@State<DocumentTemplateModel>({
  name: 'documentTemplates',
  defaults: {
    documentTemplates: {},
    documentTemplatesContent: {},
  },
})
@Injectable()
export class DocumentTemplatesState {
  static activeDocumentTemplateContent(documentTemplateId: string) {
    return createSelector(
      [DocumentTemplatesState],
      (state: DocumentTemplateModel): DocumentTemplateContent | undefined => {
        const documentTemplate = state.documentTemplates[documentTemplateId];
        const documentTemplateContent = state.documentTemplatesContent[documentTemplateId];

        if (!documentTemplate || !documentTemplateContent) {
          return undefined;
        }

        return documentTemplateContent.find((x) => x.id === documentTemplate.currentVersionId);
      },
    );
  }

  static documentTemplate(documentTemplateId: string) {
    return createSelector(
      [DocumentTemplatesState],
      (state: DocumentTemplateModel): DocumentTemplate | undefined => {
        return state.documentTemplates[documentTemplateId];
      },
    );
  }

  static documentTemplateContentList(documentTemplateId: string) {
    return createSelector(
      [DocumentTemplatesState],
      (state: DocumentTemplateModel): DocumentTemplateContent[] | undefined => {
        const documentTemplateContent = state.documentTemplatesContent[documentTemplateId];

        return documentTemplateContent;
      },
    );
  }

  @Selector([DocumentTemplatesState.documentTemplatesEntities])
  static documentCategories(documentTemplates: { [key: string]: DocumentTemplate }): string[] {
    const categories = Object.values(documentTemplates).map(
      (documentTemplate: DocumentTemplate) => documentTemplate.category,
    );

    // return distinct categories array
    return [...new Set(categories)].sort();
  }

  @Selector([DocumentTemplatesState.documentTemplatesEntities])
  static documentTemplates(documentTemplates: {
    [key: string]: DocumentTemplate;
  }): DocumentTemplate[] {
    return Object.values(documentTemplates);
  }

  @Selector()
  static documentTemplatesEntities(state: DocumentTemplateModel) {
    return state.documentTemplates;
  }

  @Selector([DocumentTemplatesState.documentTemplatesEntities])
  static publishedDocumentTemplates(documentTemplates: { [key: string]: DocumentTemplate }) {
    return Object.values(documentTemplates).filter((x) => x.isPublished);
  }

  constructor(private documentTemplatesService: DocumentTemplatesService, private store: Store) {}

  @Action(AddDocumentTemplate)
  addDocumentTemplate(ctx: StateContext<DocumentTemplateModel>, action: AddDocumentTemplate) {
    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.documentTemplatesService.add(action.documentTemplate).pipe(
      tap((data) => {
        ctx.patchState({
          documentTemplates: {
            ...state.documentTemplates,
            [data.documentTemplate.id]: data.documentTemplate,
          },
          documentTemplatesContent: {
            ...state.documentTemplatesContent,
            [data.documentTemplate.id]: data.documentTemplateContent,
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(AddDocumentTemplateContent)
  addDocumentTemplateContent(
    ctx: StateContext<DocumentTemplateModel>,
    action: AddDocumentTemplateContent,
  ) {
    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.documentTemplatesService.addContent(action.content).pipe(
      tap((content) => {
        const documentTemplateContent = state.documentTemplatesContent[content.documentTemplateId];

        ctx.patchState({
          documentTemplatesContent: {
            ...state.documentTemplatesContent,
            [content.documentTemplateId]: [...documentTemplateContent, content],
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(FetchDocumentTemplateContent)
  getDocumentTemplateContent(
    ctx: StateContext<DocumentTemplateModel>,
    action: FetchDocumentTemplateContent,
  ) {
    const state = ctx.getState();

    if (state.documentTemplatesContent[action.documentTemplateId]) {
      return;
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.documentTemplatesService.getAllContent(action.documentTemplateId).pipe(
      tap((documentTemplateContents) => {
        ctx.patchState({
          documentTemplatesContent: {
            ...ctx.getState().documentTemplatesContent,
            [action.documentTemplateId]: documentTemplateContents,
          },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(DeleteDocumentTemplate)
  deleteDocumentTemplate(ctx: StateContext<DocumentTemplateModel>, action: DeleteDocumentTemplate) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.documentTemplatesService.delete(action.documentTemplateId).pipe(
      tap(() => {
        const state = ctx.getState();
        const documentTemplateEntities = { ...state.documentTemplates };
        const documentTemplateContentEntities = { ...state.documentTemplatesContent };

        delete documentTemplateEntities[action.documentTemplateId];
        delete documentTemplateContentEntities[action.documentTemplateId];

        ctx.patchState({
          documentTemplates: documentTemplateEntities,
          documentTemplatesContent: documentTemplateContentEntities,
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(FetchDocumentTemplates)
  getDocumentTemplates(ctx: StateContext<DocumentTemplateModel>, action: FetchDocumentTemplates) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.documentTemplatesService.getAll(action.documentScope).pipe(
      tap((documentTemplates) => {
        const documentTemplateEntities = documentTemplates.reduce(
          (entities: { [key: string]: DocumentTemplate }, documentTemplate) => {
            entities[documentTemplate.id] = documentTemplate;

            return entities;
          },
          {},
        );

        ctx.patchState({ documentTemplates: documentTemplateEntities });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateDocumentTemplate)
  updateDocumentTemplate(ctx: StateContext<DocumentTemplateModel>, action: UpdateDocumentTemplate) {
    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.documentTemplatesService
      .update(action.documentTemplateId, action.documentTemplate)
      .pipe(
        tap((documentTemplate) => {
          ctx.patchState({
            documentTemplates: {
              ...state.documentTemplates,
              [documentTemplate.id]: {
                ...state.documentTemplates[documentTemplate.id],
                ...documentTemplate,
              },
            },
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(UpdateDocumentTemplateContent)
  updateDocumentTemplateContent(
    ctx: StateContext<DocumentTemplateModel>,
    action: UpdateDocumentTemplateContent,
  ) {
    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.documentTemplatesService.updateContent(action.contentId, action.content).pipe(
      tap((content) => {
        const documentTemplateContent = state.documentTemplatesContent[content.documentTemplateId];

        ctx.patchState({
          documentTemplatesContent: {
            ...state.documentTemplatesContent,
            [content.documentTemplateId]: [
              ...documentTemplateContent.filter((x) => x.id !== action.contentId),
              content,
            ],
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }
}
