import { ApplicationPurposeType, NetworthContext } from '@fundmoreai/models';

export function computeCurrentNetworth(
  applicationPurpose: ApplicationPurposeType | null,
  assetsNetworth: number,
  propertiesNetworth: number,
  primaryPropertyValue: number,
  debtBalance: number,
  existingMortgageAmount: number,
) {
  const totalAssets =
    +assetsNetworth +
    +propertiesNetworth +
    (applicationPurpose !== ApplicationPurposeType.PURCHASE ? +primaryPropertyValue : 0);
  const totalLiabilities = +debtBalance + +existingMortgageAmount;
  const networth: NetworthContext = {
    totalAssets: totalAssets,
    totalLiabilities: totalLiabilities,
    networth: totalAssets - totalLiabilities,
  };

  return networth;
}

export function computeFutureNetworth(
  applicationPurpose: ApplicationPurposeType | null,
  assetsNetworth: number,
  propertiesNetworth: number,
  saleProceeds: number,
  primaryPropertyValue: number,
  debtBalance: number,
  totalLoanAmountForAllRequestedMortgages: number,
  existingMortgageAmount: number,
  totalDownPayment: number,
  applicantsCount?: number,
  applicantId?: string,
) {
  const totalAssets = assetsNetworth + propertiesNetworth + primaryPropertyValue + saleProceeds;
  const totalLiabilities =
    debtBalance +
    existingMortgageAmount +
    (applicantId && applicantsCount
      ? totalLoanAmountForAllRequestedMortgages / applicantsCount
      : totalLoanAmountForAllRequestedMortgages) +
    (applicationPurpose === ApplicationPurposeType.PURCHASE ? totalDownPayment : 0);
  const networth: NetworthContext = {
    totalAssets: totalAssets,
    totalLiabilities: totalLiabilities,
    networth: totalAssets - totalLiabilities,
  };

  return networth;
}
