import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { catchError, finalize, tap } from 'rxjs/operators';
import { ClosingInstructionsService } from './closing-instruction.service';
import { ClosingInstruction, MMSFieldMetadata, MMSNote } from '../../shared/model';
import { LoadingEnd, LoadingStart } from '../../core/loading.state';
import { AddNotification, ApplicationResetState } from '../../shared/state.model';
import {
  FetchFctMMSUpdates,
  SendMMSFundingConfirmation,
  SetFCTMMSMilestoneOnClosingInstruction,
} from './closing-instruction.action';
import { FctMMSService } from './fct-mms/fct-mms.service';
import { patch, updateItem } from '@ngxs/store/operators';
import { EMPTY } from 'rxjs';

export type ClosingInstructionStateModel = ClosingInstruction[];

export class UpdateClosingInstruction {
  static readonly type = '@closingInstructions.updateClosingInstruction';
  constructor(
    public mortgageId: string,
    public closingInstructionId: string,
    public updates: Partial<ClosingInstruction>,
  ) {}
}

export class DeleteClosingInstruction {
  static readonly type = '@closingInstructions.deleteClosingInstruction';
  constructor(public mortgageId: string, public closingInstructionId: string) {}
}

export class CreateClosingInstruction {
  static readonly type = '@closingInstructions.createClosingInstruction';
  constructor(
    public mortgageId: string,
    public applicationId: string,
    public closingInstruction: ClosingInstruction,
  ) {}
}

export class FetchClosingInstructions {
  static readonly type = '@closingInstructions.fetchClosingInstructions';
  constructor(public applicationId: string) {}
}

export class AddClosingInstruction {
  static readonly type = '@closingInstructions.addClosingInstruction';
  constructor(public closingInstruction: ClosingInstruction) {}
}

export class AddMMSClosingInstructionNote {
  static readonly type = '@closingInstructions.addMMSClosingInstructionNote';
  constructor(public fctURN: string, public note: MMSNote) {}
}

export class UpdateClosingInstructionResponseMetadata {
  static readonly type = '@closingInstructions.updateClosingInstructionResponseMetadata';
  constructor(public fctURN: string, public metadata: MMSFieldMetadata) {}
}

@State<ClosingInstructionStateModel>({
  name: 'closingInstructions',
  defaults: [],
})
@Injectable()
export class ClosingInstructionState {
  constructor(
    private propertyClosingInstructionsService: ClosingInstructionsService,
    private fctMMSService: FctMMSService,
  ) {}

  @Action(ApplicationResetState)
  reset(ctx: StateContext<ClosingInstruction[]>) {
    ctx.setState([]);
  }

  @Selector()
  static closingInstructions(state: ClosingInstruction[]) {
    return state ?? [];
  }

  static closingInstructionById(closingInstructionId: string) {
    return createSelector([ClosingInstructionState], (state: ClosingInstructionStateModel) =>
      state.find((c) => c.id === closingInstructionId),
    );
  }

  @Action(CreateClosingInstruction)
  createClosingInstruction(
    ctx: StateContext<ClosingInstruction[]>,
    action: CreateClosingInstruction,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();
    return this.propertyClosingInstructionsService
      .postClosingInstruction(action.mortgageId, action.applicationId, action.closingInstruction)
      .pipe(
        tap((newClosingInstruction) => ctx.setState([...state, newClosingInstruction])),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(FetchClosingInstructions)
  fetchClosingInstructions(
    ctx: StateContext<ClosingInstruction[]>,
    action: FetchClosingInstructions,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.propertyClosingInstructionsService
      .fetchMortgagesClosingInstructions(action.applicationId)
      .pipe(
        tap((closingInstructions) => {
          ctx.setState(closingInstructions);
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(UpdateClosingInstruction)
  updateClosingInstruction(
    ctx: StateContext<ClosingInstruction[]>,
    action: UpdateClosingInstruction,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();

    return this.propertyClosingInstructionsService
      .patchClosingInstruction(
        action.mortgageId,
        action.closingInstructionId,
        action.updates as ClosingInstruction,
      )
      .pipe(
        tap((updatedClosingInstruction) => {
          ctx.setState([
            ...state.filter((a) => a.id !== action.closingInstructionId),
            updatedClosingInstruction,
          ]);
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(DeleteClosingInstruction)
  deleteClosingInstruction(
    ctx: StateContext<ClosingInstruction[]>,
    action: DeleteClosingInstruction,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();
    return this.propertyClosingInstructionsService
      .deleteClosingInstruction(action.mortgageId, action.closingInstructionId)
      .pipe(
        tap(() => ctx.setState([...state.filter((a) => a.id !== action.closingInstructionId)])),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(AddClosingInstruction)
  addClosingInstruction(ctx: StateContext<ClosingInstruction[]>, action: AddClosingInstruction) {
    const state = ctx.getState();
    ctx.setState([
      ...state.filter((x) => x.id !== action.closingInstruction.id),
      action.closingInstruction,
    ]);
  }

  @Action(SetFCTMMSMilestoneOnClosingInstruction)
  setMilestoneOnClosingInstruction(
    ctx: StateContext<ClosingInstruction[]>,
    action: SetFCTMMSMilestoneOnClosingInstruction,
  ) {
    const closingInstruction = ctx.getState().find((ci) => ci.id === action.closingInstructionId);
    if (!closingInstruction?.MMSRequest) {
      return;
    }
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.fctMMSService
      .setMilestones(closingInstruction.MMSRequest.id, {
        milestones: action.milestones,
      })
      .pipe(
        tap((updates) => {
          ctx.setState(
            updateItem<ClosingInstruction>(
              (ci) => ci.id === action.closingInstructionId,
              patch({
                MMSRequest: patch(updates),
              }),
            ),
          );
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(AddMMSClosingInstructionNote)
  addMMSClosingInstructionNote(
    ctx: StateContext<ClosingInstruction[]>,
    action: AddMMSClosingInstructionNote,
  ) {
    const state = ctx.getState();
    const closingInstruction = state.find((c) => c.MMSRequest?.fctURN === action.fctURN);

    if (!closingInstruction || !action.fctURN) {
      return;
    }

    ctx.setState([
      ...state.filter((c) => c.MMSRequest?.fctURN !== action.fctURN),
      Object.assign({}, closingInstruction, {
        MMSRequest: {
          ...closingInstruction.MMSRequest,
          notes: [...(closingInstruction.MMSRequest?.notes ?? []), action.note],
        },
      }),
    ]);
  }

  @Action(UpdateClosingInstructionResponseMetadata)
  updateClosingInstructionResponseMetadata(
    ctx: StateContext<ClosingInstruction[]>,
    action: UpdateClosingInstructionResponseMetadata,
  ) {
    const state = ctx.getState();
    const closingInstruction = state.find((c) => c.MMSRequest?.fctURN === action.fctURN);

    if (!closingInstruction || !action.fctURN) {
      return;
    }

    ctx.setState([
      ...state.filter((c) => c.MMSRequest?.fctURN !== action.fctURN),
      Object.assign({}, closingInstruction, {
        MMSRequest: {
          ...closingInstruction.MMSRequest,
          responseMetadata: closingInstruction.MMSRequest?.responseMetadata?.map((x) =>
            x.field === action.metadata.field && x.name === action.metadata.name
              ? { ...x, isChecked: !x.isChecked }
              : x,
          ),
        },
      }),
    ]);
  }

  @Action(FetchFctMMSUpdates)
  fetchFctMMSUpdates(ctx: StateContext<ClosingInstructionStateModel>, action: FetchFctMMSUpdates) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.fctMMSService.fetchFctMMSUpdates(action.fctMMSId).pipe(
      tap((updatedMMSRequest) => {
        ctx.setState(
          updateItem<ClosingInstruction>(
            (ci) => ci.MMSRequest?.id === action.fctMMSId,
            patch({
              MMSRequest: patch(updatedMMSRequest),
            }),
          ),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(SendMMSFundingConfirmation)
  sendMMSFundingConfirmation(
    ctx: StateContext<ClosingInstructionStateModel>,
    action: SendMMSFundingConfirmation,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const applicationId = ctx
      .getState()
      .find((ci) => ci.MMSRequest?.id === action.fctMMSId)?.applicationId;
    return this.fctMMSService
      .sendFundingConfirmation(action.fctMMSId, action.confirmationAmount)
      .pipe(
        catchError((error) => {
          ctx.dispatch(
            new AddNotification({
              title: $localize`MMS Funding Confirmation Failed`,
              message:
                error?.error?.message ||
                $localize`An error occurred while sending the funding confirmation`,
              channel: 'info',
              addedAt: new Date(),
              applicationId,
            }),
          );
          return EMPTY;
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }
}
