import { MMSError } from '../error';

export type MMSRequestError = MMSServiceMessages | MMSBackendError | MMSMessageError;

export interface MMSServiceMessages {
  serviceMessages: MMSError[];
}

export interface MMSBackendError {
  type: string;
  title: string;
  status: number;
  traceId: string;
  errors: Record<string, string[]>;
}

export interface MMSMessageError {
  message: string;
}
