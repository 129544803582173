import { injectable } from 'tsyringe';

export interface IRemainingMortgageTermCalculator {
  getRemainingMortgageTerm(maturityDate: string | Date): number;
}

@injectable()
export class RemainingMortgageTermCalculator implements IRemainingMortgageTermCalculator {
  getRemainingMortgageTerm(maturityDate: string | Date): number {
    const start = new Date();
    const end = new Date(maturityDate);

    let yearDiff = end.getFullYear() - start.getFullYear();
    let monthDiff = end.getMonth() - start.getMonth();
    const dayDiff = end.getDate() - start.getDate();

    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }

    const totalMonths = yearDiff * 12 + monthDiff;

    let fractionalMonth = 0;
    if (dayDiff !== 0) {
      const daysInMonth = new Date(end.getFullYear(), end.getMonth() + 1, 0).getDate();
      fractionalMonth = dayDiff / daysInMonth;
    }

    const totalMonthsWithFraction = totalMonths + fractionalMonth;

    return Math.round(totalMonthsWithFraction);
  }
}
