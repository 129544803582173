import {
  FlipAnalysisResultType,
  FlipAnalysisResult,
  FlipAnalysisItem,
  FlipCalculationItem,
} from '@fundmoreai/models';

const AnalysisItem = FlipAnalysisItem;
const CalculationItem = FlipCalculationItem;

export const buildOperatingCostAnalysisResult = (
  type: FlipAnalysisResultType,
  totalMonths: number,
  operatingCost: number,
): FlipAnalysisResult => {
  return {
    type,
    key: AnalysisItem.TOTAL_OPERATING_COSTS,
    value: Math.round(totalMonths * operatingCost * 100) / 100,
    inputs: [
      { [CalculationItem.TOTAL_MONTH]: totalMonths },
      { [CalculationItem.OPERATING_COST]: operatingCost },
    ],
  };
};

export const buildFinancingCostAnalysisResult = (
  type: FlipAnalysisResultType,
  totalMonths: number,
  interestCostByMonth: number,
  financingFeeAmount: number,
): FlipAnalysisResult => {
  const financingCost = totalMonths * interestCostByMonth + financingFeeAmount;
  return {
    type,
    key: AnalysisItem.TOTAL_FINANCING_COSTS,
    value: Math.round(financingCost * 100) / 100,
    inputs: [
      { [CalculationItem.TOTAL_MONTH]: totalMonths },
      { [CalculationItem.INTEREST_COST_MONTH]: interestCostByMonth },
      { [CalculationItem.FINANCING_FEES]: financingFeeAmount },
    ],
  };
};

export const buildTotalFlipCostAnalysisResult = (
  type: FlipAnalysisResultType,
  analysisOperatingCost: number,
  analysisFinancingCost: number,
  totalPurchaseCost: number,
  totalSellingCost: number,
  renovationBudget: number,
): FlipAnalysisResult => {
  const totalCost =
    analysisOperatingCost +
    analysisFinancingCost +
    totalPurchaseCost +
    totalSellingCost +
    renovationBudget;
  return {
    type,
    key: AnalysisItem.TOTAL_COSTS,
    value: Math.round(totalCost * 100) / 100,
    inputs: [
      {
        [AnalysisItem.TOTAL_OPERATING_COSTS]: analysisOperatingCost,
      },
      {
        [AnalysisItem.TOTAL_FINANCING_COSTS]: analysisFinancingCost,
      },
      { [CalculationItem.TOTAL_PURCHASE_COSTS]: totalPurchaseCost },
      { [CalculationItem.TOTAL_SELLING_COSTS]: totalSellingCost },
      { [CalculationItem.RENOVATION_COSTS]: renovationBudget },
    ],
  };
};

export const buildTotalCashNeededAnalysisResult = (
  type: FlipAnalysisResultType,
  analysisOperatingCost: number,
  totalMonths: number,
  totalPurchaseCost: number,
  interestCostByMonth: number,
  renovationBudget: number,
  downPaymentsTotal: number = 0,
): FlipAnalysisResult => {
  const totalCash =
    totalPurchaseCost +
    analysisOperatingCost +
    interestCostByMonth * totalMonths +
    renovationBudget +
    downPaymentsTotal;
  const inputs: { [key: string]: string | number }[] = [
    {
      [AnalysisItem.TOTAL_OPERATING_COSTS]: analysisOperatingCost,
    },
    { [CalculationItem.TOTAL_PURCHASE_COSTS]: totalPurchaseCost },
    { [CalculationItem.RENOVATION_COSTS]: renovationBudget },
    { [CalculationItem.INTEREST_COST_MONTH]: interestCostByMonth },
    { [CalculationItem.TOTAL_MONTH]: totalMonths },
  ];
  if (downPaymentsTotal) {
    inputs.push({ [CalculationItem.DOWN_PAYMENT_TOTAL]: downPaymentsTotal });
  }
  const analysisResult = {
    type,
    key: AnalysisItem.TOTAL_CASH_NEEDED,
    value: Math.round(totalCash * 100) / 100,
    inputs,
  };
  return analysisResult;
};

export const buildProfitAnalysisResult = (
  type: FlipAnalysisResultType,
  analysisTotalCost: number,
  propertyPurchasePrice: number,
  afterRepairValue: number,
): FlipAnalysisResult => {
  const profit = afterRepairValue - propertyPurchasePrice - analysisTotalCost;
  return {
    type,
    key: AnalysisItem.PROFIT,
    value: Math.round(profit * 100) / 100,
    inputs: [
      {
        [CalculationItem.ARV]: afterRepairValue,
      },
      { [CalculationItem.PURCHASE_PRICE]: propertyPurchasePrice },
      {
        [AnalysisItem.TOTAL_COSTS]: analysisTotalCost,
      },
    ],
  };
};

export const buildROIAnalysisResult = (
  type: FlipAnalysisResultType,
  analysisProfit: number,
  analysisTotalCash: number,
): FlipAnalysisResult => {
  const roi = (analysisProfit / (analysisTotalCash || 1)) * 100;
  return {
    type,
    key: AnalysisItem.ROI,
    value: Math.round(roi * 100) / 100,
    inputs: [
      {
        [AnalysisItem.PROFIT]: analysisProfit,
      },
      {
        [AnalysisItem.TOTAL_CASH_NEEDED]: analysisTotalCash,
      },
    ],
  };
};

export const buildROIAnnuallyAnalysisResult = (
  type: FlipAnalysisResultType,
  analysisProfit: number,
  analysisTotalCash: number,
  totalMonths: number,
): FlipAnalysisResult => {
  const roi = (analysisProfit / (analysisTotalCash || 1) / (totalMonths || 1)) * 12 * 100;
  return {
    type,
    key: AnalysisItem.ROI_ANNUALLY,
    value: Math.round(roi * 100) / 100,
    inputs: [
      {
        [CalculationItem.TOTAL_MONTH]: totalMonths,
      },
      {
        [AnalysisItem.PROFIT]: analysisProfit,
      },
      {
        [AnalysisItem.TOTAL_CASH_NEEDED]: analysisTotalCash,
      },
    ],
  };
};

export const buildRatioCostToARVAnalysisResult = (
  type: FlipAnalysisResultType,
  analysisTotalCost: number,
  afterRepairValue: number,
): FlipAnalysisResult => {
  const costToARV = (analysisTotalCost / (afterRepairValue || 1)) * 100;
  return {
    type,
    key: AnalysisItem.ROC_TO_ARV,
    value: Math.round(costToARV * 100) / 100,
    inputs: [
      {
        [AnalysisItem.TOTAL_COSTS]: analysisTotalCost,
      },
      { [CalculationItem.ARV]: afterRepairValue },
    ],
  };
};

export const buildVariableMonthlyCostAnalysisResult = (
  type: FlipAnalysisResultType,
  analysisOperatingCost: number,
  interestCostByMonth: number,
): FlipAnalysisResult => {
  return {
    type: type,
    key: AnalysisItem.VARIABLE_MONTHLY_COSTS,
    value: Math.round((analysisOperatingCost + interestCostByMonth) * 100) / 100,
    inputs: [
      {
        [AnalysisItem.TOTAL_OPERATING_COSTS]: analysisOperatingCost,
      },
      { [CalculationItem.INTEREST_COST_MONTH]: interestCostByMonth },
    ],
  };
};

export const buildMaximumPurchasePriceAnalysis = (
  type: FlipAnalysisResultType,
  analysisTotalCost: number,
  desiredProfit: number,
  afterRepairValue: number,
): FlipAnalysisResult => {
  return {
    type,
    key: AnalysisItem.MAXIMUM_PURCHASED_PRICE,
    value: Math.round((afterRepairValue - desiredProfit - analysisTotalCost) * 100) / 100,
    inputs: [
      {
        [AnalysisItem.TOTAL_COSTS]: analysisTotalCost,
      },
      { [CalculationItem.DESIRED_PROFIT]: desiredProfit },
      { [CalculationItem.ARV]: afterRepairValue },
    ],
  };
};
