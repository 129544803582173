import { FeePayer, FeeType } from './enums';

export interface Fee {
  id: string;
  type: FeeType;
  paidBy: FeePayer;
  percent: number;
  amount: number;
  subtractFromPrincipal: boolean | null;
  includeInApr: boolean;
  capFeesPercentage: number | null;
  createdAt: string;
  manuallyUpdated: boolean;
  description: string | null;
}

export interface FeeV2 extends Omit<Fee, 'type' | 'paidBy' | 'percent' | 'amount'> {
  type: FeeType | null;
  paidBy: FeePayer | null;
  percent: number | null;
  amount: number | null;
}

export enum FeeKey {
  TYPE = 'type',
  PAID_BY = 'paidBy',
  DESCRIPTION = 'description',
  PERCENT = 'percent',
  AMOUNT = 'amount',
  SUBTRACT_FROM_PRINCIPAL = 'subtractFromPrincipal',
  CAP_FEES_PERCENTAGE = 'capFeesPercentage',
  INCLUDE_IN_APR = 'includeInApr',
}
