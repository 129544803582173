import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MMSCreateRequest,
  ClosingInstruction,
  MMSFieldMetadata,
  MMSLenderProduct,
  MMSMilestone,
  MMSNote,
  MMSSuccessResponse,
  MMSServiceMessages,
  MMSUpdateResponse,
  MMSResponse,
  MMSUpdateRequest,
  MMSDocumentConfig,
  FundmoreMMSDocument,
  ClamavScanStatus,
  ApplicationStage,
  FCTMMSResponse,
} from '@fundmoreai/models';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { MilestonesInput } from '../closing-instruction.action';
// eslint-disable-next-line max-len
import { FCTMMSDocumentInput } from '../../../features/application/widgets/loan-details/closing-instruction-mms/fct-mms-documents/fct-mms-documents.action';

interface MMSDocumentAssignStakeholderOutput {
  id: string;
  applicationId: string;
  stakeholderId: string;
  applicationDocumentId: string;
  displayName: string;
  stage: ApplicationStage;
}

interface CreateMMSNotePayload {
  note: string;
  datePublished: string;
  userName: string;
}

@Injectable({
  providedIn: 'root',
})
export class FctMMSService {
  constructor(private http: HttpClient) {}

  createMMSRequest(data: MMSCreateRequest): Observable<MMSResponse | ClosingInstruction> {
    return this.http.post<MMSResponse | ClosingInstruction>(
      `${environment.mms_api_url}/create-request`,
      data,
    );
  }

  fetchMMSMetadata(data: MMSCreateRequest): Observable<MMSFieldMetadata[]> {
    return this.http.post<MMSFieldMetadata[]>(`${environment.mms_api_url}/metadata`, {
      ...data,
      applicationId: undefined,
    });
  }

  fetchMMSLenderProducts(): Observable<MMSLenderProduct[]> {
    return this.http.get<MMSLenderProduct[]>(`${environment.mms_api_url}/lender-products`);
  }

  setMilestones(mmsRequestId: string, payload: { milestones: MilestonesInput[] }) {
    return this.http.put<{ milestones: MMSMilestone[] }>(
      `${environment.mms_api_url}/${mmsRequestId}/milestones`,
      payload,
    );
  }

  createMMSNote(fctURN: string, note: CreateMMSNotePayload) {
    return this.http.post<{ response: MMSSuccessResponse | MMSServiceMessages; note: MMSNote }>(
      `${environment.mms_api_url}/note`,
      { fctURN, note },
    );
  }

  updateInstructionMMSRequest(
    data: MMSUpdateRequest,
  ): Observable<ClosingInstruction | MMSUpdateResponse> {
    return this.http.post<ClosingInstruction | MMSUpdateResponse>(
      `${environment.mms_api_url}/update-request`,
      data,
    );
  }
  updateMetadata(fctURN: string, metadata: MMSFieldMetadata): Observable<void> {
    return this.http.post<void>(`${environment.mms_api_url}/update-metadata`, { fctURN, metadata });
  }

  sendDocuments(
    mmsRequestId: string,
    payload: { applicationId: string; documentInput: FCTMMSDocumentInput[] },
  ) {
    return this.http.post<FundmoreMMSDocument[]>(
      `${environment.mms_api_url}/${mmsRequestId}/upload-application-documents`,
      payload,
    );
  }

  getDocumentConfig() {
    return this.http.get<MMSDocumentConfig[]>(`${environment.mms_api_url}/document-configs`);
  }

  getMMSDocuments(applicationId: string) {
    return this.http.get<FundmoreMMSDocument[]>(
      `${environment.mms_api_url}/documents?applicationId=${applicationId}`,
    );
  }

  getMMSDocumentSignedUrl(mmsDocumentId: string) {
    return this.http.get<{
      url: string | null;
      scanStatus?: ClamavScanStatus;
    }>(`${environment.mms_api_url}/documents/${mmsDocumentId}/content/url`);
  }

  mmsDocumentAssignStakeholder(mmsDocumentId: string, stakeholderId: string) {
    return this.http.post<MMSDocumentAssignStakeholderOutput>(
      `${environment.mms_api_url}/documents/${mmsDocumentId}/assign-stakeholder`,
      {
        stakeholderId,
      },
    );
  }

  fetchFctMMSUpdates(mmsRequestId: string) {
    return this.http.get<FCTMMSResponse>(`${environment.mms_api_url}/${mmsRequestId}`);
  }

  sendFundingConfirmation(mmsRequestId: string, confirmationAmount: number) {
    return this.http.post<void>(`${environment.mms_api_url}/${mmsRequestId}/funding-confirmation`, {
      confirmationAmount,
    });
  }
}
