import { Country, Province, State, StreetDirection, StreetType } from './enums';

export interface AgentAddressExpanded {
  id?: string;
  brokerId?: string | null;
  lawyerId?: string | null;
  address?: string | null;
  type?: AgentAddressExpandedType | null;
  isCurrent?: boolean | null;
  city?: string | null;
  province?: Province | State | null;
  country?: Country | null;
  lat?: number | null;
  long?: number | null;
  streetNumber?: string | null;
  unitType?: string | null;
  streetNumberSuffix?: string | null;
  postalCodePrefix?: string | null;
  streetName?: string | null;
  streetDirection?: StreetDirection | null;
  streetType?: StreetType | null;
  unit?: string | null;
  postalCode?: string | null;
  createdAt?: string;
  updatedAt?: string;
}

export enum AgentAddressExpandedType {
  ALTERNATIVE = 'ALTERNATIVE',
  BUSINESS = 'BUSINESS',
  FUNDING = 'FUNDING',
  RECREATION = 'RECREATION',
  RESIDENTIAL = 'RESIDENTIAL',
  MAIL = 'MAIL',
  TEMPORARY = 'TEMPORARY',
  OTHER = 'OTHER',
}

export enum AgentAddressExpandedKey {
  ADDRESS = 'address',
  CITY = 'city',
  PROVINCE = 'province',
  COUNTRY = 'country',
  LAT = 'lat',
  LONG = 'long',
  STREET_NUMBER = 'streetNumber',
  STREET_NUMBER_SUFFIX = 'streetNumberSuffix',
  STREET_NAME = 'streetName',
  STREET_TYPE = 'streetType',
  STREET_DIRECTION = 'streetDirection',
  UNIT = 'unit',
  UNIT_TYPE = 'unitType',
  POSTAL_CODE = 'postalCode',
  TYPE = 'type',
  IS_CURRENT = 'isCurrent',
}

export const AgentTypeBroker = 'broker';
export const AgentTypeLawyer = 'lawyer';
export type AgentType = typeof AgentTypeBroker | typeof AgentTypeLawyer;
