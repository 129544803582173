/* eslint-disable no-case-declarations */
import { buildSecureIfFunctionWithContext, stringTemplateParser } from '../helpers/generic';
import { Recommendation, Treshold } from '../interfaces/score.config';
import { FundmoreRecommendation, FundmoreScoreContext } from '../models/models';

import cloneDeep from 'lodash/cloneDeep';
import { FundmoreRecommendationType } from '@fundmoreai/models';

export class RecommendationProcessor {
  public process = (
    recommendation: Recommendation,
    originalContext: FundmoreScoreContext,
    treshold: Treshold,
  ): FundmoreRecommendation => {
    switch (recommendation.type) {
      case FundmoreRecommendationType.DecreaseMortgageAmount: {
        const tresholdFn = recommendation.tresholdFn || treshold.tresholdFn;
        const tresholdFunction = buildSecureIfFunctionWithContext(tresholdFn);
        const sandboxContext = cloneDeep(originalContext);

        for (let percentage = 100; percentage > 0; percentage = percentage - 1) {
          const newMortgageAmount = parseFloat(
            (
              ((originalContext.application.requestedMortgage?.loanAmount ?? 0) * percentage) /
              100
            ).toFixed(2),
          );
          if (sandboxContext.application.requestedMortgage) {
            sandboxContext.application.requestedMortgage.loanAmount = newMortgageAmount;
          }
          sandboxContext.application.enhance();

          const tresholdResult = tresholdFunction.call(sandboxContext);
          if (tresholdResult) {
            return new FundmoreRecommendation({
              display: stringTemplateParser(recommendation.value, {
                newValue: newMortgageAmount,
                percentage: 100 - percentage,
              }),
              type: recommendation.type,
              changeToNumber: newMortgageAmount,
            });
          }
        }
        return new FundmoreRecommendation({
          display: "Couldn't find a suitable loan amount. Try reducing LTV",
          type: recommendation.type,
        });
      }
      case FundmoreRecommendationType.IncreaseDownPayment: {
        const tresholdFn = recommendation.tresholdFn || treshold.tresholdFn;
        const tresholdFunction = buildSecureIfFunctionWithContext(tresholdFn);
        const sandboxContext = cloneDeep(originalContext);

        for (let amount = 1000; amount <= 100000; amount = amount + 1000) {
          const newMortgageAmount =
            (originalContext.application.requestedMortgage?.loanAmount ?? 0) - amount;
          if (sandboxContext.application.requestedMortgage) {
            sandboxContext.application.requestedMortgage.loanAmount = newMortgageAmount;
          }
          sandboxContext.application.enhance();

          const tresholdResult = tresholdFunction.call(sandboxContext);
          if (tresholdResult) {
            return new FundmoreRecommendation({
              display: stringTemplateParser(recommendation.value, {
                newDownPayment: amount,
              }),
              type: recommendation.type,
              changeToNumber: amount,
            });
          }
        }

        return new FundmoreRecommendation({
          display: "Couldn't find a suitable down payment. Try reducing LTV",
          type: recommendation.type,
        });
      }
      case FundmoreRecommendationType.ChangeRequestedMortgageMaturityDate: {
        const firstMortgageMaturityDate = new Date(
          originalContext.application.firstMortgage?.maturityDate ?? new Date(),
        );

        const requestedMortgageClosingDate = new Date(
          originalContext.application.requestedMortgage?.closingDate ?? new Date(),
        );
        const diffTime = Math.abs(
          firstMortgageMaturityDate.getTime() - requestedMortgageClosingDate.getTime(),
        );
        const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));

        const diffYears = parseFloat((diffDays / 365).toFixed(2));

        return new FundmoreRecommendation({
          display: stringTemplateParser(recommendation.value, {
            term: diffYears,
          }),
          type: recommendation.type,
          changeToNumber: diffYears,
        });
      }
    }
    return new FundmoreRecommendation({
      display: stringTemplateParser(recommendation.value, originalContext),
      type: recommendation.type,
    });
  };
}
