export interface MMSDisbursementFetchResponse {
  disbursements: MMSDisbursement[];
}

export interface MMSDisbursement {
  disbursementType: string;
  payer?: string;
  reciever?: string;
  amount: number;
}
