export class LendeskIncomeAddressModel {
  addressLine1?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  country?: string;

  constructor(
    addressLine1?: string,
    city?: string,
    province?: string,
    postalCode?: string,
    country?: string,
  ) {
    this.addressLine1 = addressLine1;
    this.city = city;
    this.province = province;
    this.postalCode = postalCode;
    this.country = country;
  }

  toString() {
    let address = '';
    if (this.addressLine1) {
      address += `${this.addressLine1} `;
    }
    if (this.city) {
      address += `,${this.city}, `;
    }
    if (this.province) {
      address += `${this.province}, `;
    }
    if (this.postalCode) {
      address += `${this.postalCode}, `;
    }
    if (this.country) {
      address += `${this.country}`;
    }

    return formatAddressDelimiters(address);
  }
}

function formatAddressDelimiters(address: string): string {
  return address?.replace(/ ,/g, ', ').replace(/[, ]$/g, '').trim();
}
