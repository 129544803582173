export type BooleanTenantSettingKey =
  | 'fctIntegrationEnabled'
  | 'incrementsEnabled'
  | 'incrementsAutomationEnabled'
  | 'discretionEnabled'
  | 'decrementsEnabled'
  | 'iqDocumentRequestsEnabled';
export type JsonTenantSettingKey = 'optionalFields';

export class TenantSettings {
  private settings: { key: string; value: string }[];

  constructor(settings: { key: string; value: string }[]) {
    this.settings = settings;
  }

  /**
   * Check if a boolean tenant setting is enabled.
   * @param key - The key for the boolean tenant setting.
   * @returns {boolean} - True if the setting is enabled, false otherwise.
   */
  isEnabled(key: BooleanTenantSettingKey): boolean {
    const setting = this.settings.find((s) => s.key === key);
    if (setting) {
      return setting.value === 'true';
    }
    console.warn(`Boolean setting key not found: ${key}`);
    return false;
  }

  /**
   * Retrieve a JSON-parsed value for a given tenant setting key.
   * @param key - The key for the JSON tenant setting.
   * @returns {T | undefined} - The parsed value of the setting if found, otherwise undefined.
   */
  getFromJSON<T>(key: JsonTenantSettingKey): T | undefined {
    const setting = this.settings.find((s) => s.key === key);
    if (setting) {
      try {
        return JSON.parse(setting.value) as T;
      } catch (error) {
        console.error(`Failed to parse JSON for key ${key}:`, error);
        return undefined;
      }
    }
    console.warn(`JSON setting key not found: ${key}`);
    return undefined;
  }

  /**
   * Note: to be used in javascript, typescript can call this.getFromJSON<T>
   */
  optionalFields(): string[] {
    return this.getFromJSON<string[]>('optionalFields') || [];
  }
}
