import { Province, provinceToProvinceCode, RestrictedDate, State } from '@fundmoreai/models';
import { ConfigurationState } from '../../configs/configuration-state';
import { fromShortISODateToDatePicker } from '@fundmoreai/helpers';

export function isInApprovedPostalCodes(
  postalCode: string,
  approvedPostalCodes: string[],
): boolean {
  if (approvedPostalCodes) {
    // Approved Postal codes contain the FSA (first 3 characters)
    return approvedPostalCodes.includes(postalCode.slice(0, 3));
  }
  return true;
}

export function isInApprovedPrimaryPostalCodes(postalCode: string): boolean {
  const approvedPostalCodes =
    ConfigurationState.rocketExtraConfig?.approvedPostalCodesPrimary ?? [];
  return isInApprovedPostalCodes(postalCode, approvedPostalCodes);
}

export function isInApprovedSecondaryPostalCodes(postalCode: string): boolean {
  const approvedPostalCodes =
    ConfigurationState.rocketExtraConfig?.approvedPostalCodesSecondary ?? [];
  return (
    isInApprovedPostalCodes(postalCode, approvedPostalCodes) &&
    !postalCode.slice(0, 3).includes('0')
  );
}

export function isInRuralZone(postalCode: string): boolean {
  const ruralZoneCodes = ConfigurationState.rocketExtraConfig?.ruralCodes;
  if (ruralZoneCodes) {
    return ruralZoneCodes.includes(postalCode) || postalCode.slice(0, 3).includes('0');
  }
  return true;
}

export function isClosingDateRestricted(
  closingDate: string,
  restrictedDates: RestrictedDate[],
  region?: Province | State | null,
): boolean {
  const regionCode = region && provinceToProvinceCode[region];
  const selectedDate = fromShortISODateToDatePicker(closingDate);
  return restrictedDates.some((restrictedDate) => {
    return (
      selectedDate &&
      selectedDate >= new Date(restrictedDate.startDate || 0) &&
      (!restrictedDate.endDate || selectedDate <= new Date(restrictedDate.endDate || 0)) &&
      (!restrictedDate.region.length || (regionCode && restrictedDate.region.includes(regionCode)))
    );
  });
}
