import { Note } from './notes.model';

export class ClearNoteToEdit {
  static readonly type = '@applicationNotes.clearNoteToEdit';
}

export class DeleteDraftNote {
  static readonly type = '@applicationNotes.deleteDraftNote';

  constructor(public noteId: string) {}
}

export class ExportNotesAsPdf {
  static readonly type = '@applicationNotes.exportNotesAsPdf';

  constructor(public applicationName: string | null) {}
}

export class FetchNotes {
  static readonly type = '@applicationNotes.fetchNotes';

  constructor(public applicationId: string) {}
}

export class PublishDraftNote {
  static readonly type = '@applicationNotes.publishDraftNote';

  constructor(public noteId: string, public content: string) {}
}

export class SaveDraftNote {
  static readonly type = '@applicationNotes.saveDraftNote';

  constructor(public applicationId: string, public content: string) {}
}

export class SaveNote {
  static readonly type = '@applicationNotes.saveNote';

  constructor(public applicationId: string, public content: string) {}
}

export class SetNoteHasChanges {
  static readonly type = '@applicationNotes.setNoteHasChanges';

  constructor(public hasChanges: boolean) {}
}

export class SetNoteToEdit {
  static readonly type = '@applicationNotes.setNoteToEdit';

  constructor(public note: Partial<Note>) {}
}

export class UpdateDraftNote {
  static readonly type = '@applicationNotes.updateDraftNote';

  constructor(public noteId: string, public content: string) {}
}

export class GenerateAINote {
  static readonly type = '@applicationNotes.generateAINote';

  constructor(
    public applicationId: string,
    public temperature?: number,
    public maxTokens?: number,
  ) {}
}

export class ResetAINote {
  static readonly type = '@applicationNotes.resetAINote';
}
