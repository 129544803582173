import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AvaChat, AvaChatMessage, AvaSuggestion } from '@fundmoreai/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AvaService {
  private base = `${environment.fundmore_ai_api_url}/ava`;

  constructor(private httpService: HttpClient) {}

  addAvaMessage(avaChatId: string, message: Partial<AvaChatMessage>) {
    return this.httpService.post<AvaChat>(`${this.base}/chat/${avaChatId}`, message);
  }

  getAvaChat(avaChatId: string) {
    return this.httpService.get<AvaChat>(`${this.base}/chat/${avaChatId}`);
  }

  getMostRecentAvaChat(applicationId: string, userId: string) {
    return this.httpService.get<AvaChat | null>(
      `${this.base}/chat/most-recent/${applicationId}/${userId}`,
    );
  }

  createAvaChat(applicationId: string, userId: string) {
    return this.httpService.post<AvaChat>(`${this.base}/chat`, { applicationId, userId });
  }

  getAvaSuggestions(chatId: string) {
    return this.httpService.get<AvaSuggestion[]>(`${this.base}/suggestions/${chatId}`);
  }

  uploadDocumentsToAva(avaChatId: string, documentIds: string[]) {
    return this.httpService.post(`${this.base}/upload-documents/${avaChatId}`, {
      documentIds,
    });
  }

  shareAVAChat(avaChatId: string) {
    return this.httpService.post<AvaChat>(`${this.base}/chat/${avaChatId}/share`, {});
  }

  getAVAChats(applicationId: string) {
    return this.httpService.get<AvaChat[]>(`${this.base}/chats/${applicationId}`);
  }
}
