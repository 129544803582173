import {
  Action,
  Actions,
  State,
  StateContext,
  Store,
  createSelector,
  ofActionDispatched,
} from '@ngxs/store';
import { MortgageComputedData, Summary } from '@fundmoreai/models';
import { Injectable } from '@angular/core';
import { MortgageComputedDataService } from './mortgage-computed-data.service';
import {
  PersistMortgageComputedData,
  SetMortgageComputedData,
} from './mortgage-computed-data.actions';
import { ApplicationResetState } from './mortgage-application.actions';
import {
  EMPTY,
  Observable,
  debounceTime,
  distinctUntilChanged,
  finalize,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { SummaryState } from './summary.state';
import { MortgageApplicationState } from './mortgage-application.state';
import hash from 'object-hash';
import { LoadingEnd, LoadingStart } from '../core/loading.state';

@State<MortgageComputedData[]>({
  name: 'mortgageComputedData',
  defaults: [],
})
@Injectable()
export class MortgageComputedDataState {
  constructor(
    private mortgageComputedDataService: MortgageComputedDataService,
    private store: Store,
    private actions$: Actions,
  ) {}

  static apr(mortgageId: string) {
    return createSelector([MortgageComputedDataState], (state: MortgageComputedData[]) => {
      return state.find((m) => m.mortgageId === mortgageId)?.calculation.apr;
    });
  }

  @Action(SetMortgageComputedData)
  setMortgageComputedData(
    ctx: StateContext<MortgageComputedData[]>,
    { data }: SetMortgageComputedData,
  ) {
    ctx.setState(data);
  }

  @Action(ApplicationResetState)
  reset(ctx: StateContext<MortgageComputedDataState>) {
    ctx.setState(undefined as any);
  }

  /**
   * For each requested mortgage, persist the computed data if it has changed.
   * This is done by comparing the hash of the current state with the hash of the new state.
   */
  @Action(PersistMortgageComputedData, { cancelUncompleted: true })
  persistMortgageComputedData$(ctx: StateContext<MortgageComputedData[]>) {
    const applicationPermissions = this.store.selectSnapshot(
      MortgageApplicationState.applicationPermissions,
    );

    return this.store.select(SummaryState.individualSummaryAllRequestedMortgages).pipe(
      debounceTime(2000),
      distinctUntilChanged(),
      takeUntil(this.actions$.pipe(ofActionDispatched(ApplicationResetState))),
      switchMap((summaries) => {
        return summaries.map((summary) => {
          const mortgageId = summary.mortgageId;
          delete summary.mortgageId;

          if (
            !mortgageId ||
            !summary ||
            !applicationPermissions ||
            Object.keys(applicationPermissions).length === 0
          ) {
            return EMPTY;
          }

          const state = ctx.getState().find((x) => x.mortgageId === mortgageId);

          if (state && JSON.stringify(state.calculation) === JSON.stringify(summary)) {
            return EMPTY;
          }

          // Don't update if not necessary
          const isEqualHash = this.mortgageComputedDataIsEqualHash(state?.calculation, summary);

          if (isEqualHash || !applicationPermissions?.canEdit) {
            return EMPTY;
          }

          return this.upsertMortgageComputedData(mortgageId, { calculation: summary }).pipe(
            tap(() =>
              ctx.setState(
                ctx.getState().map((x) => {
                  if (x.mortgageId === mortgageId) {
                    return {
                      mortgageId,
                      calculation: summary,
                    };
                  }
                  return x;
                }),
              ),
            ),
          );
        });
      }),
    );
  }

  private upsertMortgageComputedData(
    mortgageId: string,
    updates: MortgageComputedData,
  ): Observable<MortgageComputedData> {
    return this.mortgageComputedDataService.putMortgageComputedData(mortgageId, updates);
  }

  private mortgageComputedDataIsEqualHash(a: Summary | undefined, b: Partial<Summary>) {
    if (!a || !b) {
      return false;
    }
    const options = { unorderedArrays: true, unorderedObjects: true };

    //compare calculations
    const isCalculationsEqual =
      hash(removeUndefinedValues(a) ?? {}, options) ==
      hash(removeUndefinedValues(b) ?? {}, options);

    return isCalculationsEqual;
  }
}

/**
 * This function removes undefined values from an object.
 * Example:
 * removeUndefinedValues({ a: undefined, b: 1, c: { d: undefined, e: 2 } })
 * => { b: 1, c: { e: 2 } }
 *
 * @param obj Object to remove undefined values from
 * @returns Object with undefined values removed
 */
function removeUndefinedValues(obj: any): any {
  if (Array.isArray(obj)) {
    // Recursively apply to each element, filtering out undefined values
    return obj
      .filter((element) => element !== undefined)
      .map((element) => removeUndefinedValues(element));
  } else if (obj && typeof obj === 'object') {
    // If it's an object, create a new object excluding properties with undefined values
    return Object.keys(obj).reduce((acc: any, key) => {
      if (obj[key] !== undefined) {
        acc[key] = removeUndefinedValues(obj[key]);
      }
      return acc;
    }, {});
  }
  return obj;
}
