import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, retry, throwError, timer } from 'rxjs';
import { FmErrorHandlerService } from './fm-error-handler.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorHandlerService: FmErrorHandlerService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const startTime = Date.now();
    return next.handle(req).pipe(
      retry({
        count: 2,
        delay: (error: HttpErrorResponse, attempt) => {
          const duration = Date.now() - startTime;
          if (error.status === 0 || error.status === 429 || error.status === 502) {
            return timer((attempt + 2) * 1000);
          }
          if ((error.status === 503 || error.status === 504) && duration > 30000) {
            // if the error is a 503 or 504 and the duration is greater than 30 seconds, retry every 60 seconds
            return timer(attempt * 60000);
          }
          if (error.status === 503 || error.status === 504) {
            return timer((attempt + 2) * 10000);
          }
          return throwError(() => error);
        },
      }),
    );
  }
}
