export const TYPES = {
  ISubjectPropertyCalculatorToken: Symbol.for('ISubjectPropertyCalculator'),
  ITotalLoanAmountCalculatorToken: Symbol.for('ITotalLoanAmountCalculator'),
  ILTVCalculatorToken: Symbol.for('ILTVCalculator'),
  IFeeCalculatorToken: Symbol.for('IFeeCalculator'),
  IIncrementCalculatorToken: Symbol.for('IIncrementCalculator'),
  IApplicationAggregatesToken: Symbol.for('IApplicationAggregatesCalculator'),
  ILiabilityIsLoanCalculator: Symbol.for('ILiabilityIsLoanCalculator'),
  IRemainingMortgageTermCalculator: Symbol.for('IRemainingMortgageTermCalculator'),
  ITotalActualPaymentCalculator: Symbol.for('ITotalActualPaymentCalculator'),
  IBlendedRateRenewalCalculatorToken: Symbol.for('IBlendedRateRenewalCalculator'),
};
