import { Pipe, PipeTransform } from '@angular/core';
import { Application, PipelineApplication } from '@fundmoreai/models';

@Pipe({
  name: 'applicationName',
  standalone: true,
})
export class ApplicationNamePipe implements PipeTransform {
  UNKNOWN_NAME = $localize`Unknown Name`;
  transform(
    application: Pick<Application, 'name'> | Pick<PipelineApplication, 'name'> | null | undefined,
  ): string {
    if (!application?.name) {
      return this.UNKNOWN_NAME;
    }

    return `${application.name}`.trim();
  }
}
