import fetch from 'unfetch';
import { ScoreConfig, RocketExtraConfig } from '../interfaces/score.config';

// eslint-disable-next-line prefer-const
export interface StorageState {
  getScoreConfig(tenantId: string, s3BasePath: string): Promise<ScoreConfig | null>;
  getRocketExtraConfig(tenantId: string, s3BasePath: string): Promise<RocketExtraConfig | null>;
}

const CACHE_STORE: {
  [url: string]: { timestamp: number; data: ScoreConfig | null };
} = {};
/** 60 minutes */
const CACHE_TTL = 60 * 60000;

class S3StorageState implements StorageState {
  /** 5 minutes */

  private async getDefaultScoreConfig(s3BasePath: string): Promise<ScoreConfig | null> {
    const now = Date.now();
    const url = `${s3BasePath}/score/any/score.config.json`;

    if (CACHE_STORE[url] && now - CACHE_STORE[url].timestamp < CACHE_TTL) {
      return CACHE_STORE[url].data;
    }

    const settings = { method: 'GET' };
    try {
      const response = await fetch(url, settings);
      if (!response.ok) {
        return null;
      }
      const data = await response.json();

      CACHE_STORE[url] = {
        timestamp: now,
        data,
      };

      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private async getTenantScoreConfig(
    tenantId: string,
    s3BasePath: string,
  ): Promise<ScoreConfig | null> {
    const now = Date.now();
    const url = `${s3BasePath}/score/${tenantId}/score.config.json`;

    if (CACHE_STORE[url] && now - CACHE_STORE[url].timestamp < CACHE_TTL) {
      return CACHE_STORE[url].data;
    }

    const settings = { method: 'GET' };
    try {
      const response = await fetch(url, settings);
      if (!response.ok) {
        return null;
      }
      const data = await response.json();

      CACHE_STORE[url] = {
        timestamp: now,
        data,
      };

      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getScoreConfig(tenantId: string, s3BasePath: string): Promise<ScoreConfig | null> {
    let tenantConfig = await this.getTenantScoreConfig(tenantId, s3BasePath);
    if (!tenantConfig) {
      tenantConfig = await this.getDefaultScoreConfig(s3BasePath);
    }
    return tenantConfig;
  }

  async getRocketExtraConfig(
    tenantId: string,
    s3BasePath: string,
  ): Promise<RocketExtraConfig | null> {
    const url = `${s3BasePath}/score/${tenantId}/rocket.config.json`;
    const settings = { method: 'GET' };
    return fetch(url, settings)
      .then((res) => res.json())
      .then((json) => {
        return json;
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  }
}

export let StorageState: StorageState = new S3StorageState();
