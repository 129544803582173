export enum MMSNotificationType {
  DealStatus = 'DealStatus',
  Notes = 'Notes',
  TransactionStatus = 'TransactionStatus',
  DealUpdates = 'DealUpdates',
  Milestones = 'Milestones',
  FCTLawyerDocuments = 'FCTLawyerDocuments',
  Disbursement = 'Disbursement',
  ReleaseHold = 'ReleaseHold',
}
