import {
  Applicant,
  Property,
  ApplicationOtherIncome,
  FinancialLiability,
  Mortgage,
  Fee,
  MortgageCalculationAutomationSettings,
  PropertyType,
  Summary,
  ComputedRatioRateType,
  LoanType,
  PaymentFrequency,
  CalculationResult,
} from '@fundmoreai/models';
import { computeExistingMortgageAmount } from './existing-mortgage-amount.calculator';
import { computeCapFeesMaxPercentage } from './capped-fees.calculator';
import { computeCltv } from './cltv.calculator';
import { computeDebt } from './debt.calculator';
import { computeExistingMortgagePayments } from './existing-mortgage-payments.calculator';
import { computeGDS } from './gds.calculator';
import { computeLoanAmountForLTV } from './loan-amount-for-ltv.calculator';
import { computeLTV } from './ltv.calculator';
import { computeMortgageMonthlyPayment } from './mortgage-payment.calculator';
import { computePropertyValue } from './property-value.calculator';
import { computeQualifyingRate } from './qualifying-rate.calculator';
import { computeTDS } from './tds.calculator';
import { computeTotalMonthlyIncomeWithoutPropertyRentalIncome } from './total-income.calculator';
import {
  computeTotalLoanAmountWithoutCapFees,
  computeTotalLoanAmount,
} from './total-loan-amount.calculators';
import { FundmoreCalculator } from '..';

const EMPTY_CALCULATION_RESULT: CalculationResult = {
  formula: '',
  items: [],
  result: 0,
};

export type MortgageInput = Pick<
  Mortgage,
  | 'id'
  | 'firstRegularPaymentDate'
  | 'maturityDate'
  | 'totalLoanAmount'
  | 'netRate'
  | 'paymentAmount'
  | 'paymentFrequency'
  | 'compounding'
  | 'amortizationMonths'
  | 'repaymentType'
  | 'termMonths'
  | 'closingDate'
  | 'interestAdjustmentDate'
  | 'insuranceAmount'
  | 'monthlyPayment'
  | 'loanType'
  | 'amortizedMonthlyPayment'
  | 'amortizedMonthlyPaymentSnapshot'
  | 'loanAmount'
  | 'includePremiumInMortgage'
  | 'propertyId'
>;

export const computeSummaryData = (
  applicants: Applicant[],
  properties: Property[],
  otherIncomes: ApplicationOtherIncome[],
  liabilities: FinancialLiability[],
  requestedMortgages: MortgageInput[],
  primaryProperty: Property | undefined,
  existingMortgages: Partial<Mortgage>[],
  fees: Fee[],
  boc: number,
  mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
  isBlanketEnabled: boolean,
) => {
  const primaryPropertyValue = computePropertyValue(primaryProperty);
  const existingMortgageAmountPrimary = computeExistingMortgageAmount(
    existingMortgages.filter((x) => x.propertyId === primaryProperty?.id),
  );
  let totalLoanAmountWithoutCapFees = 0;
  requestedMortgages.forEach((requestedMortgage) => {
    totalLoanAmountWithoutCapFees +=
      computeTotalLoanAmountWithoutCapFees(requestedMortgage, requestedMortgage.insuranceAmount) ??
      0;
  });
  totalLoanAmountWithoutCapFees = Math.round(totalLoanAmountWithoutCapFees * 100) / 100;

  const capFeesMaxPercentage =
    computeCapFeesMaxPercentage(
      fees,
      totalLoanAmountWithoutCapFees,
      existingMortgageAmountPrimary,
      primaryPropertyValue,
    ) ?? 0;

  const loanAmount = computeLoanAmountForLTV(requestedMortgages, fees, capFeesMaxPercentage);

  // Per application LTV
  const handledProperties: string[] = [];
  const propertyValue = isBlanketEnabled
    ? primaryPropertyValue
    : requestedMortgages.reduce((acc, requestedMortgage) => {
        const property = properties.find((x) => x.id === requestedMortgage.propertyId);

        if (property && !handledProperties.includes(property.id)) {
          handledProperties.push(property.id);
          return acc + FundmoreCalculator.computePropertyValue(property);
        }

        return acc;
      }, 0);

  const existingMortgagesAmount = isBlanketEnabled
    ? existingMortgageAmountPrimary
    : requestedMortgages.reduce((acc, requestedMortgage) => {
        const existingMortgagesForProperty = existingMortgages.filter(
          (x) => x.propertyId === requestedMortgage.propertyId,
        );
        return acc + computeExistingMortgageAmount(existingMortgagesForProperty);
      }, 0);

  const ltv = computeLTV(propertyValue, existingMortgagesAmount, loanAmount);

  let mortgagePaymentsTotal = 0,
    qualifyingMortgagePaymentTotal = 0,
    benchmarkMortgagePaymentTotal = 0;
  let qualifyingRateTotal = 0;
  let principalTotal = 0,
    qualifyingPrincipalTotal = 0,
    benchmarkPrincipalTotal = 0;
  let interestTotal = 0,
    qualifyingInterestTotal = 0,
    benchmarkInterestTotal = 0;
  const benchmarkRate = boc ?? 0;

  requestedMortgages.forEach((requestedMortgage) => {
    const netRate = requestedMortgage?.netRate;
    const qualifyingRate = computeQualifyingRate(netRate, boc);
    const isLOC =
      requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
      requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX;

    const monthlyPayment = isLOC
      ? requestedMortgage.amortizedMonthlyPayment
      : !mortgageCalculationAutomationSettings ||
        !mortgageCalculationAutomationSettings[requestedMortgage.id]?.isMonthlyPaymentDisabled
      ? computeMortgageMonthlyPayment(
          requestedMortgage,
          requestedMortgage.totalLoanAmount,
          netRate,
        ) || 0
      : requestedMortgage.monthlyPayment;

    mortgagePaymentsTotal += monthlyPayment;
    qualifyingRateTotal += qualifyingRate;

    const requestedMortgageForCalculation = isLOC
      ? {
          ...requestedMortgage,
          ...requestedMortgage.amortizedMonthlyPaymentSnapshot,
        }
      : requestedMortgage;

    const qualifyingMortgageMonthlyPayment =
      computeMortgageMonthlyPayment(
        requestedMortgageForCalculation,
        requestedMortgageForCalculation.totalLoanAmount ?? 0,
        qualifyingRate,
      ) || 0;

    qualifyingMortgagePaymentTotal += qualifyingMortgageMonthlyPayment;

    const benchmarkMortgageMonthlyPayment =
      computeMortgageMonthlyPayment(
        requestedMortgageForCalculation,
        requestedMortgageForCalculation.totalLoanAmount ?? 0,
        benchmarkRate,
      ) || 0;

    benchmarkMortgagePaymentTotal += benchmarkMortgageMonthlyPayment;

    const { interest, principal } = FundmoreCalculator.computePrincipalAndInterest({
      ...requestedMortgageForCalculation,
      paymentFrequency: PaymentFrequency.MONTHLY,
      paymentAmount: monthlyPayment || null,
    });

    principalTotal += principal;
    interestTotal += interest;

    const { interest: qualifyingInterest, principal: qualifyingPrincipal } =
      FundmoreCalculator.computePrincipalAndInterest({
        ...requestedMortgageForCalculation,
        paymentFrequency: PaymentFrequency.MONTHLY,
        paymentAmount: qualifyingMortgageMonthlyPayment || null,
        netRate: qualifyingRate,
      });
    qualifyingPrincipalTotal += qualifyingPrincipal;
    qualifyingInterestTotal += qualifyingInterest;

    const { interest: benchmarkInterest, principal: benchmarkPrincipal } =
      FundmoreCalculator.computePrincipalAndInterest({
        ...requestedMortgageForCalculation,
        paymentFrequency: PaymentFrequency.MONTHLY,
        paymentAmount: benchmarkMortgageMonthlyPayment || null,
        netRate: benchmarkRate,
      });
    benchmarkPrincipalTotal += benchmarkPrincipal;
    benchmarkInterestTotal += benchmarkInterest;
  });

  const otherProperties = properties.filter((x) => x.type == PropertyType.OTHER);
  const totalMonthlyIncome = computeTotalMonthlyIncomeWithoutPropertyRentalIncome(
    applicants,
    otherIncomes,
  );
  const existingMortgagesOnPrimaryProperty = existingMortgages.filter(
    (x) => x.propertyId == primaryProperty?.id,
  );
  const existingMortgagesPaymentOnPrimaryProperty = computeExistingMortgagePayments(
    existingMortgagesOnPrimaryProperty,
  );
  const debt = computeDebt(liabilities, applicants);

  const allReqMortgagesAreLOC = requestedMortgages.every(
    (rm) =>
      rm?.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
      rm?.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX,
  );

  const gds = computeGDS(
    mortgagePaymentsTotal,
    primaryProperty,
    otherProperties,
    existingMortgages,
    existingMortgagesPaymentOnPrimaryProperty,
    totalMonthlyIncome,
    principalTotal,
    interestTotal,
    mortgageCalculationAutomationSettings,

    undefined,
    allReqMortgagesAreLOC,
  );

  const tds = computeTDS(
    mortgagePaymentsTotal,
    primaryProperty,
    otherProperties,
    existingMortgages,
    existingMortgagesPaymentOnPrimaryProperty,
    totalMonthlyIncome,
    debt,
    principalTotal,
    interestTotal,
    mortgageCalculationAutomationSettings,
    undefined,
    allReqMortgagesAreLOC,
  );

  const qgds = computeGDS(
    qualifyingMortgagePaymentTotal,
    primaryProperty,
    otherProperties,
    existingMortgages,
    existingMortgagesPaymentOnPrimaryProperty,
    totalMonthlyIncome,
    qualifyingPrincipalTotal,
    qualifyingInterestTotal,
    null,

    ComputedRatioRateType.QUALIFYING,
    allReqMortgagesAreLOC,
  );

  const qtds = computeTDS(
    qualifyingMortgagePaymentTotal,
    primaryProperty,
    otherProperties,
    existingMortgages,
    existingMortgagesPaymentOnPrimaryProperty,
    totalMonthlyIncome,
    debt,
    qualifyingPrincipalTotal,
    qualifyingInterestTotal,
    null,
    ComputedRatioRateType.QUALIFYING,
    allReqMortgagesAreLOC,
  );

  const bgds = computeGDS(
    benchmarkMortgagePaymentTotal,
    primaryProperty,
    otherProperties,
    existingMortgages,
    existingMortgagesPaymentOnPrimaryProperty,
    totalMonthlyIncome,
    benchmarkPrincipalTotal,
    benchmarkInterestTotal,
    null,

    ComputedRatioRateType.BENCHMARK,
    allReqMortgagesAreLOC,
  );

  const btds = computeTDS(
    benchmarkMortgagePaymentTotal,
    primaryProperty,
    otherProperties,
    existingMortgages,
    existingMortgagesPaymentOnPrimaryProperty,
    totalMonthlyIncome,
    debt,
    benchmarkPrincipalTotal,
    benchmarkInterestTotal,
    null,
    ComputedRatioRateType.BENCHMARK,
    allReqMortgagesAreLOC,
  );

  const cLtv = computeCltv(
    requestedMortgages,
    existingMortgages,
    properties,
    fees,
    capFeesMaxPercentage ?? 0.0,
  );

  const linkedSecurities = requestedMortgages
    .filter((m) => m.loanType !== LoanType.BRIDGE)
    .reduce((acc, mortgage) => {
      if (mortgage.propertyId) {
        acc[mortgage.propertyId] = acc[mortgage.propertyId] || [];
        acc[mortgage.propertyId].push(mortgage);
      }
      return acc;
    }, {} as Record<string, MortgageInput[]>);

  let ltvBySecurity: { [key: string]: CalculationResult } | undefined = undefined;

  if (!isBlanketEnabled) {
    ltvBySecurity = {};
    Object.entries(linkedSecurities).forEach(([propertyId, mortgages]) => {
      const linkedProperty = properties?.find((x) => x.id === propertyId);

      const linkedPropertyValue = FundmoreCalculator.computePropertyValue(linkedProperty);

      const linkedPropertyExistingMortgages = existingMortgages.filter(
        (m) => propertyId === m.propertyId,
      );

      const linkedPropertyExistingMortgageAmount = FundmoreCalculator.computeExistingMortgageAmount(
        linkedPropertyExistingMortgages,
      );

      const loanAmountForLTV = FundmoreCalculator.computeLoanAmountForLTV(
        mortgages,
        fees || [],
        capFeesMaxPercentage,
      );

      ltvBySecurity[propertyId] = FundmoreCalculator.computeLTV(
        linkedPropertyValue,
        linkedPropertyExistingMortgageAmount,
        loanAmountForLTV,
      );
    });
  }

  const ltiBySecurity = FundmoreCalculator.computeLTIBySecurity(
    requestedMortgages,
    existingMortgages,
    otherProperties,
    totalMonthlyIncome,
    fees,
    capFeesMaxPercentage,
  );

  const lti = primaryProperty ? ltiBySecurity?.[primaryProperty.id] : undefined;

  const summary: Summary = {
    lti,
    ltv,
    gds,
    tds,
    qgds,
    qtds,
    bgds,
    btds,
    cLtv,
    ltvBySecurity,
    qualifyingRate: qualifyingRateTotal,
    bocRate: benchmarkRate,
  };

  return summary;
};

export const computeSummaryDataPerRequestedMortgage = (
  applicants: Applicant[],
  properties: Property[],
  otherIncomes: ApplicationOtherIncome[],
  liabilities: FinancialLiability[],
  requestedMortgages: MortgageInput[],
  servicingMortgage: MortgageInput,
  primaryProperty: Property,
  existingMortgages: Partial<Mortgage>[],
  fees: Fee[],
  boc: number,
  mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
  isBlanketEnabled: boolean,
  tenantCode: string,
  takeFullFirstPayment: boolean,
): (Summary & { mortgageId?: string })[] => {
  const existingMortgagesForPrimaryProperty = existingMortgages.filter(
    (m) => m.propertyId === primaryProperty.id,
  );
  const primaryPropertyValue = computePropertyValue(primaryProperty);
  const existingMortgageAmountPrimary = computeExistingMortgageAmount(
    existingMortgages.filter((x) => x.propertyId === primaryProperty.id),
  );

  let totalLoanAmountWithoutCapFees = 0;
  requestedMortgages.forEach((requestedMortgage) => {
    totalLoanAmountWithoutCapFees +=
      computeTotalLoanAmountWithoutCapFees(requestedMortgage, requestedMortgage.insuranceAmount) ??
      0;
  });
  totalLoanAmountWithoutCapFees = Math.round(totalLoanAmountWithoutCapFees * 100) / 100;

  const capFeesMaxPercentage =
    computeCapFeesMaxPercentage(
      fees,
      totalLoanAmountWithoutCapFees,
      existingMortgageAmountPrimary,
      primaryPropertyValue,
    ) ?? 0;

  const benchmarkRate = boc ?? 0;

  const totalIncomeWithoutPropertyRental = computeTotalMonthlyIncomeWithoutPropertyRentalIncome(
    applicants,
    otherIncomes,
  );

  const ltiBySecurity = FundmoreCalculator.computeLTIBySecurity(
    requestedMortgages,
    existingMortgages,
    properties,
    totalIncomeWithoutPropertyRental,
    fees,
    capFeesMaxPercentage,
  );

  const mortgageSummaryData = requestedMortgages.map((requestedMortgage) => {
    const loanAmountForLTV = computeLoanAmountForLTV(
      [requestedMortgage],
      fees,
      capFeesMaxPercentage,
    );

    // Per mortgage LTV
    const linkedProperty = properties.find((x) => x.id === requestedMortgage.propertyId);
    const linkedPropertyExistingMortgages = existingMortgages.filter(
      (x) => x.propertyId === requestedMortgage.propertyId,
    );

    const propertyValue = isBlanketEnabled
      ? primaryPropertyValue
      : computePropertyValue(linkedProperty);

    const existingMortgagesAmount = isBlanketEnabled
      ? existingMortgageAmountPrimary
      : computeExistingMortgageAmount(linkedPropertyExistingMortgages);

    const ltv = computeLTV(propertyValue, existingMortgagesAmount, loanAmountForLTV);

    const totalLoanAmount =
      Math.round(
        computeTotalLoanAmount(requestedMortgage, fees, requestedMortgage.insuranceAmount, 0) * 100,
      ) / 100;

    const netRate = requestedMortgage?.netRate;
    const isLOC =
      requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
      requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX;

    const monthlyPayment = isLOC
      ? requestedMortgage.amortizedMonthlyPayment
      : !mortgageCalculationAutomationSettings ||
        !mortgageCalculationAutomationSettings[requestedMortgage.id]?.isMonthlyPaymentDisabled
      ? computeMortgageMonthlyPayment(requestedMortgage, totalLoanAmount, netRate)
      : requestedMortgage?.monthlyPayment;

    const requestedMortgageForCalculation = isLOC
      ? {
          ...requestedMortgage,
          ...requestedMortgage.amortizedMonthlyPaymentSnapshot,
        }
      : requestedMortgage;

    const { interest, principal } = FundmoreCalculator.computePrincipalAndInterest({
      ...requestedMortgageForCalculation,
      paymentFrequency: PaymentFrequency.MONTHLY,
      paymentAmount: monthlyPayment || null,
    });

    const securitiesIdsForBlanket = properties
      .filter((x) => x.type === PropertyType.PRIMARY || x.isCollateralized)
      .map((x) => x.id);

    // Use existing mortgages for the property linked to the requested mortgage if NOT blanket,
    // otherwise use existing mortgages for all securities
    const existingMortgagesForPropertyLinkedToRequestedMortgage = existingMortgages.filter(
      (x) =>
        (!isBlanketEnabled && x.propertyId === requestedMortgage.propertyId) ||
        (isBlanketEnabled && x.propertyId && securitiesIdsForBlanket.includes(x.propertyId)),
    );
    const existingMortgagesForPropertyLinkedToRequestedMortgagePayments =
      FundmoreCalculator.computeExistingMortgagePayments(
        existingMortgagesForPropertyLinkedToRequestedMortgage,
      );

    // Use the linked property if NOT blanket, otherwise use the primary property
    const requestedMortgageProperty = properties.find(
      (x) =>
        (!isBlanketEnabled && x.id === requestedMortgage.propertyId) ||
        (isBlanketEnabled && x.type === PropertyType.PRIMARY),
    );

    const otherProperties = properties.filter((x) => x.type == PropertyType.OTHER);
    const existingMortgagePayments = computeExistingMortgagePayments(
      existingMortgagesForPrimaryProperty,
    );

    const debt = computeDebt(liabilities, applicants);

    const gds = computeGDS(
      monthlyPayment,
      requestedMortgageProperty,
      otherProperties,
      existingMortgagesForPropertyLinkedToRequestedMortgage,
      existingMortgagesForPropertyLinkedToRequestedMortgagePayments,
      totalIncomeWithoutPropertyRental,
      principal,
      interest,
      mortgageCalculationAutomationSettings,
      undefined,
      isLOC,
    );

    const tds = computeTDS(
      monthlyPayment,
      primaryProperty,
      otherProperties,
      existingMortgagesForPrimaryProperty,
      existingMortgagePayments,
      totalIncomeWithoutPropertyRental,
      debt,
      principal,
      interest,
      mortgageCalculationAutomationSettings,
      undefined,
      isLOC,
    );

    const qualifyingRate = computeQualifyingRate(netRate ?? null, boc);
    const qualifyingMortgageMonthlyPayment = computeMortgageMonthlyPayment(
      requestedMortgageForCalculation,
      totalLoanAmount,
      qualifyingRate,
    );

    const { interest: qualifyingInterest, principal: qualifyingPrincipal } =
      FundmoreCalculator.computePrincipalAndInterest({
        ...requestedMortgageForCalculation,
        paymentFrequency: PaymentFrequency.MONTHLY,
        paymentAmount: qualifyingMortgageMonthlyPayment || null,
        netRate: qualifyingRate,
      });

    const qgds = computeGDS(
      qualifyingMortgageMonthlyPayment,
      requestedMortgageProperty,
      otherProperties,
      existingMortgagesForPropertyLinkedToRequestedMortgage,
      existingMortgagesForPropertyLinkedToRequestedMortgagePayments,
      totalIncomeWithoutPropertyRental,
      qualifyingPrincipal,
      qualifyingInterest,
      null,
      ComputedRatioRateType.QUALIFYING,
      isLOC,
    );

    const qtds = computeTDS(
      qualifyingMortgageMonthlyPayment,
      primaryProperty,
      otherProperties,
      existingMortgagesForPrimaryProperty,
      existingMortgagePayments,
      totalIncomeWithoutPropertyRental,
      debt,
      qualifyingPrincipal,
      qualifyingInterest,
      null,
      ComputedRatioRateType.QUALIFYING,
      isLOC,
    );

    const benchmarkMortgageMonthlyPayment = computeMortgageMonthlyPayment(
      requestedMortgageForCalculation,
      totalLoanAmount,
      benchmarkRate,
    );

    const { interest: benchmarkInterest, principal: benchmarkPrincipal } =
      FundmoreCalculator.computePrincipalAndInterest({
        ...requestedMortgageForCalculation,
        paymentFrequency: PaymentFrequency.MONTHLY,
        paymentAmount: benchmarkMortgageMonthlyPayment || null,
        netRate: benchmarkRate,
      });

    const bgds = computeGDS(
      benchmarkMortgageMonthlyPayment,
      requestedMortgageProperty,
      otherProperties,
      existingMortgagesForPropertyLinkedToRequestedMortgage,
      existingMortgagesForPropertyLinkedToRequestedMortgagePayments,
      totalIncomeWithoutPropertyRental,
      benchmarkPrincipal,
      benchmarkInterest,
      null,

      ComputedRatioRateType.BENCHMARK,
      isLOC,
    );

    const btds = computeTDS(
      benchmarkMortgageMonthlyPayment,
      primaryProperty,
      otherProperties,
      existingMortgagesForPrimaryProperty,
      existingMortgagePayments,
      totalIncomeWithoutPropertyRental,
      debt,
      benchmarkPrincipal,
      benchmarkInterest,
      null,
      ComputedRatioRateType.BENCHMARK,
      isLOC,
    );

    const cLtv = computeCltv(
      [requestedMortgage],
      existingMortgagesForPrimaryProperty,
      properties,
      fees,
      capFeesMaxPercentage ?? 0.0,
    );

    const lti = ltiBySecurity[linkedProperty.id];

    let apr =
      FundmoreCalculator.computeAPR(
        requestedMortgage,
        totalLoanAmount,
        fees || [],
        tenantCode,
        takeFullFirstPayment,
        requestedMortgages.length > 1,
      ) ?? 0;

    apr = Math.round(apr * 1000) / 1000;

    return {
      lti,
      ltv,
      gds,
      tds,
      qgds,
      qtds,
      bgds,
      btds,
      cLtv,
      apr,
      qualifyingRate,
      bocRate: benchmarkRate,
      mortgageId: requestedMortgage.id,
    };
  });

  if (servicingMortgage) {
    let servicingApr =
      FundmoreCalculator.computeAPR(
        servicingMortgage,
        servicingMortgage.totalLoanAmount,
        fees || [],
        tenantCode,
        takeFullFirstPayment,
        false,
      ) ?? 0;

    servicingApr = Math.round(servicingApr * 1000) / 1000;

    const servicingMortgageSummaryData = {
      apr: servicingApr,
      qualifyingRate: 0,
      bocRate: 0,
      ltvBySecurity: {},
      ltiBySecurity: {},
      lti: EMPTY_CALCULATION_RESULT,
      ltv: EMPTY_CALCULATION_RESULT,
      gds: EMPTY_CALCULATION_RESULT,
      tds: EMPTY_CALCULATION_RESULT,
      qgds: EMPTY_CALCULATION_RESULT,
      qtds: EMPTY_CALCULATION_RESULT,
      bgds: EMPTY_CALCULATION_RESULT,
      btds: EMPTY_CALCULATION_RESULT,
      cLtv: EMPTY_CALCULATION_RESULT,
      mortgageId: servicingMortgage.id,
    };

    mortgageSummaryData.push(servicingMortgageSummaryData);
  }

  return mortgageSummaryData;
};
