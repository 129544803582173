import { PropertyAddressDetails } from '@fundmoreai/models';
import { ConfigurationState } from '../configs/configuration-state';

export function getDistanceFromLatLonInKm(
  from: { lat: number; lng: number },
  to: { lat: number; lng: number },
) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(to.lat - from.lat);
  const dLon = deg2rad(to.lng - from.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(from.lat)) *
      Math.cos(deg2rad(to.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function isWithinKmFromAMajorCity(address: PropertyAddressDetails, km: number): boolean {
  const excaliburRentalProgramCities = ConfigurationState.cities.filter(
    (x) => x.excalibur_rental_program,
  );
  if (
    excaliburRentalProgramCities
      .map((x) => x.city.toLowerCase())
      .includes(address.city?.toLowerCase() ?? '')
  ) {
    return true;
  }
  if (
    excaliburRentalProgramCities.some((x) => {
      const distance = getDistanceFromLatLonInKm(
        { lat: address.lat ?? 0, lng: address.lng ?? 0 },
        { lat: x.lat, lng: x.lng },
      );

      return distance <= km;
    })
  ) {
    return true;
  }

  return false;
}

export function isWithinKmFromCitiesOverPopulationOf(
  address: PropertyAddressDetails,
  km: number,
  population: number,
): boolean {
  const cities = ConfigurationState.cities
    .filter((x) => x.population >= population)
    .map((x) => x.city);

  return isWithinKmFromCities(address, km, cities);
}

export function isWithinKmFromCities(
  address: PropertyAddressDetails,
  km: number,
  cities: string[],
): boolean {
  if (cities.map((x) => x.toLowerCase()).includes(address.city?.toLowerCase() ?? '')) {
    return true;
  }

  const availableCities = ConfigurationState.cities.filter((x) => cities.includes(x.city));

  if (
    availableCities.some((x) => {
      const distance = getDistanceFromLatLonInKm(
        { lat: address.lat ?? 0, lng: address.lng ?? 0 },
        { lat: x.lat, lng: x.lng },
      );
      return distance <= km;
    })
  ) {
    return true;
  }

  return false;
}

export function isInGVA(city: string) {
  const gvaCities = [
    'Vancouver',
    'Surrey',
    'Burnaby',
    'Richmond',
    'Coquitlam',
    'Langley',
    'Langley City',
    'Delta',
    'North Vancouver',
    'Maple Ridge',
    'New Westminster',
    'Port Coquitlam',
    'West Vancouver',
    'Port Moody',
    'White Rock',
    'Pitt Meadows',
    'Bowen Island',
    'Anmore',
    'Lions Bay',
  ];

  return gvaCities.includes(city);
}

export function isInGTA(city: string) {
  const gtaCities = [
    'Ajax',
    'Aurora',
    'Brampton',
    'Brock',
    'Burlington',
    'Caledon',
    'Clarington',
    'East Gwillimbury',
    'Georgina',
    'Halton Hills',
    'King',
    'Markham',
    'Milton',
    'Mississauga',
    'Newmarket',
    'Oakville',
    'Oshawa',
    'Pickering',
    'Richmond Hill',
    'Scugog',
    'Toronto',
    'Uxbridge',
    'Vaughan',
    'Whitby',
    'Stouffville',
  ];

  return gtaCities.includes(city);
}
