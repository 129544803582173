export class AddressModel {
  unitNumber?: string;
  streetNumber?: string;
  streetName?: string;
  streetType?: string;
  streetDirection?: string;
  city?: string;
  province?: string;
  postalCode?: string;
  country?: string;
  streetNumberSuffix?: string;

  constructor(
    unitNumber?: string,
    streetNumber?: string,
    streetName?: string,
    streetType?: string,
    streetDirection?: string,
    city?: string,
    province?: string,
    postalCode?: string,
    country?: string,
    streetNumberSuffix?: string,
  ) {
    this.unitNumber = unitNumber;
    this.streetNumber = streetNumber;
    this.streetNumberSuffix = streetNumberSuffix;
    this.streetName = streetName;
    this.streetType = streetType;
    this.streetDirection = streetDirection;
    this.city = city;
    this.province = province;
    this.postalCode = postalCode;
    this.country = country;
  }

  toString() {
    let address = '';
    if (this.unitNumber) {
      address += `${this.unitNumber}-`;
    }
    if (this.streetNumber) {
      address += `${this.streetNumber} `;
    }
    if (this.streetNumberSuffix) {
      address += `${this.streetNumberSuffix} `;
    }
    if (this.streetName) {
      address += `${this.streetName} `;
    }
    if (this.streetType) {
      address += `${this.streetType} `;
    }
    if (this.streetDirection) {
      address += `${this.streetDirection} `;
    }
    if (this.city) {
      address += `,${this.city}, `;
    }
    if (this.province) {
      address += `${this.province}, `;
    }
    if (this.postalCode) {
      address += `${this.postalCode}, `;
    }
    if (this.country) {
      address += `${this.country}`;
    }

    return formatAddressDelimiters(address);
  }
}

function formatAddressDelimiters(address: string): string {
  return address?.replace(/ ,/g, ', ').replace(/[, ]$/g, '').trim();
}
