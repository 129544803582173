import { MMSProvince } from '../enums';

export interface MMSSaleProperty {
  SalesPropertyAddress?: MMSSalesPropertyAddress;
}

export interface MMSSalesPropertyAddress {
  Address?: string;
  city?: string;
  province?: MMSProvince;
  postalCode?: string;
}
