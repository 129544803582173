import { FinancialAsset } from './asset';
import { DownPaymentType } from './enums';

export enum DownPaymentKey {
  APPLICATION_ID = 'applicationId',
  AMOUNT = 'amount',
  CREATED_AT = 'createdAt',
  DESCRIPTION = 'description',
  ID = 'id',
  SOURCE = 'source',
  UPDATED_AT = 'updatedAt',
  FINANCIAL_ASSET_ID = 'financialAssetId',
  FINANCIAL_ASSET = 'financialAsset',
  EXTERNAL_SEQUENCE_NUMBER = 'externalSequenceNumber',
}
export interface DownPayment {
  [DownPaymentKey.APPLICATION_ID]: string;
  [DownPaymentKey.AMOUNT]: number | undefined | null;
  [DownPaymentKey.CREATED_AT]: string;
  [DownPaymentKey.DESCRIPTION]: string | null;
  [DownPaymentKey.ID]: string;
  [DownPaymentKey.SOURCE]: DownPaymentType;
  [DownPaymentKey.UPDATED_AT]: string;
  [DownPaymentKey.FINANCIAL_ASSET_ID]: string;
  [DownPaymentKey.FINANCIAL_ASSET]?: FinancialAsset;
  [DownPaymentKey.EXTERNAL_SEQUENCE_NUMBER]?: number | null;
}

export interface DownPaymentV2
  extends Omit<DownPayment, DownPaymentKey.SOURCE | DownPaymentKey.FINANCIAL_ASSET_ID> {
  [DownPaymentKey.SOURCE]: DownPaymentType | null;
  [DownPaymentKey.FINANCIAL_ASSET_ID]: string | null;
}
