import { Action, Selector, State, StateContext } from '@ngxs/store';
import { RestrictedDate } from '@fundmoreai/models';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';

import { ApplicationRestrictedDatesService } from '../application-restricted-dates.service';
import { FetchRestrictedDates } from '../application-restricted-dates.actions';

interface RestrictedDatesStateModel {
  restrictedDates: RestrictedDate[];
}

@State<RestrictedDatesStateModel>({
  name: 'applicationRestrictedDates',
  defaults: {
    restrictedDates: [],
  },
})
@Injectable()
export class ApplicationRestrictedDatesState {
  @Selector()
  static restrictedDates(state: RestrictedDatesStateModel): RestrictedDate[] {
    return state.restrictedDates;
  }

  constructor(private readonly service: ApplicationRestrictedDatesService) {}

  @Action(FetchRestrictedDates)
  fetchRestrictedDates(ctx: StateContext<RestrictedDatesStateModel>) {
    return this.service.getAll().pipe(
      tap((restrictedDates: RestrictedDate[]) => {
        ctx.patchState({
          restrictedDates,
        });
      }),
    );
  }
}
