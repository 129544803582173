import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PropertyAddressDetails } from '@fundmoreai/models';
import { finalize, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadingEnd, LoadingStart } from '../../core/loading.state';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  private base = `${environment.integrations_api_url}/google`;

  constructor(private http: HttpClient, private store: Store) {}

  getAddressExpanded(propertyAddress: string | null): Observable<PropertyAddressDetails> {
    if (!propertyAddress) {
      return of({
        id: '',
        propertyId: '',
        country: null,
        streetNumber: null,
        streetNumberSuffix: null,
        streetName: null,
        streetType: null,
        streetDirection: null,
        unit: null,
        unitType: null,
        formattedAddress: null,
        address: null,
        province: null,
        city: null,
        postalCodePrefix: null,
        postalCode: null,
        lat: null,
        lng: null,
        rawGoogleGeocodeResponse: null,
      });
    }

    this.store.dispatch(new LoadingStart(this.constructor.name));

    return this.http
      .get<PropertyAddressDetails>(
        `${this.base}/expandedAddress?address=${encodeURIComponent(propertyAddress)}`,
      )
      .pipe(
        finalize(() => {
          this.store.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }
}
