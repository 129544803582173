import { Country, Province, State, StreetDirection, StreetType } from './enums';

export enum ResidentialType {
  RESIDENTIAL = 'RESIDENTIAL',
  BUSINESS = 'BUSINESS',
  TEMPORARY = 'TEMPORARY',
  MAIL = 'MAIL',
  OTHER = 'OTHER',
  RECREATION = 'RECREATION',
  ALTERNATIVE = 'ALTERNATIVE',
  PREVIOUS = 'PREVIOUS',
  FUNDING = 'FUNDING',
}

export enum AddressExpandedType {
  CURRENT = 'CURRENT',
  MAILING = 'MAILING',
  PREVIOUS = 'PREVIOUS',
}

export enum ResidentialStatusType {
  OWN = 'OWN',
  RENT = 'RENT',
  LIVE_WITH_PARENTS = 'LIVE_WITH_PARENTS',
  OTHER = 'OTHER',
  SECONDARY = 'SECONDARY',
}

export enum CustomEQResidentialStatusTypes {
  OWNOCCUPRENTAL = '8820cb31-700e-49fe-a8bb-9def42deeff1',
}

export interface AddressExpanded {
  id: string;
  externalSequenceNumber?: number | null;
  applicantId: string | null;
  propertyId: string | null;
  jobId: string | null;
  applicationId: string | null;
  address: string | null;
  city: string | null;
  province: Province | State | null;
  country: Country | null;
  lat: number | null;
  long: number | null;
  streetNumber: string | null;
  streetNumberSuffix?: string | null;
  streetName: string | null;
  streetType: StreetType | null;
  streetDirection: StreetDirection | null;
  unit: string | null;
  postalCode: string | null;
  type: AddressExpandedType | null;
  residentialType: ResidentialType | null;
  residentialStatusType: ResidentialStatusType | CustomEQResidentialStatusTypes | null;
  timeAtResidenceMonths: number | null;
  rent: number | null;
  includeRentInCalculations: boolean;
  unitType: string | null;
  createdAt: string;
  updatedAt: string;
}

export enum AddressExpandedKey {
  ADDRESS = 'address',
  CITY = 'city',
  PROVINCE = 'province',
  COUNTRY = 'country',
  LAT = 'lat',
  LONG = 'long',
  STREET_NUMBER = 'streetNumber',
  STREET_NUMBER_SUFFIX = 'streetNumberSuffix',
  STREET_NAME = 'streetName',
  STREET_TYPE = 'streetType',
  STREET_DIRECTION = 'streetDirection',
  UNIT = 'unit',
  POSTAL_CODE = 'postalCode',
  TYPE = 'type',
  RESIDENTIAL_TYPE = 'residentialType',
  RESIDENTIAL_STATUS_TYPE = 'residentialStatusType',
  TIME_AT_RESIDENCE_MONTHS = 'timeAtResidenceMonths',
  RENT = 'rent',
  INCLUDE_RENT_IN_CALCULATIONS = 'includeRentInCalculations',
  UNIT_TYPE = 'unitType',
}

export enum AddressExpandedHumanizedKey {
  applicantId = 'Applicant Id',
  propertyId = 'Property Id',
  address = 'Address',
  city = 'City',
  province = 'Province',
  country = 'Country',
  lat = 'Latitute',
  long = 'Longitude',
  streetNumber = 'Street Number',
  streetNumberSuffix = 'Street No. Suffix',
  streetName = 'Street Name',
  streetType = 'Street Type',
  streetDirection = 'Street Direction',
  unit = 'Unit',
  postalCode = 'Postal Code',
  type = 'Type',
  residentialType = 'Residential Type',
  residentialStatusType = 'Residential Status Type',
  timeAtResidenceMonths = 'Time At Residence Months',
  rent = 'Rent',
  includeRentInCalculations = 'Include rent in calculations',
  unitType = 'Unit Type',
}
