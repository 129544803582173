import { MMSRequest } from './request';

export interface MMSResponse {
  message: string;
  timeStamp: string;
  deal: MMSRequest;
}

export interface MMSUpdateResponse {
  updateDealResponse: {
    updatedDeal: MMSRequest;
  };
  serviceMessagesResponse: string;
}

export interface MMSSuccessResponse {
  successMessage: string;
}

export function isMMSSuccessResponse(body: any): body is MMSSuccessResponse {
  return (body as MMSSuccessResponse).successMessage !== undefined;
}
