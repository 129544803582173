import {
  CalculationItemType,
  CalculationResult,
  Fee,
  Mortgage,
  Property,
} from '@fundmoreai/models';
import { FundmoreCalculator } from '..';
import { MortgageInput } from './summary-data.calculator';

export function computeLTIBySecurity(
  requestedMortgages: Partial<Mortgage>[],
  existingMortgages: Partial<Mortgage>[],
  otherProperties: Property[],
  totalIncomeWithoutPropertyRental: number,
  fees: Fee[] | undefined,
  capFeesMaxPercentage: number | undefined,
) {
  const linkedSecurities = requestedMortgages.concat(existingMortgages).reduce((acc, mortgage) => {
    if (mortgage.propertyId) {
      acc[mortgage.propertyId] = acc[mortgage.propertyId] || [];
      acc[mortgage.propertyId].push(mortgage as MortgageInput);
    }
    return acc;
  }, {} as Record<string, MortgageInput[]>);

  const result: { [key: string]: CalculationResult } = {};

  const { totalRentalIncome: totalRentalIncomeOtherProperties } =
    FundmoreCalculator.computeRentalIncomeValues(otherProperties, existingMortgages);

  const totalIncomeWithRental = totalIncomeWithoutPropertyRental + totalRentalIncomeOtherProperties;

  Object.entries(linkedSecurities).forEach(([propertyId, mortgages]) => {
    let totalLoanAmount = 0;
    for (const mortgage of mortgages) {
      totalLoanAmount +=
        FundmoreCalculator.computeTotalLoanAmount(
          mortgage,
          fees || [],
          mortgage.insuranceAmount ?? 0,
          capFeesMaxPercentage,
        ) ?? 0;
    }

    result[propertyId] = FundmoreCalculator.computeLTI(totalIncomeWithRental, totalLoanAmount);
  });

  return result;
}

export function computeLTI(
  totalIncomeWithRental?: number,
  totalLoanAmount?: number,
): CalculationResult {
  let lti = 0;

  if (totalIncomeWithRental && totalLoanAmount) {
    lti = totalLoanAmount / (totalIncomeWithRental * 12);
  }

  const data: CalculationResult = {
    formula: '(Total Loan Amount / Total Income with Rental)',
    items: [
      {
        text: 'Total Loan Amount',
        value: totalLoanAmount?.toString(),
        type: CalculationItemType.CURRENCY,
      },
      {
        text: 'Total Income with Rental',
        value: totalIncomeWithRental?.toString(),
        type: CalculationItemType.CURRENCY,
      },
    ],
    result: lti,
  };
  return data;
}
