/**
 * It is checked by BadRequestExceptionFilter in nest api's.
 * Can be used to specify if you want the error to be formatted or not.
 */
export class FormattableError {
  error: any;
  format?: boolean;

  constructor(error: any, format?: boolean) {
    this.error = error;
    this.format = format ?? true;
  }
}
