import { Column } from '../../manager-portal/condition-manage/model';
import { DatatableName } from './datatable-columns.state';

export class UpdateDatatableSelectedDisplayColumns {
  static readonly type = '@datatableColumns.UpdateDatatableSelectedDisplayColumns';

  constructor(public datatableName: DatatableName, public selectedColumns: Column[]) {}
}

export class OrderDatatableColumns {
  static readonly type = '@datatableColumns.OrderDatatableColumns';

  constructor(public datatableName: DatatableName, public columns: Column[]) {}
}

// freeze and disable column
export class FreezeDatatableColumn {
  static readonly type = '@datatableColumns.FreezeDatatableColumn';

  constructor(public datatableName: DatatableName, public field: string) {}
}

// unfreeze and enable column
export class UnfreezeDatatableColumn {
  static readonly type = '@datatableColumns.UnfreezeDatatableColumn';

  constructor(public datatableName: DatatableName, public field: string) {}
}
