import { MMSGuarantorType } from '../enums';
import { MMSAddress } from './address';
import { MMSIndividualName } from './contact';

export interface MMSGuarantor {
  guarantorType: MMSGuarantorType;
  companyName?: string;
  guarantorName?: MMSIndividualName; //required
  guarantorAddress?: MMSAddress; //required
  signatoryName?: MMSIndividualName;
  consentingSpouse?: boolean;
}
