import { ServicingApplicationPurpose } from '../application';

export enum ServicingType {
  INCREASE_PAYMENT = 'INCREASE_PAYMENT',
  DECREASE_PAYMENT = 'DECREASE_PAYMENT',
  CHANGE_PAYMENT_DATE = 'CHANGE_PAYMENT_DATE',
  CHANGE_PAYMENT_FREQUENCY = 'CHANGE_PAYMENT_FREQUENCY',
  RENEWAL_ON_MATURITY = 'RENEWAL_ON_MATURITY',
  RENEWAL_EARLY = 'RENEWAL_EARLY',
  COST_OF_BORROWING_UPDATE = 'COST_OF_BORROWING_UPDATE',
}

export const ServicingTypesByPurpose: Record<ServicingApplicationPurpose, ServicingType[]> = {
  [ServicingApplicationPurpose.PAYMENT_CHANGE]: [
    ServicingType.INCREASE_PAYMENT,
    ServicingType.DECREASE_PAYMENT,
    ServicingType.CHANGE_PAYMENT_DATE,
    ServicingType.CHANGE_PAYMENT_FREQUENCY,
  ],
  [ServicingApplicationPurpose.SERVICING_RENEWAL]: [
    ServicingType.RENEWAL_ON_MATURITY,
    ServicingType.RENEWAL_EARLY,
  ],
  [ServicingApplicationPurpose.COST_OF_BORROWING]: [ServicingType.COST_OF_BORROWING_UPDATE],
};

export const AllowedServicingTypesByServicingType: Record<ServicingType, ServicingType[]> = {
  // Payment Change
  [ServicingType.INCREASE_PAYMENT]: [
    ServicingType.CHANGE_PAYMENT_DATE,
    ServicingType.INCREASE_PAYMENT,
  ],
  [ServicingType.DECREASE_PAYMENT]: [
    ServicingType.CHANGE_PAYMENT_DATE,
    ServicingType.DECREASE_PAYMENT,
  ],
  [ServicingType.CHANGE_PAYMENT_DATE]: [
    ServicingType.CHANGE_PAYMENT_DATE,
    ServicingType.CHANGE_PAYMENT_FREQUENCY,
    ServicingType.DECREASE_PAYMENT,
    ServicingType.INCREASE_PAYMENT,
  ],
  [ServicingType.CHANGE_PAYMENT_FREQUENCY]: [
    ServicingType.CHANGE_PAYMENT_DATE,
    ServicingType.CHANGE_PAYMENT_FREQUENCY,
  ],
  // Renewal
  [ServicingType.RENEWAL_ON_MATURITY]: [ServicingType.RENEWAL_ON_MATURITY],
  [ServicingType.RENEWAL_EARLY]: [ServicingType.RENEWAL_EARLY],
  // Cost of Borrowing
  [ServicingType.COST_OF_BORROWING_UPDATE]: [ServicingType.COST_OF_BORROWING_UPDATE],
};
