import { ApplicationStage } from '@fundmoreai/models';

export interface Role {
  description: string;
  id: string;
  name: string;
  originalName?: string;
  requiresEditApplicationPermission: boolean | null;
  assignable?: boolean | null;
  notifyOnAssignment: boolean;
  emailAddressToNotify?: string | null;
  isServicing: boolean;
  ezidoxCollectorType?: number | null;
  UserRole: UserRole;
  stages?: ApplicationStage[] | null;
}

export interface UserRole {
  expiresOn?: Date | null;
}

export interface RolePermission {
  category: string;
  id: string;
  name: string;
}
