import { ApplicationStage, MoveStageModel } from '../application';

export interface GeneralAbstractPermissions {
  general: GeneralPermissions;
  applicationList: ApplicationListPermissions;
  computed: ComputedPermissions;
}

export interface GeneralAbstractPermissionsResponse {
  general: GeneralPermissions;
  applicationListOrigination: ApplicationListPermissions;
  applicationListServicing: ApplicationListPermissions;
  computedOrigination: ComputedPermissions;
  computedServicing: ComputedPermissions;
}

export interface StageMovePermissionMap {
  servicingStageMovePermissionsMap: {
    [key: string]: ApplicationStage[];
  };
  originationStageMovePermissionsMap: {
    [key: string]: ApplicationStage[];
  };
}

export interface ApplicationListPermissions {
  hierarchyUserIds: string[];
  canEdit: boolean;
  canEditAssigned: boolean;
  canEditInClosedStage: boolean;
  canEditInNewStage: boolean;
  canEditOwn: boolean;
  canFund: boolean;
  canLockApplication: boolean;
  canMoveStage: MoveStageModel[];
  canBypassTasks: boolean;
  canRevertDecision: boolean;
  listApplicationsInPipeline: boolean;
  listUnassignedApplicationsInPipeline: boolean;
  shouldConfirmApplicationStageMove: boolean;

  canArchiveApplication: boolean;
  canCreate: boolean;
  canDecline: boolean;
  canDeleteApplication: { stage: ApplicationStage[] }[];

  canEditPriority: boolean;
}

export interface GeneralPermissions {
  canUpdateOwnOutOfOfficeDetails: boolean;
  canViewReports: boolean;
  canManageSystemConfiguration: boolean;
  editUserOutOfOffice: boolean;
  editUserPreferences: boolean;
  generateDocumentsOnApplication: boolean;
  manageNotes: { read: boolean; create: boolean; delete: boolean; update: boolean };
  userManagement: { create: boolean; update: boolean; delete: boolean };
  userSettings: { read: boolean; update: boolean };
  manageBrokers: boolean;
  manageLawyers: boolean;
}

export interface ComputedPermissions {
  canDeleteApplicationInStages: ApplicationStage[];
  canMergeApplications: { [key: string]: boolean };
  canManageDuplicates: { [key: string]: boolean };
}
