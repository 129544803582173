import { ApplicationStage } from './application';

export interface User {
  externalAuthSystemId: string;
  externalEmailSequenceNumber?: number;
  ezidoxCollectorId: string;
  id: string;
  roles?: Role[] | null;
  superAdmin: boolean;
  email: string;
  lastName?: string;
  firstName?: string;
  phoneNumber: string;
  displayName?: string;
}

export interface Role {
  description: string;
  id: string;
  name: string;
  originalName?: string;
  assignable?: boolean | null;
  notifyOnAssignment?: boolean | null;
  emailAddressToNotify?: string | null;
  isServicing: boolean;
  customEmailTemplatePath?: string | null;
  UserRole?: UserRole | null;
  stages?: ApplicationStage[] | null;
}

export interface UserRole {
  expiresOn?: Date | null;
}

export enum ExternalAuthSystemType {
  COGNITO = 'COGNITO',
  API_KEY = 'API_KEY',
  ACTIVE_DIRECTORY = 'ACTIVE_DIRECTORY',
}
