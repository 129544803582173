import { ApplicationBrokerCommission } from '@fundmoreai/models';

export class SetApplicationBrokerCommissions {
  static readonly type = '@brokerCommission.SetApplicationBrokerCommissions';
  constructor(public brokerCommissions: ApplicationBrokerCommission[] | undefined | null) {}
}

export class UpsertApplicationBrokerCommission {
  static readonly type = '@brokerCommission.UpsertApplicationBrokerCommission';
  constructor(
    public applicationId: string,
    public mortgageId: string,
    public brokerCommission: ApplicationBrokerCommission,
  ) {}
}

export class RecomputeApplicationBrokerCommissionBasedOnBuydownChange {
  static readonly type =
    '@brokerCommission.RecomputeApplicationBrokerCommissionBasedOnBuydownChange';
  constructor(public applicationId: string, public mortgageId: string) {}
}
