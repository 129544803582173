import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, combineLatest, map, switchMap, EMPTY } from 'rxjs';
import { ApplicantsState } from '../../../../portal/applicants.state';
import {
  Applicant,
  CompoundPeriod,
  ConstructionApplicationItem,
  ConstructionMortgage,
  CustomerType,
  DrawSchedule,
  Fee,
  FeeType,
  Mortgage,
  MortgageCalculationAutomationSettings,
  MortgagePosition,
  Property,
  PropertyInsurance,
  PropertyViewModel,
  RepaymentType,
} from '../../../../shared/model';
import { FeesState, IndexedFees } from 'src/app/portal/fees.state';
import { PropertiesState } from 'src/app/portal/properties.state';
import { ConditionsDisplayService } from 'src/app/shared/services/conditions-display.service';
import { InsuranceState } from 'src/app/portal/insurances.state';
import { DefaultPackageService } from './default.package.service';
import { PropertyInsuranceState } from 'src/app/portal/property-insurance/property-insurances.state';
import { PropertyViewModelService } from '../../property-view-model.service';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import { ConstructionApplicationItemState } from 'src/app/portal/construction/construction-application-items.state';
import { ConstructionDrawScheduleState } from 'src/app/portal/construction/construction-draw-schedule.state';
import { ConstructionModuleState } from 'src/app/portal/construction/construction-module.state';
import { ConstructionMortgageState } from 'src/app/portal/construction/construction-mortgage.state';
import { ChargesState } from '../state/charges.state';
import {
  BaseDocumentContext,
  CalvertDocumentContext,
  Charge,
  GenerateDocumentsPackageServiceBase,
  Mortgagor,
  MortgagorGroup,
} from '../model';
import { MortgagesServices } from 'src/app/portal/mortgages.services';
import { AmortizationScheduleService } from './amortization-schedule.service';
import { MortgagesV2State } from 'src/app/portal/mortgages-v2/mortgages-v2.state';
import { DocumentTemplate } from 'src/app/features/manager-portal/documents-management/model';
import { AppFeaturesState } from 'src/app/shared/app-features.state';
import { EqCoreState } from '../../../../portal/eq-core.state';

@Injectable()
export class CalvertPackageService
  extends DefaultPackageService
  implements GenerateDocumentsPackageServiceBase
{
  constructor(
    amortizationScheduleService: AmortizationScheduleService,
    applicantsState: ApplicantsState,
    conditionDisplayService: ConditionsDisplayService,
    mortgagesServices: MortgagesServices,
    private propertyViewModelService: PropertyViewModelService,
    store: Store,
  ) {
    super(
      amortizationScheduleService,
      applicantsState,
      conditionDisplayService,
      mortgagesServices,
      store,
    );
  }

  getContext$(documentTemplate: DocumentTemplate): Observable<CalvertDocumentContext> {
    return combineLatest([
      super.getContext$(documentTemplate),
      this.store.select(ApplicantsState.lawyers),
      this.store.select(ChargesState.charges),
      this.store.select(ConstructionMortgageState.constructionMortgage),
      this.store.select(ConstructionApplicationItemState.constructionApplicationItems),
      this.store.select(ConstructionApplicationItemState.totalAmountAllowed),
      this.store.select(ConstructionApplicationItemState.totalBudgetPercentageUsed),
      this.store.select(ConstructionDrawScheduleState.drawSchedules),
      this.store.select(ConstructionModuleState.constructionModuleEnabled),
      this.store.select(FeesState.indexedFees),
      this.store.select(MortgagesV2State.firstFoundRequestedMortgage).pipe(
        switchMap((mortgage) => {
          if (!mortgage) {
            return EMPTY;
          }
          return this.store.select(InsuranceState.insuranceAmount(mortgage.id));
        }),
      ),
      this.store.select(MortgagesV2State.firstFoundRequestedMortgage).pipe(
        switchMap((mortgage) => {
          if (!mortgage) {
            return EMPTY;
          }
          return this.store.select(MortgagesV2State.netAmountToBeAdvanced(mortgage.id));
        }),
      ),
      this.store.select(MortgagesV2State.firstFoundRequestedMortgage).pipe(
        switchMap((mortgage) => {
          if (!mortgage) {
            return EMPTY;
          }
          return this.store.select(
            MortgagesV2State.totalLoanAmountByRequestedMortgage(mortgage.id),
          );
        }),
      ),
      this.store.select(PropertyInsuranceState.applicationInsurancesList),
      this.store.select(PropertiesState.propertiesListWithOwners),
      this.store.select(PropertiesState.securities),
      this.store.select(MortgagesV2State.mortgageCalculationAutomationSettings),
      this.store.select(AppFeaturesState.iadByFpdEnabled),
      this.store.select(AppFeaturesState.takeFullFirstPayment),
    ]).pipe(
      map(
        ([
          defaultContext,
          lawyers,
          charges,
          constructionMortgage,
          constructionItem,
          totalAmountAllowed,
          totalBudgetPercentageUsed,
          drawSchedules,
          enableConstructionModule,
          indexedFees,
          insuranceAmount,
          netAmountToBeAdvanced,
          totalLoanAmount,
          propertyInsurances,
          properties,
          securities,
          mortgageCalculationAutomationSettings,
          iadByFpdEnabled,
          takeFullFirstPayment,
        ]) => {
          return this.getCalvertContext(
            defaultContext,
            documentTemplate,
            lawyers,
            charges,
            constructionMortgage,
            constructionItem,
            totalAmountAllowed,
            totalBudgetPercentageUsed,
            drawSchedules,
            enableConstructionModule,
            indexedFees,
            insuranceAmount,
            totalLoanAmount,
            netAmountToBeAdvanced,
            propertyInsurances,
            properties,
            securities,
            mortgageCalculationAutomationSettings,
            iadByFpdEnabled,
            takeFullFirstPayment,
          );
        },
      ),
    );
  }

  private getCalvertContext(
    defaultContext: BaseDocumentContext,
    documentTemplate: DocumentTemplate,
    lawyers: Applicant[] | undefined,
    charges: Charge[],
    constructionMortgage: ConstructionMortgage | undefined,
    constructionApplicationItems: ConstructionApplicationItem[],
    constructionTotalAmountAllowed: number,
    constructionTotalBudgetUsed: number,
    drawSchedules: DrawSchedule[],
    enableConstructionModule: boolean,
    indexedFees: IndexedFees,
    insuranceAmount: number | null,
    totalLoanAmount: number | undefined,
    netAmountToBeAdvanced: number | null,
    propertyInsurances: PropertyInsurance[],
    properties: Property[] | undefined,
    securities: Property[] | undefined,
    mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
    iadByFpdEnabled: boolean,
    takeFullFirstPayment: boolean,
  ): CalvertDocumentContext {
    const {
      amountToBeAdvanced,
      applicants,
      existingAndRefinanceMortgages,
      fees,
      firstMortgage,
      primaryProperty,
      primaryPropertyExistingAndRefinanceMortgages,
      propertyAppraisal,
      requestedMortgage,
      secondMortgage,
    } = defaultContext;
    const mortgage = this.mortgagesServices.toCommitmentMortgage(
      requestedMortgage,
      amountToBeAdvanced,
      mortgageCalculationAutomationSettings,
      iadByFpdEnabled,
    );
    const existingAndRefinanceMortgagesFirstMortgages = existingAndRefinanceMortgages.filter(
      (m) => m.mortgageType?.trim() === MortgagePosition.FIRST,
    );
    const blendedMortgageRate = FundmoreCalculator.computeBlendedMortgageRate(
      firstMortgage?.mortgageBalance,
      firstMortgage?.netRate,
      secondMortgage?.mortgageBalance,
      secondMortgage?.netRate,
    );
    const totalMortgagePayments = FundmoreCalculator.computeTotalMortgagePayments(
      firstMortgage?.monthlyPayment,
      secondMortgage?.monthlyPayment,
    );
    const primaryPropertyOwnersTitle = primaryProperty?.PropertyOwners?.filter(
      (owner) => owner.individualNameOnTitle,
    ).map((owner) => owner.individualNameOnTitle);
    const primaryPropertyOwnersTitleAsString =
      primaryPropertyOwnersTitle?.length ?? 0 > 1
        ? primaryPropertyOwnersTitle?.slice(0, -1).join(', ') +
          ' and ' +
          primaryPropertyOwnersTitle?.slice(-1)
        : primaryPropertyOwnersTitle?.join();
    const propertiesWithMortgage = this.propertyViewModelService.propertiesMortgagesViewModel(
      properties,
      existingAndRefinanceMortgages,
    );
    const securitiesWithMortgages = this.propertyViewModelService.propertiesMortgagesViewModel(
      securities,
      existingAndRefinanceMortgages,
    );
    const dailyPerDiem =
      mortgage.netRate && mortgage.loanAmount
        ? (mortgage.netRate * mortgage.loanAmount) / 365
        : null;
    const brokerFee = this.getBrokerFee(fees);
    const commitmentFee = this.getCommitmentFee(fees);
    const lenderFee = this.getLenderFee(fees);
    const totalFees = fees.reduce((a, b) => a + b.amount, 0);
    const companyDirectorApplicants = applicants?.filter(
      (a) =>
        a.customerType === CustomerType.GUARANTOR && !!a.ApplicantCompanyDirectorDetails?.length,
    );
    const corporateApplicants = applicants?.filter(
      (a) => a.customerType?.toString() === CustomerType.COMPANY,
    );

    return {
      ...defaultContext,
      amortizationCompoundingString: this.makeAmortizationCompoundingString(requestedMortgage),
      amortizationRepaymentString: this.makeAmortizationRepaymentString(requestedMortgage),
      applicantsRowspan: this.makeApplicantsRowspan(applicants),
      blendedMortgageRate,
      brokerFee,
      calculatedAmount: netAmountToBeAdvanced ?? 0 - (mortgage.prepaymentAmount ?? 0),
      charges,
      checkCommitmentFee: !!(brokerFee || commitmentFee || lenderFee),
      clientFunds: ['Land', 'Hard Costs'],
      commitmentFee,
      commitmentFeeBalance: this.getCommitmentFeeBalance(fees),
      construction: enableConstructionModule
        ? {
            constructionMortgage,
            constructionApplicationItems,
            constructionTotalAmountAllowed,
            constructionTotalBudgetUsed,
            drawSchedules,
          }
        : undefined,
      companyDirectorApplicants,
      corporateApplicants,
      dailyPerDiem,
      dailyTotalInterest: FundmoreCalculator.computeDailyTotalInterest(
        totalLoanAmount,
        mortgage.netRate,
      ),
      existingAndRefinanceMortgages: existingAndRefinanceMortgages.filter(
        (m) => m.propertyId === primaryProperty?.id,
      ),
      existingAndRefinanceMortgagesFirstMortgages,
      fees: fees.filter(
        (f) =>
          f.type !== FeeType.COMMITMENT && f.type !== FeeType.BROKER && f.type !== FeeType.LENDER,
      ),
      guarantors: documentTemplate.options?.applicants
        ? applicants?.filter((a) =>
            documentTemplate.options
              ? documentTemplate.options.applicants?.some((x) => x.id === a.id)
              : false,
          )
        : defaultContext.guarantors,
      indexedFees,
      insuranceAmount,
      interestCalculationPeriodLongString:
        this.makeInterestCalculationPeriodLongString(requestedMortgage),
      interestCalculationPeriodShortString:
        this.makeInterestCalculationPeriodShortString(requestedMortgage),
      isCompany: securities
        ? securities.findIndex((s) => s.PropertyOwners?.find((p) => p.isCompany === true)) > -1
        : false,
      isInterestOnly: mortgage.repaymentType === RepaymentType.INTEREST_ONLY,
      lenderFee,
      mortgage,
      mortgagorGroups: this.makeMortgagorGroups(primaryProperty, securities),
      netAmountToBeAdvanced,
      primaryPropertyOwnersTitleAsString,
      properties: propertiesWithMortgage,
      propertyInsurances: propertyInsurances.filter(
        (insurance) => insurance.propertyId === primaryProperty?.id,
      ),
      scheduleA: this.makeScheduleA(securities),
      securities,
      securitiesWithMortgage: this.makeSecurities(propertyInsurances, securitiesWithMortgages),
      tcc: FundmoreCalculator.computeTCCCalvert(
        requestedMortgage,
        fees,
        requestedMortgage.monthlyPayment,
      ),
      totalCharges: insuranceAmount ?? 0 + totalFees,
      totalCommitmentFeeAmount: this.getTotalCommitmentFeeAmount(fees),
      totalLoanAmount,
      totalMortgagePayments,
      totalOfSecurities: FundmoreCalculator.getTotalOfSecurities(
        primaryProperty,
        primaryPropertyExistingAndRefinanceMortgages,
        propertyAppraisal?.appraisedValue,
        propertiesWithMortgage,
      ),
      totalPayment: this.amortizationScheduleService
        .getAmortizationSchedule(
          requestedMortgage,
          amountToBeAdvanced,
          mortgageCalculationAutomationSettings,
          iadByFpdEnabled,
          takeFullFirstPayment,
        )
        .reduce((a, b) => a + b.payment, 0),
    };
  }

  private getCommitmentFee(fees: Fee[]) {
    return fees.find((x) => x.type === FeeType.COMMITMENT);
  }

  private getCommitmentFeeBalance(fees: Fee[]) {
    const commitmentFeeBalance = fees.reduce(
      (total, fee) => (fee.type === FeeType.COMMITMENT ? total : total + fee.amount),
      0,
    );

    return commitmentFeeBalance;
  }

  private getSecurityPosition(mortgage: Mortgage[]) {
    const positionArray: number[] = [];
    const numberArray = [
      'First',
      'Second',
      'Third',
      'Fourth',
      'Fifth',
      'Sixth',
      'Seventh',
      'Eighth',
      'Ninth',
      'Tenth',
    ];

    mortgage.forEach((m) => {
      const index = numberArray.findIndex((n) => n === m.mortgageType);
      positionArray.push(index);
    });
    const largerIndex = Math.max(...positionArray);

    return numberArray[largerIndex + 1] || numberArray[1];
  }

  private getTotalCommitmentFeeAmount(fees: Fee[]) {
    const commitmentFee = this.getCommitmentFee(fees);
    const brokerFee = this.getBrokerFee(fees);
    const lenderFee = this.getLenderFee(fees);

    return (
      (brokerFee ? brokerFee.amount : 0) +
      (lenderFee ? lenderFee.amount : 0) +
      (commitmentFee ? commitmentFee.amount : 0)
    );
  }

  private makeAmortizationRepaymentString(requestedMortgage: Mortgage) {
    return requestedMortgage.repaymentType === RepaymentType.INTEREST_ONLY
      ? 'Not Applicable / Interest Only Mortgage'
      : requestedMortgage.repaymentType === RepaymentType.PRINCIPAL_AND_INTEREST
      ? 'Not Applicable / Principal and Interest'
      : requestedMortgage.amortizationMonths
      ? `${requestedMortgage.amortizationMonths} Months - ${requestedMortgage.repaymentType}`
      : `N/A`;
  }

  private makeAmortizationCompoundingString(requestedMortgage: Mortgage) {
    return requestedMortgage.compounding === CompoundPeriod.SEMI_ANNUAL
      ? `the amortization is ${(requestedMortgage.amortizationMonths ?? 0 / 12).toFixed(2)} years.`
      : requestedMortgage.compounding === CompoundPeriod.SIMPLE_INTEREST
      ? 'this mortgage is not amortized: interest only.'
      : `the amortization is ${(requestedMortgage.amortizationMonths ?? 0 / 12).toFixed(
          2,
        )} years - ${requestedMortgage.repaymentType}.`;
  }

  public makeApplicantsRowspan(applicants: Applicant[] | undefined) {
    return (
      applicants?.map((a) => {
        return (
          1 +
          (a.currentResidence?.address ? 1 : 0) +
          (a.homePhone ? 1 : 0) +
          (a.workPhone ? 1 : 0) +
          (a.cellPhone ? 1 : 0) +
          (a.email ? 1 : 0)
        );
      }) || []
    );
  }

  private makeInterestCalculationPeriodLongString(requestedMortgage: Mortgage) {
    return requestedMortgage.compounding === CompoundPeriod.SEMI_ANNUAL
      ? 'calculated semi-annually not in advance'
      : requestedMortgage.compounding === CompoundPeriod.SIMPLE_INTEREST
      ? 'simple interest'
      : requestedMortgage.compounding;
  }

  private makeInterestCalculationPeriodShortString(requestedMortgage: Mortgage) {
    return requestedMortgage.compounding === CompoundPeriod.SEMI_ANNUAL
      ? 'Semi-Annually not in advance'
      : requestedMortgage.compounding === CompoundPeriod.SIMPLE_INTEREST
      ? 'Annually (Simple Interest)'
      : requestedMortgage.compounding;
  }

  private makeScheduleA(securities: Property[] | undefined) {
    const PROPERTY_LABEL = [
      'Firstly',
      'Secondly',
      'Thirdly',
      'Fourthly',
      'Fifthly',
      'Sixthly',
      'Seventhly',
      'Eighthly',
      'Ninthly',
      'Tenthly',
    ];

    return securities?.map((security) => {
      const index = securities.indexOf(security) + 1;

      return { label: PROPERTY_LABEL[index], security };
    });
  }

  private makeSecurities(
    propertyInsurances: PropertyInsurance[],
    propertyViewModel: PropertyViewModel[],
  ) {
    return propertyViewModel.map((p) => {
      return {
        estimatedValue: p.estimatedValue,
        propertyAddress: p.propertyAddress,
        legalDescription: p.legalDescription,
        mortgages: p.Mortgages,
        title: p.PropertyOwners,
        plan: p.plan,
        block: p.block,
        lot: p.lot,
        shortLegalDescription: p.shortLegalDescription,
        CalvertMortgagePosition: this.getSecurityPosition(p.Mortgages),
        propertyInsurances: propertyInsurances.filter((insurance) => insurance.propertyId === p.id),
        externalSecurityConditions: {
          hasOtherSecurityConditions: p.hasOtherSecurityConditions ?? false,
          otherSecurityConditions: p.otherSecurityConditions,
        },
      };
    });
  }

  private makeMortgagorGroups(
    primaryProperty: Property | undefined,
    securities: Property[] | undefined,
  ) {
    const PROPERTY_LABEL = [
      'firstly',
      'secondly',
      'thirdly',
      'fourthly',
      'fifthly',
      'sixthly',
      'seventhly',
      'eighthly',
      'ninthly',
      'tenthly',
    ];

    let mortgagorGroups: MortgagorGroup[] = [];

    const primaryMortgagors: Mortgagor[] | undefined = primaryProperty?.PropertyOwners.map(
      (propertyOwner) => {
        return {
          namesOnTitle: [propertyOwner.individualNameOnTitle?.trim()],
          address: propertyOwner.address?.trim(),
        };
      },
    );

    mortgagorGroups = [
      {
        mortgagors: primaryMortgagors ?? [],
        labels: [PROPERTY_LABEL[0]],
      },
    ];

    securities?.forEach((security) => {
      const index = securities.indexOf(security) + 1;

      const securityMortgagors: Mortgagor[] = security.PropertyOwners.map((propertyOwner) => {
        return {
          namesOnTitle: [propertyOwner.individualNameOnTitle?.trim()],
          address: propertyOwner.address?.trim(),
        };
      });

      let updatedMortgagors = false;

      mortgagorGroups.every((mortgagorGroup) => {
        if (mortgagorGroup.mortgagors.length === securityMortgagors.length) {
          let same = true;

          for (let i = 0; i < mortgagorGroup.mortgagors.length; i++) {
            if (
              mortgagorGroup.mortgagors[i].namesOnTitle[0] !==
                securityMortgagors[i].namesOnTitle[0] ||
              mortgagorGroup.mortgagors[i].address !== securityMortgagors[i].address
            ) {
              same = false;
              break;
            }
          }

          if (same) {
            updatedMortgagors = true;
            mortgagorGroup.labels.push(PROPERTY_LABEL[index]);
            return false;
          }
        }

        return true;
      });

      if (!updatedMortgagors) {
        mortgagorGroups.push({
          mortgagors: securityMortgagors,
          labels: [PROPERTY_LABEL[index]],
        });
      }
    });

    mortgagorGroups.forEach((mortgagorGroup) => {
      if (mortgagorGroup.mortgagors.length > 1) {
        mortgagorGroup.mortgagors = mortgagorGroup.mortgagors.reduce((newMortgagors, mortgagor) => {
          if (newMortgagors.filter((m) => m.address === mortgagor.address).length > 0) {
            newMortgagors
              .find((m) => m.address === mortgagor.address)
              ?.namesOnTitle.push(mortgagor.namesOnTitle[0]);
          } else {
            newMortgagors.push(mortgagor);
          }

          return newMortgagors;
        }, [] as Mortgagor[]);
      }
    });

    return mortgagorGroups;
  }
}
