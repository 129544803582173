import { Action, Selector, State, StateContext, createSelector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ResetState } from 'src/app/shared';
import { DefaultWidget, ExpandWidget, SaveWidgets, ShowEditMode } from './dashboard.actions';
import { ResetEditModeState } from 'src/app/shared/state.model';
import { RouterSelectors } from 'src/app/router-state/router.selectors';

interface DashboardStateModel {
  version: number;
  loading: boolean;
  widgets: DashboardWidget[];
  widgetMobile: DashboardWidget[];
  servicingWidgets: DashboardWidget[];
  servicingWidgetMobile: DashboardWidget[];
}

export interface DashboardCard {
  cols: number;
  rows: number;
  expanded: boolean;
  editMode: boolean;
  position: number;
}
export interface DashboardWidget extends DashboardCard {
  widget: Widget;
}

export type Widget =
  | 'servicing'
  | 'income'
  | 'credit'
  | 'networth'
  | 'stakeholders'
  | 'loan'
  | 'results'
  | 'summary'
  | 'score'
  | 'construction'
  | 'flip'
  | 'conditions';

export const widgetConfig: {
  default: DashboardWidget[];
  large: DashboardWidget[];
  small: DashboardWidget[];
} = {
  default: [
    { widget: 'summary', cols: 1, rows: 2, expanded: true, editMode: false, position: 1 },
    { widget: 'score', cols: 1, rows: 2, expanded: true, editMode: false, position: 2 },
    { widget: 'results', cols: 1, rows: 2, expanded: true, editMode: false, position: 3 },
    { widget: 'flip', cols: 2, rows: 3, expanded: true, editMode: false, position: 4 },
    { widget: 'construction', cols: 2, rows: 3, expanded: true, editMode: false, position: 6 },
    { widget: 'loan', cols: 2, rows: 5, expanded: true, editMode: false, position: 7 },
    { widget: 'credit', cols: 1, rows: 2, expanded: false, editMode: false, position: 8 },
    { widget: 'income', cols: 1, rows: 2, expanded: false, editMode: false, position: 9 },
    { widget: 'networth', cols: 2, rows: 3, expanded: true, editMode: false, position: 10 },
    { widget: 'conditions', cols: 2, rows: 6, expanded: true, editMode: false, position: 12 },
    { widget: 'stakeholders', cols: 2, rows: 4, expanded: true, editMode: false, position: 11 },
  ],
  large: [
    { widget: 'summary', cols: 2, rows: 4, expanded: true, editMode: false, position: 1 },
    { widget: 'score', cols: 2, rows: 4, expanded: true, editMode: false, position: 2 },
    { widget: 'results', cols: 2, rows: 4, expanded: true, editMode: false, position: 3 },
    { widget: 'loan', cols: 2, rows: 4, expanded: true, editMode: false, position: 4 },
    { widget: 'credit', cols: 2, rows: 4, expanded: true, editMode: false, position: 5 },
    { widget: 'income', cols: 2, rows: 3, expanded: true, editMode: false, position: 6 },
    { widget: 'networth', cols: 2, rows: 5, expanded: true, editMode: false, position: 7 },
    { widget: 'construction', cols: 2, rows: 3, expanded: true, editMode: false, position: 9 },
    { widget: 'flip', cols: 2, rows: 3, expanded: true, editMode: false, position: 10 },
    { widget: 'conditions', cols: 2, rows: 6, expanded: true, editMode: false, position: 11 },
    { widget: 'stakeholders', cols: 2, rows: 4, expanded: true, editMode: false, position: 8 },
  ],
  small: [
    { widget: 'summary', cols: 2, rows: 2, expanded: false, editMode: false, position: 1 },
    { widget: 'score', cols: 2, rows: 2, expanded: false, editMode: false, position: 2 },
    { widget: 'results', cols: 2, rows: 2, expanded: false, editMode: false, position: 3 },
    { widget: 'loan', cols: 2, rows: 3, expanded: false, editMode: false, position: 4 },
    { widget: 'credit', cols: 2, rows: 2, expanded: false, editMode: false, position: 5 },
    { widget: 'income', cols: 2, rows: 2, expanded: false, editMode: false, position: 6 },
    { widget: 'networth', cols: 2, rows: 6, expanded: false, editMode: false, position: 7 },
    { widget: 'stakeholders', cols: 2, rows: 2, expanded: false, editMode: false, position: 8 },
    { widget: 'construction', cols: 2, rows: 4, expanded: false, editMode: false, position: 9 },
    { widget: 'flip', cols: 2, rows: 3, expanded: false, editMode: false, position: 10 },
    { widget: 'conditions', cols: 2, rows: 4, expanded: true, editMode: false, position: 11 },
  ],
};

export const servicingWidgetConfig: {
  default: DashboardWidget[];
  large: DashboardWidget[];
  small: DashboardWidget[];
} = {
  default: [
    { widget: 'servicing', cols: 2, rows: 4, expanded: true, editMode: false, position: 1 },
    { widget: 'summary', cols: 1, rows: 2, expanded: true, editMode: false, position: 2 },
    { widget: 'flip', cols: 2, rows: 3, expanded: true, editMode: false, position: 3 },
    { widget: 'construction', cols: 2, rows: 3, expanded: true, editMode: false, position: 4 },
    { widget: 'loan', cols: 2, rows: 5, expanded: true, editMode: false, position: 5 },
    { widget: 'credit', cols: 2, rows: 4, expanded: true, editMode: false, position: 6 },
    { widget: 'stakeholders', cols: 2, rows: 4, expanded: true, editMode: false, position: 7 },
    { widget: 'conditions', cols: 2, rows: 6, expanded: true, editMode: false, position: 8 },
  ],
  large: [
    { widget: 'servicing', cols: 2, rows: 4, expanded: true, editMode: false, position: 1 },
    { widget: 'summary', cols: 2, rows: 4, expanded: true, editMode: false, position: 2 },
    { widget: 'loan', cols: 2, rows: 4, expanded: true, editMode: false, position: 3 },
    { widget: 'credit', cols: 2, rows: 4, expanded: true, editMode: false, position: 4 },
    { widget: 'stakeholders', cols: 2, rows: 4, expanded: true, editMode: false, position: 5 },
    { widget: 'construction', cols: 2, rows: 3, expanded: true, editMode: false, position: 6 },
    { widget: 'flip', cols: 2, rows: 3, expanded: true, editMode: false, position: 7 },
    { widget: 'conditions', cols: 2, rows: 6, expanded: true, editMode: false, position: 8 },
  ],
  small: [
    { widget: 'servicing', cols: 2, rows: 4, expanded: true, editMode: false, position: 1 },
    { widget: 'summary', cols: 2, rows: 2, expanded: false, editMode: false, position: 2 },
    { widget: 'loan', cols: 2, rows: 3, expanded: false, editMode: false, position: 3 },
    { widget: 'credit', cols: 2, rows: 2, expanded: false, editMode: false, position: 4 },
    { widget: 'stakeholders', cols: 2, rows: 2, expanded: false, editMode: false, position: 5 },
    { widget: 'construction', cols: 2, rows: 4, expanded: false, editMode: false, position: 6 },
    { widget: 'flip', cols: 2, rows: 3, expanded: false, editMode: false, position: 7 },
    { widget: 'conditions', cols: 2, rows: 4, expanded: true, editMode: false, position: 8 },
  ],
};

@State<DashboardStateModel>({
  name: DashboardState.NAME,
  defaults: DashboardState.DEFAULT_STATE_VALUES,
})
@Injectable()
export class DashboardState {
  static NAME = 'dashboard';
  static DEFAULT_STATE_VALUES = {
    version: 21,
    loading: false,
    widgetMobile: widgetConfig.small,
    widgets: widgetConfig.default,
    servicingWidgetMobile: servicingWidgetConfig.small,
    servicingWidgets: servicingWidgetConfig.default,
  };

  @Selector([DashboardState, RouterSelectors.servicing])
  static cards(state: DashboardStateModel, isServicingEnabled: boolean | undefined) {
    return isServicingEnabled ? state.servicingWidgets : state.widgets;
  }

  @Selector([DashboardState, RouterSelectors.servicing])
  static cardsMobile(state: DashboardStateModel, isServicingEnabled: boolean | undefined) {
    return isServicingEnabled ? state.servicingWidgetMobile : state.widgetMobile;
  }

  static inEditMode(widget: Widget) {
    return createSelector([DashboardState.cards], (widgets: DashboardWidget[]) => {
      const stateWidget = widgets.find((w) => w.widget === widget);

      return stateWidget?.editMode;
    });
  }

  constructor(private store: Store) {}

  @Action(ExpandWidget)
  expandWidget(ctx: StateContext<DashboardStateModel>, { value }: ExpandWidget) {
    if (this.store.selectSnapshot(RouterSelectors.servicing)) {
      const widgetLarge = servicingWidgetConfig.large.find((a) => a.widget === value.widget);

      if (!widgetLarge) {
        return;
      }

      const state = ctx.getState();
      const servicingWidgets = state.servicingWidgets.map((widgetObj) =>
        widgetObj.widget === value.widget ? { ...widgetLarge } : widgetObj,
      );
      const servicingWidgetMobile = state.servicingWidgetMobile.map((widgetObj) =>
        widgetObj.widget === value.widget ? { ...widgetLarge } : widgetObj,
      );

      ctx.patchState({
        ...state,
        servicingWidgets,
        servicingWidgetMobile,
      });

      return;
    }

    const widgetLarge = widgetConfig.large.find((a) => a.widget === value.widget);

    if (!widgetLarge) {
      return;
    }

    const state = ctx.getState();
    const widgets = state.widgets.map((widgetObj) =>
      widgetObj.widget === value.widget ? { ...widgetLarge } : widgetObj,
    );
    const widgetMobile = state.widgetMobile.map((widgetObj) =>
      widgetObj.widget === value.widget ? { ...widgetLarge } : widgetObj,
    );

    ctx.patchState({
      ...state,
      widgets,
      widgetMobile,
    });
  }

  @Action(DefaultWidget)
  defaultWidget(ctx: StateContext<DashboardStateModel>, { value }: DefaultWidget) {
    if (this.store.selectSnapshot(RouterSelectors.servicing)) {
      const widgetDefault = servicingWidgetConfig.default.find((a) => a.widget === value.widget);

      if (!widgetDefault) {
        return;
      }

      const state = ctx.getState();
      const servicingWidgets = state.servicingWidgets.map((widgetObj) =>
        widgetObj.widget === value.widget ? { ...widgetDefault } : widgetObj,
      );
      const servicingWidgetMobile = state.servicingWidgetMobile.map((widgetObj) =>
        widgetObj.widget === value.widget ? { ...widgetDefault } : widgetObj,
      );

      ctx.patchState({
        ...state,
        servicingWidgets,
        servicingWidgetMobile,
      });

      return;
    }

    const widgetDefault = widgetConfig.default.find((a) => a.widget === value.widget);

    if (!widgetDefault) {
      return;
    }

    const state = ctx.getState();
    const widgets = state.widgets.map((widgetObj) =>
      widgetObj.widget === value.widget ? { ...widgetDefault } : widgetObj,
    );
    const widgetMobile = state.widgetMobile.map((widgetObj) =>
      widgetObj.widget === value.widget ? { ...widgetDefault } : widgetObj,
    );

    ctx.patchState({
      ...state,
      widgets,
      widgetMobile,
    });
  }

  @Action(ShowEditMode)
  showEditMode(ctx: StateContext<DashboardStateModel>, { value }: ShowEditMode) {
    const state = ctx.getState();

    if (this.store.selectSnapshot(RouterSelectors.servicing)) {
      const servicingWidgets = state.servicingWidgets.map((widgetObj) =>
        widgetObj.widget === value.widget
          ? { ...widgetObj, editMode: !widgetObj.editMode }
          : widgetObj,
      );
      const servicingWidgetMobile = state.servicingWidgetMobile.map((widgetObj) =>
        widgetObj.widget === value.widget
          ? { ...widgetObj, editMode: !widgetObj.editMode }
          : widgetObj,
      );

      ctx.patchState({
        ...state,
        servicingWidgets,
        servicingWidgetMobile,
      });

      return;
    }

    const widgets = state.widgets.map((widgetObj) =>
      widgetObj.widget === value.widget
        ? { ...widgetObj, editMode: !widgetObj.editMode }
        : widgetObj,
    );
    const widgetMobile = state.widgetMobile.map((widgetObj) =>
      widgetObj.widget === value.widget
        ? { ...widgetObj, editMode: !widgetObj.editMode }
        : widgetObj,
    );

    ctx.patchState({
      ...state,
      widgets,
      widgetMobile,
    });
  }

  @Action(SaveWidgets)
  saveWidgets(ctx: StateContext<DashboardStateModel>, { value }: SaveWidgets) {
    const isServicingEnabled = this.store.selectSnapshot(RouterSelectors.servicing);
    const wc = isServicingEnabled ? servicingWidgetConfig : widgetConfig;
    const state = ctx.getState();
    const widgets = isServicingEnabled ? state.servicingWidgets : state.widgets;

    // action dispatched when reordering widgets; reordering is enabled only for large screens
    if (value.length !== wc.default.length) {
      const defaultWidgets = widgets.length === wc.default.length ? widgets : wc.default;
      // add filtered/missing widgets back to state (in case they are removed on dashboard)
      const missingWidgets = [...defaultWidgets].filter(
        (a) => !value.find((v) => v.widget === a.widget),
      );
      let reorderedWidgets = this.regenerateWidgetsPosition(value);

      reorderedWidgets = missingWidgets.concat(reorderedWidgets);
      // order by position
      reorderedWidgets = reorderedWidgets.sort((a, b) => a.position - b.position);

      if (isServicingEnabled) {
        ctx.patchState({
          servicingWidgets: [...reorderedWidgets],
        });

        return;
      }

      ctx.patchState({
        widgets: [...reorderedWidgets],
      });

      return;
    }

    if (isServicingEnabled) {
      ctx.patchState({
        servicingWidgets: [...value],
      });

      return;
    }

    ctx.patchState({
      widgets: [...value],
    });
  }

  @Action(ResetState)
  resetState(ctx: StateContext<DashboardStateModel>) {
    ctx.patchState({ ...DashboardState.DEFAULT_STATE_VALUES });
  }

  @Action(ResetEditModeState)
  resetEditModeState(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();

    if (this.store.selectSnapshot(RouterSelectors.servicing)) {
      const servicingWidgets = state.servicingWidgets.map((widget: DashboardWidget) => ({
        ...widget,
        editMode: false,
      }));
      const servicingWidgetMobile = state.servicingWidgetMobile.map((widget: DashboardWidget) => ({
        ...widget,
        editMode: false,
      }));

      ctx.patchState({ servicingWidgets });
      ctx.patchState({ servicingWidgetMobile });

      return;
    }

    const widgets = state.widgets.map((widget: DashboardWidget) => ({
      ...widget,
      editMode: false,
    }));
    const widgetMobile = state.widgetMobile.map((widget: DashboardWidget) => ({
      ...widget,
      editMode: false,
    }));

    ctx.patchState({ widgets });
    ctx.patchState({ widgetMobile });
  }

  // function to regenerate widget positions
  private regenerateWidgetsPosition(value: DashboardWidget[]) {
    let index = 1;
    const newWidgets: DashboardWidget[] = [];
    value.forEach((widget) => {
      newWidgets.push({ ...widget, position: index });
      index++;
    });

    return newWidgets;
  }
}
