export function computePartnerLoanYield(
  netRate?: number | null,
  partnerLoanName?: string | null,
  yieldTenantSetting?: string | null,
): number {
  if (!netRate || !yieldTenantSetting || !partnerLoanName) {
    return null;
  }
  let partnerLoanYield = null;
  let partnerLoanOptions;
  try {
    partnerLoanOptions = JSON.parse(yieldTenantSetting);
  } catch (e) {
    console.error('Error parsing partnerLoanOptions', e);
    return null;
  }
  const partnerLoanYieldBase = partnerLoanOptions[partnerLoanName];
  if (partnerLoanYieldBase) {
    partnerLoanYield = partnerLoanYieldBase - netRate;
  }

  return partnerLoanYield;
}
