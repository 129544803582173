import {
  ApplicationWarningKeys,
  ApplicationConditionDocumentStatus,
  ApplicationApprovalAction,
} from '../shared/model';
import {
  ApplicationStatusType,
  PipelineApplication,
  InsuranceStatus,
  WarningActions,
  RiskFlagSeverity,
  ApplicationRiskFlagStatus,
  PriorityType,
  DecisionEngineStatus,
  RateChangeNotification,
  MCUCreateProductsResponse,
  MCUCreditCardFulfillResponse,
  MMSNotificationType,
  MMSMessageCounters,
  MMSDocumentUploadNotificationPayload,
} from '@fundmoreai/models';

export enum EventMessageType {
  DOCUMENT_UPLOADED = 'document_uploaded',
  EZIDOX_DOCUMENT_UPLOADED_WEBHOOK = 'ezidox_document_uploaded_webhook',
  EZIDOX_DOCUMENT_UPLOADED = 'ezidox_document_uploaded',
  EZIDOX_APPLICATION_CREATED = 'ezidox_application_created',
  EZIDOX_APPLICATION_UPDATED = 'ezidox_application_updated',
  EZIDOX_APPLICATION_NOTE = 'ezidox_application_note',
  EZIDOX_APPLICATION_STATUS = 'ezidox_application_status',
  EZIDOX_DOCUMENT_REVIEWED = 'ezidox_document_reviewed',
  EZIDOX_APPLICATION_CREATE_FAILED = 'ezidox_application_create_failed',
  EZIDOX_APPLICATION_UPDATE_FAILED = 'ezidox_application_update_failed',
  EZIDOX_APPLICATION_DECLINE_FAILED = 'ezidox_application_decline_failed',
  EZIDOX_APPLICANT_UPDATED = 'ezidox_applicant_updated',
  EZIDOX_APPLICANT_UPDATE_FAILED = 'ezidox_applicant_update_failed',
  EZIDOX_APPLICANT_DELETED = 'ezidox_applicant_deleted',
  EZIDOX_APPLICANT_DELETE_FAILED = 'ezidox_applicant_delete_failed',
  EZIDOX_ATTACHMENT_UPLOADED = 'ezidox_attachment_uploaded',
  EZIDOX_SHARE_FAILED = 'ezidox_share_failed',
  CHAT_MENTION = 'chat_mention',
  CHAT_EDIT = 'chat_edit',
  CHAT_DELETE = 'chat_delete',
  CHAT_MESSAGE = 'chat_message',
  INSURANCE_QUOTE_UPDATED = 'insurance_quote_updated',
  UW_AUTOMATION = 'uw_automation',
  APPLICATION_WARNINGS_UPDATED = 'application_warnings_updated',
  APPLICATION_CONDITION_DOCUMENT_COMMENT_ADDED = 'application_condition_document_comment_added',
  CONDITION_DOCUMENT_REVIEWED = 'condition_document_reviewed',
  CONDITION_DOCUMENT_UPLOADED = 'condition_document_uploaded',
  APPROVAL_REQUEST_REVIEWED = 'approval_request_reviewed',
  APPROVAL_REQUEST_CANCELED = 'approval_request_canceled',
  APPROVAL_RECOMMENDED_FOR_APPROVAL = 'approval_recommended_for_approval',
  APPROVAL_REQUESTED = 'approval_requested',
  DEFAULT_TASKS_CREATED = 'default_tasks_created',
  BULK_TASKS_ASSIGNED = 'bulk_tasks_assigned',
  TASK_ASSIGNED = 'task_assigned',
  TASK_CREATED = 'task_created',
  TASK_UPDATED = 'task_updated',
  TASK_DELETED = 'task_deleted',
  TASK_MARK_AS_COMPLETED = 'task_mark_as_completed',
  TASK_MARK_AS_NOT_COMPLETED = 'task_mark_as_not_completed',
  EQB_LOAN_UPDATE_SUCCESS_SYNC = 'eqb_loan_update_success_sync',
  EQB_LOAN_UPDATE_SUCCESS_ASYNC = 'eqb_loan_update_success_async',
  EQB_LOAN_UPDATE_FAILURE_SYNC = 'eqb_loan_update_failure_sync',
  EQB_LOAN_UPDATE_FAILURE_ASYNC = 'eqb_loan_update_failure_async',
  EQB_LOAN_DECISION_SUCCESS = 'eqb_loan_decision_success',
  EQB_LOAN_DECISION_FAILURE = 'eqb_loan_decision_failure',
  APPLICATION_ASSIGNED = 'application_assigned',
  USER_DETAILS_CHANGED = 'user_details_changed',
  DELEGATE_SET = 'delegate_set',
  APPLICATION_STAGE_CHANGED = 'application_stage_changed',
  APPLICATION_UPDATE_PUSHED = 'application_update_pushed',
  APPLICATION_CREATED = 'application_created',
  FCT_APPLICATION_UPDATED = 'fct_application_updated',
  FCT_APPLICATION_STATUS_UPDATED = 'fct_application_status_updated',
  FCT_NOTES_UPDATED = 'fct_notes_updated',
  APPLICATION_STATUS_INTEGRATION_CHANGED = 'application_status_integration_changed',
  APPLICATION_DELETED = 'application_deleted',
  APPLICATION_ARCHIVED = 'application_archived',
  APPLICATION_UNARCHIVED = 'application_unarchived',
  APPLICATION_LOCKED = 'application_locked',
  APPLICATION_UNLOCKED = 'application_unlocked',
  APPLICATION_DUPLICATE = 'application_duplicate',
  APPLICATION_RISK_FLAG_CREATED = 'application_risk_flag_created',
  APPLICATION_RISK_FLAG_RESOLVED = 'application_risk_flag_resolved',
  APPLICATION_RISK_FLAG_DELETED = 'application_risk_flag_deleted',
  APPLICATION_RISK_FLAG_MESSAGE = 'application_risk_flag_message',
  APPLICATION_RISK_FLAG_MESSAGE_DELETED = 'application_risk_flag_message_deleted',
  APPLICATION_PRIORITY_UPDATED = 'application_priority_updated',
  IDS_BLOCKLIST_SCAN_COMPLETED = 'ids_blocklist_scan_completed',
  APPLICANT_MEMBER_OPTIONS_REFRESH = 'applicant_member_options_refresh',
  APPLICANT_MEMBER_TYPE_UPDATED = 'applicant_member_type_updated',
  APPLICANT_MARKED_AS_MEMBER = 'applicant_marked_as_member',
  APPLICANT_MEMBER_ACCOUNTS_UPDATED = 'applicant_member_accounts_updated',
  APPLICATION_DECISION_ENGINE_POLLING_EXECUTED = 'application_decision_engine_polling_executed',
  DOCUMENT_REQUEST_CREATED = 'document_request_created',
  DOCUMENT_SIGNATURE_REQUEST_CREATED = 'document_signature_request_created',
  DOCUMENT_SIGNATURE_REQUEST_UPDATED = 'document_signature_request_updated',
  DOCUMENT_REQUEST_DELETED = 'document_request_deleted',
  DOCUMENT_REQUEST_NOTE_CREATED = 'document_request_note_created',
  APPLICATION_CREATE_MEMBERS = 'application_create_members',
  APPLICATION_RETRIEVE_JOINT_MEMBERSHIPS = 'application_retrieve_joint_memberships',
  PRODUCT_RATE_CHANGED = 'product_rate_changed',
  RATE_HOLD_EXPIRED = 'rate_hold_expired',
  APPLICATION_CREATE_PRODUCTS = 'application_create_products',
  APPLICATION_CREDIT_CARD_FULFILL = 'application_credit_card_fulfill',
  APPLICATION_NOTE_LIST_REFRESH = 'application_note_list_refresh',
  APPLICANT_CONNECTED_PARTY_CHECK_FINISHED = 'applicant_connected_party_check_finished',
  MMS_REQUEST_UPDATED = 'mms_request_updated',
  MMS_DOCUMENTS_UPLOADED = 'mms_documents_uploaded',
  NEW_AVA_MESSAGE = 'new_ava_message',
  APPLICATION_INCREMENTS_UPDATED = 'application_increments_updated',
}

export enum EzidoxEventType {
  DOCUMENT_UPLOADED = 'document_uploaded',
  DOCUMENT_ACCEPTED = 'document_accepted',
  DOCUMENT_REJECTED = 'document_rejected',
  DOCUMENT_IRRELEVANT = 'document_irrelevant',
  DOCUMENT_COMMENT = 'document_comment',
  APPLICATION_STATUS_UPDATED = 'application_status_updated',
  APPLICATION_CREATED = 'application_created',
  APPLICATION_NOTE_CREATED = 'application_note_created',
  APPLICATION_NOTE_UPDATED = 'application_note_updated',
  APPLICATION_NOTE_REMOVED = 'application_note_removed',
}

export enum ChannelConstants {
  CHAT = 'chat',
  USER = 'user',
  APPLICATION = 'deals',
}

export interface PubSubConfig {
  authKey: string;
  channelGroups: string[];
  expiresAt: Date;
}

export interface PubNubEvent {
  message: EventMessage;
  channel: string;
  timetoken: string | number;
  userMetadata?: object;
  actions?: {
    [type: string]: {
      [value: string]: Array<{
        uuid: string;
        actionTimetoken: string | number; // timetoken
      }>;
    };
  };
}

export interface EventMessage {
  body: NotificationBody;
  type: EventMessageType;
}

export interface NotificationBody {
  id: string;
  pubNubMessageId: string;
  applicationName: string;
  document_name?: string;
  message?: string;
  applicantId?: string;
  ezidoxStakeholderId?: string;
  content?: string;
  userId?: string;
  applicationId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  quoteId?: string;
  warnings?: ApplicationWarningKeys[];
  applicationConditionDocumentFileId?: string;
  status?: ApplicationConditionDocumentStatus;
  applicationTaskIds: string[];
  taskName: string;
  taskNames: string[];
  applicationApproval: {
    applicationApprovalId: string;
    approvalTypeName: string;
    approverFullName: string;
    requesterFullName: string;
    actorFullName: string;
    actorSameAsReviewer: boolean;
    actorSameAsRequester: boolean;
    action: ApplicationApprovalAction;
  };
  applicationDecisionEngine: { status: DecisionEngineStatus };
  applicationRiskFlag: {
    id: string;
    type: string;
    description: string;
    severity: RiskFlagSeverity;
    status: ApplicationRiskFlagStatus;
  };
  applicationRiskFlagMessage: {
    id: string;
    applicationRiskFlagId: string;
    createdById: string;
    message: string;
  };
  applicantMemberOptions: {
    applicantId: string;
    applicationId: string;
    name: string;
    surname: string;
  };
  ezidoxHookEvent: EzidoxEventType;
  ezidoxStatusString: string;
  ezidoxStatusId: number;
  multiple: boolean;
  roleId: string;
  userName: string;
  requesterName: string;
  currentStage: string;
  previousStage: string;
  authorName: string;
  stakeholderNames: string[];
  insuranceStatus?: InsuranceStatus;
  applicationStatus: ApplicationStatusType;
  fctReferenceId?: string;
  insuranceType: string;
  insuranceQuoteNumber?: string;
  originalApplicationName?: string;
  warningAction: WarningActions;
  updatedWarning: ApplicationWarningKeys;
  priority: PriorityType;
  jointMemberships: boolean | null;
  rateChange?: RateChangeNotification;
  error?: string;
  createProductsResponse?: MCUCreateProductsResponse;
  creditCardFulfillResponse?: MCUCreditCardFulfillResponse;
  mmsNotification?: {
    successful: MMSNotificationType[];
    failed: MMSNotificationType[];
    fctURN: string;
    counters: MMSMessageCounters;
  };
  mmsDocumentUploadNotification: {
    mmsDocumentUploadSuccess?: MMSDocumentUploadNotificationPayload[];
    mmsDocumentUploadFailed?: MMSDocumentUploadNotificationPayload[];
  };
  avaChatId: string;
  applicationIsServicing: boolean;
}

export interface ApplicationUserEvent {
  event: PubNubEvent;
  applications: PipelineApplication[] | undefined;
  currentApplicationId: string | undefined;
  callback: (applicationId?: string) => void;
}
