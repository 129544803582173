import { Data, Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngxs/router-plugin';
import { RouterStateModel } from './router-state.model';

export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateModel> {
  serialize(routerState: RouterStateSnapshot): RouterStateModel {
    const {
      url,
      root: { queryParams },
    } = routerState;

    let { root: route } = routerState;
    let applicationApprovalId: string | undefined;
    let approvalTypeId: string | undefined;
    let servicing: boolean | undefined;

    while (route.firstChild) {
      const sidenavOutlet = route.children.find((x) => x.outlet === 'sidenav');

      if (!applicationApprovalId && sidenavOutlet) {
        applicationApprovalId = sidenavOutlet.params.applicationApprovalId;
      }

      if (!approvalTypeId && sidenavOutlet) {
        approvalTypeId = sidenavOutlet.params.approvalTypeId;
      }

      if (route.firstChild.data.servicing) {
        servicing = true;
      }

      route = route.firstChild;
    }

    const params: Params = {
      ...route.params,
      applicationApprovalId,
      approvalTypeId,
    };

    const data: Data = {
      servicing,
    };

    return { url, params, queryParams, data };
  }
}
