import 'reflect-metadata';
import { inject, injectable } from 'tsyringe';
import { RequestedMortgageCalculation } from '@fundmoreai/models';
import { IFeeCalculator } from './fee.calculator';
import { ApplicationCalculationModel } from '../models/application-calculation.model';
import { TYPES } from '../container/types';
import { TotalLoanAmountCalculatorModel } from '../models/total-loan-amount-calculator.model';

export interface ITotalLoanAmountCalculator {
  getApplicationTotalLoanAmountWithoutPremium(): number;
  getOnlyTotalLoanAmount(): number;
}

@injectable()
export class TotalLoanAmountCalculator implements ITotalLoanAmountCalculator {
  private feeCalculator: IFeeCalculator;
  private requestedMortgages: RequestedMortgageCalculation[] | null;

  private calculatedApplicationTotalLoanAmountWithoutPremium?: number;
  #calculatedOnlyTotalLoanAmount?: number;

  constructor(
    @inject(TYPES.IFeeCalculatorToken)
    feeCalculator: IFeeCalculator,
    @inject(TotalLoanAmountCalculatorModel)
    totalLoanAmountCalculatorModel: TotalLoanAmountCalculatorModel,
  ) {
    this.feeCalculator = feeCalculator;
    this.requestedMortgages = totalLoanAmountCalculatorModel.requestedMortgages;
  }

  private computeRequestedMortgagesTotalLoanAmountWithoutPremium(): number {
    const loanAmount = this.getTotalLoanAmount(this.requestedMortgages);
    const capFees = this.feeCalculator.getCappedFees();
    return loanAmount + capFees;
  }

  private getTotalLoanAmount(
    mortgages: Pick<RequestedMortgageCalculation, 'loanAmount'>[] | null,
  ): number {
    if (!mortgages || mortgages.length === 0) {
      return 0;
    }
    return mortgages
      .filter((mortgage) => mortgage.loanAmount)
      .reduce((acc, mortgage) => acc + (mortgage.loanAmount ?? 0), 0);
  }

  public getOnlyTotalLoanAmount(): number {
    if (!this.#calculatedOnlyTotalLoanAmount) {
      this.#calculatedOnlyTotalLoanAmount = this.getTotalLoanAmount(this.requestedMortgages);
    }
    return this.#calculatedOnlyTotalLoanAmount;
  }

  public getApplicationTotalLoanAmountWithoutPremium(): number {
    if (this.calculatedApplicationTotalLoanAmountWithoutPremium === undefined) {
      this.calculatedApplicationTotalLoanAmountWithoutPremium =
        this.computeRequestedMortgagesTotalLoanAmountWithoutPremium();
    }
    return this.calculatedApplicationTotalLoanAmountWithoutPremium;
  }
}
