import { RouterState, RouterStateModel as RouterStateOuterModel } from '@ngxs/router-plugin';
import { Selector } from '@ngxs/store';
import { RouterStateModel } from './router-state.model';

export class RouterSelectors {
  @Selector([RouterState])
  static selectedApprovalTypeId({ state }: RouterStateOuterModel<RouterStateModel>) {
    return state?.params.approvalTypeId;
  }

  @Selector([RouterState])
  static selectedApplicationApprovalId({ state }: RouterStateOuterModel<RouterStateModel>) {
    return state?.params.applicationApprovalId;
  }

  @Selector([RouterState])
  static selectedDocumentTypeId({ state }: RouterStateOuterModel<RouterStateModel>) {
    return state?.params.documentTypeId;
  }

  @Selector([RouterState])
  static selectedDocumentTypeTemplateId({ state }: RouterStateOuterModel<RouterStateModel>) {
    return state?.params.documentTypeTemplateId;
  }

  @Selector([RouterState])
  static selectedRiskFlagTemplateId({ state }: RouterStateOuterModel<RouterStateModel>) {
    return state?.params.riskFlagTemplateId;
  }

  @Selector([RouterState])
  static selectedRestrictedDateId({ state }: RouterStateOuterModel<RouterStateModel>) {
    return state?.params.restrictedDateId;
  }

  @Selector([RouterState])
  static selectedTaskId({ state }: RouterStateOuterModel<RouterStateModel>) {
    return state?.params.taskId;
  }

  @Selector([RouterState])
  static servicing({ state }: RouterStateOuterModel<RouterStateModel>) {
    return state?.data.servicing;
  }
}
