import {
  Applicant,
  ConstructionApplicationItem,
  ConstructionMortgage,
  DocumentDraft,
  DrawSchedule,
  Fee,
  FinancialAsset,
  FinancialLiability,
  Mortgage,
  Property,
  PropertyOwner,
  CommitmentRequestedMortgage,
  FundmoreNarrative,
  PropertyViewModel,
  ConditionOfApprovalStatus,
  ConditionKey,
  ConditionLinkedDocumentType,
  ApplicationPurposeType,
  NetworthContext,
  FundmoreRecommendation,
  ResponsibleType,
  Summary,
} from '@fundmoreai/models';
import { ConditionKeyRecord } from 'src/app/shared/enum-records-metadata';
import { Observable } from 'rxjs';
import { CompanySettingsModel } from 'src/app/core/store/company-settings.state';
import { IndexedFees } from 'src/app/portal/fees.state';
import { Fund, Lawyer, PropertyInsurance, DMDocument } from 'src/app/shared';
import { Application, PropertyAppraisal } from 'src/app/shared/model';
import { ApplicationConditionStatus, DocumentPackageType } from './enums';
import {
  DocumentTemplate,
  DocumentTemplateOptionValue,
} from '../../manager-portal/documents-management/model';
import { Language } from 'src/app/portal/navigation/language-picker/language.const';
import { Note } from '../../application/notes/notes.model';
import { ApplicationApproval } from '../../application/approvals/application-approval.model';
import { Income } from '../../application/widgets/income/income.model';

export interface BaseDocumentContext {
  amortizationSchedule: AmortizationSchedule[];
  amountToBeAdvanced: number | null;
  appLanguage: Language | undefined;
  applicants: (Applicant & { income?: Income[] })[] | undefined;
  application: Application;
  applicationConditions: JoinedApplicationCondition[];
  approvals: ApplicationApproval[] | undefined;
  apr: number | undefined;
  assignedUsers: { name: string | undefined; surname: string | undefined; specialTitle: string }[];
  atEndOfTermValues: {
    totalInterest: number;
    totalPrincipal: number;
    outstandingBalance: number;
    totalPayment: number;
  };
  borrowers: Applicant[] | undefined;
  broker: Applicant | undefined;
  brokerFee: Fee | undefined;
  brokerName: string;
  companySettings?: CompanySettingsModel | undefined;
  conditions: string[];
  currentCondition?: JoinedApplicationCondition;
  currentDate: Date;
  dayToPay: number | undefined;
  documentLanguage: Language | undefined;
  existingAndRefinanceMortgages: Mortgage[];
  fees: Fee[];
  financialAssets: {
    total: number;
    assets: FinancialAsset[];
  };
  firstMortgage: Mortgage | undefined;
  fundmoreNarratives: FundmoreNarrative[] | undefined;
  fundmoreRecommendation: string | undefined;
  fundmoreResultNarratives: FundmoreNarrative[] | undefined;
  fundmoreScoreRecommendations: FundmoreRecommendation[] | undefined;
  fund: Fund | undefined;
  gds: number;
  gdsDisplay: string;
  guarantors: Applicant[] | undefined;
  incomes: Income[] | undefined;
  lawyers: Lawyer[];
  lenderFee: Fee | undefined;
  liabilities: {
    nonFilteredLiabilities: FinancialLiability[];
    totalBalance: number;
    totalPayment: number;
  };
  ltv: number;
  ltvDisplay: string;
  mortgage: CommitmentRequestedMortgage;
  notes: Note[];
  otherFeesWithoutBrokerAndLender: number;
  primaryApplicant: Applicant | undefined;
  primaryProperty: Property | undefined;
  primaryPropertyExistingAndRefinanceMortgages: Mortgage[];
  propertyAppraisal: PropertyAppraisal | undefined;
  requestedMortgage: Mortgage;
  requestedMortgages: MortgageExpandedForContext[];
  secondMortgage: Mortgage | undefined;
  stakeholderLawyers: Applicant[] | undefined;
  thirdMortgage: Mortgage | undefined;
  tds: number;
  tdsDisplay: string;
  totalDownPayment: string;
  totalIncome: number;
  totalPayment: number;
  shortAddressStreet: string | undefined;
  shortAddressDetails: string | undefined;
  isOnlyOneStakeholder: boolean;
  selectedConditions: string[];
  selectedStakeholder: Applicant | undefined;
  selectedProperty: Property | undefined;
  selectedLawyer: Lawyer | undefined;
  selectedStakeholderLawyer: Applicant | undefined;
  summary: Summary;
  totalCostOfBorrowing: number;
  servicingTotalCostOfBorrowing: number;
  useLiftPayment?: boolean | null;
  paymentAmountWithLift?: number | null;
  isLenderLiability?: boolean | null;
}

export interface CalvertDocumentContext extends BaseDocumentContext {
  amortizationCompoundingString: string;
  amortizationRepaymentString: string;
  applicantsRowspan: number[];
  blendedMortgageRate: number | undefined;
  calculatedAmount: number;
  charges: Charge[];
  checkCommitmentFee: boolean;
  clientFunds: string[];
  commitmentFee: Fee | undefined;
  commitmentFeeBalance: number;
  construction:
    | {
        constructionMortgage: ConstructionMortgage | undefined;
        constructionApplicationItems: ConstructionApplicationItem[];
        constructionTotalAmountAllowed: number;
        constructionTotalBudgetUsed: number;
        drawSchedules: DrawSchedule[];
      }
    | undefined;
  companyDirectorApplicants: Applicant[] | undefined;
  corporateApplicants: Applicant[] | undefined;
  dailyPerDiem: number | null;
  dailyTotalInterest: number | undefined;
  existingAndRefinanceMortgagesFirstMortgages: Mortgage[];
  indexedFees: IndexedFees;
  insuranceAmount: number | null;
  interestCalculationPeriodLongString: string;
  interestCalculationPeriodShortString: string;
  isCompany: boolean;
  isInterestOnly: boolean;
  mortgagorGroups: MortgagorGroup[];
  netAmountToBeAdvanced: number | null;
  primaryPropertyOwnersTitleAsString: string | undefined;
  properties: PropertyViewModel[];
  propertyInsurances: PropertyInsurance[];
  scheduleA:
    | {
        label: string;
        security: Property;
      }[]
    | undefined;
  securities: Property[] | undefined;
  securitiesWithMortgage: {
    estimatedValue: number;
    propertyAddress: string | null | undefined;
    legalDescription: string | undefined | null;
    mortgages: Mortgage[];
    title: PropertyOwner[];
    plan: string | null;
    block: string | null;
    lot: string | null;
    shortLegalDescription: string | undefined | null;
    CalvertMortgagePosition: string;
    propertyInsurances: PropertyInsurance[];
    externalSecurityConditions: {
      hasOtherSecurityConditions: boolean;
      otherSecurityConditions: string | undefined | null;
    };
  }[];
  tcc: number;
  totalCharges: number;
  totalCommitmentFeeAmount: number;
  totalLoanAmount: number | undefined;
  totalMortgagePayments: number | undefined;
  totalOfSecurities: { totalPriorBalance: number; totalAppraisedValue: number };
}

export interface GentaiDocumentContext extends BaseDocumentContext {
  amortizationString: string;
  bdm: { name: string | undefined; surname: string | undefined }[];
  brokerageName: string | null | undefined;
  brokerDescriptions: string[];
  brokerInfo: {
    name: string;
    address: string;
    phone: string;
  };
  brokerNotes?: string | null;
  calculatedTotalAmount: {
    bValue: number;
    cValue: number;
    cLessCosts: number;
    dValue: number;
    eValue: number;
    fValue: number;
    gValue: number;
    totalCostOfCredit: number;
  };
  coborrowers: Applicant[] | undefined;
  currentNetworth: NetworthContext;
  feesDictionary: { [x: string]: Fee[] };
  feesTotalAmount: number;
  futureNetworth: NetworthContext;
  insuranceAmount: number | null;
  interestAdjustmentAmount: number;
  interestRate: {
    rbcPrime: number;
    primePlusNumber: number;
    addToRBCPrime: number;
    interestRateDetails: string;
    prepaymentDetails: string;
  };
  isBCproperty: boolean | undefined;
  isBritishColumbiaProperty: boolean | undefined;
  mortgageBalanceAtEndOfTerm: number | null;
  netAmountToAdvance: number | null;
  networth: {
    networth: number;
    totalAssets: number;
    totalLiabilities: number;
    payoutDebt: number;
    payoutMortgageBalance: number;
  };
  outstandingBalance: number;
  partyDescriptions: string[];
  prepaymentFeeDetails: string;
  totalPaymentAsString: string;
}

export interface EQDocumentContext extends BaseDocumentContext {
  primeRate: number;
  computed: {
    totalRegularPayment: number;
  };
}

export interface GenerateDocumentsPackageItem {
  htmlRawContent: string;
  htmlTinymceContent: string;
  draftContext?: DocumentContext;
  draftKeys?: DocumentDraft[];
  tinymceChangesCount: number;
  header: string;
  footer: string;
  isSelected: boolean;
  type: DocumentPackageType;
  name: string;
  isDefault?: boolean;
  options: DocumentTemplateOptionValue;

  getContext$(documentTemplate: DocumentTemplate): Observable<DocumentContext>;
}

export interface DocumentCommitment {
  name: string;
  checked: boolean;
  type: DocumentPackageType;
  options?: DocumentTemplateOptionValue;
}

export interface Package {
  name: string;
  checked: boolean;
  documents: DocumentCommitment[];
}

export interface DocumentPackage {
  packages: Package[];
}

export interface Charge {
  chargeName: string;
  id: string;
  amount: number;
  description?: string;
}

export interface GenerateDocumentsPackageServiceBase {
  getContext$(documentTemplate: DocumentTemplate): Observable<DocumentContext>;
}

export interface DocumentTemplateFiles {
  contentPath: string;
  headerPath: string;
  footerPath: string;
}

export interface GenericPdfHtml {
  content: string;
  header: string;
  footer: string;
}

export interface ChargesModel {
  charges: Charge[];
  chargeApplicationId: string;
}

export interface AmortizationSchedule {
  paymentDueDate: string;
  interest: number;
  principal: number;
  balance: number;
  payment: number;
}

export type DocumentContext = BaseDocumentContext | GentaiDocumentContext | CalvertDocumentContext;

export interface ApplicationCondition {
  id: string;
  applicationId: string;
  conditionId: string;
  stakeholderId: string;
  status: ApplicationConditionStatus | null;
  comments?: string | null;
  createdBy: string;
  createdAt?: string;
}

export interface JoinedApplicationCondition extends ApplicationCondition {
  condition?: Condition;
}

export interface Condition {
  index: number | null;
  createdAt: string;
  dmDocument: DMDocument[] | null;
  conditionLinkedDocumentTypes: ConditionLinkedDocumentType[] | null | undefined;
  document: string | null;
  dueDays: number | null;
  id: string;
  isDefault: boolean;
  name: string;
  products: string[] | null;
  requiredStage: string | null;
  responsible: ResponsibleType | null;
  status: ConditionOfApprovalStatus | null;
  text: string;
  text_french: string;
  isCustom: boolean | null;
  applicationPurpose: ApplicationPurposeType | null;
  conditionType: string | null;
}

export interface ApplicationDocumentToDocumentType {
  applicationDocumentId: string;
  displayName: string;
  documentTypeId: string | null;
  stakeholderId: string;
}

export interface ConditionDocumentToUpload {
  id: string;
  file: File;
  category: string | DMDocument | null;
  share?: boolean;
}

export interface Mortgagor {
  namesOnTitle: (string | undefined)[];
  address: string | undefined;
}

export interface MortgagorGroup {
  mortgagors: Mortgagor[];
  labels: string[];
}

export interface MortgageExpandedForContext extends Mortgage {
  amortizationSchedule?: AmortizationSchedule[];
  apr?: number;
  atEndOfTermValues?: {
    totalInterest: number;
    totalPrincipal: number;
    outstandingBalance: number;
    totalPayment: number;
  };
  totalPayment?: number;
  ltv?: number;
  gds?: number;
  tds?: number;
}

export enum ConditionTableKey {
  NAME = 'name',
  DM_DOCUMENT = 'dmDocument',
  APPLICATION_PURPOSE = 'applicationPurpose',
  PRODUCTS = 'products',
  RESPONSIBLE = 'responsible',
  TEXT = 'text',
  TEXT_FRENCH = 'text_french',
  CONDITION_TYPE = 'conditionType',
  STAKEHOLDER = 'stakeholder',
}

export const ConditionOfApprovalTableKeyRecord: Record<ConditionTableKey, string> = {
  [ConditionTableKey.NAME]: ConditionKeyRecord[ConditionKey.NAME],
  [ConditionTableKey.DM_DOCUMENT]: ConditionKeyRecord[ConditionKey.DM_DOCUMENT],
  [ConditionTableKey.PRODUCTS]: ConditionKeyRecord[ConditionKey.PRODUCTS],
  [ConditionTableKey.RESPONSIBLE]: ConditionKeyRecord[ConditionKey.RESPONSIBLE],
  [ConditionTableKey.TEXT]: ConditionKeyRecord[ConditionKey.TEXT],
  [ConditionTableKey.TEXT_FRENCH]: ConditionKeyRecord[ConditionKey.TEXT_FRENCH],
  [ConditionTableKey.APPLICATION_PURPOSE]: ConditionKeyRecord[ConditionKey.APPLICATION_PURPOSE],
  [ConditionTableKey.CONDITION_TYPE]: ConditionKeyRecord[ConditionKey.CONDITION_TYPE],
  [ConditionTableKey.STAKEHOLDER]: $localize`Stakeholder`,
};
