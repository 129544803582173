import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApplicantCreditCardOffer,
  ApplicantCreditCardOfferDetails,
  Application,
  ContactType,
  CustomerType,
  ExpandedClientId,
  StakeholderContact,
} from '@fundmoreai/models';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Applicant } from '../shared';
import { ApplicantTypeUpdate } from './applicants.actions';

@Injectable({
  providedIn: 'root',
})
export class ApplicantsService {
  constructor(private http: HttpClient) {}

  patchApplicant(applicantId: string, applicant: Partial<Applicant>): Observable<Applicant> {
    return this.http
      .patch<Applicant>(`${environment.api_url}/applicants/${applicantId}`, applicant)
      .pipe(
        map((stakeholder) => {
          // TODO update once there's server support
          stakeholder.contacts = ApplicantsService.makeContacts(stakeholder);

          return stakeholder;
        }),
      );
  }

  patchApplicantCredit(applicantId: string, applicant: Partial<Applicant>): Observable<Applicant> {
    return this.http
      .patch<Applicant>(`${environment.api_url}/applicants/${applicantId}/credit`, applicant)
      .pipe(
        map((stakeholder) => {
          stakeholder.contacts = ApplicantsService.makeContacts(stakeholder);

          return stakeholder;
        }),
      );
  }

  patchApplicantMemberType(
    applicantId: string,
    restrictedPartyMember: boolean,
    staffMember: boolean,
    isStudent: boolean | null,
  ): Observable<Applicant> {
    return this.http.patch<Applicant>(
      `${environment.fundmore_v2_api_url}/applicant/${applicantId}`,
      {
        restrictedPartyMember,
        staffMember,
        isStudent,
      },
    );
  }

  postApplicant(applicationId: string, applicant: Partial<Applicant>): Observable<Applicant> {
    return this.http.post<Applicant>(
      `${environment.api_url}/applicants?applicationId=${applicationId}`,
      applicant,
    );
  }

  deleteApplicant(applicantId: string): Observable<void> {
    return this.http.delete<void>(`${environment.api_url}/applicants/${applicantId}`);
  }

  static makeContacts(stakeholder: Applicant): StakeholderContact[] {
    const contacts: StakeholderContact[] = [
      {
        type: ContactType.email,
        value: stakeholder.email,
      },
      {
        type: ContactType.cellPhone,
        value: stakeholder.cellPhone ?? '',
      },
      {
        type: ContactType.workPhone,
        value: stakeholder.workPhone,
      },
      {
        type: ContactType.homePhone,
        value: stakeholder.homePhone ?? '',
      },
      {
        type: ContactType.faxNumber,
        value: stakeholder.faxNumber,
      },
      {
        type: ContactType.web,
        value: stakeholder.web,
      },
    ];

    return contacts;
  }

  swapApplicantType(
    applicantId: string,
    newCustomerType: CustomerType,
    replacementApplicantId?: string,
  ) {
    return this.http.patch<ApplicantTypeUpdate>(
      `${environment.api_url}/applicants/${applicantId}/updateType`,
      { newCustomerType, replacementApplicantId },
    );
  }

  generateNewClientId(applicantId: string) {
    return this.http.patch<{ clientId: string; expandedClientId: ExpandedClientId }>(
      `${environment.api_url}/applicants/${applicantId}/generateClientId`,
      {},
    );
  }

  updateClientId(applicantId: string, clientId: string | null) {
    return this.http.patch<Applicant>(
      `${environment.api_url}/applicants/${applicantId}/updateClientId`,
      { clientId },
    );
  }

  markAsMember(applicantId: string, existingMemberOptionId: string, memberNumber: string) {
    return this.http.patch<Applicant & { Application: Application }>(
      `${environment.fundmore_v2_api_url}/applicant/${applicantId}/mark-as-member`,
      { id: existingMemberOptionId, memberNumber },
    );
  }

  markAsNewProspect(applicantId: string) {
    return this.http.patch<Applicant>(
      `${environment.fundmore_v2_api_url}/applicant/${applicantId}/mark-as-new-prospect`,
      {},
    );
  }

  refreshExistingMemberOptions(applicant: Applicant) {
    return this.http.post<Partial<Applicant>>(
      `${environment.fundmore_v2_api_url}/applicant/${applicant.id}/member-options/refresh`,
      {
        name: applicant.name,
        surname: applicant.surname,
        dob: applicant.dateOfBirth,
        sin: applicant.sin,
      },
    );
  }

  startClientScreening(applicantId: string, applicationId: string) {
    return this.http.post<Applicant>(
      `${environment.fundmore_v2_api_url}/applicant/${applicantId}/start-client-screening`,
      { applicationId },
    );
  }

  getApplicantsCreditCardOffers(applicationId: string) {
    return this.http.get<ApplicantCreditCardOffer[]>(
      `${environment.fundmore_v2_api_url}/application/${applicationId}/credit-card-offers`,
    );
  }

  updateApplicantCreditCardOffer(
    applicantId: string,
    applicantCreditCardOffer: ApplicantCreditCardOffer,
  ) {
    return this.http.patch<ApplicantCreditCardOffer>(
      `${environment.fundmore_v2_api_url}/applicant/${applicantId}/credit-card-offer/${applicantCreditCardOffer.id}`,
      {
        presentOffer: applicantCreditCardOffer.presentOffer,
        offerAccepted: applicantCreditCardOffer.offerAccepted,
      },
    );
  }

  updateApplicantCreditCardOfferDetails(
    applicantId: string,
    applicantCreditCardOfferDetails: ApplicantCreditCardOfferDetails,
  ) {
    return this.http.patch<ApplicantCreditCardOffer>(
      `${environment.fundmore_v2_api_url}/applicant/${applicantId}/credit-card-offer-details/${applicantCreditCardOfferDetails.id}`,
      {
        selected: applicantCreditCardOfferDetails.selected,
        amountToRequest: applicantCreditCardOfferDetails.amountToRequest,
      },
    );
  }

  createMembershipIds(applicationId: string) {
    return this.http.post<Partial<Applicant>[] | null>(
      `${environment.fundmore_v2_api_url}/applicant/${applicationId}/onboarding`,
      {},
    );
  }

  retrieveJointMembershipIds(applicationId: string) {
    return this.http.post<Partial<Applicant>[] | null>(
      `${environment.fundmore_v2_api_url}/applicant/${applicationId}/jointMemberships`,
      {},
    );
  }
}
