import { ApplicationStage, CustomerType } from '@fundmoreai/models';
import { Application, Mortgage, Property } from '../../../../shared';

interface ManageDuplicateApplicant {
  name: string;
  surname: string;
  customerType?: CustomerType;
  isPrimary: boolean;
}

interface DuplicateApplicationApplicant {
  name: string;
  surname: string;
  customerType?: CustomerType;
  isPrimary?: boolean;
  email?: string;
}

export interface ManageDuplicateApplicationViewModel {
  id: string;
  applicationDate?: string | null;
  createdAt: string;
  currentStage?: ApplicationStage | null;
  updatedAt: string;
  source: string | null;
  primaryApplicant: ManageDuplicateApplicant;
  coborrowers?: ManageDuplicateApplicant[];
  brokers?: ManageDuplicateApplicant[];
  underwriters: { userId: string }[];
  requestedMortgage?: Mortgage;
  primaryProperty: Property;
}

export interface DuplicateApplication {
  id?: string;
  createdAt: string;
  source: string;
  primaryApplicant: DuplicateApplicationApplicant;
  brokers?: DuplicateApplicationApplicant[];
  underwriters: {
    userId: string;
    User: {
      firstName: string;
      lastName: string;
      email: string;
    };
  }[];
  requestedMortgage: { purpose: string | null; loanNumber?: string };
  primaryProperty: { propertyAddress?: string | null };
}

export interface ManageDuplicateUserAccount
  extends Omit<ManageDuplicateApplicationViewModel, 'underwriters'> {
  underwriters: { userId: string; firstName: string | undefined; lastName: string | undefined }[];
}

export interface ApplicationAction {
  id: string;
  action: string;
  payload?: Partial<Application>;
}

export enum DuplicateOptions {
  KEEP = 'Keep',
  ARCHIVE = 'Archive',
  DECLINE = 'Decline',
  DELETE = 'Delete',
}

export const DuplicateOptionsValues: Record<DuplicateOptions, string> = {
  [DuplicateOptions.ARCHIVE]: $localize`:@@DuplicateOptions.ARCHIVE:Archive`,
  [DuplicateOptions.DELETE]: $localize`:@@DuplicateOptions.DELETE:Delete`,
  [DuplicateOptions.KEEP]: $localize`:@@DuplicateOptions.Keep:Keep`,
  [DuplicateOptions.DECLINE]: $localize`:@@DuplicateOptions.Decline:Decline`,
};

export enum NotificationsOptions {
  THERE_ARE_NO_DUPLICATES = 'There are no duplicates',
  I_WILL_MANAGE_IT_LATER = 'I will manage it later',
  OTHER = 'Other',
}

export const NotificationsOptionsValues: Record<NotificationsOptions, string> = {
  // eslint-disable-next-line max-len
  [NotificationsOptions.THERE_ARE_NO_DUPLICATES]: $localize`:@@NotificationsOptions.THERE_ARE_NO_DUPLICATES:There are no duplicates`,
  // eslint-disable-next-line max-len
  [NotificationsOptions.I_WILL_MANAGE_IT_LATER]: $localize`:@@NotificationsOptions.I_WILL_MANAGE_IT_LATER:I will manage it later`,
  [NotificationsOptions.OTHER]: $localize`:@@NotificationsOptions.OTHER:Other`,
};

export interface ApplicationActionsHolder {
  // key will be the id of the application
  [key: string]: {
    action: string;
    payload?: Partial<Application>;
  };
}

export interface DuplicatesEmail {
  reason: string;
  comment: string;
  name: string;
  surname: string;
  address?: string | null;
}
