/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApplicationStatusType,
  DateRangeVacation,
  Application,
  DocumentManagementType,
  InsurerType,
  Summary,
  NetworthContext,
  ExternalAuthSystemType,
  OpenDocumentsStrategy,
  OneTimeLinkGenerationStrategy,
  FundmoreScoreOutput,
  ApplicationStage,
  FinancialLiabilityKey,
  FinancialLiabilitySource,
  AgentContactType,
  AgentContact,
} from '@fundmoreai/models';

import { Role } from '../features/shared/user';

export * from '@fundmoreai/models';

export interface ApplicationDolphinIntegration {
  databaseCode: string;
  createdAt: Date;
  updatedAt: Date;
  dolphinServicingData: any;
}

export interface ApplicationApprovedContent {
  documentId: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ApplicationDeclinedContent {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ApplicationPendingContent {}

export interface ApplicationStatus {
  applicationId: string;
  status: ApplicationStatusType;
  underwriterId: string | undefined;
  lenderNotes: string | undefined;
  content:
    | ApplicationApprovedContent
    | ApplicationDeclinedContent
    | ApplicationPendingContent
    | undefined;
  isCommitted: boolean;
  isCreditCardFulfilled: boolean;
  isAwaitingDocs: boolean;
  updatedAt: string;
}

export interface ApplicationAssignedRoles {
  [key: string]: UserAccount[];
}

// Database User
export interface User {
  externalAuthSystemId: string;
  ezidoxCollectorId: string;
  externalAuthSystemType: ExternalAuthSystemType | undefined;
  id: string;
  roles: Role[];
  superAdmin: boolean;
  email: string;
  lastName: string | undefined;
  firstName: string | undefined;
  phoneNumber: string;
  displayName?: string;
  dateRangeVacation?: DateRangeVacation;
  isOutOfOffice?: boolean;
  loanAmount: number | undefined;
  unlimitedLoanAmount: boolean | undefined;
  isActive: boolean;
  directManager?: string;
  externalEmailSequenceNumber?: number;
  profileImageKey?: string;
  unlimitedBridgeSecured?: boolean;
  unlimitedMortgageSecured?: boolean;
  unlimitedNonMortgageSecured?: boolean;
  unlimitedTotalConnection?: boolean;
  maxBridgeSecured?: number;
  maxMortgageSecured?: number;
  maxNonMortgageSecured?: number;
  maxTotalConnection?: number;
}

// Cognito User
export interface UserData {
  email: string;
  enabled: boolean;
  lastName: string | undefined;
  firstName: string | undefined;
  phoneNumber: string;
  status: string;
  profileImageUrl: string | null;
  phoneNumberVerified?: boolean;
  emailVerified?: boolean;
  preferredMfaSetting?: MFAOptions;
  userMFASettingList?: MFAOptions[];
}
export type MFAOptions = 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA';
export interface Tenant {
  id: string;
  name: string;
  code: string;
  settings: TenantSettingsConfig;
}
export interface UserTenant {
  id: string;
  name: string;
  code: string;
}
export interface TenantSettingsConfig {
  dolphinRead: string;
  dolphinServicing: string;
  intellifiServicing: string;
  hasCustomScore: string;
  reportingModule: string;
  enableConstructionModule?: string;
  enableFundmoreScore?: string;
  enable5Cs?: string;
  flipModuleEnabled?: string;
  equifaxEnabled?: string;
  sagenEnabled?: string;
  cmhcEnabled?: string;
  cgEnabled?: string;
  demo?: string;
  enableAutomaticAssignment: string;
  enableExpandedLendingLimits?: string;
  enableAdvancedAutomaticAssignment?: string;
  delegateRemovalEnabled?: string;
  automaticCompoundingEnabled?: string;
  disabledPropertyDetailsTabs?: string;
  enableCloneDeal?: string;
  fnfEnabled?: string;
  fctEnabled?: string;
  eqMapsEnabled?: string;
  fctIntegrationEnabled?: string;
  optionalFields?: string;
  partnerLoanOptions?: string;
  restrictCBLiabilityEditing?: string;
  applyCreditReportsOnIngestion?: string;
  documentManagementType?: DocumentManagementType;
  dmOpenDocumentsStrategy?: OpenDocumentsStrategy;
  dmOneTimeLinkGenerationStrategy?: OneTimeLinkGenerationStrategy;
  blockConditionalApprovalBasedOnDLA?: string;
  blockConditionalApprovalWhenUnresolvedApprovals?: string;
  blockConditionalApprovalWhenUnresolvedRiskFlags?: string;
  blockSendToServicingWhenUnresolvedApprovals?: string;
  clientIdGenerationEnabled?: string;
  timezone?: string;
  enableOOOReplacementAutomation?: string;
  createCollectorAccount?: string;
  blockApplyingProductsWhenWarnings?: string;
  checkProductApplyOnStageTransitionEnabled?: string;
  insuranceAutomationEnabled?: string;
  helpRedirectLink?: string;
  automaticArchivingSettings?: string;
  assigneeDLALimitEnabled?: string;
  automaticLoanAmountCalculationDisabled?: string;
  aiFeatures?: string;
  commitmentExpiryDateOffset?: number;
  additionalSecuritiesDisabled?: string;
  enableFraudIq?: string;
  memberIdIntegrationEnabled?: string;
  decisionEngineIntegrationEnabled?: string;
  enableBlocklistScan?: string;
  locCalculatorParameters?: string;
  ficoScoreMappingOverride?: { [key: string]: number };
  iqDocumentRequestsEnabled?: string;
  paymentFrequencyByRateType?: string;
  condoFeesPercentageByOccupancy?: { [key: string]: number };
  decisionEngineSubmitStageBasedBehaviour?: string;
  isPrimaryResidenceEnabled?: string;
  purposeCodeByApplicationPurpose?: string;
  enableBlanket?: string;
  iadByFpdEnabled?: string;
  enableProductStatus?: string;
  blockApplicationActionsStage?: string;
  incrementsEnabled?: string;
  incrementsAutomationEnabled?: string;
  decrementsEnabled?: string;
  enableProductDefaultValueOverride?: string;
  rateChangeNotificationsEnabled?: string;
  enableClamavFileScanning?: string;
  discretionEnabled?: string;
  signPaymentChangeFormTaskAutomationEnabled?: string;
  reissueCOBTaskAutomationEnabled?: string;
  blockMovingWithoutApproveStartingWithStage?: string;
  excludeExtraPaymentInTerm?: string;
  incrementsParameters?: string;
  isServicingEnabled?: string;
  preventManualAgentCreation?: string;
  fctMmsIntegrationEnabled?: string;
  isBlendedRateOnMCUEnabled?: string;
  // enabled when missing, intended to be used to enable the old streetview component
  googleMapsEmbedded?: string;
  isAVAEnabled?: string;
  takeFullFirstPayment?: string;
  otherRateConfigurations?: string;
  widgetViewPermissions?: string;
  ltiSettings?: string;
}

export interface RestrictCBLiabilityEditing {
  overrideFields: FinancialLiabilityKey[];
  readonlySources: FinancialLiabilitySource[];
  allowDelete: boolean;
}

export interface UserAccount {
  user: User;
  userData: UserData;
  tenant: UserTenant;
}

export interface ApplicationVerification {
  id: string;
  tenantId: string;
  applicationId: string;
  applicantId: string;
  field: string;
  source: string;
  createdAt: string;
  updatedAt: string;
  meta: {
    document: {
      name: string;
      category: string;
    };
    confidence: string;
  };
}

export interface ApplicationNetworth extends Application {
  assets: any[];
  assetType: string;
  assetDescription: string;
  assetValue: number;
  property: any[];
  propertyAddress: string;
  propertyLoanAmount: number;
  propertyMonthlyPayment: number;
  propertyValue: number;
}

export interface ApplicationCredit extends Application {
  liabilities: any[];
  credit: any[];
  score: number;
  creditType: string;
  creditDescription: string;
  creditValue: number;
  creditBalance: number;
  creditPayment: number;
  summary: string[];
  activeTradelines: any[];
  collections: any[];
  crRT: any[];
  paymentStatus: string;
  paymentStatusDate: string;
}

export interface ApplicationStages {
  currentStage: ApplicationStage;
  previousStage: ApplicationStage;
}

export interface EzidoxDocumentTemplate {
  Id: number;
  Name: string;
  Description: string;
}

export interface Insurance {
  insurancePremium: number;
  insuranceAmount: number;
}

export interface Insurer {
  id: string;
  name: string;
}

export interface Fund {
  id: string;
  tenantId: string;
  shortName: string;
  longName: string;
  isDefault: boolean;
}

export interface InsuranceQuote {
  insurerName: InsurerType;
  insuranceNumber: string;
  insurancePremium: number;
  productName: string;
  conditions: string[];
  propertyValue: number;
}

export interface StandardRatesModel {
  bocConventionalMortgage5Year?: number;
  primeRate?: number;
}

export interface HealthCheckResponseDb {
  host: string;
  canAuthenticate: string;
}

export interface HealthCheckResponseBody {
  db: HealthCheckResponseDb;
}

export interface HealthCheckResponse {
  body: HealthCheckResponseBody;
}

export interface TenantDeclineReason {
  id: string;
  type: string;
  reason: string;
  isDeleted?: boolean;
}

export interface DocumentProductDetails {
  documentsToBeCollectedId?: number;
}

export type MortgageClassificationOptions = undefined | { [key: string]: string };

export interface NetWorthOutput {
  currentNetWorth: NetworthContext;
  futureNetWorth: NetworthContext;
}

export interface ApplicationComputedData {
  id: string;
  applicationId: string;
  calculation: Summary;
  score: FundmoreScoreOutput;
  netWorth: NetWorthOutput;
}

export interface LockReason {
  id: string;
  type: string;
  reason: string;
  deletedAt?: string;
  isDefault: boolean;
}

export interface LockHistory {
  reasonId: string;
  comment: string;
  createdAt: string;
}
