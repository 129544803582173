import { MortgageComputedData } from '@fundmoreai/models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MortgageComputedDataService {
  constructor(private http: HttpClient) {}

  putMortgageComputedData(
    mortgageId: string,
    mortgageComputedData: MortgageComputedData,
  ): Observable<MortgageComputedData> {
    return this.http.put<MortgageComputedData>(
      `${environment.fundmore_v2_api_url}/mortgages/${mortgageId}/computed-data`,
      mortgageComputedData,
    );
  }
}
