/* eslint-disable max-classes-per-file */
export interface MMSContact {
  name?: MMSIndividualName | MMSCompanyName; //required
  communication: MMSCommunication;
}

export class MMSIndividualName {
  firstName?: string; //required
  middleName?: string;
  lastName?: string; //required
  companyName?: string;

  static isMMSIndividualName(obj: any): obj is MMSIndividualName {
    return obj.firstName !== undefined && obj.lastName !== undefined;
  }
}

export class MMSCompanyName {
  companyName?: string; //required

  static isMMSCompanyName(obj: any): obj is MMSCompanyName {
    return obj.companyName !== undefined;
  }
}

export interface MMSCommunication {
  phone1?: string;
  phone2?: string;
  extension?: string;
  fax?: string;
  Email?: string;
}
