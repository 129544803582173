import { ApplicationKey } from '../application';
import { MortgageKey } from '../mortgage';

enum ServicingTypeFieldsEnum {
  EFFECTIVE_DATE = ApplicationKey.EFFECTIVE_DATE,
  SERVICING_TYPE = ApplicationKey.SERVICING_TYPE,
  PROJECTED_BALANCE = MortgageKey.PROJECTED_BALANCE,
  NEXT_PAYMENT_DATE = MortgageKey.NEXT_PAYMENT_DATE,
  ORIGINAL_AMORTIZATION = MortgageKey.ORIGINAL_AMORTIZATION,
  PROPERTY_TAX_AMOUNT = MortgageKey.PROPERTY_TAX_AMOUNT,
  PREPAYMENT_OPTIONS = MortgageKey.PREPAYMENT_OPTIONS,
  NEW_MATURITY_DATE = MortgageKey.NEW_MATURITY_DATE,
}

export const ServicingTypeFields = [...Object.values(ServicingTypeFieldsEnum)];
